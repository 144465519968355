import { DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
import { Notification as Notify } from "@progress/kendo-react-notification";
import { Error, Hint } from "@progress/kendo-react-labels";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import moment from "moment";
 import React, { useRef } from 'react';
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import {
  trainerItemRender,
  trainerValueRender,
} from "components/Trainers/TrainerRenderItems";
import {
  CurrencyFormat,
  checkHasTaxEnable,
  checkIfUserCanAddBankAccount,
  getFullImageUrl,
} from "utils";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  scheduleItemRender,
  scheduleValueRender,
} from "components/schedule/ScheduleRenderItems";
import withNotification from "components/Hoc/withNotification";
import LocationDD from "components/class/LocationDD";
import { SelectedLocationType } from "components/class/ClassTypes";
import useQuickCheckout from "./QuickCheckout.hook";
import { NumericTextBox, Switch } from "@progress/kendo-react-inputs";
import {
  PaymentItemRender,
  PaymentValueRender,
} from "components/Payment/PaymentRenderItems";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { TooltipContentPackageService } from "components/checkout/OrderSummary";
import PaymentModalCreation from "components/PaymentCheckout/PaymentModalCreation";

const QuickCheckout = (props: any) => {


  const {
    selectedItems,
    toggleAvailibilityDialog,
    unAvailableList,
    submitBtnLoading,
    handleCheckout,
    toggleApptDialog,
    apptModified,
    selectedAppt,
    maximum,
    handleChangeAppt,
    providerList,
    trainerfilterChange,
    modified,
    addApptLoading,
    handleAddAppointment,
    setRenewConfirmDialog,
    multipleSlots,
    handleDeleteSlot,
    loading,
    handleChange,
    AllClientsList,
    subClient,
    availServices,
    disableServiceDropdown,
    filterChange,
    Attributes,
    handleUpdateLocation,
    availSchedules,
    availOriginalSchedules,
    validateBtnLoading,
    submit,
    handleValidation,
    handleMultipleSignoff,
    unAvailableListDialog,
    renewConfirmDialog,
    customDialog,
    handleCancelCustom,
    customService,
    handleSaveCustom,
    handleChangeCustomService,
    customLoading,
    submitCustom,
    selectedPaymentProfile,
    paymentProfiles,
    Taxes,
    TotalTaxAmount,
    handleCustomServicePurchase,
    handleModifyCustomService,
    onCardOrBankAdd,
    PaymentMethodItems,
    showPaymentDialog,
    handleClosePaymethodDialog,
    addNewItem,
    handleSuccessClosePaymethodDialog,
    redirection
  } = useQuickCheckout(props);
  const h5Ref = useRef(null);
  const PaymethodDialog = () => {
    return (
      <>
        <Dialog
          className="bz-addCard-dialog"
          title={`${
            checkIfUserCanAddBankAccount() ? "Add Bank or Card" : "Add Card"
          }`}
          onClose={() => handleClosePaymethodDialog()}
          style={{ zIndex: 9999 }}
        >
          <PaymentModalCreation
            staffPage={true}
            addNewItem={addNewItem}
            handleClosePaymethodDialog={handleClosePaymethodDialog}
            handleSuccessClosePaymethodDialog={
              handleSuccessClosePaymethodDialog
            }
            UserMemberId={props?.userData?.UserMemberId}
          />
        </Dialog>
      </>
    );
  };
  const renderUnAvailableListDialog = () => {
    return (
      <Dialog
        className="bz-mybookings-dialog-box bz-custom-content-height"
        title={"Confirm Quick Checkout"}
        onClose={toggleAvailibilityDialog}
        width={440}
        // height={400}
      >
        {unAvailableList.length === 0 ? (
          <div className={"bz-no-upcoming-appoinments text-muted"}>
            There is no other appointment scheduled with the selected sign off
            date.
          </div>
        ) : (
          <>
            <div className={""}>
              {`There is another appointment already scheduled with the selected sign off date for ${selectedItems?.provider?.FullName}, do you still want to continue?`}
            </div>
          </>
        )}
        <DialogActionsBar>
          <Button
            onClick={toggleAvailibilityDialog}
            disabled={submitBtnLoading}
          >
            {"Cancel"}
          </Button>
          <BuzopsButton
            loading={submitBtnLoading}
            disabled={submitBtnLoading}
            label={
              unAvailableList.length === 0 ? "Checkout" : "Checkout Anyway"
            }
            onClick={() => handleCheckout()}
          />
        </DialogActionsBar>
      </Dialog>
    );
  };

  const ShowExistingAppointments = () => {
    let schedulesDropdown = availSchedules;
    if (multipleSlots?.length > 0) {
      const schedulesList = multipleSlots?.map((i: any) => {
        return i?.PreviousSchedule?.ScheduleId;
      });
      schedulesDropdown = availSchedules?.map((i: any) => {
        if (schedulesList.includes(i.ScheduleId)) {
          return { ...i, disabled: true };
        }
        return {
          ...i,
          disabled: false,
        };
      });
    }

    const PickedSchedules = schedulesDropdown?.filter((i: any) => i?.disabled);
    return (
      <div className="mb-3">
        <div className={"selected-slot-header"}>
          <div>
            {" "}
            <h5 className="card-title mb-0">Existing Schedules</h5>{" "}
            <span className="small text-muted">
              Number of picked schedules: &nbsp;
              {PickedSchedules?.length}
            </span>
          </div>
        </div>
        <div className="bz-selected-appt-quick-check" style={{maxWidth:'280px'}}>
          {ExistingSlotsList(schedulesDropdown, selectedAppt?.PreviousSchedule)}
        </div>
      </div>
    );
  };
  const AddAppointmentsDialog = () => {
    let showPickSchedules = false;
    let schedulesDropdown = [];
    if (
      selectedItems?.service?.PrivateServiceInstanceId !== 0 &&
      selectedItems?.service?.HasPaidSchedules &&
      selectedItems?.service?.SessionAvailableToSchedule <= 0 &&
      availOriginalSchedules?.length > 1
    ) {
      showPickSchedules = true;
      schedulesDropdown = availSchedules;
      if (multipleSlots?.length > 0) {
        const schedulesList = multipleSlots?.map((i: any) => {
          return i?.PreviousSchedule?.ScheduleId;
        });
        schedulesDropdown = availSchedules?.filter((i: any) => {
          if (schedulesList.includes(i.ScheduleId)) {
            return false;
          }
          return true;
        });
      }
    }
    return (
      <div>
        
        <div className="bz-content-text-row align-items-start bz-notify-row">
          <div className="bz-content-title text-right">
            Sign Off Date
            <span className="required-asterisk">*</span>
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body main-datetimepicker">
            <DateTimePicker
              value={selectedAppt?.signOffDate}
              max={maximum}
              adaptive={true}
              size={"small"}
              width={"100%"}
              onChange={(e) => handleChangeAppt(e.target.value, "signOffDate")}
            />
          </div>
        </div>
        <div className="bz-content-text-row bz-notify-row">
          <div className="bz-content-title text-right">
            Provider<span className="required-asterisk">*</span>
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body bz-pickschedule-blk">
            <DropDownList
              data={providerList}
              textField="FullName"
              dataItemKey="UserId"
              className={"customDropdown"}
              valueRender={(
                el: React.ReactElement<HTMLSpanElement>,
                value: any
              ) => trainerValueRender(el, value, selectedAppt?.provider)}
              itemRender={(
                li: React.ReactElement<HTMLLIElement>,
                itemProps: ListItemProps
              ) => trainerItemRender(li, itemProps, selectedAppt?.provider)}
              onChange={(e) => handleChangeAppt(e.target.value, "provider")}
              filterable={true}
              style={{ width: "100%" }}
              onFilterChange={(e) => trainerfilterChange(e)}
            />
            {apptModified && !selectedAppt?.provider && (
              <Error>{"Please select Provider"}</Error>
            )}
          </div>
        </div>
        <div className="bz-content-text-row align-items-start bz-notify-row">
          <div className="bz-content-title text-right">Notes</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            <textarea
              className="form-control"
              onChange={(e) => handleChangeAppt(e.target.value, "notes")}
              value={selectedAppt?.notes}
            ></textarea>
          </div>
        </div>
        <div className="bz-content-text-row align-items-start bz-notify-row">
          <div className="bz-content-title text-right"></div>
          <span className="bz-colon text-center"></span>
          <div className="bz-content-body">
            <div className="">
              <Button
                onClick={toggleApptDialog}
                disabled={addApptLoading || submitBtnLoading}
              >
                {"Reset"}
              </Button>
              <BuzopsButton
                loading={addApptLoading}
                disabled={addApptLoading || submitBtnLoading}
                label={"Add"}
                onClick={() => {
                   handleAddAppointment();
        
                   window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const showErrorMessage = (key: any, mainKey: any) => {
    if (
      key === "schedulePicked" &&
      selectedItems?.service?.HasPaidSchedules &&
      selectedItems?.service?.SessionAvailableToSchedule <= 0 &&
      !selectedItems[key]
    ) {
      return <Error>{`${mainKey} is required`}</Error>;
    } else if (!selectedItems[key] || selectedItems[key].length <= 0) {
      return <Error>{`${mainKey} is required`}</Error>;
    }
    return <></>;
  };

  const renderRenewConfirmDialog = () => {
    return (
      <Dialog
        title={"Please confirm"}
        onClose={() => setRenewConfirmDialog(false)}
      >
        <p style={{ margin: "25px", textAlign: "center", width: "350px" }}>
          {`This is scheduled to auto-renew the payment in the amount of [${CurrencyFormat(
            selectedItems?.service?.PackageCost || 0
          )}]. Would you like to auto-renew this service?`}
        </p>
        <DialogActionsBar>
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            onClick={() => handleCheckout(false)}
          >
            Cancel, <small>turn off auto-renew</small>
          </button>
          <Button primary onClick={() => handleCheckout(false, true)}>
            Ok, <small>please charge this client</small>
          </Button>
        </DialogActionsBar>
      </Dialog>
    );
  };
  const renderCustomService = () => {
    return (
      <div className="">
        <div style={{ width: "100%" }} className="bz-add-bank">
          <div className="">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <NumericTextBox
                  min={0}
                  id={"NumberOfSessions"}
                  name={"NumberOfSessions"}
                  placeholder={"No. of Appointments"}
                  className="k-dropdown-width-100"
                  label={"No. of Appointments"}
                  value={customService?.NumberOfSessions}
                  width={"100%"}
                  onChange={(e: any) =>
                    handleChangeCustomService(
                      e.target.value,
                      "NumberOfSessions"
                    )
                  }
                />
                {submitCustom && customService?.NumberOfSessions <= 0 && (
                  <Error>{"Please enter minimum one Appointment"}</Error>
                )}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <NumericTextBox
                  min={0}
                  max={120}
                  width={"100%"}
                  value={customService?.DurationInMinutes}
                  id={"DurationInMinutes"}
                  name={"DurationInMinutes"}
                  placeholder={"Duration"}
                  className="k-dropdown-width-100"
                  label={"Duration"}
                  onChange={(e: any) =>
                    handleChangeCustomService(
                      e.target.value,
                      "DurationInMinutes"
                    )
                  }
                />
                <Hint>
                  <span className="form-text text-muted">
                    Max duration is 120 minutes
                  </span>
                </Hint>
                {submitCustom &&
                  (customService?.DurationInMinutes <= 0 ||
                    customService?.DurationInMinutes > 120) && (
                    <Error>{"Please enter valid Duration"}</Error>
                  )}
              </div>
            </div>
            <div className="row">
              {customLoading ? (
                <BuzopsLoader type="list" />
              ) : (
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <NumericTextBox
                    min={0}
                    id={"Rate"}
                    name={"Rate"}
                    placeholder={"Per Appointment Rate"}
                    className="k-dropdown-width-100"
                    label={"Per Appointment Rate"}
                    value={customService?.Rate}
                    width={"100%"}
                    format={"c2"}
                    onChange={(e: any) =>
                      handleChangeCustomService(e.target.value, "Rate")
                    }
                  />
                  {customService?.NumberOfSessions > 0 ? (
                    <Hint>
                      <span className="form-text text-muted">
                        {`Estimated Per Appointment Rate is ${CurrencyFormat(
                          customService?.OriginalRate
                        )}`}
                      </span>
                    </Hint>
                  ) : null}
                  {submitCustom && customService?.Rate <= 0 && (
                    <Error>{"Please enter Per Appointment Rate"}</Error>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="d-flex pt-2">
            <Button
              type={"reset"}
              onClick={() => handleCancelCustom()}
              className="ml-0"
            >
              Cancel
            </Button>
            <BuzopsButton
              primary
              label={"Continue"}
              type={"submit"}
              onClick={() => handleSaveCustom()}
            />
          </div>
        </div>
      </div>
    );
  };

  const selectedSlotList = () => {
    // console.log(multipleSlots,'multipleSlots')
    return multipleSlots?.map((item: any, index: number) => {
      const className = item.IsAvailable ? "available" : "not-available";
      let description = "Available";
      if (!item.IsAvailable) {
        description = item.Description;
      }
      const notes = item?.notes;
      return (
        <div className="card bz-shadow bz-border-blue bz-upcoming-apponments-sm-card mb-3">
          <div className="card-body py-2">
            <div className="media pr-4">
              <div className="media-body">
                <div style={{ lineHeight: 1.2 }}>
                  <div>{item?.provider?.FullName}</div>
                  <span className="text-muted mb-0">
                    {" "}
                    {moment(item.signOffDate).format("MM/DD/YYYY")}{" "}
                    {moment(item?.signOffDate).format("h:mm A")}
                  </span>
                  <div className="mt-0 mb-0">
                    <small className={``}>{notes}</small>
                  </div>
                </div>
              </div>
            </div>
            <span
              className="bz-close-icon"
              role={"button"}
              onClick={() => handleDeleteSlot(index)}
            >
              <i className="far fa-trash-alt"></i>
            </span>
          </div>
        </div>
      );
    });
  };
  const ExistingSlotsList = (slots: any, selectedApptVal: any) => {
    return slots?.map((item: any, index: number) => {
      let selectedVal = false;
      if (selectedApptVal && selectedApptVal?.ScheduleId === item?.ScheduleId) {
        selectedVal = true;
      }

      return (
        <div
          className={`card bz-shadow  bz-upcoming-apponments-sm-card mb-3 ${item?.disabled ? "bz-border-grey":"bz-border-blue"}`}
          style={{
            fontSize: "0.65rem",
            backgroundColor: selectedVal ? "#BBF0FB" : item?.disabled ? "#F5F6F7": "inherit",
            opacity: item?.disabled ? "0.5" : "1",
          }}
          onClick={() => handleChangeAppt(item, "PreviousSchedule")}
        >
          <div className="card-body py-2">
            <div className={"row align-items-center w-100 test-1"}>
              <div className={"col-3 text-center "}>
                {item?.TrainerPhoto ? (
                  <img
                    style={{ borderRadius: "20px" }}
                    width={38}
                    alt="TrainerPhoto"
                    src={getFullImageUrl(item?.TrainerPhoto)}
                  />
                ) : (
                  <img width={38} src="/images/user.png" />
                )}
              </div>
              <div className={"col-9 pl-2 pr-0"}>
                <span key={1} className={""}>
                  <p className="mb-0">{`${item?.TrainerName}`}</p>
                  <span className="d-block">{item?.ShortFromDate}</span>
                  <span>{`${item?.ScheduleName}`}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <h5 className="mb-3 bz_fs_1_5">
        <button
          className="btn btn-primary bz-back-btn btn-sm"
          onClick={() => props?.handleSelectionPage("quickCheckout", false)}
        >
          <i className="fas fa-arrow-left"></i>
        </button>
        &nbsp;Quick Checkout
      </h5>
      <hr />
      {loading ? (
        <>
          <BuzopsLoader type="list" />
        </>
      ) : (
        <>
          <div className="bz-subsection-row bz-plan-overview bz-addon-cancellation">
            <div className="left-section1" style={{ width: "50%" }}>
              <div className="bz-mb-2_5rem">
                <div className="bz-content-text-row bz-notify-row">
                  <div className="bz-content-title text-right">
                    Client<span className="required-asterisk">*</span>
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <DropDownList
                      key={selectedItems?.userData?.UserMemberId}
                      data={AllClientsList}
                      textField="FullName"
                      dataItemKey="UserMemberId"
                      defaultValue={{ UserMemberId: subClient }}
                      value={selectedItems?.userData}
                      onChange={(e) =>
                        handleChange(e.target.value, "userMemberId")
                      }
                      disabled={props?.EntityId && props?.EntityId !== null}
                      style={{ width: "100%" }}
                    />
                    {modified && showErrorMessage("userData", "Client")}
                  </div>
                </div>
                <div className="bz-content-text-row bz-notify-row">
                  <div className="bz-content-title text-right">
                    One-on-One<span className="required-asterisk">*</span>
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body payment-profile">
                    <div>
                      <DropDownList
                        data={availServices}
                        textField="Name"
                        dataItemKey="PrivateServiceInstanceId"
                        value={
                          selectedItems?.service || {
                            Name: "Select Service",
                            PrivateServiceInstanceId: -1,
                          }
                        }
                        onChange={(e) =>
                          handleChange(e.target.value, "service")
                        }
                        disabled={disableServiceDropdown}
                        filterable={true}
                        style={{ width: "100%" }}
                        onFilterChange={(e) => filterChange(e)}
                        className={"customDropdown"}
                      />
                    </div>
                    <div>
                    </div>
                    {selectedItems?.service?.PrivateServiceInstanceId === 0 &&
                      selectedItems?.service?.Configured && (
                        <div
                          onClick={() => handleModifyCustomService()}
                          className="d-flex btn btn-link modify-custom-service"
                        >
                          Modify New Custom One-on-One
                        </div>
                      )}
                    {modified && showErrorMessage("service", "One-on-One")}
                  </div>
                </div>
                {selectedItems?.service?.PrivateServiceInstanceId === 0 &&
                  selectedItems?.service?.Configured && (
                    <>
                      <div className="bz-content-text-row bz-notify-row">
                        <div className="bz-content-title text-right"></div>
                        <span className="bz-colon text-center"></span>
                        <div className="bz-content-body">
                          <div className="custom-service-item">
                            <div className="section-3">
                              <span className={"buz-font-600"}>
                                {"Appointments"}
                              </span>
                              <span>{": "}</span>
                              <span>
                                {selectedItems?.service?.NumberOfSessions}
                              </span>
                            </div>
                            <div className="section-5">
                              <span className={"buz-font-600"}>
                                {"Appointment Rate"}
                              </span>
                              <span>{": "}</span>
                              <span>
                                {CurrencyFormat(selectedItems?.service?.Rate)}
                              </span>
                            </div>
                          </div>
                          <div className="custom-service-item ">
                            <div className="section-4">
                              <span className={"buz-font-600"}>
                                {"Duration"}
                              </span>
                              <span>{": "}</span>
                              <span>{`${parseInt(
                                selectedItems?.service?.Duration
                              )} minutes`}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bz-content-text-row bz-notify-row">
                        <div className="bz-content-title text-right">
                          {"Purchase cost"}
                        </div>
                        <span className="bz-colon text-center">:</span>
                        <div className="bz-content-body">
                          {CurrencyFormat(selectedItems?.service?.purchaseCost)}
                        </div>
                      </div>
                      {checkHasTaxEnable() ? (
                        <div className="bz-content-text-row bz-notify-row">
                          <div className="bz-content-title text-right">
                            Surcharges
                            <span className="required-asterisk">*</span>
                          </div>
                          <span className="bz-colon text-center">:</span>
                          <div className="bz-content-body">
                            <Switch
                              checked={selectedItems?.service?.CanCollectTaxes}
                              defaultChecked={false}
                              name={`CanCollectTaxes}`}
                              onChange={(e: any) =>
                                handleChange(e.value, "CanCollectTaxes")
                              }
                              className="mr-1 d-inline-flex"
                            />
                          </div>
                        </div>
                      ) : null}
                      {selectedItems?.service?.CanCollectTaxes &&
                      checkHasTaxEnable() ? (
                        <div className="bz-content-text-row bz-notify-row">
                          <div className="bz-content-title text-right">
                            <Tooltip
                              content={(tooltipProps: any) => {
                                return (
                                  <TooltipContentPackageService
                                    title={tooltipProps.title}
                                    data={Taxes}
                                    TotalTaxAmount={TotalTaxAmount}
                                    Name={selectedItems?.service.Name}
                                  />
                                );
                              }}
                              tooltipClassName="bz-tooltip-table"
                            >
                              <a
                                title="Surcharges"
                                style={{
                                  borderBottom: "1px dotted #0d6efd",
                                  cursor: "pointer",
                                }}
                              >
                                {"Surcharges to be collected"}
                              </a>
                            </Tooltip>
                          </div>
                          <span className="bz-colon text-center">:</span>
                          <div className="bz-content-body">
                            {CurrencyFormat(TotalTaxAmount)}
                          </div>
                        </div>
                      ) : null}
                      <div className="bz-content-text-row bz-notify-row">
                        <div className="bz-content-title text-right">
                          {"Down Payment"}
                        </div>
                        <span className="bz-colon text-center">:</span>
                        <div className="bz-content-body">
                          {CurrencyFormat(
                            selectedItems?.service?.CanCollectTaxes
                              ? selectedItems?.service?.purchaseCost +
                                  TotalTaxAmount
                              : selectedItems?.service?.purchaseCost
                          )}
                        </div>
                      </div>
                    </>
                  )}
                <div className="bz-content-text-row bz-notify-row">
                  <div className="bz-content-title text-right">
                    Location<span className="required-asterisk">*</span>
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <LocationDD
                      width={"100%"}
                      Phone={selectedItems?.userData?.Phone}
                      label={false}
                      disabled={false}
                      showPhoneCallIWillCall={true}
                      Attributes={Attributes}
                      handleUpdateLocation={(
                        selectedLocation: SelectedLocationType
                      ) => handleUpdateLocation(selectedLocation)}
                    />
                  </div>
                </div>
                {selectedItems?.service?.PrivateServiceInstanceId === 0 &&
                  selectedItems?.service?.Configured && (
                    <div className="bz-content-text-row bz-notify-row">
                      <div className="bz-content-title text-right">
                        Payment Profile
                        <span className="required-asterisk">*</span>
                      </div>
                      <span className="bz-colon text-center">:</span>
                      <div className="bz-content-body payment-profile">
                        <div>
                          <DropDownList
                            id={`PaymentGatewayPaymentProfileId`}
                            name={`PaymentGatewayPaymentProfileId`}
                            data={paymentProfiles}
                            textField="MaskedCCNumber"
                            // dataItemKey="PaymentProfileID"
                            valueRender={(
                              el: React.ReactElement<HTMLSpanElement>,
                              value: any
                            ) =>
                              PaymentValueRender(
                                el,
                                value,
                                selectedPaymentProfile
                              )
                            }
                            itemRender={PaymentItemRender}
                            onChange={(e) => {
                              handleChange(e.value, "paymentProfile");
                            }}
                            style={{ width: "100%" }}
                          />
                        </div>
                        <div className="addNewBtn">
                          <DropDownButton
                            text="Add New"
                            onItemClick={(e: any) => onCardOrBankAdd(e)}
                            items={PaymentMethodItems.filter(
                              (i: any) => i?.visible === true
                            )}
                            look="flat"
                            className="btn btn-link add-new-btn"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                {selectedItems?.service?.HasPaidSchedules &&
                  selectedItems?.service?.SessionAvailableToSchedule <= 0 &&
                  availOriginalSchedules?.length === 1 && (
                    <>
                      <div className="bz-content-text-row bz-notify-row">
                        <div className="bz-content-title text-right">
                          Pick the schedule{" "}
                          <span className="required-asterisk">*</span>
                        </div>
                        <span className="bz-colon text-center">:</span>
                        <div className="bz-content-body bz-pickschedule-blk">
                          <DropDownList
                            data={availOriginalSchedules}
                            textField="ScheduleName"
                            dataItemKey="ScheduleId"
                            className={"customDropdown"}
                            valueRender={(
                              el: React.ReactElement<HTMLSpanElement>,
                              value: any
                            ) =>
                              scheduleValueRender(
                                el,
                                value,
                                selectedItems?.schedulePicked
                              )
                            }
                            itemRender={(
                              li: React.ReactElement<HTMLLIElement>,
                              itemProps: ListItemProps
                            ) =>
                              scheduleItemRender(
                                li,
                                itemProps,
                                selectedItems?.schedulePicked
                              )
                            }
                            onChange={(e) =>
                              handleChange(e.target.value, "schedulePicked")
                            }
                            filterable={true}
                            style={{ width: "100%" }}
                            onFilterChange={(e) => trainerfilterChange(e)}
                          />
                          {modified &&
                            showErrorMessage("schedulePicked", "Slot")}
                        </div>
                      </div>
                    </>
                  )}
                {!selectedItems?.schedulePicked &&
                  selectedItems?.service?.PrivateServiceInstanceId > 0 && (
                    <div className="bz-content-text-row bz-notify-row">
                      <div className="bz-content-title text-right"></div>
                      <span className="bz-colon text-center"></span>
                      <div className="bz-content-body">
                        <div className="bz-plan-note">
                          <p className="mb-0">
                            <Notify key="info" type={{ style: "info" }}>
                              {selectedItems?.service?.HasPaidSchedules &&
                              selectedItems?.service
                                ?.SessionAvailableToSchedule <= 0
                                ? `Please select the existing ${availOriginalSchedules?.length} scheduled appointment to do quick checkout.`
                                : selectedItems?.service
                                    ?.SessionAvailableToSchedule > 1
                                ? `The One-on-One has ${
                                    selectedItems?.service
                                      ?.SessionAvailableToSchedule || 0
                                  } (remaining) appointments available for a quick checkout.`
                                : `The One-on-One has ${
                                    selectedItems?.service
                                      ?.SessionAvailableToSchedule || 0
                                  } (remaining) appointments available for a quick checkout, system will deduct one appointment after the submit.`}
                            </Notify>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                {selectedItems?.service?.PrivateServiceInstanceId === 0 &&
                  selectedItems?.service?.Configured && (
                    <div className="bz-content-text-row bz-notify-row">
                      <div className="bz-content-title text-right"></div>
                      <span className="bz-colon text-center"></span>
                      <div className="bz-content-body">
                        <div className="bz-plan-note">
                          <p className="mb-0">
                            <Notify key="info" type={{ style: "info" }}>
                              {`The Custom One-on-One has ${
                                selectedItems?.service
                                  ?.SessionAvailableToSchedule || 0
                              } appointments for a quick checkout.Please add all appointments for quick checkout.`}
                            </Notify>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                {!selectedItems?.schedulePicked &&
                  (selectedItems?.service?.SessionAvailableToSchedule > 1 ||
                    (selectedItems?.service?.SessionAvailableToSchedule > 0 &&
                      selectedItems?.service?.PrivateServiceInstanceId === 0) ||
                    (selectedItems?.service?.HasPaidSchedules &&
                      selectedItems?.service?.SessionAvailableToSchedule <= 0 &&
                      availOriginalSchedules?.length > 1)) && (
                    <div className="">{AddAppointmentsDialog()}</div>
                  )}

                {selectedItems?.service?.PrivateServiceInstanceId !== 0 &&
                ((selectedItems?.service?.HasPaidSchedules &&
                  selectedItems?.service?.SessionAvailableToSchedule <= 0 &&
                  availOriginalSchedules?.length === 1) ||
                  selectedItems?.service?.SessionAvailableToSchedule === 1) ? (
                  <>
                    <div className="bz-content-text-row align-items-start bz-notify-row">
                      <div className="bz-content-title text-right">
                        Sign Off Date
                        <span className="required-asterisk">*</span>
                      </div>
                      <span className="bz-colon text-center">:</span>
                      <div className="bz-content-body">
                        <DateTimePicker
                          value={selectedItems?.signOffDate}
                          max={maximum}
                          onChange={(e) =>
                            handleChange(e.target.value, "signOffDate")
                          }
                        />
                      </div>
                    </div>
                    <div className="bz-content-text-row bz-notify-row">
                      <div className="bz-content-title text-right">
                        Provider<span className="required-asterisk">*</span>
                      </div>
                      <span className="bz-colon text-center">:</span>
                      <div className="bz-content-body bz-pickschedule-blk">
                        <DropDownList
                          data={providerList}
                          textField="FullName"
                          dataItemKey="UserId"
                          className={"customDropdown"}
                          valueRender={(
                            el: React.ReactElement<HTMLSpanElement>,
                            value: any
                          ) =>
                            trainerValueRender(
                              el,
                              value,
                              selectedItems?.provider
                            )
                          }
                          itemRender={(
                            li: React.ReactElement<HTMLLIElement>,
                            itemProps: ListItemProps
                          ) =>
                            trainerItemRender(
                              li,
                              itemProps,
                              selectedItems?.provider
                            )
                          }
                          onChange={(e) =>
                            handleChange(e.target.value, "provider")
                          }
                          filterable={true}
                          style={{ width: "100%" }}
                          onFilterChange={(e) => trainerfilterChange(e)}
                        />
                        {modified && showErrorMessage("provider", "Provider")}
                      </div>
                    </div>
                    <div className="bz-content-text-row align-items-start bz-notify-row">
                      <div className="bz-content-title text-right">Notes</div>
                      <span className="bz-colon text-center">:</span>
                      <div className="bz-content-body">
                        <textarea
                          className="form-control"
                          onChange={(e) =>
                            handleChange(e.target.value, "notes")
                          }
                          value={selectedItems?.notes}
                        ></textarea>
                      </div>
                    </div>
                    <div className="bz-content-text-row">
                      <div className="bz-content-title text-right"></div>
                      <span className="bz-colon text-center"></span>
                      <div className="bz-content-body pl-0">
                        <Button
                          className="ml-0"
                          disabled={validateBtnLoading}
                          onClick={() =>
                            props?.handleSelectionPage("quickCheckout", false)
                          }
                        >
                          Cancel
                        </Button>
                        <BuzopsButton
                          loading={validateBtnLoading}
                          disabled={validateBtnLoading || redirection}
                          label={"Submit"}
                          onClick={() => handleValidation()}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <div style={{width:'40%'}}>
              {selectedItems?.service?.HasPaidSchedules &&
              selectedItems?.service?.SessionAvailableToSchedule <= 0 &&
              availOriginalSchedules?.length > 1 &&
              selectedItems?.service?.PrivateServiceInstanceId !== 0 ? (
                <>{ShowExistingAppointments()}</>
              ) : null}
              {multipleSlots?.length > 0 ? (
                <>
                  <div className="">
                    <div className={"selected-slot-header"}>
                      <div>
                        {" "}
                         <h5 ref={h5Ref} className="card-title mb-0">Selected Slots</h5>{" "}

                        <span className="small text-muted">
                          Number of selected slots: &nbsp;
                          {multipleSlots?.length}
                        </span>
                      </div>
                    </div>
                    <div className="bz-selected-appt-quick-check"  style={{maxWidth:'280px'}}>
                      {selectedSlotList()}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>

          {!selectedItems?.schedulePicked &&
          (selectedItems?.service?.SessionAvailableToSchedule > 1 ||
            (selectedItems?.service?.SessionAvailableToSchedule > 0 &&
              selectedItems?.service?.PrivateServiceInstanceId === 0) ||
            (selectedItems?.service?.HasPaidSchedules &&
              selectedItems?.service?.SessionAvailableToSchedule <= 0 &&
              availOriginalSchedules?.length > 1)) ? (
            <div className="row">
              <div className="d-flex justify-content-end">
                <Button
                  className="ml-0"
                  disabled={submitBtnLoading}
                  onClick={() =>
                    props?.handleSelectionPage("quickCheckout", false)
                  }
                >
                  Cancel
                </Button>
                {selectedItems?.service?.PrivateServiceInstanceId === 0 ? (
                  <BuzopsButton
                    loading={submitBtnLoading}
                    disabled={
                      submitBtnLoading ||
                      submit ||
                      multipleSlots?.length !==
                        selectedItems?.service?.SessionAvailableToSchedule
                    }
                    label={"Submit"}
                    onClick={() => handleCustomServicePurchase()}
                  />
                ) : (
                  <BuzopsButton
                    loading={submitBtnLoading}
                    disabled={
                      submitBtnLoading || submit || multipleSlots?.length === 0
                    }
                    label={"Submit"}
                    onClick={() => handleMultipleSignoff()}
                  />
                )}
              </div>
            </div>
          ) : null}
        </>
      )}
      {unAvailableListDialog && renderUnAvailableListDialog()}
      {renewConfirmDialog && renderRenewConfirmDialog()}
      {customDialog && (
        <Dialog
          className="bz-book-Appoinment-dialog"
          title={"Custom One-on-one"}
          onClose={() => handleCancelCustom()}
          width={"35%"}
        >
          {renderCustomService()}
        </Dialog>
      )}
      {showPaymentDialog && PaymethodDialog()}
    </>
  );
};

export default withNotification(QuickCheckout);
