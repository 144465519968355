import React, { useEffect, useRef, useState } from "react";
import { TenantService } from "services/tenant/index.service";
import { useHistory } from "react-router-dom";
import {
  GetLocalStore,
  LocalStore,
  RemoveAllExceptTenant,
} from "utils/storage";
import BuzopsLoader from "generic-components/BuzopsLoader";
import Service from "components/service/Service";
import ShowMoreText from "react-show-more-text";
import BuzopsNotification from "generic-components/BuzopsNotification";
import { CapitalizeStr } from "utils";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import PurchaseClassList from "components/class/PurchaseClassList";
import PurchasePlanList from "components/plans/PurchasePlanList";
import PurchasePackageList from "components/package/PurchasePackageList";
import { useDispatch, useSelector } from "react-redux";
import { setUserConfiguration } from "redux-slices/userSlice";
const About = (props: any) => {
  const history = useHistory();
  const dispatch= useDispatch()
  const [tenantDetails, setTenantDetails] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [servicesData, setServicesData] = useState<any>([]);
  const [isServiceLoading, setIsServiceLoading] = useState(false);
  const targetRef = useRef<any>();
  const [message, setMessage] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const redirectionObj = useSelector((state:any)=>state?.redirection)
  const [notification, setNotification] = useState({
    error: false,
    success: false,
  });
  const handleNotification = (type: string) => {
    setNotification({ ...notification, [type]: false });
  };
  const unloadFunc = () => {
    dispatch(setUserConfiguration({
      loggedIn:false,
      userDetails:null
    }))
    //use the history and the location from the props instead of window
    props.history.replace({
      ...props.location,
      state: undefined,
    });
    const errorMsg = `You have logged out successfully `;
    setMessage(errorMsg);
    setNotification({ ...notification, success: true });
  };

  useEffect(() => {
    let timer1 = setTimeout(() => {
      setNotification({ ...notification, error: false, success: false });
    }, 3000);
    return () => {
      clearTimeout(timer1);
    };
  }, [notification.error, notification.success]);

  if (props.location?.state?.logout) {
    RemoveAllExceptTenant();

    const hostValues = window.location.host.split(".");
    if (
      hostValues.length === 3 &&
      hostValues[0] !== "www" &&
      hostValues[0] !== "ui"
    ) {
      const tenantName = hostValues[0];
      LocalStore("tenant", tenantName);
    }
  }

  useEffect(() => {
    if (!GetLocalStore("userDetails") && props.location?.state?.logout) {
      unloadFunc();
    }
  }, [GetLocalStore("userDetails")]);

  useEffect(() => {
    fetchTenants();
  }, [GetLocalStore("tenant")]);

  async function fetchTenants() {
    setTenantDetails(GetLocalStore('tenantDetails'));
    if (props?.show === "service" || !props?.show) {
      fetchServices();
    }
    if (!GetLocalStore("tenant")) {
      if(redirectionObj?.firstPathValue !==""){
        if(redirectionObj?.firstPathValue ==="external"){
          history.push(`/${redirectionObj?.firstPathValue}/${redirectionObj?.secondRoute}`);
        }else{
          history.push(`/${redirectionObj?.firstPathValue}`);
        }
      }else{
        history.push(`/`);
      }
      return true;
    }else if(GetLocalStore("userDetails") && GetLocalStore("UserMemberId")){
      if(redirectionObj?.firstPathValue !=="" && redirectionObj?.secondRoute !==""){
        history.push(`/${redirectionObj?.firstPathValue}/${redirectionObj?.secondRoute}`);
      }else if(redirectionObj?.firstPathValue !==""){
        history.push(`/${redirectionObj?.firstPathValue}`);
      }else
      {
        history.push(`/summary`);
      }
      return true;
    }
 
  }

  async function fetchServices() {
    const service = new TenantService();
    let req = {};
    setIsServiceLoading(true);
    if (props?.shortCodeValue) {
      req = { ShortCode: props?.shortCodeValue, 
        IncludeHostOnlineOnly:false
      };
    }else{
      req = { 
        IncludeHostOnlineOnly:true
      };
    }
    req= {...req,IsActive:true}
    const servicedet = await service.getServiceDetails(req);
    const result = servicedet.Result;
    if (result) {
      setServicesData(result);
    }
    setIsServiceLoading(false);
  }

  const aboutData = () => {
    return (
      <>
        <div className="card border-0">
          <div className="card-body px-0">
            <h5 className="card-title bzo-primary-heading">About Us</h5>
            <div className="card-text">
              <ShowMoreText
                lines={2}
                more="Show more"
                less="Show less"
                className="justify-content bz-showHide-description"
                expanded={false}
                width={targetRef?.current?.offsetWidth}
                children={
                  <p
                    className={"justify-content"}
                    dangerouslySetInnerHTML={{
                      __html: tenantDetails?.Description,
                    }}
                  ></p>
                }
              />
              {/* <p
                 className={"justify-content"}
                 dangerouslySetInnerHTML={{
                   __html: tenantDetails?.Description,
                 }}
               ></p> */}
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleTabSelect = (tabValue: number) => {
    setSelectedTab(tabValue);
  };
  const tabs = [
    {
      title: "One-on-One",
      visible: tenantDetails?.IsIndividualServicesTabVisible,
      id: 1,
    },
    {
      title: "Group",
      visible: tenantDetails?.IsGroupServicesTabVisible,
      id: 2,
    },
    {
      title: "Memberships",
      visible: tenantDetails?.IsMemberplanTabVisible,
      id: 3,
    },
    {
      title: "Packages",
      visible: tenantDetails?.IsPackageTabVisible,
      id: 4,
    },
  ];

  if (isLoading) {
    return <BuzopsLoader />;
  }

  return (
    <div className={`row justify-content-center bz-classes-in-online bz-tabstrip ${tenantDetails?.IsAboutSectionVisible? "bz-aboutus-page" : "bz-noaboutus-page"}`}>
      <div className="col-12 col-xl-10">
        {tenantDetails?.IsAboutSectionVisible && (
          <>
            <div
              className={"d-flex justify-content-start bzo-about-section"}
              ref={targetRef}
            >
              {aboutData()}
            </div>
            <hr />
          </>
        )}
        {!props?.show && (
          <TabStrip
            selected={selectedTab}
            onSelect={(e) => handleTabSelect(e.selected)}
          >
            {tabs
              .filter((i) => i?.visible === true)
              .map((item, index) => {
                return (
                  <TabStripTab
                    title={`${CapitalizeStr(item.title)}`}
                    key={index}
                  >
                    {item.id === 1 && (
                      <Service
                        servicesData={servicesData}
                        isServiceLoading={isServiceLoading}
                        // serviceShortName={props?.shortCodeValue}
                      />
                    )}
                    {item.id === 2 && <PurchaseClassList page={"online"} />}
                    {item.id === 3 && <PurchasePlanList />}
                    {item.id === 4 && <PurchasePackageList page={"online"} />}
                  </TabStripTab>
                );
              })}
          </TabStrip>
        )}
        {props?.show === "service" && (
          <Service
            ShortCodeStatus={props.ShortCodeStatus}
            servicesData={servicesData}
            isServiceLoading={isServiceLoading}
            serviceShortName={props?.shortCodeValue}
          />
        )}
        {props?.show === "class" && (
          <PurchaseClassList
            page={"online"}
            ShortCodeStatus={props.ShortCodeStatus}
            ShortCodeDescription={props.ShortCodeDescription}
            guest={true}
            classShortName={props?.shortCodeValue}
            classSessionId={props?.classSessionId}
          />
        )}
        {props?.show === "plan" && (
          <PurchasePlanList
            page={"online"}
            ShortCodeStatus={props?.ShortCodeStatus}
            guest={true}
            planShortName={props?.shortCodeValue}
          />
        )}
        {props?.show === "package" && (
          <PurchasePackageList page={"online"} ShortCodeStatus={props.ShortCodeStatus}  guest={true} packageShortName={props?.shortCodeValue} />
        )}
        <BuzopsNotification
          notification={notification}
          message={message}
          handleNotification={(type: any) => handleNotification(type)}
        />
      </div>
    </div>
  );
};

export default About;
