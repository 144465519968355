import { NumericTextBox, RadioButton } from "@progress/kendo-react-inputs";
import { useFormik } from "formik";
import BuzopsButton from "generic-components/BuzopsButton";
import { useEffect, useState } from "react";
import { SecuritySystemType } from "utils/constants";
import * as Yup from "yup";
import {
  CheckinNotificationPreference,
  TrainerCheckinNotificationPreference,
} from "../Utilities/constants";

interface ICheckinsFormProps {
  SecuritySystemType: SecuritySystemType;
  CheckinsEnabled: boolean;
  CheckinNotificationPreference: CheckinNotificationPreference;
  TrainerCheckinNotificationPreference: TrainerCheckinNotificationPreference;
  OneOnOneCheckinPreference: {
    Enabled: boolean;
    MaxTimeSpanInMinutesBeforeScheduleForCheckin: number;
  };
  GroupClassCheckinPreference: {
    Enabled: boolean;
    MaxTimeSpanInMinutesBeforeScheduleForCheckin: number;
  };
  DaysToWaitToSuspendAccessAfterPaymentDecline: number;
}

const schema = Yup.object({
  CheckinNotificationPreference: Yup.number().required(
    "Checkin Notification Preference Required"
  ),
  TrainerCheckinNotificationPreference: Yup.number().required(
    "Trainer CheckinNotification Preference  Required"
  ),

  OneOnOneCheckinPreference: Yup.object({
    MaxTimeSpanInMinutesBeforeScheduleForCheckin: Yup.number().required(
      "Max TimeSpan In Minutes Before Schedule For Checkin"
    ),
  }),

  GroupClassCheckinPreference: Yup.object({
    MaxTimeSpanInMinutesBeforeScheduleForCheckin: Yup.number().required(
      "Max TimeSpan In Minutes Before Schedule For Checkin"
    ),
  }),
  DaysToWaitToSuspendAccessAfterPaymentDecline: Yup.number().required(
    "Days To Wait To Suspend Access After Payment Decline"
  ),
});

const CheckinsForm = (props: any) => {
  const [btnLoading, setBtnLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      SecuritySystemType: 1,
      CheckinNotificationPreference: CheckinNotificationPreference.None,
      TrainerCheckinNotificationPreference:
        TrainerCheckinNotificationPreference.None,
      OneOnOneCheckinPreference: {
        Enabled: true,
        MaxTimeSpanInMinutesBeforeScheduleForCheckin: 0,
      },
      GroupClassCheckinPreference: {
        Enabled: true,
        MaxTimeSpanInMinutesBeforeScheduleForCheckin: 0,
      },
      DaysToWaitToSuspendAccessAfterPaymentDecline: 0,
    } as ICheckinsFormProps,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      values.OneOnOneCheckinPreference.Enabled = true;
      values.GroupClassCheckinPreference.Enabled = true;
      values.CheckinsEnabled = true;

      props.handleSubmit(values, setBtnLoading);
    },
  });

  useEffect(() => {
    if (props?.securitySystemConfigs) {
      const values: ICheckinsFormProps = { ...props.securitySystemConfigs };
      values.OneOnOneCheckinPreference = props.securitySystemConfigs
        ?.OneOnOneCheckinPreference || {
        Enabled: true,
        MaxTimeSpanInMinutesBeforeScheduleForCheckin: 0,
      };
      values.GroupClassCheckinPreference = props.securitySystemConfigs
        ?.GroupClassCheckinPreference || {
        Enabled: true,
        MaxTimeSpanInMinutesBeforeScheduleForCheckin: 0,
      };
      formik.setValues({ ...props.securitySystemConfigs });
    }
  }, [props?.securitySystemConfigs]);

  return (
    <>
      <h5
        className="d-flex justify-content-between mb-3 bz_fs_1_5"
        style={{ fontSize: "1rem", fontWeight: 600 }}
      >
        Check-ins Settings
      </h5>
      <div className="container-fluid px-0">
        <form onSubmit={formik.handleSubmit}>
          <div className=" row">
            <div className="col-6 col-sm-6 col-md-4 barcodelabel">
              Service Name:
            </div>
            <div className="col-6 col-sm-6 col-md-8 barcodelabel">
              Earliest check-in allowed
            </div>
          </div>
          <div className="row my-2 align-items-center ">
            <div className="col-6 col-sm-6 col-md-4 service-label">
              <span>One-on-one Appointments</span>
            </div>
            <div className="col-4 col-sm-6 col-md-8 service-label">
              <NumericTextBox
                min={0}
                max={240}
                step={15}
                id={
                  "OneOnOneCheckinPreference.MaxTimeSpanInMinutesBeforeScheduleForCheckin"
                }
                name={
                  "OneOnOneCheckinPreference.MaxTimeSpanInMinutesBeforeScheduleForCheckin"
                }
                onChange={(event) =>
                  formik.setFieldValue(
                    "OneOnOneCheckinPreference.MaxTimeSpanInMinutesBeforeScheduleForCheckin",
                    Math.min(event?.target?.value || 0, 240)
                  )
                }
                value={
                  formik.values.OneOnOneCheckinPreference
                    ?.MaxTimeSpanInMinutesBeforeScheduleForCheckin
                }
              />
              <small className="bz-text-muted"> {`minutes before start`}</small>
              {formik.errors?.OneOnOneCheckinPreference
                ?.MaxTimeSpanInMinutesBeforeScheduleForCheckin ? (
                <p style={{ color: "red" }} className="mb-0">
                  {
                    formik.errors?.OneOnOneCheckinPreference
                      ?.MaxTimeSpanInMinutesBeforeScheduleForCheckin
                  }
                </p>
              ) : null}
            </div>
          </div>
          <div className="row my-2 align-items-center">
            <div className="col-6 col-sm-6 col-md-4 service-label">
              <span>Group Appointments</span>
            </div>
            <div className="col-4 col-sm-6 col-md-8 service-label">
              <NumericTextBox
                min={0}
                max={240}
                step={15}
                id={
                  "GroupClassCheckinPreference.MaxTimeSpanInMinutesBeforeScheduleForCheckin"
                }
                name={
                  "GroupClassCheckinPreference.MaxTimeSpanInMinutesBeforeScheduleForCheckin"
                }
                onChange={(event) =>
                  formik.setFieldValue(
                    "GroupClassCheckinPreference.MaxTimeSpanInMinutesBeforeScheduleForCheckin",
                    Math.min(event?.target?.value || 0, 240)
                  )
                }
                value={
                  formik.values.GroupClassCheckinPreference
                    ?.MaxTimeSpanInMinutesBeforeScheduleForCheckin
                }
              />
              <small className="bz-text-muted"> {`minutes before start`}</small>
              {formik.errors?.GroupClassCheckinPreference
                ?.MaxTimeSpanInMinutesBeforeScheduleForCheckin ? (
                <p style={{ color: "red" }} className="mb-0">
                  {
                    formik.errors?.GroupClassCheckinPreference
                      ?.MaxTimeSpanInMinutesBeforeScheduleForCheckin
                  }
                </p>
              ) : null}
            </div>
          </div>
          <div
            className="row"
            style={{ marginBottom: "1rem", marginTop: "1rem" }}
          >
            <h6 className="barcodelabel">Notify the client check-ins by</h6>
            <div className="col-md-2" style={{ textAlign: "left" }}>
              <span dir="ltr" className="selectbarcode1">
                <RadioButton
                  name="CheckinNotificationPreference"
                  value={CheckinNotificationPreference.None}
                  checked={
                    formik.values.CheckinNotificationPreference ===
                    CheckinNotificationPreference.None
                  }
                  onChange={(e) =>
                    formik.setFieldValue(
                      "CheckinNotificationPreference",
                      e.value
                    )
                  }
                  label="None"
                />
              </span>
            </div>
            <div className="col-md-2" style={{ textAlign: "left" }}>
              <span dir="ltr" className="selectbarcode1">
                <RadioButton
                  name="CheckinNotificationPreference"
                  value={CheckinNotificationPreference.Email}
                  checked={
                    formik.values.CheckinNotificationPreference ===
                    CheckinNotificationPreference.Email
                  }
                  onChange={(e) =>
                    formik.setFieldValue(
                      "CheckinNotificationPreference",
                      e.value
                    )
                  }
                  label="Email"
                />
              </span>
            </div>
            <div className="col-md-3" style={{ textAlign: "left" }}>
              <span dir="ltr" className="selectbarcode selectbarcode1">
                <RadioButton
                  name="CheckinNotificationPreference"
                  value={CheckinNotificationPreference.SMS}
                  checked={
                    formik.values.CheckinNotificationPreference ===
                    CheckinNotificationPreference.SMS
                  }
                  onChange={(e) =>
                    formik.setFieldValue(
                      "CheckinNotificationPreference",
                      e.value
                    )
                  }
                  disabled
                >
                  <label
                    className="k-radio-label barcodelabel"
                    style={{ opacity: "0.6" }}
                  >
                    SMS (<i>Coming Soon</i>)
                  </label>
                </RadioButton>
              </span>
            </div>
            <div className="col-md-4" style={{ textAlign: "left" }}>
              <span dir="ltr" className="selectbarcode selectbarcode1">
                <RadioButton
                  name="CheckinNotificationPreference"
                  value={CheckinNotificationPreference.Both}
                  checked={
                    formik.values.CheckinNotificationPreference ===
                    CheckinNotificationPreference.Both
                  }
                  onChange={(e) =>
                    formik.setFieldValue(
                      "CheckinNotificationPreference",
                      e.value
                    )
                  }
                  disabled
                >
                  <label
                    className="k-radio-label barcodelabel"
                    style={{ opacity: "0.6" }}
                  >
                    Both Email & SMS (<i>Coming Soon</i>)
                  </label>
                </RadioButton>
              </span>
            </div>
            <div className="col-12">
              {formik.errors?.CheckinNotificationPreference ? (
                <p style={{ color: "red" }}>
                  {formik.errors?.CheckinNotificationPreference}
                </p>
              ) : null}
            </div>
          </div>
          <div
            className="row"
            style={{ marginBottom: "1rem", marginTop: "1rem" }}
          >
            <h6 className="barcodelabel"> Notify the staff check-ins by</h6>
            <div className="col-md-2" style={{ textAlign: "left" }}>
              <span dir="ltr" className="selectbarcode1">
                <RadioButton
                  name="TrainerCheckinNotificationPreference"
                  value={TrainerCheckinNotificationPreference.None}
                  checked={
                    formik.values.TrainerCheckinNotificationPreference ===
                    TrainerCheckinNotificationPreference.None
                  }
                  onChange={(e) =>
                    formik.setFieldValue(
                      "TrainerCheckinNotificationPreference",
                      e.value
                    )
                  }
                  label="None"
                />
              </span>
            </div>
            <div className="col-md-2" style={{ textAlign: "left" }}>
              <span dir="ltr" className="selectbarcode1">
                <RadioButton
                  name="TrainerCheckinNotificationPreference"
                  value={TrainerCheckinNotificationPreference.Email}
                  checked={
                    formik.values.TrainerCheckinNotificationPreference ===
                    TrainerCheckinNotificationPreference.Email
                  }
                  onChange={(e) =>
                    formik.setFieldValue(
                      "TrainerCheckinNotificationPreference",
                      e.value
                    )
                  }
                  label="Email"
                />
              </span>
            </div>
            <div className="col-md-3" style={{ textAlign: "left" }}>
              <span dir="ltr" className="selectbarcode selectbarcode1">
                <RadioButton
                  name="TrainerCheckinNotificationPreference"
                  value={TrainerCheckinNotificationPreference.SMS}
                  checked={
                    formik.values.TrainerCheckinNotificationPreference ===
                    TrainerCheckinNotificationPreference.SMS
                  }
                  onChange={(e) =>
                    formik.setFieldValue(
                      "TrainerCheckinNotificationPreference",
                      e.value
                    )
                  }
                  disabled
                >
                  <label
                    className="k-radio-label barcodelabel"
                    style={{ opacity: "0.6" }}
                  >
                    SMS (<i>Coming Soon</i>)
                  </label>
                </RadioButton>
              </span>
            </div>
            <div className="col-md-4" style={{ textAlign: "left" }}>
              <span dir="ltr" className="selectbarcode selectbarcode1">
                <RadioButton
                  name="TrainerCheckinNotificationPreference"
                  value={TrainerCheckinNotificationPreference.Both}
                  checked={
                    formik.values.TrainerCheckinNotificationPreference ===
                    TrainerCheckinNotificationPreference.Both
                  }
                  onChange={(e) =>
                    formik.setFieldValue(
                      "TrainerCheckinNotificationPreference",
                      e.value
                    )
                  }
                  disabled
                >
                  <label
                    className="k-radio-label barcodelabel"
                    style={{ opacity: "0.6" }}
                  >
                    Both Email & SMS (<i>Coming Soon</i>)
                  </label>
                </RadioButton>
              </span>
            </div>
            <div className="col-12">
              {formik.errors?.TrainerCheckinNotificationPreference ? (
                <p style={{ color: "red" }}>
                  {formik.errors?.TrainerCheckinNotificationPreference}
                </p>
              ) : null}
            </div>
          </div>
          {/* <div className={`bz-content-text-row ${errorClass}`}>
              <div className="bz-content-title text-right">
                Days To Wait To Suspend Access After Payment Decline
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <NumericTextBox
                  min={0}
                  max={240}
                  step={15}
                  id={"DaysToWaitToSuspendAccessAfterPaymentDecline"}
                  name={"DaysToWaitToSuspendAccessAfterPaymentDecline"}
                  onChange={(event) =>
                    formik.setFieldValue(
                      "DaysToWaitToSuspendAccessAfterPaymentDecline",
                      event.target.value
                    )
                  }
                  value={
                    formik.values.DaysToWaitToSuspendAccessAfterPaymentDecline
                  }
                />
                {formik.errors?.DaysToWaitToSuspendAccessAfterPaymentDecline ? (
                  <p style={{ color: "red" }} className="mb-0">
                    {
                      formik.errors
                        ?.DaysToWaitToSuspendAccessAfterPaymentDecline
                    }
                  </p>
                ) : null}
              </div>
            </div> */}
          <div className="float-right">
            <BuzopsButton
              type="submit"
              label="Save"
              className="btn m-0"
              disabled={!formik.dirty || btnLoading}
              loading={btnLoading}
            />
          </div>
          <br />
        </form>
      </div>
    </>
  );
};

export default CheckinsForm;
