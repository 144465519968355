import React from 'react';
import {  Route, Switch, BrowserRouter } from 'react-router-dom';
import FallbackLoading from 'generic-components/FallbackLoading'
import AppContext from 'context-api/AppContext'
import 'scss/index.scss'
import Error404 from 'generic-components/BuzopsError404/Error404';
import { LocalStore } from 'utils/storage';
import { publicRoutes } from 'routes/routes';
import SessionExpired from 'generic-components/SessionExpired';
import withClearCache from 'ClearCache';
import TimeZoneContext from 'context-api/TimeZoneContext';
import {ErrorBoundary} from 'react-error-boundary'
import ErrorBoundaryComponent from 'generic-components/ErrorBoundaryComponent';
import CheckinsLayout from 'layout/CheckinsLayout';
import GenerateImage from 'components/generateImage/GenerateImage';
const StaffLayout = React.lazy(() => import('./layout/StaffLayout'));
const AccessControlLayout = React.lazy(() => import('./layout/AccessControlLayout'));
const ExternalLayout = React.lazy(() => import('./layout/ExternalLayout'));
const PublicLayout = React.lazy(() => import('./layout/PublicLayout'));
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const GuestLayout = React.lazy(() => import('./layout/GuestLayout'));
const ReportsLayout = React.lazy(() => import('./layout/ReportsLayout'));
const AffiliateLayout = React.lazy(() => import('./layout/AffiliateLayout'));

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return  <ErrorBoundary
  FallbackComponent={ErrorBoundaryComponent}
  onReset={() => {
    // window.open(`chrome://settings/cookies`)
    // reset the state of your app so the error doesn't happen again
  }}
><ClearCacheComponent /></ErrorBoundary>
}

function MainApp(props:any) {
  // console.log(getBuildDate(packageJson.buildDate))
  const hostValues=window.location.host.split('.')
  let tenantStatus=false;
  if(hostValues.length===3 && hostValues[0]!=='www' && hostValues[0]!=='ui'){
    const tenantName=hostValues[0]
    // const tenantName= 'stechnologies'
    LocalStore("tenant",tenantName)
    tenantStatus=true;
  }

  // Removed console logs in production
  if (process.env.NODE_ENV === 'production') {
    console.log = function () {};
  }

  return (
    <BrowserRouter>
    <AppContext>
      <TimeZoneContext>
        <React.Suspense fallback={FallbackLoading()}>
          <Switch>
            <Route path="/401" key={'SessionExpired'} component={SessionExpired} />
            <Route path="/404" key={'Error404'}   component={Error404} />
            <Route path="/generateImage/:imageId"  key={'generateImage'}  component={GenerateImage} />
            <Route path="/app"  key={'DefaultLayout'}  component={DefaultLayout} />
            <Route path="/affiliate"  key={'AffiliateLayout'}  component={AffiliateLayout} />
            <Route path="/external"  key={'ExternalLayout'}  component={ExternalLayout} />
            <Route path="/reports"  key={'ReportsLayout'}  component={ReportsLayout} />
            <Route path="/admin"  key={'AccessControlLayout'}  component={AccessControlLayout} />
            <Route path="/kiosk"  key={'CheckinsLayout'}  component={CheckinsLayout} />
            <Route path="/member/:uuid/:clientId"  key={'StaffLayout'}  component={StaffLayout} />
            {publicRoutes.map((route, id) => {
              if(route.key !=='Tenant Home'){
                  return tenantStatus && <Route
                      path={route.path}
                      key={route.key}
                      component={PublicLayout} 
                    />
                }
                })}
            {tenantStatus && <Route path="/:providerId"  key={'GuestLayout'}   component={GuestLayout} />}

            {tenantStatus && <Route path="/"  key={'PublicLayout1'}   component={PublicLayout} />}
          </Switch>
        </React.Suspense>
      </TimeZoneContext>
    </AppContext>
    </BrowserRouter>
  );
}

export default App;
