import AppointmentTabs from "../AppointmentTabs";

const ViewComplimentaryAppointments = (props: any) => {


  const renderAppointments = () => {
    return (
      <>
        <h5 className="mb-3 bz_fs_1_5">
          <button
            className="btn btn-primary bz-back-btn btn-sm"
            onClick={() =>
              props?.handleSelectionPage("viewComplimentaryEvents", false)
            }
          >
            <i className="fas fa-arrow-left"></i>
          </button>
          &nbsp;Complimentary/Unpaid Appointments
        </h5>
        <AppointmentTabs
          {...props}
          ShowComplimentarySessionsOnly={true}
        />
      </>
    );
  };
  return <>{renderAppointments()}</>;
};

export default ViewComplimentaryAppointments;
