import { useEffect, useRef, useState } from "react";

import "../styles.scss";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import PipeDrive from "components/pipedrive/PipeDrive";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";

import { ThirdPartyConfigService } from "services/thirdparty-configuration/index.service";
import { SiteSettingService } from "services/site-setting/index.service";
import withNotification from "components/Hoc/withNotification";
import BuzopsLoader from "generic-components/BuzopsLoader";

import LiveStream from "components/CheckIns/LiveStream";

import buzops_icon from "../../../Images/buzops_icon.png";
import pipedrive_icon from "../../../Images/pipedrive_icon.png";
import SMSIntegration from "./SMSIntegration";
import { useApiClient } from "services/axios-service-utils";
import { PipeDriveService } from "services/pipedrive/index.service";

export const securityType: any = {
  "1": "Proximity Reader System",
  "2": "Scanner System",
};

const ThirdParty = (props: any) => {
  const { axiosRequest } = useApiClient();

  const accessControlList = [
    //     {
    //     "name": "Brivo",
    //     "icon": "https://uatchargehub.buzops.com/Assets/Images/brivo.png",
    //     "desc": "dsasd asfasfas asfasfas asfasf aasfasf afas asfas fasfasf asfasfasfas fasfasfasf asfds gdrg dgdfh dgdfh dfhdf h dfh fh",
    //     "configured": false
    // },
    // {
    //   name: "Security System",
    //   icon: "https://uatchargehub.buzops.com/Assets/Images/squareup.png",
    //   desc: "Integrate buzops with CRM. Pipedrive is sales-focused CRM that lets you streamline your sales process.",
    //   configured: false,
    // },
  ];
  const crmList = [
    {
      name: "Pipe Drive",
      icon: "https://uatchargehub.buzops.com/Assets/Images/pipedrive.png",
      desc: "Integrate buzops with CRM. Pipedrive is sales-focused CRM that lets you streamline your sales process.",
      configured: false,
    },
  ];
  const messagingList = {
    name: "SMS",
    icon: "https://uatchargehub.buzops.com/Assets/Images/pipedrive.png",
    desc: "Integrate the buzops SMS solutions to notify the client's appointment confirmation, modification, etc. This feature facilitates real-time messaging conversations as well.",
    configured: false,
  };

  const [visibleAC, setVisibleAC] = useState<boolean>(false);
  const [IsPipeDriveConfigured, setIsPipeDriveConfigured] = useState<boolean>(false);
  const [smsConfig, setSmsConfig] = useState<any>(null);
  const [visibleForm, setVisibleForm] = useState<boolean>(false);
  const toggleACDialog = () => {
    setVisibleAC(!visibleAC);
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [visibleCRM, setVisibleCRM] = useState<boolean>(false);
  const [visibleSMS, setVisibleSMS] = useState<boolean>(false);
  const [LiveStreamEnabled, setLiveStreamEnabled] = useState<boolean>(false);
  const toggleCRMDialog = () => {
    setVisibleCRM(!visibleCRM);
    fetchServices()
  };
  const toggleSMSDialog = () => {
    setVisibleSMS(!visibleSMS);
  };


  const [selectedSecType, setSelectedSecType] = useState<string>("");
  const [securitySystemConfigs, setSecuritySystemConfig] = useState<any>({});
  const handleCustomChange = (securityType: string) => {
    setSelectedSecType(securityType);
  };
  const service = new ThirdPartyConfigService();
  const pipeDriveService = new PipeDriveService();

  useEffect(() => {
    fetchServices();
  }, []);
  const siteService = new SiteSettingService();
  const fetchServices = async (loadUserSession = true) => {
    setLoading(true);
    let getResult;
    if (loadUserSession) {
      const userSession = await siteService.getTenantDetails(
        props.match?.params?.uuid
      );
      await fetchInitialPipelineConfiguration();
      getResult = await service.getSecuritySystemConfigs();

      if (userSession?.Item) {
        if (
          userSession?.Item?.UserSession.UserClubId == 1 &&
          userSession?.Item?.UserSession.CurrentApplicationType === 1 &&
          getResult?.data?.SecuritySystemType?.toString() === "1" &&
          userSession?.Item?.UserSession.ClubId != 1
        ) {
          const data = { Type: "SecuritySystem" };
          window.parent.postMessage(data, "*");
          return true;
        }
      }
    } else {
      getResult = await service.getSecuritySystemConfigs();
    }
    if (getResult?.success) {
      setSelectedSecType(getResult?.data?.SecuritySystemType);
      setSecuritySystemConfig(getResult?.data);
    } else {
      if (getResult.msg) {
        props?.handleNotificationMessage(getResult.msg, "error");
      }
    }
    await fetchSMSConfiguration()
    setLoading(false);
  };

  const fetchSMSConfiguration =async()=>{
    await axiosRequest.get(`sms/configuration`, {
      successCallBack: (response: any) => {
        const data = response.data.Item;
        setSmsConfig(data || null);
      },
    });
  }

  const fetchInitialPipelineConfiguration = async () => {
    const result = await pipeDriveService.getPipeDriveConfiguration();
    if (result?.ApiKey && result?.ApiKey !== "" && result?.ApiKey?.length > 0) {
      setIsPipeDriveConfigured(true);
    } else {
      setIsPipeDriveConfigured(false);
    }
  };
  const AccessControlListing = (props: any) => {
    return props?.items?.map((item: any, i: number) => {
      return (
        <div
          key={i}
          className="gatewaylist access_bg"
          onClick={() => {
            props.handleaccessControlSelected(item); //needs call back to call an custom function
          }}
        >
          <div
            className="row"
            style={{
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            {/* <div className="col-3">
              
            </div> */}
            <div className="col-12" style={{ textAlign: "center" }}>
              <img src={buzops_icon} alt="" style={{ width: "180px" }} />
              <h6>
                <b>
                </b>
              </h6>

              <p
                style={{ fontSize: "1em", textAlign: "left", color: "#717171" }}
              >
                {item.desc}
              </p>
            </div>
            <div className="col-10">
              <button className="btn config_btn">Configure</button>
            </div>
          </div>
        </div>
      );
    });
  };

  const CRMListingSingle = (props: any) => {
    return (
      <>
        <div>
          <div
            className="gatewaylist access_bg"
            onClick={() => {
              props.handleCRMSelected(props.item); //needs call back to call an custom function
            }}
          >
            <div
              className="row"
              style={{
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <div className="col-12" style={{ textAlign: "center" }}>
                <img
                  src={pipedrive_icon}
                  alt=""
                  style={{ width: "180px", marginBottom: "15px" }}
                />
                <p
                  style={{
                    fontSize: "1em",
                    textAlign: "left",
                    color: "#717171",
                  }}
                >
                  {props.item.desc}
                </p>
              </div>
              <div className="col-10">
                <button className="btn config_btn" style={IsPipeDriveConfigured ? {backgroundColor: "#0d6efd", color: "#fff"}:{}}>{IsPipeDriveConfigured ? "Configured":"Configure"}</button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };


  const handleCRMSelected = (val: any) => {
    setVisibleCRM(!visibleCRM);
  };
  const handleSMSSelected =async () => {
    await axiosRequest.get(`sms/configuration`, {
      successCallBack: (response: any) => {
        const data = response.data.Item;
        if(data?.HasClubInTrial){
          const errorMsg = "Cannot configure SMS feature during the trial period";
          props?.handleNotificationMessage(errorMsg, "error");
        }else{
          setVisibleSMS(!visibleSMS);
        }
      },
      errorCallBack: (response: any) => {
        const errorMsg = response?.response?.data?.Messages?.[0] || "Internal server error";
        props?.handleNotificationMessage(errorMsg, "error");
      },
    });
  
  };

  const handleCRMSave = async () => {
    console.log("handleCRMSave");
    const f = await (childRef.current as any)?.doSomething();
    console.log(f);
    toggleCRMDialog();
  };
  const handleSMSSave = async (refresh=false) => {
    const f = await (childRef.current as any)?.doSomething();
    console.log(f);
    toggleSMSDialog();
    if(refresh){
      const sucessMsg = "SMS Configuration Successfully Completed";
      props?.handleNotificationMessage(sucessMsg, "success");
    }
      setLoading(true)
      await fetchSMSConfiguration()
      setLoading(false)
  };


  const toggleSecurityDialog = () => {
    setVisibleAC(false);
    setVisibleForm((prev) => !prev);
  };
  const childRef = useRef();

  const onSecurityTypeSelect = async () => {
    //
    toggleSecurityDialog();
  };

  if (loading) {
    return <BuzopsLoader />;
  }
  return (
    <>
      <Card
        // className="bz-pipedrive-container"
        style={{ background: "white", height: "auto" }}
      >
        <CardHeader>
          <CardTitle className="mb-0">
            <h4 className="bz-article-headline">Third Party Integrations</h4>
            <span style={{ float: "right", display: "none" }}>
              <i className="fa fa-home" aria-hidden="true"></i>
              {" >> "} Third-Party Integration
            </span>
          </CardTitle>
        </CardHeader>
        <CardBody
        // className="p-lg-4"
        >
          <div className="row">
            <div className="col-sm-12 col-xs-12 col-md-6 col-lg-8">
              <div className="row" style={{ marginBottom: "1rem" }}>
                <div className="col-sm-12 col-xs-12 col-md-12 col-lg-5">
                  <h5 className="bz-article-title pb-0">CRM SOLUTIONS</h5>
                  <hr />
                  <div>
                    {crmList.length &&
                      crmList.map((item: any, i) => {
                        return (
                          <div key={i}>
                            <CRMListingSingle
                              item={item}
                              handleCRMSelected={handleCRMSelected}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-sm-12 col-xs-12 col-md-12 col-lg-5">
                  <h5 className="bz-article-title pb-0">MESSAGING SOLUTIONS</h5>
                  <hr />
                  <div className="gatewaylist access_bg">
                    <div
                      className="row"
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="col-12" style={{ textAlign: "center" }}>
                        <p
                          style={{
                            marginBottom: "15px",
                            display: "flex",
                            fontSize: "30px",
                            fontWeight: "bolder",
                            color: "green",
                            justifyContent: "center",
                          }}
                        >
                          {messagingList?.name}
                        </p>
                        <p
                          style={{
                            fontSize: "1em",
                            textAlign: "left",
                            color: "#717171",
                          }}
                        >
                          {messagingList?.desc}
                        </p>
                      </div>

                      <div className="col-10">
                        <button
                          className="btn config_btn"
                          onClick={() => handleSMSSelected()}
                        >
                          {smsConfig?.HasSMSNotificationEnabled
                            ? "Configured"
                            : "Configure"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      {visibleAC && (
        <Dialog
          title={"Configure the Access Control System for check-In customers"}
          onClose={toggleACDialog}
          width={600}
          height={500}
        >
          <div className="p-4">
            {/* Custom Popup Start*/}
            <div className="row" style={{ marginBottom: "1rem" }}>
              <div className="col-sm-12 col-xs-12 col-md-12 col-lg-6">
                <div
                  className="simple_intger access_bg1"
                  onClick={() => handleCustomChange("2")}
                >
                  <h4>Simple Integration</h4>
                  <p>
                    You don't require any additional hardware t setup this. Our
                    system will create barcodes for your customers and they will
                    be granted access by scanning it throug the mobile, tablet
                    or laptop.
                  </p>
                  <div className="simple_intger_radio">
                    <input
                      type="radio"
                      name="selectedSecType"
                      className="k-radio"
                      style={{ verticalAlign: "center" }}
                      aria-describedby="firstNameHint"
                      value="2"
                      checked={selectedSecType?.toString() === "2"}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-xs-12 col-md-12 col-lg-6">
                <div
                  className="simple_intger access_bg1"
                  onClick={() => handleCustomChange("1")}
                >
                  <h4>Hardware/Device base</h4>
                  <p>
                    Select this option if you already have my hardware based
                    access control system in pace or you want to plan fro a
                    biometric of RFID supported devices in your premises.
                  </p>
                  <div className="simple_intger_radio">
                    <input
                      type="radio"
                      name="selectedSecType"
                      className="k-radio"
                      style={{ verticalAlign: "center" }}
                      aria-describedby="firstNameHint"
                      value="1"
                      checked={selectedSecType?.toString() === "1"}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            {/* Custom Popup End */}
            {/* <RadioButton
              name="selectedSecType"
              value={"2"}
              checked={selectedSecType?.toString() === "2"}
              label="Simple"
              onChange={() => handleCustomChange("2")}
            />
            <br />
            <br />
            <RadioButton
              name="selectedSecType"
              value={"1"}
              checked={selectedSecType?.toString() === "1"}
              label="Hardware"
              onChange={() => handleCustomChange("1")}
              ariaDescribedBy={"firstNameHint"}
            /> */}
            {/* <Hint id={"firstNameHint"}>Selected</Hint> */}
            <br />
          </div>
          <DialogActionsBar>
            {/* <Button 
            
            >
              Submit
            </Button> */}
            <button
              className="btn config_btn"
              primary
              onClick={() => onSecurityTypeSelect()}
              disabled={!selectedSecType}
            >
              Submit
            </button>
          </DialogActionsBar>
        </Dialog>
      )}

      {visibleCRM && (
        <Dialog
          title={"Pipedrive integration"}
          onClose={toggleCRMDialog}
          height={"calc(100vh - 2rem)"}
          width={"1000px"}
          style={{ alignItems: "end", paddingRight: "1rem" }}
          className="bz-pipedrive-dialog"
        >
          <PipeDrive
            uuid={props.match?.params?.uuid || ""}
            from={"thirdparty"}
            ref={childRef}
            handleCRMSave={handleCRMSave}
          ></PipeDrive>
          {/* <DialogActionsBar layout={"start"}>
            <BuzopsButton
              onClick={() => handleCRMSave()}
              primary
              disabled={false}
              type={"button"}
              label={"Save"}
            />
          </DialogActionsBar> */}
        </Dialog>
      )}
      {visibleSMS && (
        <Dialog
          title={"SMS integration"}
          onClose={toggleSMSDialog}
          height={"calc(100vh - 2rem)"}
          width={"1000px"}
          style={{ alignItems: "end", paddingRight: "1rem" }}
          className="bz-pipedrive-dialog"
        >
          <SMSIntegration
            uuid={props.match?.params?.uuid || ""}
            from={"thirdparty"}
            ref={childRef}
            handleSMSSave={handleSMSSave}
            smsConfig={smsConfig}
          />
        </Dialog>
      )}
      {LiveStreamEnabled && (
        <Dialog
          title={"Live Stream"}
          onClose={() => setLiveStreamEnabled(false)}
          height={"calc(100vh - 2rem)"}
          width={"calc(100vw/2)"}
          style={{ alignItems: "end", paddingRight: "1rem" }}
        >
          <LiveStream />
        </Dialog>
      )}
    </>
  );
};

export default withNotification(ThirdParty);
