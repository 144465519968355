import React from "react";
import {
  Grid,
  GridColumn as Column,
  GRID_COL_INDEX_ATTRIBUTE,
  GridToolbar,
  GridDetailRowProps,
} from "@progress/kendo-react-grid";
import { Checkbox } from "@progress/kendo-react-inputs";
import moment from "moment";
import { CurrencyFormat } from "utils";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { BiCheckCircle, BiErrorCircle, BiInfoCircle } from "react-icons/bi";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import BuzopsButton from "generic-components/BuzopsButton";
import {
  PromoPayoutDisplayStatus,
  PromotionPayoutStatusType,
  PromotionSubscriptionTypes,
  SourceTransactionPaymentModeEnum,
} from "utils/form-utils";
import {
  AutoComplete,
  DropDownList,
  ListItemProps,
  MultiSelect,
} from "@progress/kendo-react-dropdowns";
import withNotification from "components/Hoc/withNotification";
import BuzopsLoader from "generic-components/BuzopsLoader";
import useAffiliateProgramPayouts from "./useAffiliateProgramPayouts";
import ExcelExport from "atoms/ExcelExport/ExcelExport";
import { MdDownload } from "react-icons/md";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomPopup } from "components/checkout/OrderSummary";
import CustomCalendar, { FilterOptions } from "atoms/CustomCalendar";
import { GetLocalStore } from "utils/storage";
const DATA_ITEM_KEY: string = "PromotionPayoutId";

const PayoutExcelColumns = [
  { field: "Debit", title: "Debit/Credit" },
  { field: "AffiliateFullName", title: "Affiliate Name" },
  { field: "PromotionName", title: "Promotion Name" },
  { field: "SubscriptionType", title: "Subscription Type" },
  { field: "SubscriptionDetails", title: "Subscription Details" },
  { field: "AppliedRewardDetails", title: "Reward Rule" },
  { field: "PayoutAmount", title: "Payout Amount" },
  { field: "CreateDateTime", title: "Generated On" },
  { field: "DisplayStatusDetails", title: "Status" },
];
const DateCell = (props: any) => {
  return (
    <td>
      {props?.dataItem[props?.field]
        ? props?.dataItem[props?.field]
        : "NA"}
    </td>
  );
};

const PayoutDetails = (props: any, handleViewDebits: any) => {
  const rewardAmount = props?.dataItem?.RewardAmount;
  const reconciledRewardAmount = props?.dataItem?.ReconciledRewardAmount;
  const status = props?.dataItem?.Status;
  const checkReconcillation = status === 0 || status === 1 ? true : false;
  if (checkReconcillation && rewardAmount !== reconciledRewardAmount) {
    return (
      <>
        <span
          style={{
            color: props?.dataItem?.IsDebit ? "red" : "green",
            textDecoration: "line-through",
          }}
        >
          {props?.dataItem?.IsDebit
            ? `(${CurrencyFormat(Math.abs(rewardAmount))})`
            : CurrencyFormat(Math.abs(rewardAmount))}
        </span>{" "}
        <span
          style={{
            color: props?.dataItem?.IsDebit ? "red" : "green",
          }}
          className="pr-2"
        >
          {props?.dataItem?.IsDebit
            ? `(${CurrencyFormat(Math.abs(reconciledRewardAmount))})`
            : CurrencyFormat(Math.abs(reconciledRewardAmount))}
        </span>
        {props?.dataItem?.LinkedDebitPayouts?.length > 0 ? (
          <span
            style={{ color: "#1999C0", cursor: "pointer" }}
            key={props?.dataItem?.expanded?.toString()}
            onClick={() => handleViewDebits(props?.dataItem)}
          >
            {props?.dataItem?.expanded ? <i className="fa fa-eye-slash" /> : <i className="fa fa-eye" />}
          </span>
        ) : null}
      </>
    );
  } else if (checkReconcillation && rewardAmount === reconciledRewardAmount) {
    return (
      <span style={{ color: props?.dataItem?.IsDebit ? "red" : "green" }}>
        {props?.dataItem?.IsDebit
          ? `(${CurrencyFormat(Math.abs(reconciledRewardAmount))})`
          : CurrencyFormat(Math.abs(reconciledRewardAmount))}
      </span>
    );
  } else {
    return (
      <>
        <span
          style={{
            color: props?.dataItem?.IsDebit ? "red" : "green",
            textDecoration: "line-through",
          }}
        >
          {props?.dataItem?.IsDebit
            ? `(${CurrencyFormat(Math.abs(rewardAmount))})`
            : CurrencyFormat(Math.abs(rewardAmount))}
        </span>
      </>
    );
  }
};
const PayoutCell = (props: any, handleViewDebits: any) => {
  let PayoutContent = props?.dataItem?.AppliedRewardDetails;
  if (
    props?.dataItem?.SourceTransactionPaymentMode ===
    SourceTransactionPaymentModeEnum.REFUND ||
    props?.dataItem?.SourceTransactionPaymentMode ===
    SourceTransactionPaymentModeEnum.VOIDED
  ) {
    PayoutContent = "[Client Refunded]";
  } else if (
    props?.dataItem?.SourceTransactionPaymentMode ===
    SourceTransactionPaymentModeEnum.CHARGEBACK ||
    props?.dataItem?.SourceTransactionPaymentMode ===
    SourceTransactionPaymentModeEnum.RETURN
  ) {
    PayoutContent =
      props?.dataItem?.SourceTransactionPaymentMode ===
        SourceTransactionPaymentModeEnum.CHARGEBACK
        ? "[Chargeback]"
        : "[Return]";
  }
  return (
    <td>
      {PayoutDetails(props, handleViewDebits)}
      <BuzopsTooltip
        html={
          <a
            title="Payout"
            style={{
              borderBottom: "1px dotted #d9d9d9",
              cursor: "pointer",
            }}
          >
            {PayoutContent}
          </a>
        }
        content={<PayoutToolTip dataItem={props?.dataItem} />}
        position={"right"}
      />
    </td>
  );
};

const StatusCell = (props: any) => {
  const description = PromotionPayoutStatusType?.filter(
    (i: any) => i?.id === props.dataItem[props?.field]
  )?.[0]?.text;
  return <td>{description}</td>;
};

const itemRender = (
  li: React.ReactElement<HTMLLIElement>,
  itemProps: ListItemProps
) => {
  const itemChildren = `${itemProps?.dataItem?.FullName} ${itemProps?.dataItem?.Email}`;

  return React.cloneElement(li, li.props, itemChildren);
};

const AmountCell = (props: any) => {
  return <td>{CurrencyFormat(props?.dataItem[props?.field])} </td>;
};
const ReconciliationDetailCell = (props: any) => {
  return (
    <td>{`${CurrencyFormat(
      Math.abs(props?.dataItem?.DebitReconciled)
    )} from available ${CurrencyFormat(
      Math.abs(props?.dataItem?.RewardAmount)
    )} reconciled`}</td>
  );
};
const ClientPaymentSubTotalTooltip = (data: any) => {
  const tooltipData = data?.dataItem;
  return (
    <table className="table table-sm table-borderless">
      <tbody>
        <tr className="border-0 p-0" style={{ width: "100%" }}>
          <td>{"Payments"}</td>
          <td className="text-center">:</td>
          <td className="text-right">
            {CurrencyFormat(tooltipData?.PageTotalPurchaseAmount)}
          </td>
        </tr>
        <tr className="border-0 p-0" style={{ width: "100%" }}>
          <td>{"Refunds"}</td>
          <td className="text-center">:</td>
          <td className="text-right">
            {CurrencyFormat(tooltipData?.PageTotalAmountRefunded)}
          </td>
        </tr>
        <tr
          className="border-top-1 p-0"
          style={{ width: "100%", borderTop: "1px solid white" }}
        >
          <td>{"Net"}</td>
          <td className="text-center">:</td>
          <td className="text-right">
            {CurrencyFormat(
              tooltipData?.PageTotalPurchaseAmount -
              tooltipData?.PageTotalAmountRefunded
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
const ClientPaymentGrandTotalTooltip = (data: any) => {
  const tooltipData = data?.dataItem;
  return (
    <table className="table table-sm table-borderless">
      <tbody>
        <tr className="border-0 p-0" style={{ width: "100%" }}>
          <td>{"Payments"}</td>
          <td className="text-center">:</td>
          <td className="text-right">
            {CurrencyFormat(tooltipData?.TotalPurchaseAmount)}
          </td>
        </tr>
        <tr className="border-0 p-0" style={{ width: "100%" }}>
          <td>{"Refunds"}</td>
          <td className="text-center">:</td>
          <td className="text-right">
            {CurrencyFormat(tooltipData?.TotalAmountRefunded)}
          </td>
        </tr>
        <tr
          className="border-top-1 p-0"
          style={{ width: "100%", borderTop: "1px solid white" }}
        >
          <td>{"Net"}</td>
          <td className="text-center">:</td>
          <td className="text-right">
            {CurrencyFormat(
              tooltipData?.TotalPurchaseAmount -
              tooltipData?.TotalAmountRefunded
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export const ClientPaymentToolTip = (data: any) => {
  const tooltipData = data?.dataItem;
  const subscriptionType = PromotionSubscriptionTypes?.filter(
    (j: any) => j?.id === tooltipData?.EntityType
  )[0]?.text;
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', gap:'10px' }}>
        <div style={{ width: '50%' }}>{"Promotion Name"}</div>

        <div style={{ display: 'flex', justifyContent: 'space-between', width: '50%', gap: '5px' }}>   <span> :</span> <span style={{ flexGrow: '1' }}> {tooltipData?.PromotionName}</span>  </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', gap:'10px' }}>
        <div style={{ width: '50%' }}>{"Subscription Type"}</div>

        <div style={{ display: 'flex', justifyContent: 'space-between', width: '50%', gap: '5px' }}>   <span> :</span> <span style={{ flexGrow: '1' }}> {subscriptionType}</span>  </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', gap:'10px' }}>
        <div style={{ width: '50%' }}>{"Subscription Name"}</div>

        <div style={{ display: 'flex', justifyContent: 'space-between', width: '50%', gap: '5px' }}>   <span> :</span> <span style={{ flexGrow: '1' }}> {tooltipData?.SubscriptionName}</span>  </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', gap:'10px' }}>
        <div style={{ width: '50%' }}>{"Client Name"}</div>

        <div style={{ display: 'flex', justifyContent: 'space-between', width: '50%', gap: '5px' }}>   <span> :</span> <span style={{ flexGrow: '1' }}> {tooltipData?.ClientFullName}</span>  </div>
      </div>
    </div>
  );
};
export const PayoutToolTip = (data: any) => {
  const tooltipData = data?.dataItem;
  if (!tooltipData?.IsDebit) {
    return (
      <table className="table table-sm table-borderless">
        <tbody>
          <tr className="border-0 p-0" style={{ width: "100%" }}>
            <td className="text-right">{tooltipData?.AppliedRewardDetails}</td>
          </tr>
        </tbody>
      </table>
    );
  }
  let PayoutContent = tooltipData?.AppliedRewardDetails;
  if (
    tooltipData?.SourceTransactionPaymentMode ===
    SourceTransactionPaymentModeEnum.REFUND ||
    tooltipData?.SourceTransactionPaymentMode ===
    SourceTransactionPaymentModeEnum.VOIDED
  ) {
    PayoutContent = `Refunded on ${moment(
      tooltipData?.SourceTransactionDateTime
    ).format("MM/DD/YYYY hh:mm A")}`;
  } else if (
    tooltipData?.SourceTransactionPaymentMode ===
    SourceTransactionPaymentModeEnum.CHARGEBACK ||
    tooltipData?.SourceTransactionPaymentMode ===
    SourceTransactionPaymentModeEnum.RETURN
  ) {
    const textValue =
      tooltipData?.SourceTransactionPaymentMode ===
        SourceTransactionPaymentModeEnum.CHARGEBACK
        ? "Chargeback"
        : "Return";

    PayoutContent = `${tooltipData?.ClientFullName}: ${textValue} on ${moment(
      tooltipData?.SourceTransactionDateTime
    ).format("MM/DD/YYYY hh:mm A")}`;
  }

  return (
    <table className="table table-sm table-borderless">
      <tbody>
        <tr className="border-0 p-0" style={{ width: "100%" }}>
          <td>{tooltipData?.ClientFullName}</td>
          <td className="text-left"> 
          <div style={{display:'flex', justifyContent:'space-between', gap:'5px'}}>
<span>:</span>
         <span> {PayoutContent}</span>
          </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
const ClientPaymentCell = (props: any, handleLinkedPayouts: any) => {
  let amt = 0;
  if (
    props?.dataItem?.SourceTransactionPaymentMode ===
    SourceTransactionPaymentModeEnum.CHARGE
  ) {
    amt = props?.dataItem?.PurchasePrice;
  } else if (
    props?.dataItem?.SourceTransactionPaymentMode ===
    SourceTransactionPaymentModeEnum.CHARGEBACK ||
    props?.dataItem?.SourceTransactionPaymentMode ===
    SourceTransactionPaymentModeEnum.REFUND ||
    props?.dataItem?.SourceTransactionPaymentMode ===
    SourceTransactionPaymentModeEnum.RETURN ||
    props?.dataItem?.SourceTransactionPaymentMode ===
    SourceTransactionPaymentModeEnum.VOIDED
  ) {
    amt = props?.dataItem?.AmountRefunded;
  }
  return (
    <td>
      {" "}
      <BuzopsTooltip
        html={
          <a
            title="Client payment"
            style={{
              borderBottom: "1px dotted #d9d9d9",
              cursor: "pointer",
            }}
          >
            <span style={{ color: props?.dataItem?.IsDebit ? "red" : "green" }}>
              {props?.dataItem?.IsDebit
                ? `(${CurrencyFormat(amt)})`
                : CurrencyFormat(amt)}
            </span>
          </a>
        }
        content={<ClientPaymentToolTip dataItem={props?.dataItem} />}
        position={"right"}
      />
    </td>
  );
};
const ClientPaymentFooter = (props: any, PayoutTotals: any) => {
  return (
    <td style={{ fontSize: "0.85rem" }}>
      <div>
        <BuzopsTooltip
          html={
            <a
              title="Total Purchases"
              style={{
                borderBottom: "1px dotted #d9d9d9",
                cursor: "pointer",
              }}
            >
              {CurrencyFormat(
                PayoutTotals?.PageTotalPurchaseAmount -
                PayoutTotals?.PageTotalAmountRefunded
              )}
            </a>
          }
          content={<ClientPaymentSubTotalTooltip dataItem={PayoutTotals} />}
          position={"bottom"}
        />
        <hr style={{ margin: "3px 0px" }} />
        <BuzopsTooltip
          html={
            <a
              title="Total Purchases"
              style={{
                borderBottom: "1px dotted #d9d9d9",
                cursor: "pointer",
              }}
            >
              {CurrencyFormat(
                PayoutTotals?.TotalPurchaseAmount -
                PayoutTotals?.TotalAmountRefunded
              )}
            </a>
          }
          content={<ClientPaymentGrandTotalTooltip dataItem={PayoutTotals} />}
          position={"right"}
        />
      </div>
    </td>
  );
};

const FooterTotals = (props: any, PayoutTotals: any) => {
  return (
    <td style={{ fontSize: "0.85rem" }}>
      <div>
        <div>Page Total</div>
        <hr style={{ margin: "3px 0px" }} />
        <div>Grand Total</div>
      </div>
    </td>
  );
};

const ClientRewardFooter = (props: any, PayoutTotals: any) => {
  return (
    <td style={{ fontSize: "0.85rem" }}>
      <div>
        <div>{CurrencyFormat(PayoutTotals?.PageTotalReconciledRewardAmount)}</div>
        <hr style={{ margin: "3px 0px" }} />
        <div>{CurrencyFormat(PayoutTotals?.TotalReconciledRewardAmount)}</div>
      </div>
    </td>
  );
};

const DisplayStatusCell = (
  props: any,
  handleLinkedPayouts: any,
  show = false,
  hightlightItem: any = null
) => {
  const Status = props.dataItem?.DisplayStatus;
  let text = null;
  switch (Status) {
    case 0:
      let DisplayMessage = `${props?.dataItem?.DisplayStatusDescription}, ${props?.dataItem?.DisplayStatusDetails} `
      if(props?.dataItem?.IsDebit && props?.dataItem?.DebitBalance > 0 &&
        props?.dataItem?.DebitReconciled === 0){
          DisplayMessage = "Not Reconciled";
        }
      text = (
        <div style={{ display: 'flex', justifyContent: 'space-between', }}>
          <BiInfoCircle color="#ff6700" style={{ marginTop: '2px' }} />
          <span className="pl-1" style={{ flexGrow: '1' }}>{DisplayMessage}</span>{" "}
        </div>
      );
      break;
    case 1:
      let DisplayStatusDetails = props?.dataItem?.DisplayStatusDetails;
      if (
        props?.dataItem?.DebitBalance === 0 &&
        props?.dataItem?.DebitReconciled > 0
      ) {
        DisplayStatusDetails = "Reconciled";
      } else if (
        props?.dataItem?.DebitBalance > 0 &&
        props?.dataItem?.DebitReconciled > 0
      ) {
        DisplayStatusDetails = "Partially Reconciled";
      } else if (
        props?.dataItem?.DebitBalance > 0 &&
        props?.dataItem?.DebitReconciled === 0
      ) {
        DisplayStatusDetails = "Not Reconciled";
      }
      text = (
        <div style={{ display: 'flex', justifyContent: 'space-between', }}>
          <BiCheckCircle color="green" style={{ marginTop: '2px' }} />
          <span className="pl-1" style={{ flexGrow: '1' }}>{DisplayStatusDetails}</span>{" "}
        </div>
      );
      break;
    case 2:
      text = (
        <div style={{ display: 'flex', justifyContent: 'space-between', }}>
          <BiErrorCircle color="red" style={{ marginTop: '2px' }} />
          <span className="pl-1" style={{ flexGrow: '1' }}>{`${props?.dataItem?.DisplayStatusDetails}`}</span>{" "}
        </div>
      );

      break;
    case 3:
      text = (
        <div style={{ display: 'flex', justifyContent: 'space-between', }}>
          <BiErrorCircle color="#ff6700" style={{ marginTop: '2px' }} />
          <span className="pl-1" style={{ flexGrow: '1' }}>{`${props?.dataItem?.DisplayStatusDetails}`}</span>{" "}
        </div>
      );
      break;
    case 4:
      text = (
        <div style={{ display: 'flex', justifyContent: 'space-between', }}>
          <BiInfoCircle color="#ff6700" style={{ marginTop: '2px' }} />
          <span className="pl-1" style={{ flexGrow: '1' }}>{`${props?.dataItem?.DisplayStatusDetails}`}</span>{" "}
        </div>
      );
      break;
    default:
      break;
  }
  let PayoutId = 0
  if (hightlightItem) {
    PayoutId = hightlightItem?.PromotionPayoutId;
  }
  return (
    <td>
      {text}
      {show ? (
        <div>
          {props?.dataItem?.LinkedCreditPayouts?.length > 0 ? (
            <small
              onClick={() => handleLinkedPayouts(props?.dataItem, false, PayoutId)}
              className="btn btn-link" style={{lineHeight:'normal', textAlign:'left', paddingLeft:'18px'}}
            >
              {"View Reconciled Details"}
            </small>
          ) : null}
        </div>
      ) : null}
    </td>
  );
};

const AffiliateProgramPayouts = (props: any) => {
  let ClubName = GetLocalStore("tenantUserSession")?.ClubName;
  if (!props?.admin) {
    ClubName = GetLocalStore("AffiliateDetails")?.UserSession?.ClubName;
  }

  const {
    toggleDialog,
    singlePayout,
    handleChangeSinglePayout,
    notesLoading,
    singlePayoutLoading,
    SubmitNotes,
    selectedPayout,
    handleSingleUpdate,
    historyLoading,
    payoutHistory,
    handleNotesDialog,
    handleEditSinglePayout,
    handleViewPayoutHistory,
    loading,
    dataState,
    // filter,
    // filterChange,
    pageRef,
    totalItems,
    pageChange,
    selectAll,
    handleSelectAll,
    bulkPayout,
    handleBulkPayout,
    handleCancelBulkUpdate,
    btnLoading,
    handleCheckboxChange,
    handleBulkUpdate,
    showDialog,
    showBulkAction,
    getExcelData,
    handleBulkPayoutDialog,
    filteredPromoters,
    handlePromoterSelecton,
    selectedPromoter,
    selectedStatus,
    handleFilterStatus,
    setFromDateToDateFunc,
    PayoutTotals,
    handleMarkPaidToday,
    dropDownBtnLoading,
    handleRejectPayout,
    BulkActionItems,
    handleBulkMarkPaidToday,
    handleBulkRejectPayout,
    handleLinkedPayouts,
    linkedPayouts,
    fetchLinkedPayoutsLoading,
    handleConfirmReject,
    handleCloseConfirmReject,
    rejectConfirmDialog,
    selectedRejectItem,
    showDebitRecords,
    handleViewDebits,
  } = useAffiliateProgramPayouts(props);
  const _exporter = React.createRef<any>();
  const excelExport = () => {
    if (_exporter.current) {
      _exporter.current?.save();
    }
  };



  const NotesDialog = () => {
    return (
      <>
        <Dialog title={"Add Notes"} onClose={() => toggleDialog("Notes")}>
          <p
            style={{
              margin: "0",
              width: "350px",
              textAlign: "center",
              fontSize: "16px",
            }}
            className="bz-addNotes-textarea"
          >
            <textarea
              name="Notes"
              value={singlePayout?.Notes}
              className="form-control"
              onChange={(e) =>
                handleChangeSinglePayout(e.target.value, "Notes")
              }
              rows={4}
              cols={50}
            ></textarea>
          </p>
          <DialogActionsBar>
            <Button
              onClick={() => toggleDialog("Notes")}
              disabled={notesLoading}
            >
              {"Cancel"}
            </Button>
            <BuzopsButton
              label={"Save"}
              loading={notesLoading}
              disabled={
                notesLoading ||
                !singlePayout?.Notes ||
                singlePayout?.Notes?.trim()?.length === 0 ||
                !props?.admin
              }
              onClick={() => SubmitNotes(selectedPayout)}
            />
          </DialogActionsBar>
        </Dialog>
      </>
    );
  };
  const RejectConfirmDialog = () => {
    console.log(selectedRejectItem,'selectedRejectItem')
    return (
      <Dialog onClose={() => handleCloseConfirmReject()} width={"45%"}>
        <p className={"dialogContainer"}>
          {selectedRejectItem?.dataItem?.IsDebit ? (
            <div>
              <p>
                {selectedRejectItem?.dataItem?.LinkedCreditPayouts?.length > 0 ? `Performing this action will waive the selected debit payout and all the applied reconciliations will be rolled back. This action cannot be undone.`:`Performing this action will waive the selected debit payout`}
              </p>
              <p className="buz-font-600">
                Are you sure you would like to proceed?
              </p>
            </div>
          ) : (
            <div>
              <p>
                {selectedRejectItem?.dataItem?.LinkedDebitPayouts?.length > 0 ? `Performing this action will reject the selected credit payout and all the applied reconciliations will be rolled back. This action cannot be undone.`:`Performing this action will reject the selected credit payout`}
              </p>
              <p className="buz-font-600">
                Are you sure you would like to proceed?
              </p>
            </div>
          )}
        </p>
        <DialogActionsBar>
          <Button
            onClick={() => handleCloseConfirmReject()}
            disabled={dropDownBtnLoading?.loading}
          >
            {"No"}
          </Button>
          <BuzopsButton
            label={"Yes"}
            key={dropDownBtnLoading?.loading?.toString()}
            loading={dropDownBtnLoading?.loading}
            disabled={dropDownBtnLoading?.loading}
            onClick={() => handleRejectPayout(selectedRejectItem)}
          />
        </DialogActionsBar>
      </Dialog>
    );
  };
  const PayoutStatusDialog = () => {
    return (
      <>
        <Dialog
          title={"Update Payout Status"}
          onClose={() => toggleDialog("SinglePayout")}
        >
          <div className="d-flex flex-column mb-1">
            <div className="bzc-provider-name   py-2 px-2">
              <span className="k-label">Payout Status</span>
              <div className="bzc-time-sec">
                <DropDownList
                  id={`SinglePayoutStatus`}
                  name={`SinglePayoutStatus`}
                  data={PromotionPayoutStatusType?.filter(
                    (i: any) => i?.id !== 0
                  )}
                  textField="text"
                  style={{ width: "100%" }}
                  dataItemKey="id"
                  value={
                    PromotionPayoutStatusType?.filter(
                      (i: any) => i?.id === singlePayout?.Status
                    )[0]
                  }
                  onChange={(e) =>
                    handleChangeSinglePayout(e.value?.id, "Status")
                  }
                />
              </div>
            </div>
            {singlePayout.Status === 1 ? (
              <div className="bzc-provider-name py-2 px-2">
                <span className="k-label">Paid Date</span>
                <div className="bzc-time-sec">
                  <DatePicker
                    id={"PaidDateTime"}
                    name={"PaidDateTime"}
                    max={new Date()}
                    onChange={(e) => {
                      handleChangeSinglePayout(e.value, "PaidDateTime");
                    }}
                    width={"100%"}
                    value={
                      singlePayout?.PaidDateTime
                        ? new Date(singlePayout?.PaidDateTime)
                        : new Date()
                    }
                    popup={CustomPopup}
                  />
                </div>
              </div>
            ) : null}
            <div className="bzc-provider-name px-2">
              <span className="k-label">Notes</span>
              <div className="bzc-time-sec">
                <textarea
                  name="SinglePayoutNotes"
                  value={singlePayout.Notes}
                  className="form-control"
                  onChange={(e) =>
                    handleChangeSinglePayout(e.target.value, "Notes")
                  }
                  rows={4}
                  cols={50}
                ></textarea>
              </div>
            </div>
          </div>
          <DialogActionsBar>
            <Button
              onClick={() => toggleDialog("SinglePayout")}
              disabled={singlePayoutLoading}
            >
              {"Cancel"}
            </Button>
            <BuzopsButton
              label={"Update"}
              loading={singlePayoutLoading}
              disabled={singlePayoutLoading}
              onClick={() => handleSingleUpdate(selectedPayout)}
            />
          </DialogActionsBar>
        </Dialog>
      </>
    );
  };
  const BulkPayoutStatusDialog = () => {
    return (
      <>
        <Dialog
          title={"Update Payout Status"}
          onClose={() => handleCancelBulkUpdate()}
        >
          <div className="d-flex flex-column mb-1">
            <div className="bzc-provider-name py-2 ">
              <span className="k-label">Payout Status</span>
              <div className="bzc-time-sec">
                <DropDownList
                  id={`BulkPayoutStatus`}
                  name={`BulkPayoutStatus`}
                  // disabled={filterData?.length === 0}
                  data={PromotionPayoutStatusType?.filter(
                    (i: any) => i?.id !== 0
                  )}
                  style={{ width: "100%" }}
                  textField="text"
                  dataItemKey="id"
                  value={
                    bulkPayout.Status || {
                      id: -1,
                      text: "Please select Payout Status",
                    }
                  }
                  onChange={(e) => handleBulkPayout(e.value, "Status")}
                />
              </div>
            </div>
            {bulkPayout.Status?.id === 1 ? (
              <div className="bzc-provider-name py-2 ">
                <span className="k-label">Paid Date</span>
                <div className="bzc-time-sec">
                  <DatePicker
                    id={"PaidDateTime"}
                    name={"PaidDateTime"}
                    max={new Date()}
                    onChange={(e) => {
                      handleBulkPayout(e.value, "PaidDateTime");
                    }}
                    width={"100%"}
                    value={
                      bulkPayout?.PaidDateTime
                        ? new Date(bulkPayout?.PaidDateTime)
                        : new Date()
                    }
                    popup={CustomPopup}
                  />
                </div>
              </div>
            ) : null}
            <div className="bzc-provider-name py-2">
              <span className="k-label">Notes</span>
              <div className="bzc-time-sec">
                <textarea
                  name="BulkPayoutNotes"
                  value={bulkPayout.Notes}
                  className="form-control"
                  onChange={(e) => handleBulkPayout(e.target.value, "Notes")}
                  rows={4}
                  cols={50}
                ></textarea>
              </div>
            </div>
          </div>
          <DialogActionsBar>
            <Button
              onClick={() => handleCancelBulkUpdate()}
              disabled={btnLoading}
            >
              Cancel
            </Button>
            <BuzopsButton
              label={"Update"}
              loading={btnLoading}
              disabled={!bulkPayout.Status || btnLoading}
              onClick={() => handleBulkUpdate()}
            />
          </DialogActionsBar>
        </Dialog>
      </>
    );
  };

  const ChildPayoutCell = (propData: any, parentData: any) => {
    const linkedDebits = parentData?.LinkedDebitPayouts;
    const AmountReconciled =
      linkedDebits?.find(
        (i: any) => i?.PayoutId === propData?.dataItem?.PromotionPayoutId
      )?.AmountReconciled || 0;
    return <td>{CurrencyFormat(AmountReconciled)}</td>;
  };
  const PayoutHistoryDialog = () => {
    return (
      <>
        <Dialog
          title={"Payout History"}
          onClose={() => toggleDialog("History")}
          width={"45%"}
          height={"350px"}
        >
          {historyLoading ? (
            <BuzopsLoader type="list" />
          ) : (
            <Grid data={payoutHistory} pageable={false} className=" bzc-grid">
              <Column field="Status" title="Status" cell={StatusCell} width={110}/>
              <Column field="Notes" title="Notes"  width={110}/>
              <Column field="UpdateDateTime" title="Updated On" width={110} />
              <Column field="UpdatedByFullName" title="Updated By"   width={110} />
            </Grid>
          )}

          <DialogActionsBar>
            <BuzopsButton
              onClick={() => toggleDialog("History")}
              label={"Close"}
            />
          </DialogActionsBar>
        </Dialog>
      </>
    );
  };

  const LinkedPayoutsDialog = () => {
    const data = linkedPayouts.childItems || [];
    console.log(linkedPayouts.parentItem, "linkedPayouts.parentItem");
    if (linkedPayouts.parentItem?.IsDebit) {
      const highlightclassName = "highlight-payout"
      if (linkedPayouts?.hightlight > 0) {
      }
      return (
        <>
          <Dialog
            title={"Reconciliation Details"}
            onClose={() => toggleDialog("LinkedPayouts")}
            width={"65%"}
            height={"400px"}
          >
            {fetchLinkedPayoutsLoading ? (
              <BuzopsLoader type="list" />
            ) : (
              <>
                <p>{` The Payout Debit of `}<span style={{color:'red'}}>({CurrencyFormat(
                  Math.abs(linkedPayouts.parentItem?.RewardAmount)
                )})</span> {` was generated for the refund/ chargeback/ return transactions of ${CurrencyFormat(
                  linkedPayouts.parentItem?.AmountRefunded
                )} on ${linkedPayouts.parentItem?.SourceTransactionDateTime
                  } for ${linkedPayouts.parentItem?.ClientFullName}.`}</p>

                <p>
                  <span style={{color:'red'}}>({CurrencyFormat(
                    Math.abs(linkedPayouts.parentItem?.RewardAmount)
                  )})</span> was used for reconciliation with a balance of {
                    Math.abs(linkedPayouts.parentItem?.ReconciledRewardAmount) > 0 ? <span style={{color:'red'}}>({CurrencyFormat(
                      Math.abs(linkedPayouts.parentItem?.ReconciledRewardAmount)
                  )})
                    </span> : <span>{CurrencyFormat(
                    Math.abs(linkedPayouts.parentItem?.ReconciledRewardAmount)
                  )}
                    </span>}
                </p>
                <p>
                  <b>
                    The credit payouts transactions reconciled are listed below
                    :
                  </b>
                </p>
                <Grid data={data} pageable={false} className=" bzc-grid">
                  <Column
                    title="Payout Credit"
                  
                    cell={(props: any) => (
                      <td className={linkedPayouts?.hightlight === props?.dataItem?.PromotionPayoutId ? highlightclassName : ""} >
                        {`${CurrencyFormat(
                          Math.abs(props?.dataItem?.RewardAmount)
                        )} on ${props?.dataItem?.CreateDateTime}`}
                      </td>
                    )}
                  />
                  <Column
                    title="Reconciled"
                    width={110}
                    cell={(props: any) =>
                      {
                        const linkedCredits = linkedPayouts.parentItem?.LinkedCreditPayouts;
                        const AmountReconciled =
                          linkedCredits?.find(
                            (i: any) => i?.PayoutId === props?.dataItem?.PromotionPayoutId
                          )?.AmountReconciled || 0;

                       return (
                        <td className={linkedPayouts?.hightlight === props?.dataItem?.PromotionPayoutId ? highlightclassName : ""} style={{
                          color: "red",
                        }}>
                          {`(${CurrencyFormat(AmountReconciled)})`}
                        </td>
                      )
                      } 
                  }
                  />
                  <Column
                    title="Balance"
                    width={90}
                    cell={(props: any) => (
                      <td className={linkedPayouts?.hightlight === props?.dataItem?.PromotionPayoutId ? highlightclassName : ""} >
                        {`${CurrencyFormat(
                          props?.dataItem?.ReconciledCreditBalance
                        )}`}
                      </td>
                    )}
                  />
                  <Column
                    field="DisplayStatusDetails"
                    title="Payout Status"
                    cell={(props: any) => (
                      <td className={linkedPayouts?.hightlight === props?.dataItem?.PromotionPayoutId ? highlightclassName : ""} >{`${props?.dataItem?.DisplayStatusDetails}`}</td>
                    )}
                  />
                </Grid>
              </>
            )}
            {linkedPayouts.parentItem?.LinkedCreditPayouts?.length === 0 ? (
              <p>
                {`If you would like to waive the debit payout for the Affiliate ${linkedPayouts.parentItem?.AffiliateFullName}, you may click on the "Waive" button below.`}
              </p>
            ) : null}

            <DialogActionsBar>
              {linkedPayouts.parentItem?.LinkedCreditPayouts?.length === 0 ? (
                <BuzopsButton
                  loading={
                    dropDownBtnLoading?.id ===
                    linkedPayouts.parentItem?.PromotionPayoutId &&
                    dropDownBtnLoading?.loading
                  }
                  onClick={() => {
                    handleConfirmReject(linkedPayouts.parentItem, true, true);
                  }}
                  label={"Waive"}
                />
              ) : null}
              <BuzopsButton
                onClick={() => toggleDialog("LinkedPayouts")}
                label={"Close"}
              />
            </DialogActionsBar>
          </Dialog>
        </>
      );
    } else {
      return (
        <>
          <Dialog
            title={"Reconciliation Details"}
            onClose={() => toggleDialog("LinkedPayouts")}
            width={"55%"}
            height={"400px"}
          >
            {fetchLinkedPayoutsLoading ? (
              <BuzopsLoader type="list" />
            ) : (
              <>
                {`The Payout Credit of ${CurrencyFormat(
                  Math.abs(linkedPayouts.parentItem?.RewardAmount)
                )} has been reconciled and has a balance of ${CurrencyFormat(
                  linkedPayouts.parentItem?.ReconciledRewardAmount
                )}`}

                <p>
                  <b>
                    The refund/ chargeback/ return transactions used for
                    reconciliation are listed below :
                  </b>
                </p>
                <Grid data={data} pageable={false} className=" bzc-grid">
                  <Column title="Money Out">
                    <Column field="ClientFullName" title="Client Name" />
                    <Column
                      field="AmountRefunded"
                      title="Transaction Amount"
                      cell={AmountCell}
                    />
                    <Column
                      field="SourceTransactionDateTime"
                      title="Transaction Date"
                    />
                  </Column>
                  <Column
                    field="AmountReconciled"
                    title="Reconciliation Detail"
                    cell={(props: any) => ReconciliationDetailCell(props)}
                  />
                </Grid>
              </>
            )}

            <DialogActionsBar>
              <BuzopsButton
                onClick={() => toggleDialog("LinkedPayouts")}
                label={"Close"}
              />
            </DialogActionsBar>
          </Dialog>
        </>
      );
    }
  };

  const handleView = async (screen: string, item?: any) => {
    if (screen === "history") {
      handleViewPayoutHistory(item);
    } else if (screen === "notes") {
      handleNotesDialog(item);
    } else if (screen === "edit") {
      handleEditSinglePayout(item);
    } else if (screen === "MarkPaidToday") {
      handleMarkPaidToday(item);
    } else if (screen === "reject") {
      handleConfirmReject(item, false);
    }
  };
  const handleBulkView = (screen: string) => {
    if (screen === "edit") {
      handleBulkPayoutDialog();
    } else if (screen === "MarkPaidToday") {
      handleBulkMarkPaidToday();
    } else if (screen === "reject") {
      handleBulkRejectPayout();
    }
  };

  const ActionCell = (propsData: any, admin: boolean) => {
    const navigationAttributes = useTableKeyboardNavigation(
      propsData.PromotionPayoutId
    );
    const addItems = [
      {
        text: "Mark as Paid Today",
        key: "MarkPaidToday",
        visible: propsData?.dataItem?.DisplayStatus !== 1 && admin,
      },
      {
        text: "Edit Payout",
        key: "edit",
        visible: admin,
      },
      {
        text: propsData?.dataItem?.IsDebit ? "Waive" : "Reject",
        key: "reject",
        visible: propsData?.dataItem?.DisplayStatus !== 2 && admin,
      },
      {
        text: "History",
        key: "history",
        visible: true,
      },
      {
        text: "Notes",
        key: "notes",
        visible: true,
      },
    ];
    return (
      <td
        style={propsData.style}
        className={propsData.className}
        colSpan={propsData.colSpan}
        role={"gridcell"}
        aria-colindex={propsData.ariaColumnIndex}
        aria-selected={propsData.selected}
        {...{ [GRID_COL_INDEX_ATTRIBUTE]: propsData.columnIndex }}
        {...navigationAttributes}
      >
        <div className="d-flex flex-wrap justify-content-start align-items-center">
          <DropDownButton
            text={
              <>
                {dropDownBtnLoading?.loading &&
                  propsData?.dataItem?.PromotionPayoutId ===
                  dropDownBtnLoading?.id &&
                  !showDialog?.LinkedPayouts ? null : (
                  <i className={`fas fa-cogs`}></i>
                )}{" "}
                <i
                  className={`${dropDownBtnLoading?.loading &&
                    propsData?.dataItem?.PromotionPayoutId ===
                    dropDownBtnLoading?.id &&
                    !showDialog?.LinkedPayouts
                    ? "btnloading fa fa-spinner fa-spin"
                    : "btnNotloading"
                    }`}
                ></i>
                Perform Action
              </>
            }
            onItemClick={(e: any) =>
              handleView(e?.item?.key, propsData?.dataItem)
            }
            items={addItems?.filter((i: any) => i?.visible)}
            primary={false}
            className=""
            popupSettings={{
              animate: false,
              popupClass: "payoutPerformActions",
            }}
          />
        </div>
      </td>
    );
  };
  const BulkUpdatePayout = () => {
    const filterData = dataState?.filter((i: any) => i?.selected === true);
    return (
      <>
        <div className="bz-cancellation-type mb-1">
          <div className="bzc-provider-name">
            <span className="k-label">Select All</span>
            <div className="bzc-time-sec">
              <Checkbox
                name="SelectAllCheckBox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
            </div>
          </div>
          {filterData?.length > 0 ? (
            <div className="d-flex align-items-end">
              <DropDownButton
                text={
                  <>
                    <i className={`fas fa-cogs`}></i>
                    Perform Action
                  </>
                }
                onItemClick={(e: any) => handleBulkView(e?.item?.key)}
                items={BulkActionItems?.filter((i: any) => i?.visible)}
                primary={false}
                className=""
              />
            </div>
          ) : null}
        </div>
      </>
    );
  };

  const DetailComponent = (props: GridDetailRowProps) => {
    if (showDebitRecords?.loading) {
      return (<div style={{ height: "150px", width: "100%" }}>
        <BuzopsLoader />
      </div>)
    }
    const data = showDebitRecords.records;
    const parentItem = showDebitRecords.dataItem;

    if (data?.length > 0) {
      return (
        <Grid data={data}  className="payoutaffiliatesdetails">
          <Column
            field="Payout"
            title="Payout"
            cell={(props: any) => ChildPayoutCell(props, parentItem)}
          />
          <Column field="CreateDateTime" title="Created" />
          
          <Column
            field="DisplayStatusDetails"
            title="Status"
            cell={(propData: any) =>
              DisplayStatusCell(propData, handleLinkedPayouts, true, parentItem)
            }
          />
           
        </Grid>
      );
    }
  };

  return (
    <>
      <div className={`d-flex w-100 justify-content-center flex-column pb-1 ${!props?.hideTitle ? "manage-affiliate-container-grid":""}  payout`}>
        <div className={`row py-2 ${!props?.hideTitle ? "px-3":""}  d-flex align-items-center`}>
          <div className="col-md-12">
            {" "}
            {!props?.hideTitle ?
            <h6 className="bz_fs_1_5 affiliatemainheading mb-0">
              Payouts
            </h6> :null}
            {!props?.hideTitle ?
            <p className="text-muted desaffiliates mt-1 mb-1">
              Effortlessly oversee and manage affiliate payouts for a
              streamlined and efficient partnership experience.
            </p>:null}
          </div>
        </div>
        <div
          className={`metric-list-row  row ${!props?.hideTitle ? "px-4":"px-1"}`}
          style={{ marginBottom: "0" }}
        >
          <div className="col-md-12  col-12 paadingpayouts ">
            <div className="filter-payouts row">
              {!props?.AffiliateUserId ? (
                <div className="px-1 col-lg-4 col-md-4 mb-1">
                  <AutoComplete
                    data={filteredPromoters}
                    itemRender={itemRender}
                    style={{ width: "100%" }}
                    className="select_clientdrop"
                    value={
                      selectedPromoter?.type === "search"
                        ? selectedPromoter?.value
                        : selectedPromoter?.value?.FullName
                    }
                    // style={{width:'100%'}}
                    onChange={(e: any) => handlePromoterSelecton(e)}
                    placeholder={"Search Affiliate"}
                  />
                </div>
              ) : null}
              <div className="px-1 col-lg-4 col-md-4 mb-3">
                <MultiSelect
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                  placeholder="Select Payout Status"
                  onChange={handleFilterStatus}
                  value={selectedStatus.map((item: any) =>
                    PromoPayoutDisplayStatus.find((c) => c.id === item)
                  )}
                  data={PromoPayoutDisplayStatus}
                  textField="text"
                />
              </div>
              <div className="px-1 col-lg-2 col-md-2 col-8 paadingpayouts  mb-3 ">
                <CustomCalendar
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary w-100"
                  setFromDateToDate={setFromDateToDateFunc}
                  selectedDateItem={FilterOptions.ThisMonth}
                  onlyPastDates
                />
              </div>
              {props?.AffiliateUserId ? (
                <div className="px-1 col-lg-6 col-md-6 col-4 paadingpayouts  text-right mb-3">
                  <div className="row">
                    <div className="col-lg-12 align-items-start justify-content-end paadingpayouts ">
                      <div className="export-btn-container mr-1">
                        <span className="download-icon">
                          <MdDownload
                            onClick={excelExport}
                            title="Download"
                            color="#008DB9"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {!props?.AffiliateUserId ? (
                <div className="px-1 col-lg-2 col-md-2 col-4 paadingpayouts  text-right mb-3">
                  <div className="row">
                    <div className="col-lg-12 align-items-start justify-content-end paadingpayouts ">
                      <div className="export-btn-container mr-1">
                        <span className="download-icon">
                          <MdDownload
                            onClick={excelExport}
                            title="Download"
                            color="#008DB9"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <ExcelExport
          data={getExcelData() || []}
          fileName={`${ClubName} Affiliate Payouts-${moment().format(
            "MM-DD-YYYY HH:mm:ss"
          )}.xlsx`}
          _exporter={_exporter}
          columns={PayoutExcelColumns}
        />
        {loading ? (
          <BuzopsLoader type="list" />
        ) : (
          <Grid
            className={`bzc-grid payouts ${props?.admin && showBulkAction
              ? "bz-affiliate-payout-grid"
              : "bz-affiliate-payout-grid-no-checkbox"
              }`}
            fixedScroll={true}
            data={dataState}
            dataItemKey={DATA_ITEM_KEY}
            detail={DetailComponent}
            expandField="expanded"
            // filterable={false}
            // filter={filter}
            // onFilterChange={filterChange}
            skip={pageRef?.current.skip}
            take={pageRef?.current.take}
            total={totalItems}
            pageable={{
              info: true,
              type: "numeric",
              previousNext: true,
              pageSizes: [10, 20, 50, 100],
            }}
            onPageChange={pageChange}
            groupable={{ footer: "visible", enabled: false }}
          >
            {showBulkAction && props?.admin ? (
              <GridToolbar>{BulkUpdatePayout()}</GridToolbar>
            ) : null}
            {props?.admin && showBulkAction ? (
              <Column
                filterable={false}
                cell={(props) => (
                  <td>
                    <Checkbox
                      name={`Checkbox-${props.dataItem.PromotionPayoutId}`}
                      id={`Checkbox-${props.dataItem.PromotionPayoutId}`}
                      checked={props.dataItem.selected === true}
                      onChange={(e) => handleCheckboxChange(e, props.dataItem)}
                    />
                  </td>
                )}
              />
            ) : null}
            {!props?.AffiliateUserId ? (
              <Column
                field="AffiliateFullName"
                title="Affiliate Name"
                footerCell={(data: any) => FooterTotals(data, PayoutTotals)}
              />
            ) : null}
            {/* <Column field="PromotionName" title="Promotion Name" /> */}
            <Column
              field="AppliedRewardDetails"
              title="Payout"
              cell={(props) => PayoutCell(props, handleViewDebits)}
            
              footerCell={(data: any) => ClientRewardFooter(data, PayoutTotals)}
            />
            <Column field="CreateDateTime" title="Created" cell={DateCell} />
            <Column
              field="PromotionName"
              title="Client Payment"
              cell={(propData: any) =>
                ClientPaymentCell(propData, handleLinkedPayouts)
              }
              footerCell={(data: any) =>
                ClientPaymentFooter(data, PayoutTotals)
              }
            />
            <Column
              field="DisplayStatusDetails"
              title="Status"
              cell={(propData: any) =>
                DisplayStatusCell(propData, handleLinkedPayouts, true)
              }
            />
            <Column
              field="Action"
              title="Action"
              cell={(propData: any) => ActionCell(propData, props?.admin)}
            />
          </Grid>
        )}

        {rejectConfirmDialog && RejectConfirmDialog()}
        {showDialog?.Notes && NotesDialog()}
        {showDialog?.SinglePayout && PayoutStatusDialog()}
        {showDialog?.BulkPayout && BulkPayoutStatusDialog()}
        {showDialog?.History && PayoutHistoryDialog()}
        {showDialog?.LinkedPayouts && LinkedPayoutsDialog()}
      </div>
    </>
  );
};

export default withNotification(AffiliateProgramPayouts);
