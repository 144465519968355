import React, { useEffect, useRef, useState } from "react";
import { Card } from "@progress/kendo-react-layout";
import { getTimeFormat } from "utils";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { PackageService } from "services/package/index.service";
import { ListView } from "@progress/kendo-react-listview";
import moment from "moment";
import { Loader } from "@progress/kendo-react-indicators";

const AuditHistory = (props: any) => {
  const showMoreRef = useRef<any>();
  const [dataList, setDataList] = useState<any>({
    pageNumber: 0,
    maxPageNumber: 0,
    auditHistory: [],
  });
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    fetchAudit(0);
  }, [props?.TypeOfChange, props?.allItems]);

  const fetchAudit = async (pageNumber: number) => {
    if (pageNumber === 0) {
      setloading(true);
    }
    const pageSize = 10;
    const req: any = {
      PageNumber: pageNumber,
      PageSize: pageSize,
      UserMemberId: props?.UserMemberId,
      SortBy: "AuditDate",
      SortDirection: "desc",
    };
    if (!props?.allItems) {
      req.Type = props?.Type;
      req.EntityId = props?.EntityId;
    }
    if (props?.TypeOfChange) {
      req.TypeOfChange = props?.TypeOfChange;
    }
    const auditData = new PackageService();
    const result = await auditData.getAuditHistory(req);
    if (pageNumber === 0) {
      setloading(false);
    }
    if (result?.ResponseCode === 100) {
      const maxPageNumber = result.TotalItems / pageSize;
      if (pageNumber === 0) {
        setDataList({
          ...dataList,
          pageNumber: pageNumber,
          maxPageNumber: maxPageNumber,
          auditHistory: result.Items || [],
        });
      }
      return result.Items || [];
    }
    return [];
  };
  const handlePageChange = async (status = "more") => {
    if (status === "more") {
      const check = await requestIfNeeded();
      if (check.status) {
        setPageLoading(true);
        const moreData = await fetchAudit(check.page);
        const test = dataList?.auditHistory?.concat(moreData);
        setPageLoading(false);
        if (moreData.length > 0) {
          setDataList({
            ...dataList,
            auditHistory: test,
            pageNumber: check.page,
          });
          if (showMoreRef.current) {
            showMoreRef.current?.scrollIntoView({ behavior: "smooth" });
          }
        }
      }
    }
  };

  const requestIfNeeded = () => {
    const nextPage = dataList.pageNumber + 1;
    const maxPageNum = dataList.maxPageNumber;

    if (nextPage <= maxPageNum) {
      return {
        status: true,
        page: nextPage,
      };
    } else {
      return {
        status: false,
        page: nextPage,
      };
    }
  };
  const MyItemRender = (props: any) => {
    let item = props.dataItem;
    return (
      <div
        style={{ backgroundColor: "#fff" }}
        className="py-2 bz-package-audit-history-list"
      >
        {" "}
        <span className="text-muted d-block mb-0 audit-flex">
          <span>
            <i className="far fa-calendar-alt"></i>
          </span>
          <div>
            <span className="bz-activity-time-date text-capitalize">
              {" "}
              {moment(item?.AuditDate).format("L")}{" "}
              {getTimeFormat(item?.AuditDate)}
            </span>
            <span className="bz-activity-dis-div">{item?.ActionDetail}</span>
            <label className="bz-requestedBy font-italic bz-fw-600">
              {item?.RequestedBy}
            </label>{" "}
          </div>
        </span>
      </div>
    );
  };

  return (
    <>
      {" "}
      <div className={"row bz-auditHistory-blk"}>
        {props?.heading && (
          <h5 className="mb-2 bz_fs_1_5">
            {props?.TypeOfChange ? "Coupon Audit" : "Audit History"}
          </h5>
        )}
        {loading ? (
          <BuzopsLoader type={"list"} />
        ) : (
          <>
            {dataList?.auditHistory.length === 0 ? (
              <>
                <div className={"bz-norecords-available text-muted"}>
                  <span>
                    {props?.TypeOfChange
                      ? "No Coupon history Available"
                      : "No Audit history Available"}
                  </span>
                </div>
              </>
            ) : (
              <>
                <Card
                  className={
                    "bzo-services-container bz-package-audit-history border-0"
                  }
                >
                  <ListView item={MyItemRender} data={dataList?.auditHistory || []} />
                  <div className="d-flex justify-content-center align-items-center bz-services-showMore">
                    <div ref={showMoreRef}>
                      {pageLoading ? (
                        <>
                          <Loader type="converging-spinner" />
                        </>
                      ) : (
                        <>
                          {dataList?.pageNumber + 1 <
                            dataList?.maxPageNumber && (
                              <span
                                className="btn btn-link  align-baseline bz-cursor-pointer p-0"
                                onClick={() => handlePageChange()}
                              >
                                View More <i className="fas fa-caret-down"></i>
                              </span>
                            )}
                        </>
                      )}
                    </div>
                  </div>
                </Card>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default AuditHistory;
