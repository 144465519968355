const LiveStreamExcelColumns = [
  { field: "Occured", title: "Time & Date" },
  { field: "Type", title: "Checked in for" },
  { field: "TrainerName", title: "Provider Name" },
  { field: "EntityName", title: "Service Name" },
  { field: "ScheduledStartTime", title: "Appointment Time" },
  { field: "FullName", title: "Client Name" },
  { field: "ClubName", title: "Location Name" },
];

const CheckinTypeOptions = [
  { id: 1, text: `All Check-Ins` },
  { id: 0, text: `Location` },
  {
    id: 2,
    text: `Group`,
  },
  {
    id: 3,
    text: `One-on-One`,
  },
];

const LiveStreamFilterOptions = [
  { id: 1, text: `Last 1 hour` },
  {
    id: 6,
    text: `Last 6 hours`,
  },
  {
    id: 0,
    text: `Current Day`,
  },
];

const CheckinsConstants = {
  LiveStreamExcelColumns,
  CheckinTypeOptions,
  LiveStreamFilterOptions,
};

export default CheckinsConstants;
