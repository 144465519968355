import { Dialog } from "@progress/kendo-react-dialogs";
import {
  Field,
  Form,
  FormElement,
} from "@progress/kendo-react-form";
import { useState } from "react";
import { emailValidator } from "validators/validator";
import { FormInput } from "utils/form-components";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import BuzopsButton from "generic-components/BuzopsButton";
import { ClassService } from "services/class/index.service";
import { TenantService } from "services/tenant/index.service";
import { Error } from "@progress/kendo-react-labels";

const UpdateSubmemberEmailDialog = (props: any) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const classService = new ClassService();
  const service = new TenantService();

  const [disabledContinue, setDisabledContinue] = useState(false);
  const [timer, setTimer] = useState<any>();

  const validateEmail = async (value: string) => {
    if (!value || value?.trim()==="") return;
    setLoading(true);
    const resp = await classService.validateEmail(value);
    if (resp.Item.UserId !== 0) {
      setErrorMessage("The email address provided is already taken. Please provide an alternate email address.")
      
      setDisabledContinue(true);
    }else{
      setErrorMessage("")
    }
    setLoading(false);
  };

  const onChangeEmail = (event: any) => {
    const { value } = event;
    const val=value?.trim()
    setEmail(val);
    setDisabledContinue(false);
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      validateEmail(val);
    }, 500);
    setTimer(newTimer);
  };

  const handleEmailSubmit = async (values: any) => {
    setLoading(true);

    const res = await service?.saveUserInfo({
      ...props.userDetails,
      Email: values.email,
      MemberType: props.userDetails?.MemberType?.id,
    });
    if (res?.ResponseCode === 100) {
      props.setMessage("Email updated successfully");
      props.setNotification({
        ...props.notification,
        success: true,
        error: false,
      });
      props.setUserDetails({ ...props.userDetails, Email: values.email });
      props?.setShowUpdateEmailDialog(false);
    } else {
      const errorMsg = res?.ErrorMessages?.[0] || "Error in Creating Client";
      props.setMessage(errorMsg);
      props.setNotification({
        ...props.notification,
        error: true,
        success: false,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Dialog
        className="bz-edit-email-dialog"
        title={"Enter your email address"}
        onClose={() => props.setShowUpdateEmailDialog(false)}
      >
        <Form
          onSubmit={handleEmailSubmit}
          render={(formRenderProps) => (
            <FormElement>
              <div className="">
                <div className="bzo-login-card bz-emailLogin-card border-0 bz-border-radius-1">
                  <div className="bzo-login-body">
                    <div className="form-group mb-4">
                      <Field
                        id={"email"}
                        name={"email"}
                        component={FormInput}
                        validator={emailValidator}
                        autoFocus={true}
                        hidelabel={true}
                        onChange={onChangeEmail}
                        value={email}
                      />
                      {errorMessage && <Error>{errorMessage}</Error>}
                    </div>
                    <div className="d-flex justify-content-end">
                      <ButtonGroup>
                        <Button
                          type={"reset"}
                          onClick={() => props.setShowUpdateEmailDialog(false)}
                        >
                          Cancel
                        </Button>

                        <BuzopsButton
                          type={"submit"}
                          label={"Submit"}
                          loading={loading}
                          disable={loading || disabledContinue}
                          className="mr-0"
                        />
                      </ButtonGroup>
                    </div>
                  </div>
                </div>
              </div>
            </FormElement>
          )}
        />
      </Dialog>
    </>
  );
};

export default UpdateSubmemberEmailDialog;
