import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Chip,
  ChipList,
  ChipProps,
} from "@progress/kendo-react-buttons";
import { Card, CardBody } from "@progress/kendo-react-layout";
import { ListView } from "@progress/kendo-react-listview";
import Auth from "components/authcomponent/Auth";
import Checkout from "components/checkout/Checkout";
import withClientSelection from "components/Hoc/withClientSelection";
import withNotification from "components/Hoc/withNotification";
import BuzopsLoader from "generic-components/BuzopsLoader";
import BuzopsWindow from "generic-components/BuzopsWindow";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { PackageService } from "services/package/index.service";
import { CheckUserMemberStatus, CurrencyFormat, getFullDate } from "utils";
import { GetLocalStore, LocalStore, RemoveLocalStore } from "utils/storage";
import back_icon from "../../Images/back_icon.svg";
import { Loader } from "@progress/kendo-react-indicators";
import { PackageFrequency, PackageFrequencyEnum } from "utils/form-utils";
import { useSelector } from "react-redux";
import { GenerateOfferLabel } from "components/AffiliateSubscriptions/AffiliateSubscriptions.view";
import { CalculateOffer } from "utils/utils";

export const renderFrequencyItem=(dataItem:any,expiryAfter:any=false,checkoutPage:any=null)=>{
  if(dataItem?.Frequency===0 || !dataItem?.Frequency) {return `None`}
  const item=PackageFrequency?.filter((i:any)=>i?.id===dataItem?.Frequency)?.[0]
  const frequencyDescription=item?.description
  let repeatfreqValue=item?.shortValue
  if(dataItem?.RepeatFrequency >1){
    repeatfreqValue=`${item?.shortValue}s`
  }
  let endAfter=""
  if(dataItem?.FrequencyEndType===0 || dataItem?.FrequencyEndType===null){
    endAfter="never end"
  }else if(dataItem?.FrequencyEndType===1){
    endAfter=`up to ${dataItem?.Occurrence || 0} Occurrences`
  }
  if(dataItem?.Frequency=== PackageFrequencyEnum.ONETIME){
    let text=``
    if(dataItem?.ExpireOn=== 8){
      const expiryData=getFullDate(dataItem?.ExpiryDate)
      text= `${expiryData}`
    }else{
      if(dataItem?.ExpireOn === 2){
        if(expiryAfter){
          let expireAfterDate = moment()
          .add(dataItem?.ExpireAfterDaysOnPurchase, "days")
          .format("L");
          if(dataItem?.StartDate){
            expireAfterDate = moment(dataItem?.StartDate).add(dataItem?.ExpireAfterDaysOnPurchase, "days").format("L");
          }
         text= `${getFullDate(expireAfterDate)}`
        }else{
          let expireOnValue='day'
          if(dataItem?.ExpireAfterDaysOnPurchase > 1){
            expireOnValue='days'
          }
          text= `${dataItem?.ExpireAfterDaysOnPurchase} ${expireOnValue}`
        }
      }else  if(dataItem?.ExpireOn === 4){
        if(expiryAfter){
          let expireAfterDate = moment()
          .add(dataItem?.ExpireAfterDaysOnPurchase, "months")
          .format("L");
          if(dataItem?.StartDate){
            expireAfterDate = moment(dataItem?.StartDate).add(dataItem?.ExpireAfterDaysOnPurchase, "months").format("L");
          }
         text= `${getFullDate(expireAfterDate)}`
        }else{
          let expireOnValue='month'
          if(dataItem?.ExpireAfterDaysOnPurchase > 1){
            expireOnValue='months'
          }
          text= `${dataItem?.ExpireAfterDaysOnPurchase} ${expireOnValue}`
        }
      }
    }
    return text
  }else{
    return `${frequencyDescription}, occur every ${dataItem?.RepeatFrequency || 0} ${repeatfreqValue} [${endAfter}]`
  }
}

const PurchasePackageList = (props: any) => {
  const history = useHistory();
  const redirectionObj = useSelector((state:any)=>state?.redirection)
  const showMoreRef = useRef<any>();
  const [pageLoading, setPageLoading] = useState(false);
  const [loginStatus, setLoginStatus] = useState(false);
  const [packagesList, setPackagesList] = useState<any>({
    pageNumber: 0,
    maxPageNumber: 0,
    packageData: [],
  });
  const userDetails = GetLocalStore("userDetails");
  const isLoggedIn = userDetails ? true : false;
  const [isPackageLoading, setIsPackageLoading] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<any>([]);
  const [visibility, setVisibility] = useState({
    purchase: false,
  });
  const [searchText, setSearchText] = useState("");
  const [expandedItem, setExpandItem] = useState(null);
  const [descExpandedItem, setDescExpandItem] = useState(null);

  useEffect(() => {
    fetchPackages(0, true);
    if(props?.page==='inhouse' && !props?.userMemberId){
      props?.handleClientPopup(null,null)
    }
  }, []);

  const handleSearch = (value: string) => {
    if (value) {
      setSearchText(value);
      if (value.length > 2) {
        fetchPackages(0, value);
      }
    } else {
      setSearchText("");
      fetchPackages(0);
    }
  };

  async function fetchPackages(pageNumber: number, searchText: any = null) {
    const pageSize = 10;
    let req: any = {
      SortDirection: "ascending",
      SortBy: "Name",
      PageSize: pageSize,
      PageNumber: pageNumber,
    };
    const service = new PackageService();
    if (searchText?.length > 2) {
      req = {
        SortDirection: "ascending",
        SortBy: "Name",
        PageSize: pageSize,
        PageNumber: pageNumber,
        Filters: [
          {
            Field: "Name",
            Operator: "contains",
            FieldDataType: "string",
            Value: searchText,
          },
        ],
      };
    }
    const newObj: any = new Object();
    if (props.staffPage) {
      newObj.CanClientBookOnline = false;
    }else{
      newObj.CanClientBookOnline = true;
    }
    if(props?.packageShortName){
      newObj.ShortCode = props?.packageShortName
    }
    if(props?.PromotionId){
      newObj.PromotionId = props?.PromotionId
    }
    // Commenting and keeping the original code for eview by original dev
    // if (props.page === "online") {
    //   newObj.CanClientBookOnline = true;
    // }
    // if(props?.providerName){
    //   newObj.UserAssociateId=props?.provider?.AssociateId
    // }
    // if(props?.classShortName){
    //   newObj.ShortCode=props?.classShortName
    // }
    const reqObj = { ...req, ...newObj };
    if (pageNumber === 0) {
      setIsPackageLoading(true);
    }
    const result = await service.getAllPackages(reqObj);
    if (pageNumber === 0) {
      setIsPackageLoading(false);
    }
    if (result?.ResponseCode === 100) {
      const maxPageNumber = result.TotalItems / pageSize;
      if (pageNumber === 0) {
        setPackagesList({
          ...packagesList,
          pageNumber: pageNumber,
          maxPageNumber: maxPageNumber,
          packageData: result?.Items || [],
        });
      }
      return result?.Items || [];
    }
    return [];
  }

  const handleCloseLogin = () => {
    setLoginStatus(false);
  };

  const handleView = (screen: string) => {
    setVisibility({ ...visibility, [screen]: true });
  };

  const scrollHandler = async (event: any) => {
    const e = event.nativeEvent;
    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      const check = await requestIfNeeded();
      if (check.status) {
        const moreData = await fetchPackages(check.page);
        if (moreData.length > 0) {
          const test = packagesList.packageData.concat(moreData);
          setPackagesList({
            ...packagesList,
            packageData: test,
            pageNumber: check.page,
          });
        }
      }
    }
  };

  const handlePageChange = async (status = "more") => {
    if(status==='more'){
      const check = await requestIfNeeded();
      if (check.status) {
        setPageLoading(true)
        const moreData = await fetchPackages(check.page);
        setPageLoading(false)
        if (moreData.length > 0) {
          const test = packagesList.packageData.concat(moreData);
          setPackagesList({
            ...packagesList,
            packageData: test,
            pageNumber: check.page,
          });
        }
      }
    }
  };

  const requestIfNeeded = () => {
    const nextPage = packagesList.pageNumber + 1;
    const maxPageNum = packagesList.maxPageNumber;
    if (nextPage < maxPageNum) {
      return {
        status: true,
        page: nextPage,
      };
    } else {
      return {
        status: false,
        page: nextPage,
      };
    }
  };
  const handleExpand = (item: any) => {
    setExpandItem(item.PackageId);
  };
  const handleCollapse = () => {
    setExpandItem(null);
  };

  const handleDescExpand = (item: any) => {
    setDescExpandItem(item.PackageId);
  };
  const handleDescCollapse = () => {
    setDescExpandItem(null);
  };

  const handlePackageCheckout = (obj: any) => {
    const { firstPathValue, staffuuid, clientMemberId } = redirectionObj
    if (firstPathValue === 'member') {
      history.push(`/${firstPathValue}/${staffuuid}/${clientMemberId}/checkout`, obj);
    } else if (firstPathValue === '') {
      history.push(`/checkout`, obj);
    } else {
      history.push(`/${firstPathValue}/checkout`, obj);
    }
  }

  const handleSelectPackage = (item: any) => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    const packList = {...item,
      AffiliateId: props?.AffiliateDetails?.UserAssociateId  || null,
      AffiliateUserId: props?.AffiliateDetails?.UserId  || null,
      PromotionId: props?.PromotionId  || null,
      CouponId: props?.offerObj?.CouponId  || null,
      CouponDescription: props?.offerObj?.CouponCode  || null};
    setSelectedPackage(packList);
    if (props.page === "inhouse") {
      handleView("purchase");
    } else {
      if (isLoggedIn) {
        if(props?.staffPage){
          handlePackageCheckout({
            package: packList,
            staffPage: true,
            userData: props?.userData,
            uuid: props?.uuid,
          })
        }else{
          handlePackageCheckout({
            package: packList,
          })
        }
      } else {
        RemoveLocalStore("serviceItem");
        RemoveLocalStore("classItem");
        LocalStore("packageItem", packList);
        setLoginStatus(true);
      }
    }
  };

  const RenderItem = (text: any) => {
    let item = text.dataItem;
    return (
      <div className="col-12" key={item.ClassId}>
        <div className="row p-3 align-middle buz-online-list-view bz-cursor-pointer pe-5 pe-sm-0">
          <div className="col-sm-7 col-lg-8">
            <div className="buzopsListItem m-0">{item.Name}</div>
          </div>
        </div>
      </div>
    );
  };


  const MyItemRender = (data: any) => {
    let item = data.dataItem;
    let strikeClassName = "text-capitalize";
    if (item?.Status === 2) {
      strikeClassName = "strike-word text-capitalize";
    }
    let strikeClassName2 = "text-uppercase";
    if (item?.Status === 2) {
      strikeClassName2 = "strike-word text-uppercase";
    }
    let collpaseStatus = false;
    if (expandedItem === item.PackageId) {
      collpaseStatus = true;
    }
    let collpaseDescStatus = false;
    if (descExpandedItem === item.PackageId) {
      collpaseDescStatus = true;
    }
    let expireAfterDaysOnPurchase = item?.ExpireAfterDaysOnPurchase;
    const expiryAfter = moment()
      .add(expireAfterDaysOnPurchase, "days")
      .format("L");
    const filterClass = item?.Classes.filter((i: any) => i.ClassId === null);
    let AllClasses = false;
    if (filterClass.length > 0) {
      AllClasses = true;
    }
    return (
      <>
        <div>
          <div className="bzc-packages-list-card-new bz-pkg-new bz-default-cursor mb-3">
            <div className="card-body">
              <div className="bzc-packages-list-row-new">
                <div className="section-1">
                  <div className="bzc-service-name">
                    <div className="bz-name">
                      <h6 className="service-name">{item?.Name}</h6>
                      <span className="bz-availability-packages">
                        <h5 className={`mb-0`}>
                          {item?.SessionType === 2
                            ? `Unlimited Group Appointments`
                            : `Limited (${item?.Schedules}) Group Appointments`}
                        </h5>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="section-2 grid-parent-container">
                  <div className="child-1 grid-child package-count">
                    <div className="bz-main-provider">
                      <div className="bz-provider-name pl-0">
                        <h6>Count</h6>
                        <small>
                        {item?.SessionType === 2
                          ? `Unlimited Group Appointments`
                          : `${item?.Schedules} Group Appointments`}
                      </small>
                      </div>
                    </div>
                  </div>
                  <div className="child-2 grid-child">
                    <div className="bz-main-provider">
                      <div className="bz-provider-name">
                        {item?.Frequency === PackageFrequencyEnum.ONETIME ? (<>
                          <h6>{item?.ExpireOn=== 8 ? "Expire On":"Expire After"}</h6>
                        <span className="bz-provider" style={{textTransform:"capitalize"}}>
                          {renderFrequencyItem(item,true)}
                        </span></>):(<><h6>Frequency</h6>
                        <span className="bz-provider" style={{textTransform:"capitalize"}}>
                          {renderFrequencyItem(item,true)}
                        </span></>)}
                      </div>
                    </div>
                  </div>
                  <div className="child-3 grid-child">
                    <div className="bz-main-provider">
                      <div className="bz-provider-name pl-0 mt-2">
                        <h6>Redeem for</h6>
                      </div>
                    </div>
                    <div className="bz-row bz-packages-tags-new">
                      
                      {AllClasses ? (
                        <>
                          <Chip
                            type={"success"}
                            disabled={true}
                            text={"All Group Appointments"}
                            removable={false}
                          />
                        </>
                      ) : (
                        <>
                          <ChipList
                            style={{ width: "auto", flexWrap: "wrap" }}
                            defaultData={collpaseStatus ?item?.Classes:item?.Classes.slice(0,2)}
                            chip={(props: ChipProps) => {
                              return (
                                <Chip
                                  type={"success"}
                                  disabled={true}
                                  text={props?.dataItem?.Name}
                                  removable={false}
                                />
                              );
                            }}
                          />
                          {item?.Classes.length > 2 && (<>    {collpaseStatus ? (
                            <span
                              className="btn align-baseline bz-cursor-pointer p-0"
                              onClick={(e) => handleCollapse()}
                              style={{ color: "#008db9" }}
                            >
                              <small>View Less</small>
                            </span>
                          ) : (
                            <span
                              className="btn bz-cursor-pointer  p-0"
                              onClick={(e) => handleExpand(item)}
                              style={{ color: "#008db9" }}
                            >
                              <small>View More</small>
                            </span>
                          )}</>)}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="section-3">
                  <div className="bzc-session-price">
                   
                    <span className={`${strikeClassName}`}>
                   
                    {props?.AffiliateDetails && props?.offerObj  ? (<>
                          <del>
                            {CurrencyFormat(item?.Cost)}
                          </del>
                          <span className="buz-font-600 buz-text-medium" style={{color:'green'}}>{` ${CurrencyFormat(CalculateOffer(item?.Cost,props?.offerObj))}`}</span>  <GenerateOfferLabel offerObj={props?.offerObj}/>
                        </>):(<>
                          {CurrencyFormat(item?.Cost)}
                        </>)}
                    </span>

                  </div>
                </div>
                <div className="section-4 bzc-col-btn">
                  <Button
                    primary={true}
                    look={"default"}
                    onClick={() => {
                      handleSelectPackage(item);
                    }}
                  >
                    Select
                  </Button>
                </div>
              
              </div>
              <div className="">
            <div className="bz-name">
              <span className="bz-availability-appointments">
                {item.Description !== "" && (
                  <>
                    {collpaseDescStatus ? (
                      <span
                        className="align-baseline bz-cursor-pointer p-0"
                        onClick={(e) => handleDescCollapse()}
                      >
                        view details <i className="fas fa-angle-up"></i>
                      </span>
                    ) : (
                      <span
                        className="bz-cursor-pointer  p-0"
                        onClick={(e) => handleDescExpand(item)}
                      >
                        view details <i className="fas fa-angle-down"></i>
                      </span>
                    )}
                  </>
                )}
              </span>
            </div>
          </div>
          {collpaseDescStatus && (
            <>
              <div className="service-desc bz-ol-muted">
                {item.Description}
              </div>
            </>
          )}
            </div>
          </div>
        </div>
      </>
    );
  };
  const popUpWindow = () => {
    let userMember=props?.userMemberId
    if(!userMember){
      userMember=props?.clientSubmitDetails?.UserMemberId
    }
    let Component = <></>;
    let title = "";
    let visible = false;
    let handleEvent = { ...visibility };
    if (visibility.purchase) {
      Component = (
        <Checkout
          handleWindow={() => props.handleWindow()}
          page={"package"}
          package={selectedPackage}
          UserMemberId={userMember}
        />
      );
      title = "Purchase Packages";
      visible = visibility.purchase;
      handleEvent = { ...visibility, purchase: false };
    }
    return (
      <BuzopsWindow
        title={
          <>
            <div className={"buzops-window-title"}>
                <span
                className="pr-2"
                onClick={() => setVisibility(handleEvent)}
              >
                <img src={back_icon} alt="" />
              </span>
              &nbsp;{title}
            </div>
          </>
        }
        reduceWidth={10}
        className={"bzo-class-window"}
        visibility={visible}
        key={visible.toString()}
        onWindowClose={() => setVisibility(handleEvent)}
      >
        {Component}
      </BuzopsWindow>
    );
  };

  const renderPackagesList = () => {
    if (isPackageLoading) {
      return <BuzopsLoader type={"list"} />;
    } else if (packagesList.packageData.length === 0) {
      return (
        <>
          <div className={"bz-no-upcoming-appoinments text-muted"}>
            No Packages Available{" "}
          </div>
        </>
      );
    }
    return (<>
      <ListView
        item={(props) => MyItemRender(props)}
       
        data={packagesList.packageData || []}
        style={{
          width: "100%",
        }}
      />
      <div className="d-flex justify-content-center align-items-center bz-services-showMore">
        <div ref={showMoreRef}>
          {pageLoading ? (<>
            <Loader type="converging-spinner" />
          </>):(<>
            {packagesList.pageNumber+1 <  packagesList.maxPageNumber && (
            <span
              className="btn btn-link  align-baseline bz-cursor-pointer p-0"
              onClick={() => handlePageChange()}
            >
              View More <i className="fas fa-caret-down"></i>
            </span>
          )}
          </>)}
        </div>
      </div>
    </>
    );
  };
  
  return (
    <>
      {props?.packageShortName && props?.packageShortName !== null && (
        <>
          <div className="row">
            <div className={"d-flex justify-content-start mt-3"}>
              <h5 className="card-title bzo-primary-heading mb-0">
              Packages
              </h5>
            </div>
          </div>
          <br />
        </>
      )}
        {props?.packageShortName  && !props?.ShortCodeStatus && !props?.PromotionId  ?  <div className={"bzo-services-container border-0 text-muted"}>
        The requested subscription is no longer accessible to use.
        </div>: <>
        
        <div
        className={
          props.page == "online" ? "bz-online-classes" : "bz-inhouse-classes"
        }
      >
        <div className="bzc-purchase-class-list-view bz-packages-list-view bz-inhouse-classes bz-purchaseclass-scroll">
          <div className={"row"}>
            <div className={"col-xs-12 col-md-12 col-md-12 col-lg-12"}>
              <Card className="my-0">
               
                {props?.classShortName && props?.classShortName !== null && (
                  <h5 className="card-title bzo-primary-heading mb-0">
                    Group
                  </h5>
                )}
                <CardBody className="bz-purchase-class-list pb-0">
                  {!props?.packageShortName && 
                  <div className="bz-packages-action d-flex justify-content-end">
                    <div className="form-group bz-packages-search bzo-search-service">
                      <span className="fa fa-search form-control-feedback"></span>
                      <input
                        type={"search"}
                        className="form-control"
                        placeholder={"Find Packages"}
                        onChange={(e) => handleSearch(e.target.value)}
                        value={searchText}
                      />
                    </div>
                  </div>}
                  {renderPackagesList()}
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
      {props.page === "inhouse" && popUpWindow()}
      <Auth
        {...props}
        handleCloseLogin={() => handleCloseLogin()}
        showComponent={loginStatus}
        key={loginStatus.toString()}
      />
        </>}
      
    </>
  );
};

export default withClientSelection(withNotification(PurchasePackageList))
