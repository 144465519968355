import React, { useEffect, useState } from "react";
import BuzopsLoader from "generic-components/BuzopsLoader";
import moment from "moment";
import { TenantService } from "services/tenant/index.service";
import { useHistory } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import ComplimentaryAppointments from "./ComplimentaryAppointments";
import ComplimentaryCheckout from "./ComplimentaryCheckouts";
import { CheckUserMemberStatus } from "utils";
import withNotification from "components/Hoc/withNotification";
import QuickCheckout from "./QuickCheckout";
import { PermissionEnum } from "utils/PermissionEnum";
import CheckPermissions from "utils/permissions";
import ViewComplimentaryAppointments from "./ViewComplimentaryAppointments";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import ClientNameWithMemberType from "../ClientNameWithMemberType";
import ClientOverviewInfo from "../ClientOverviewInfo";
import Location from "components/Location/Location";
import { useSelector } from "react-redux";
import BuzopsLongNameHandler from "generic-components/BuzopsLongNameHandler";

const Services = (props: any) => {
  const [availServices, setAvailServices] = useState<any>([]);
  const [loading, setLoading] = useState<any>(true);
  const redirectionObj = useSelector((state:any)=>state?.redirection)
  const [selectionPage, setSelectionPage] = useState({
    complimentaryCheckout:
      props?.selectedButton === "complimentary" ? true : false,
    complimentarySchedule:
      props?.selectedButton === "complimentaryschedule" ? true : false,
    quickCheckout: props?.selectedButton === "quick" ? true : false,
    assignOneonOneEvent: false,
    viewComplimentaryEvents: false,
  });
  const service = new TenantService();
  const history = useHistory();
  useEffect(() => {
    fetchMemberServices();
  }, []);

  const handleSelectionPage = (name: string, val: boolean) => {
    if(val){
      if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
        props?.handleNotificationMessage("", "error", true, "client");
        return;
      } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
        props?.handleNotificationMessage("", "error", false, "staff");
        return;
      }
    }
    setSelectionPage({
      ...selectionPage,
      [name]: val,
    });
    if(!val){
      fetchMemberServices()
    }
    props?.handleSelectedButton();
  };

  const RedirectToMybooking = (e: any, dataItem: any) => {
    e.stopPropagation();
    if (!CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    }
    if(redirectionObj?.firstPathValue !==""){
      history.push(`/${redirectionObj?.firstPathValue}/mybooking`,{
        selectedService: dataItem,
      });
    }else{
      history.push(`/mybooking`,{
        selectedService: dataItem,
      });
    }
  };
  const RedirectToMybookingForStaff = (e: any, dataItem: any) => {
    e.stopPropagation();
    if (!CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    history.push(
      `/member/${props?.uuid}/${props?.userData?.UserMemberId}/mybooking`,
      {
        selectedService: dataItem,
        staffPage: true,
        userData: props?.userData,
        uuid: props?.uuid,
      }
    );
  };

  const fetchMemberServices = async () => {
    let memberId = props?.userData?.UserMemberId;
    if (memberId) {
      setLoading(true);
      const req = {
        UserMemberId: memberId,
        CanExcludeNonAvailableAppointmentServices:false
      };
      const res = await service.getServiceFilter(req);
      setLoading(false);
      if (res.length > 0) {
        const result = res;
        setAvailServices(result);
      } else {
        setAvailServices([]);
      }
    }
  };

  const TooltipContentTemplate = (props: any) => {
    if (props?.data?.SessionAvailableToSchedule === 0) {
      return <div>{"No Available Appointments"}</div>;
    }
    const item=props?.data
    return (
      <table className="table table-sm table-borderless">
          <tr className="border-0 p-0" style={{ width: "100%" }}>
            <td>{'Paid'}</td>
            <td className="text-right">{item?.PaidSchedules}</td>
          </tr>
          <tr className="border-0 p-0" style={{ width: "100%" }}>
            <td>{'Used'}</td>
            <td className="text-right">{item?.Completed}</td>
          </tr>
          <tr className="border-0 p-0" style={{ width: "100%" }}>
            <td>{'Unused'}</td>
            <td className="text-right">{item?.PaidSchedules - item?.Completed}</td>
          </tr>
          <tr className="border-0 p-0" style={{ width: "100%" }}>
            <td>{'Unpaid'}</td>
            <td className="text-right"> {item?.NumberOfSessions - item?.PaidSchedules}</td>
          </tr>
          <tr className="border-0 p-0" style={{ width: "100%" }}>
            <td>{'Scheduled'}</td>
            <td className="text-right">{item?.Scheduled}</td>
          </tr>
      </table>
    );
  };

  const showClientInfo = () => {
    return <ClientOverviewInfo memberType={props?.userData?.MemberType} clientName={props?.userData?.FullName} email={props?.userData?.Email} phoneNo={props?.userData?.Phone} />
  }
  if (selectionPage?.complimentarySchedule) {
    return (
      <>
      <ComplimentaryAppointments
        {...props}
        uuid={props?.uuid}
        userData={props?.userData}
        staffPage={props?.staffPage}
        handleSelectionPage={(name: string, val: boolean) =>
          handleSelectionPage(name, val)
        }
      />
      </>
    );
  }
  if (
    selectionPage?.quickCheckout &&
    ((props?.staffPage && CheckPermissions(PermissionEnum["QuickCheckout"])) ||
      !props?.staffPage)
  ) {
    return (
      <>
        <QuickCheckout
          {...props}
          uuid={props?.uuid}
          userData={props?.userData}
          staffPage={props?.staffPage}
          handleSelectionPage={(name: string, val: boolean) =>
            handleSelectionPage(name, val)
          }
          EntityId={null}
        />
      </>
    );
  }

  if (selectionPage?.complimentaryCheckout) {
    return (
      <>
        <ComplimentaryCheckout
          {...props}
          uuid={props?.uuid}
          userData={props?.userData}
          handleSelectionPage={(name: string, val: boolean) =>
            handleSelectionPage(name, val)
          }
        />
      </>
    );
  }
  if (selectionPage?.viewComplimentaryEvents) {
    return (
      <>
        <ViewComplimentaryAppointments
          {...props}
          uuid={props?.uuid}
          userData={props?.userData}
          staffPage={props?.staffPage}
          handleSelectionPage={(name: string, val: boolean) =>
            handleSelectionPage(name, val)
          }
        />
      </>
    );
  }

  return (
    <>
      <h5 className="mb-0 bz_fs_1_5 mb-2">One-on-One</h5>
      <div className="complimentary-btns">
        <div className="bz-schedule-btn-container">
          {props?.staffPage && (
            <>
              <Button
                className={"bz-schedule-btn"}
                onClick={() =>
                  handleSelectionPage("complimentarySchedule", true)
                }
              >
                Schedule complimentary appointment
              </Button>
              <span className="bz-vertical-line"></span>
              {((props?.staffPage &&
                CheckPermissions(PermissionEnum["ComplementaryCheckout"])) ||
                !props?.staffPage) && (
             <>
                <Button
                  className={"bz-schedule-btn"}
                  onClick={() =>
                    handleSelectionPage("complimentaryCheckout", true)
                  }
                >
                  Complimentary Checkout
                </Button>
                <span className="bz-vertical-line"></span>
             </>
              )}
            </>
          )}
         <Button
            className={"bz-schedule-btn"}
            onClick={() => handleSelectionPage("viewComplimentaryEvents", true)}
          >
            View Complimentary/Unpaid Appointments
          </Button>
          <span className="bz-vertical-line"></span>
          <Button
            className={"bz-schedule-btn"}
            onClick={() => props?.goToPurchasePage()}
          >
            Purchase
          </Button>
        </div>
      </div>
      {loading ? (
        <>
          <BuzopsLoader type={"list"} />
        </>
      ) : (
        <>
          {availServices.length === 0 ? (
            <div className={"bz-norecords-available text-muted"}>
              No Subscribed One-on-One{" "}
            </div>
          ) : (
            <div className={"row one-on-one mt-3"}>
              {availServices.map((val: any, ind: any) => {
                return (
                  <div
                    className={"col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4"}
                  >
                    <div
                      className={`card bz-service-card bz-plans-card bz-shadow bz-arrow-right ${val?.StatusDescription} mb-3`}
                    >
                      <div
                        className={"card-body p-3"}
                        onClick={() => {
                          props.handleSelectionItem(
                            val.PrivateServiceInstanceId,
                            val?.StatusDescription,
                            "service",
                            val
                          );
                        }}
                      >
                        <div className={"bz-service-card-inner pl-0"}>
                          <h5
                            className={"card-title d-flex align-items-center"}
                          >
                            <span className="bz-plan-title  d-flex align-items-center">
                               <BuzopsLongNameHandler name={val.Name} maxLength={15} className="taxName-tooltip"/>
                              {val?.Attributes !== "" && (
                                <Location
                                  Attributes={val?.Attributes}
                                  showDetails={true}
                                  VirtualMeetingLink={val?.VirtualMeetingLink}
                                />
                              )}
                            </span>
                            <span
                              className={`badge badge-status-${val?.StatusDescription}`}
                            >
                              {val?.StatusDescription}
                            </span>
                          </h5>

                          <p
                            className={"card-subtitle mb-2 text-muted flex-txt"}
                          >
                            <span className="txt-title">Next Recurrence</span>
                            <span className="title-column">:</span>
                            <span>
                              {val.NextRecurrence !== "N/A"
                                ? moment(val.NextRecurrence).format("L")
                                : "N/A"}
                            </span>
                          </p>
                          <p
                            className={"card-subtitle mb-2 text-muted flex-txt"}
                          >
                            <span className="txt-title">Subscribed On</span>{" "}
                            <span className="title-column">:</span>
                            <span>
                              {val.PurchaseDate !== "N/A"
                                ? moment(val.PurchaseDate).format("L")
                                : "N/A"}
                            </span>
                          </p>
                          {(val?.StatusDescription !== "Cancelled" ||
                            (val?.StatusDescription === "Cancelled" &&
                              val?.CanScheduleAppointment)) && (
                            <p
                              className={
                                "card-subtitle mb-2 text-muted flex-txt"
                              }
                            >
                              <span className="txt-title">Availability</span>{" "}
                              <span className="title-column">:</span>
                              <span>
                                <BuzopsTooltip
                                  html={
                                    <a
                                      title="Appointments"
                                      style={{
                                        borderBottom: "1px dotted #d9d9d9",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {val.SessionAvailableToSchedule}
                                    </a>
                                  }
                                  content={() => {
                                    if (val.SessionAvailableToSchedule === 0) {
                                      return (
                                        <div>{"No Available Appointments"}</div>
                                      );
                                    }
                                    return (
                                      <table className="table table-sm table-borderless">
                                        <tbody>
                                          <tr
                                            className="border-0 p-0"
                                            style={{ width: "100%" }}
                                          >
                                            <td>{"Paid"}</td>
                                            <td className="text-right">
                                              {val?.PaidSchedules}
                                            </td>
                                          </tr>
                                          <tr
                                            className="border-0 p-0"
                                            style={{ width: "100%" }}
                                          >
                                            <td>{"Used"}</td>
                                            <td className="text-right">
                                              {val?.Completed}
                                            </td>
                                          </tr>
                                          <tr
                                            className="border-0 p-0"
                                            style={{ width: "100%" }}
                                          >
                                            <td>{"Unused"}</td>
                                            <td className="text-right">
                                              {val?.PaidSchedules -
                                                val?.Completed}
                                            </td>
                                          </tr>
                                          <tr
                                            className="border-0 p-0"
                                            style={{ width: "100%" }}
                                          >
                                            <td>{"Unpaid"}</td>
                                            <td className="text-right">
                                              {" "}
                                              {val?.NumberOfSessions -
                                                val?.PaidSchedules}
                                            </td>
                                          </tr>
                                          <tr
                                            className="border-0 p-0"
                                            style={{ width: "100%" }}
                                          >
                                            <td>{"Scheduled"}</td>
                                            <td className="text-right">
                                              {val?.Scheduled}
                                            </td>
                                          </tr>
                                          <tr
                                            className="border-0 p-0"
                                            style={{ width: "100%" }}
                                          >
                                            <td>{"Complimentary"}</td>
                                            <td className="text-right">
                                              {val?.Complimentary}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    );
                                  }}
                                  position={"bottom"}
                                />
                              </span>
                            </p>
                          )}
                          <p
                            className={"card-subtitle mb-2 text-muted flex-txt"}
                          >
                            <span className="txt-title">
                              Predicted Renewal On
                            </span>{" "}
                            <span className="title-column">:</span>
                            <span>
                              {val.PredictedRenewalDate
                                ? val?.RenewalStatus === 3
                                  ? "N/A"
                                  : moment(val.PredictedRenewalDate).format("L")
                                : "N/A"}
                            </span>
                          </p>
                        </div>
                        <span className={"bz-arrow-icon"}>
                          <i className={"fas fa-caret-right"}></i>
                        </span>
                        <div className="services-member-schedule">
                          <div>
                            <span className="bz-member-name d-flex align-items-center justify-content-start text-muted bz-fs-12px text-capitalize">
                              <ClientNameWithMemberType
                                memberType={val?.MemberType}
                                clientName={val?.MemberName}
                              />
                            </span>
                          </div>
                          <div>
                            {!props?.staffPage &&
                              val?.SessionAvailableToSchedule > 0 &&
                              val?.CanScheduleAppointment && (
                                <span className={"bz-schedule-link-btn2"}>
                                  <Button
                                    className="customtooltip"
                                    primary={false}
                                    onClick={(e) => RedirectToMybooking(e, val)}
                                  >
                                    <i className="fas fa-calendar-alt"></i>{" "}
                                    Schedule
                                    <span className="tooltiptext">
                                      Schedule
                                    </span>
                                  </Button>
                                </span>
                              )}
                            {props?.staffPage &&
                              val?.CanScheduleAppointment &&
                              val?.SessionAvailableToSchedule > 0 && (
                                <span className={"bz-schedule-link-btn2"}>
                                  <Button
                                    primary={false}
                                    onClick={(e) =>
                                      RedirectToMybookingForStaff(e, val)
                                    }
                                  >
                                    <i className="fas fa-calendar-alt"></i>
                                    Schedule
                                  </Button>
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default withNotification(Services);
