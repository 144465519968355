import React, { useEffect, useState } from "react";
import { useApiClient } from "services/axios-service-utils";
import { SiteSettingService } from "services/site-setting/index.service";
const initioalConfigValue = {
  AccountSId: "",
  AuthToken: "",
  PhoneNumber: null,
};
const useSMSIntegration = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [configuration, setConfiguration] = useState<any>(initioalConfigValue);
  const [submit, setSubmit] = useState<any>(false);
  const [smsConfigured, setSmsConfigured] = useState<any>(false);
  const [phoneNumbersList, setPhoneNumbersList] = useState<any>([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const service = new SiteSettingService();
  const { axiosRequest } = useApiClient();

  useEffect(() => {
    fetchAllApis();
  }, []);

  const fetchAllApis = async () => {
    setLoading(true);
    await fetchUserSession();
    await fetchSMSConfiguration();
    setLoading(false);
  };

  const handleChange = (value: any, name: string) => {
    setConfiguration({ ...configuration, [name]: value });
  };
  const fetchUserSession = async () => {
    await service.getTenantDetails(
      props.from ? props?.uuid : props?.match?.params?.uuid
    );
  };
  const errorCallBack = (message: string) => {
    props?.handleNotificationMessage(message, "error");
  };

  const fetchSMSConfiguration = async () => {
    const res = props?.smsConfig;
    if (res?.HasSMSNotificationEnabled) {
      const payload = {
        AccountSId: res?.AccountSId,
        AuthToken: res?.AuthToken,
      };
      await axiosRequest.post("sms/configuration/PhoneNumbers", payload, {
        successCallBack: (response: any) => {
          const data = response.data.Items;
          setPhoneNumbersList(data || []);
          setConfiguration({
            AccountSId: res.AccountSId,
            AuthToken: res.AuthToken,
            PhoneNumber: res.PhoneNumber,
          });
          setSmsConfigured(true);
        },
        errorCallBack: (response: any) => {
          errorCallBack(
            response?.response?.data?.Messages?.[0] || "Internal server error"
          );
        },
      });
    } else {
      setSmsConfigured(false);
    }
  };

  const handleNext = async () => {
    setSubmit(true);
    if (
      !configuration?.AccountSId?.trim() ||
      !configuration?.AuthToken?.trim()
    ) {
      return;
    }
    const payload = configuration;
    setButtonLoading(true);
    await axiosRequest.post("sms/configuration/PhoneNumbers", payload, {
      successCallBack: (response: any) => {
        const data = response?.data?.Items;
        setPhoneNumbersList(data || []);
        setSubmit(false);
        setButtonLoading(false);
      },
      errorCallBack: (response: any) => {
        console.log(response?.response, "response");
        errorCallBack(
          response?.response?.data?.Messages?.[0] ||
            "Please provide valid configuration details"
        );
        setButtonLoading(false);
      },
    });
  };

  const handleSubmit = async () => {
    setSubmit(true);
    if (!configuration?.PhoneNumber) {
      return;
    }
    setButtonLoading(true);
    const payload = configuration;
    await axiosRequest.post("sms/configuration", payload, {
      successCallBack: (response: any) => {
        const sucessMsg = "SMS Configuration Successfully Completed";
        props?.handleNotificationMessage(sucessMsg, "success");
        props?.handleSMSSave(true);
        setButtonLoading(false);
      },
      errorCallBack: (response: any) => {
        console.log(response, "response");
        errorCallBack(
          response?.response?.data?.Messages?.[0] || "Issue in configuration of SMS"
        );
        setButtonLoading(false);
      },
    });
  };

  const handleCancel = () => {
    props?.handleSMSSave();
  };

  const handleModify = () => {
    setSmsConfigured(false);
    setConfiguration(initioalConfigValue);
    setPhoneNumbersList([]);
  };

  return {
    loading,
    handleModify,
    handleSubmit,
    handleCancel,
    handleNext,
    handleChange,
    configuration,
    smsConfigured,
    submit,
    phoneNumbersList,
    buttonLoading,
  };
};

export default useSMSIntegration;
