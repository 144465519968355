import React, { useEffect, useState } from "react";
import { AffiliateProgramService } from "services/affiliateprogram/index.service";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import OneonOneLinks from "./OneonOneLinks";
import PackageLinks from "./PackageLinks";
import MembershipLinks from "./MembershipLinks";
import { GetLocalStore } from "utils/storage";
import { MdContentCopy } from "react-icons/md";
import SharableLinksDialog from "./SharableLinkDialog";

const MyLinks = (props: any) => {
  const [showDialog, setShowDialog] = React.useState(false);
  const [selectedLink, setSelectedLink] = React.useState({
    url: "",
    name: "",
  });
  const [promotions, setPromotions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPromotion, setSelectedPromotion] = useState<any>(null);
  const service = new AffiliateProgramService();

  useEffect(() => {
    getPromotions();
  }, []);

  const handleSelectLink = (link: string, name: string) => {
    setSelectedLink({
      url: link,
      name,
    });
    setShowDialog(true);
  };

  const getPromotions = async () => {
    const req = {
      AffiliateId: parseInt(props?.associateId),
    };
    setLoading(true);
    const res = await service.getPromotions(req);
    setPromotions(res?.Items || []);
    setSelectedPromotion(res?.Items?.[0]);
    setLoading(false);
  };

  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };
  const handleChange = (dataItem: any) => {
    setSelectedPromotion(dataItem);
  };
  const copyToClipBoard = (text: any) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        console.log('Text successfully copied to clipboard');
      })
        .catch((error) => {
          console.error('Unable to copy text to clipboard:', error);
        });
    } else {
      console.warn('Clipboard API is not supported in this browser. You may need to use a different approach.');
    }
  };

  let DomainName = GetLocalStore("tenantUserSession")?.DomainName;
  if (!props?.uuid) {
    DomainName = GetLocalStore("AffiliateDetails")?.DomainName;
  }

  const link = `https://${DomainName}.${process.env.REACT_APP_DOMAIN}.com/promolink/${props?.affiliateDetails?.AffiliateCode}/${selectedPromotion?.ShortCode}`;

  if (loading) {
    return <BuzopsLoader type="list" />;
  }
  console.log(selectedPromotion)
  return (
    <div className="promotion-overview">
      <h5 className="mb-1 bz_fs_1_5 affiliatemainheading">{"My Links"}</h5>
      <p className="text-muted">  Share the promotion link to provide clients with access to a comprehensive list of services or direct service links for easy client sign-up.</p>

      <div className="metric-list-row row px-2 mt-lg-2 mb-sm-0">
        <div className="col-lg-3 reports-date-range pl-0 col-12 col-md-4">
          <DropDownList
            data={promotions}
            textField="Name"
            dataItemKey="PromotionId"
            name="PromotionId"
            label="Select a Promotion"
            style={{ width: "100%" }}
            value={
              promotions?.filter((i: any) => i?.PromotionId === selectedPromotion?.PromotionId)?.[0] || {
                PromotionId: 0,
                Name: "Please Select Promotion",
              }
            }
            onChange={(e) => handleChange(e.value)}
          />
        </div>
        {selectedPromotion?.PromotionId ? <div className="col-lg-3 col-md-4 col-12 mylinkspadding">
          <div className="d-flex align-items-center mb-2 mt-lg-4 mt-sm-2">
            <div className="flex-grow-1">
              <div className="d-flex align-items-center linkbg">
                <div className="flex-grow-1">
                  {" "}
                  <a href={link} target="_blank">
                    <i
                      className="fa fa-link"
                      style={{
                        fontSize: "11px",
                        marginRight: "5px",
                      }}
                    ></i>
                    Promo Link
                  </a>
                </div>
                <div className="flex-shrink-0">
                  <span className="bz-cursor-pointer">
                    <MdContentCopy
                      onClick={() => copyToClipBoard(link)}
                      title={"Copy"}
                    />
                  </span>
                </div>
              </div>
            </div>

            <div className="flex-shrink-0 px-2">
              {" "}
              <a href="#" className="btn btn-primary-buzops btn-sm text-white" onClick={() => handleSelectLink(link, "Promo Link")}>
                {" "}
                <em className="mdi mdi-share-variant-outline"></em>
              </a>
            </div>
          </div>
        </div> : null}

      </div>

      <div className="row mt-3">
        <div className="col-lg-4 col-md-6 links d-flex align-items-stretch">
          <div className=" summary-card-payout w-100  mb-4" >
            <h5 className="affiliatemainheading pb-2">One-On-One</h5>
            <div className="linkdescriptions" style={{maxHeight:'500px', overflowY:'scroll',  overflowX:'hidden'}}>
              <OneonOneLinks
                affiliateDetails={props?.affiliateDetails}
                Promotion={selectedPromotion}
                handleSelectLink={handleSelectLink}
                DomainName={DomainName}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 links d-flex align-items-stretch">
          <div className=" summary-card-payout w-100  mb-4 ">
            <h5 className="affiliatemainheading pb-2">Packages</h5>
            <div className="linkdescriptions" style={{maxHeight:'500px', overflowY:'scroll', overflowX:'hidden'}}>
              <PackageLinks
                affiliateDetails={props?.affiliateDetails}
                Promotion={selectedPromotion}
                handleSelectLink={handleSelectLink}
                DomainName={DomainName}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-12 links d-flex align-items-stretch">
          <div className=" summary-card-payout w-100 mb-4">
            <h5 className="affiliatemainheading pb-2">Memberships</h5>
            <div className="linkdescriptions" style={{maxHeight:'500px', overflowY:'scroll',  overflowX:'hidden'}}>

            <MembershipLinks
              affiliateDetails={props?.affiliateDetails}
              Promotion={selectedPromotion}
              handleSelectLink={handleSelectLink}
              DomainName={DomainName}
            />
            </div>
          </div>
        </div>
      </div>
      {showDialog && <SharableLinksDialog {...props} copyToClipBoard={copyToClipBoard} selectedLink={selectedLink} toggleDialog={toggleDialog} />}
    </div>
  );
};

export default MyLinks;
