import ManagePayoutPaymentTerms from "components/AffiliateProgram/Promotions/ManagePayoutPaymentTerms";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import React from "react";
import { CurrencyFormat } from "utils";
import {
  PromotionSubscriptionTypes,
  RewardTypeList,
  SecondTierCommissionType,
} from "utils/form-utils";
import useAffiliatePromotionOverview from "./AffiliatePromotionOverview.hook";
import withNotification from "components/Hoc/withNotification";
import BuzopsLoader from "generic-components/BuzopsLoader";
import {
  MetricsToolTip,
  OfferTooltip,
} from "components/AffiliateProgram/Promotions/Promotions.view";
import ProspectsDialog from "./ProspectsDialog";
import { SubscriptionsChartContainer } from "./Overview";

const AffiliatePromotionOverview = (props: any) => {
  const promotionData = useAffiliatePromotionOverview(props);
  const {
    handleBack,
    promotion,
    rewardTypes,
    showAffiiateDialog,
    loading,
    toggleProspectDialog,
    showProspectDialog,
  } = promotionData;
  if (loading) {
    return <BuzopsLoader type="list" />;
  }
  return (
    <>
      <div className="promotion-overview">
        <h5 className="mb-3 bz_fs_1_5 affiliatemainheading">
          <button
            className="btn btn-primary bz-back-btn btn-sm mr-3"
            onClick={() => handleBack()}
          >
            <i className="fas fa-arrow-left"></i>
          </button>
          &nbsp;{"Promotion Overview"}
        </h5>

        <div className="d-flex justify-content-start align-self-center w-100">
          <h6 style={{ fontSize: "14px" }}>{promotion?.Name}</h6>
        </div>
        <div className="metric-list-row row mt-2 px-1">
          <div className="col-xl-7 col-lg-6 col-md-12 col-12 padding-affiliates">
            <div className="row">
              <div className="col-sm col-sm-12 col-md-6 col-xl-6">
                <div className="card metrics-list-bd-count-card">
                  <div className="metrics-list-bd-count-card-body">
                    <div className="row">
                      <div style={{ width: "75px" }}>
                        <div className="metrics-list-avatar metrics-list-avatar-lg rounded-circle metrics-list-badge-circle-light-info m-lg-auto ml-xl-auto">
                          <i className="mdi mdi-finance"></i>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "calc(100% - 75px)",
                          padding: "0 24px",
                        }}
                      >
                        <div className="metrics-card-info metrics-list-card-content">
                          <p>Net Revenue</p>
                          <div className="m-0 metrics-list-count-card-title d-flex align-items-center">
                            <BuzopsTooltip
                              html={
                                <a
                                  title="Net Revenue from Purchases"
                                  style={{
                                    borderBottom: "1px dotted #d9d9d9",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CurrencyFormat(
                                    promotion?.KeyMetrics
                                      ?.NetRevenueFromPurchases || 0
                                  )}
                                </a>
                              }
                              content={
                                <MetricsToolTip
                                  KeyMetrics={promotion?.KeyMetrics}
                                />
                              }
                              position={"right"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm col-sm-12 col-md-6 col-xl-6">
                <div className="card metrics-list-bd-count-card">
                  <div className="metrics-list-bd-count-card-body">
                    <div className="row">
                      <div style={{ width: "75px" }}>
                        <div className="metrics-list-avatar metrics-list-avatar-lg rounded-circle metrics-list-circle-light-primary m-lg-auto ml-xl-auto">
                          <i className="mdi mdi-account-multiple"></i>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "calc(100% - 75px)",
                          padding: "0 24px",
                        }}
                      >
                        <div className="metrics-card-info metrics-list-card-content">
                          <p>Prospects </p>
                          <div className="m-0 metrics-list-count-card-title d-flex align-items-center">
                            {promotion?.KeyMetrics?.LeadsGenerated > 0 ? (
                              <a
                                onClick={() => toggleProspectDialog()}
                                style={{
                                  borderBottom: "1px dotted #d9d9d9",
                                  cursor: "pointer",
                                }}
                              >
                                {promotion?.KeyMetrics?.LeadsGenerated || 0}
                              </a>
                            ) : (
                              <span>
                                {promotion?.KeyMetrics?.LeadsGenerated || 0}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-sm col-sm-12 col-md-6 col-xl-6">
                <div className="card metrics-list-bd-count-card">
                  <div className="metrics-list-bd-count-card-body">
                    <div className="row">
                      <div style={{ width: '75px' }}>
                        <div className="metrics-list-avatar metrics-list-avatar-lg rounded-circle metrics-list-circle-light-success m-lg-auto ml-xl-auto">

                          <i className="mdi  mdi-account-check-outline"></i>
                        </div>
                      </div>
                      <div style={{ width: 'calc(100% - 75px)', padding: '0 24px' }}>
                        <div className="metrics-card-info metrics-list-card-content">
                          <p  >Subscriptions Redeemed</p>
                          <div className="m-0 metrics-list-count-card-title d-flex align-items-center">
                            {promotion?.KeyMetrics?.SubscriptionsRedeemed || 0}
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col-sm col-sm-12 col-md-6 col-xl-6">
                <div className="card metrics-list-bd-count-card">
                  <div className="metrics-list-bd-count-card-body">

                    <div className="row">
                      <div style={{ width: '75px' }}>
                        <div className="metrics-list-avatar metrics-list-avatar-lg rounded-circle metrics-list-circle-light-warning m-lg-auto ml-xl-auto">
                          <i className="mdi mdi-account-remove"></i>
                        </div>
                      </div>
                      <div style={{ width: 'calc(100% - 75px)', padding: '0 24px' }}>
                        <div className="metrics-card-info metrics-list-card-content metrics-list-card-content ">
                          <p >Subscriptions Cancelled</p>
                          <div className="m-0 metrics-list-count-card-title  d-flex align-items-center">
                            {promotion?.KeyMetrics?.SubscriptionsCancelled || 0}
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-sm col-md-12 col-lg-6 col-xl-5">
            <div className="">
              <SubscriptionsChartContainer
                dataItem={[
                  {
                    text: "healthy",
                    category: "Active Subscriptions",
                    value: promotion?.KeyMetrics?.HealthySubscriptions || 0,
                  },
                  {
                    text: "failed",
                    category: "Failed Payment Subscriptions",
                    value: promotion?.KeyMetrics?.FailedPaymentSubscriptions || 0,
                  },
                  {
                    text: "cancelled",
                    category: "Cancelled Subscriptions",
                    value: promotion?.KeyMetrics?.SubscriptionsCancelled || 0,
                  },
                ]}
              />
            </div>
          </div>
        </div>

        <div className="summary-cards-list row  px-2">
          {/* <div className="summary-card">
            <div className="summary-card-heading">Rewards summary</div>
            <div className="summary-card-body row pl-0">
              <div className="summary-items col-md-6">
                <div className="d-flex justify-content-between">
                  <span>Pending</span>
                  <span className="highlight">0</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span>Denied</span>
                  <span className="highlight">0</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span>Approved</span>
                  <span className="highlight">0</span>
                </div>
              </div>
              <div className="total col-md-6 d-flex align-items-center">
                <span>Total Unpaid</span>
                <span className="highlight">nothing</span>
              </div>
            </div>
          </div> */}
          <div className=" col-lg-6 col-xl-6 col-md-12 col-12 d-flex align-items-stretch">
            <div className="summary-card  w-100">
              <div className="summary-card-heading">Promotion Rewards & Offers</div>
              {/* <div className="summary-card-heading">Payouts Summary</div> */}

              <div className="summary-card-body row pl-0">
                <div className="summary-items col-md-12">
                  {/* <div className="d-flex justify-content-between payoutinfo align-items-center">
                    <div className="w-70 buz-font-500 ">
                      {" "}
                      <span>Pending</span>
                    </div>
                    <div className="w-30">
                      <span className="payout-pending ">
                        {" "}
                        {CurrencyFormat(
                          promotion?.KeyMetrics?.PendingPayouts || 0
                        )}
                      </span>
                    </div>
                  </div> */}
                  {/* <div className="d-flex justify-content-between align-items-center">
                    <div className="w-70 buz-font-500">
                      <span> Money Out</span>
                    </div>
                    <div className="w-30">
                      <span className="">
                        {CurrencyFormat(
                          promotion?.KeyMetrics?.PaidPayouts || 0
                        )}
                      </span>
                    </div>
                  </div> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div
                      className="buz-font-600"
                      style={{ flexGrow: "1", color: "#008db9" }}
                    >
                      {" "}
                      Rewards{" "}
                    </div>
                  </div>
                  <div>
                    {promotion?.PromotionRewards?.map((reward: any) => {
                      let RewardCreditTypeId = reward?.RewardCreditTypeId;
                      RewardCreditTypeId = 1;
                      let TypeValue = RewardTypeList?.filter(
                        (i: any) => i?.id === RewardCreditTypeId
                      )[0]?.text;
                      const RewardTypeValue = rewardTypes?.filter(
                        (i: any) => i?.RewardTypeId === reward?.RewardType
                      )[0]?.RewardTypeName;

                      const commissionSource = SecondTierCommissionType?.filter(
                        (i: any) => i?.id === reward?.CommissionSource
                      )[0]?.text;

                      let descriptionValue = "";
                      if (RewardCreditTypeId === 1) {
                        descriptionValue =
                          reward?.CommissionType === 1
                            ? `${CurrencyFormat(
                              reward?.RewardAmount
                            )} ${RewardTypeValue}`
                            : `${reward?.RewardAmount}%  ${RewardTypeValue}`;
                      } else if (RewardCreditTypeId === 2) {
                        descriptionValue = `${CurrencyFormat(
                          reward?.RewardAmount
                        )} ${RewardTypeValue}`;
                      } else if (RewardCreditTypeId === 3) {
                        descriptionValue = `${reward?.RewardAmount}%  ${commissionSource}`;
                      }
                      return (
                        <>
                          <div
                            className="d-flex justify-content-between mb-2"
                            style={{ width: "100%", gap: "7px" }}
                          >
                            <div>{TypeValue}</div>
                            <div>
                              <BuzopsTooltip
                                html={
                                  <span
                                    style={{
                                      borderBottom: "1px dotted #d9d9d9",
                                      cursor: "pointer",
                                    }}
                                    title={`Reward Name :  ${reward?.RewardName}`}
                                  >
                                    {descriptionValue}
                                  </span>
                                }
                              />
                            </div>
                          </div>
                          <small>
                            {promotion?.ValidForAnySubscription &&
                              "Reward Applied to All Subscriptions"}
                          </small>
                        </>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div
                      className="buz-font-600"
                      style={{ flexGrow: "1", color: "#008db9" }}
                    >
                      {" "}
                      Offer{" "}
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-between mb-2"
                    style={{ width: "100%", gap: "7px" }}
                  >
                    <div>{"Offer"}</div>
                    <div>
                      <BuzopsTooltip
                        html={
                          <span style={{
                            borderBottom: "1px dotted #d9d9d9",
                            cursor: "pointer",
                          }} title="Offer">
                            {promotion?.Offer?.Description}
                          </span>
                        }
                        content={<OfferTooltip data={promotion?.Offer} />}
                        position={"left"}
                      />
                    </div>
                  </div>

                  {/* <div className="d-flex justify-content-between payoutinfo  align-items-center">
                    <div className="w-70 buz-font-500 ">
                      {" "}
                      <span>Overdue</span>
                    </div>
                    <div className="w-30">
                      <span className="payout-overdue">
                        {" "}
                        {CurrencyFormat(
                          promotion?.KeyMetrics?.OverduePayouts || 0
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between payoutinfo  align-items-center">
                    <div className="w-70 buz-font-500 ">
                      {" "}
                      <span>Rejected </span>
                    </div>
                    <div className="w-30">
                      <span className="payout-recjected">
                        {" "}
                        {CurrencyFormat(
                          promotion?.KeyMetrics?.RejectedPayouts || 0
                        )}{" "}
                      </span>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* <div className=" col-lg-6 col-xl-6 col-md-12 col-12 d-flex align-items-stretch">
            <div className="summary-card  w-100">
              <div className="summary-card-heading d-flex">
                <div> Rewards </div>
              </div>
              <div className="summary-card-body row pl-0">
                <div className="summary-items">
                  {promotion?.PromotionRewards?.map((reward: any) => {
                    let RewardCreditTypeId = reward?.RewardCreditTypeId;
                    RewardCreditTypeId = 1;
                    let TypeValue = RewardTypeList?.filter(
                      (i: any) => i?.id === RewardCreditTypeId
                    )[0]?.text;
                    const RewardTypeValue = rewardTypes?.filter(
                      (i: any) => i?.RewardTypeId === reward?.RewardType
                    )[0]?.RewardTypeName;

                    const commissionSource = SecondTierCommissionType?.filter(
                      (i: any) => i?.id === reward?.CommissionSource
                    )[0]?.text;

                    let descriptionValue = "";
                    if (RewardCreditTypeId === 1) {
                      descriptionValue =
                        reward?.CommissionType === 1
                          ? `${CurrencyFormat(
                              reward?.RewardAmount
                            )} ${RewardTypeValue}`
                          : `${reward?.RewardAmount}%  ${RewardTypeValue}`;
                    } else if (RewardCreditTypeId === 2) {
                      descriptionValue = `${CurrencyFormat(
                        reward?.RewardAmount
                      )} ${RewardTypeValue}`;
                    } else if (RewardCreditTypeId === 3) {
                      descriptionValue = `${reward?.RewardAmount}%  ${commissionSource}`;
                    }
                    return (
                      <>
                        <div
                          className="d-flex justify-content-between mb-2"
                          style={{ width: "100%", gap: "7px" }}
                        >
                          <div style={{ width: "50%" }}>{TypeValue}</div>
                          <div style={{ width: "50%" }}>
                            <BuzopsTooltip
                              html={
                                <span
                                  className="buz-font-600"
                                  title={`Reward Name :  ${reward?.RewardName}`}
                                >
                                  : {descriptionValue}
                                </span>
                              }
                            />
                          </div>
                        </div>
                        {promotion?.ValidForAnySubscription &&
                          "Reward Applied to All Subscriptions"}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className=" col-lg-6 col-xl-6 col-md-12 col-12 d-flex align-items-stretch">
            <div className="summary-card  w-100">
              <div className="summary-card-heading d-flex">
                <div> Offer </div>
              </div>
              <div className="summary-card-body row pl-0">
                <div className="summary-items">
                  <div
                    className="d-flex justify-content-between mb-2"
                    style={{ width: "100%", gap: "7px" }}
                  >
                    <div style={{ width: "50%" }}>{"Offer"}</div>
                    <div style={{ width: "50%" }}>
                      <BuzopsTooltip
                        html={
                          <span className="buz-font-600" title="Offer">
                            : {promotion?.Offer?.Description}
                          </span>
                        }
                        content={<OfferTooltip data={promotion?.Offer} />}
                        position={"left"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="summary-card">
            <div className="summary-card-heading d-flex">
              <div> Payment Term and Min. payout </div>
              <BuzopsTooltip
                html={
                  <button
                    onClick={() => handlePaymentTerm(promotion)}
                    className="btn btn-icon"
                    title="Edit"
                  >
                    <BiEdit color="#008db9" />
                  </button>
                }
              />
            </div>
            <div className="summary-card-body row pl-0">
              <div className="summary-items">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <span>{"Payment Term "}</span>
                    <div className="px-2">:</div>
                    <div>
                      <span className="buz-font-600">
                        {promotion?.PromotionAffiliates?.length > 0
                          ? promotion?.PromotionAffiliates[0]
                              ?.PaymentTermDescription
                          : promotion?.PaymentTerm?.Description}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <span>{"Min. Payout"}</span>
                    <div className="px-2">:</div>
                    <div>
                      <span className="buz-font-600">
                        {CurrencyFormat(
                          promotion?.PromotionAffiliates?.length > 0
                            ? promotion?.PromotionAffiliates[0]?.PayoutMinValue
                            : promotion?.PaymentTerm?.PayoutMinValue
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {!promotion?.ValidForAnySubscription && (
            <div className=" col-lg-6 col-xl-6 col-md-12 col-12 d-flex align-items-stretch">
              <div className="summary-card  w-100">
                <div className="summary-card-heading d-flex">
                  <div>Subscription Level Rewards </div>
                </div>
                <div className="summary-card-body row pl-0">
                  <div className="summary-items">
                    {promotion?.PromotionSubscriptions?.map((i: any) => {

                      const subscriptionType =
                        PromotionSubscriptionTypes?.filter(
                          (sub: any) => sub?.id === i?.SubscriptionType
                        )[0]?.text;
                      return (
                        <>
                          <div
                            className="d-flex justify-content-between"
                            style={{ width: "100%", gap: "10px" }}
                          >
                            <div
                              style={{ width: "50%" }}
                            >{`${subscriptionType} - ${i?.SubscriptionName}`}</div>
                            <div style={{ width: "50%" }} className="">
                              <div className="buz-font-400">
                                 <span className="rewardname"> {i?.RewardName} <span style={{display:'inline-block'}}> 
                              {promotion?.PromotionRewards[0]?.RewardId !== i?.Reward?.RewardId ?
                                <BuzopsTooltip
                                  html={
                                    <span
                                      className="buz-font-400 pl-1"
                                      style={{fontSize:'10px'}}
                                      title={`The Subscription reward has been customized differently from the Promotion reward.`}
                                    >
                                      ({"Customized"})
                                    </span>
                                  }
                                /> : null}</span> </span> 
                                
                              </div>
                           
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showAffiiateDialog && (
        <ManagePayoutPaymentTerms PromotionOverview={promotionData} />
      )}
      {showProspectDialog && (
        <ProspectsDialog
          PromotionProspects={promotion?.KeyMetrics?.PromotionProspects}
          toggleProspectDialog={toggleProspectDialog}
          admin={props?.admin}
          affiliatePage={true}
          promotionPage={true}
        />
      )}
    </>
  );
};

export default withNotification(AffiliatePromotionOverview);
