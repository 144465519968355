import purchaseOneOnOne from "./purchase_individual_service.svg";
import bookIndService from "./BookIndividualServiceAppt.svg";
import activeIcon from "./active.png";
import noAccessIcon from "./no-access.png";
import pendingIcon from "./pending.png";
import suspendedIcon from "./suspended.png";
import consultation from "./consultation.png";
import notPaid from "./not-paid.png";
import notificationIcon from "./notificationIcon.png";
import activityicon from "./activityicon.png";
import addrewardicon from "./addrewardicon.png";
import addwalleticon from "./addwalleticon.png";
import rewardicon from "./rewardicon.png";
import walleticon from "./walleticon.png";
import financeMetricIcon from "./finance.svg";
const bzImages ={
  purchaseOneOnOne,
  bookIndService,
  activeIcon,
  noAccessIcon,
  pendingIcon,
  suspendedIcon,
  consultation,
  notPaid,
  notificationIcon,
  activityicon,
  addrewardicon,
  addwalleticon,
  rewardicon,
  walleticon,
  financeMetricIcon
};
export  default bzImages;
