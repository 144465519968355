import BuzopsLoader from "generic-components/BuzopsLoader";
import React, { useEffect, useState } from "react";
import { PackageService } from "services/package/index.service";
import PaymentMethods from "./../PaymentMethods";
import Transactions from "./../Transactions";

const PackagePayments = (props: any) => {
  const [selectedPackage, setSelectedPackage] = useState<any>(null);
  const [loading, setLoading] = useState<any>(true);
  const service = new PackageService();

  useEffect(() => {
    fetchApi();
  }, []);

  const fetchApi=async()=>{
    setLoading(true);
    await fetchPackageSummary()
    setLoading(false);
  }

  const fetchPackageSummary = async () => {
    const req = {
      UserMemberId: props?.userData?.UserMemberId,
      PackageInstanceId: props?.selectedPackageId,
    };
    const res = await service?.packageInstanceFilter(req);
    setSelectedPackage(res?.Items[0]);
  };
  const renderTransactions = () => {
    return (
      <Transactions
        userData={props?.userData}
        staffPage={props?.staffPage}
        allItems={false}
        EntityId={props?.selectedPackageId}
        FilterType={512}
        onSuccessfullPayment={() => props?.onSuccessfullPayment()}

      />
    );
  };

  const renderPaymentMethods = () => {
    const entityDetails = {
      MemberRecurringDetailId: selectedPackage?.MemberRecurringDetailId,
      PaymentGatewayProfileId: selectedPackage?.PaymentGatewayPaymentProfileId,
      FilterType: "package",
      UserMemberId: props?.userData?.UserMemberId,
      AuditType: 512,
    };
    return (
      <PaymentMethods
        entityDetails={entityDetails}
        fetchPackageSummary={() => fetchPackageSummary()}
        userData={props?.userData}
        staffPage={props?.staffPage}
      />
    );
  };
  return (
    <>
      {/* <h5 className="mb-3 bz_fs_1_5">Payments</h5> */}
      <>
        {/* <hr /> */}
        {loading ? (
          <>
            <BuzopsLoader type={"list"} />
          </>
        ) : (
          <div className="bz-subsection-row bz-plan-overview">
            <div className="left-section">{renderPaymentMethods()}</div>
          </div>
        )}
        <hr />
        {renderTransactions()}
      </>
    </>
  );
};

export default PackagePayments;
