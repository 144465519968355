import BuzopsLoader from "generic-components/BuzopsLoader";
import React, { useEffect, useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { PackageService } from "services/package/index.service";

const PackageLinks = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  async function fetchPackages() {
    const fetchService = new PackageService();
    let req: any = {
      PromotionId: props?.Promotion?.PromotionId,
    };
    setLoading(true);
    const res = await fetchService.getAllPackages(req);
    if (res?.Items) {
      setData(res?.Items);
    }
    setLoading(false);
  }
  const copyToClipBoard = (text: any) => {
    navigator.clipboard.writeText(text);
  };

  useEffect(() => {
    if (props?.Promotion?.PromotionId) {
      fetchPackages();
    }
  }, [props?.Promotion]);

  if (loading) {
    return <BuzopsLoader type="list" />;
  }
  if (data?.length === 0 || !props?.Promotion?.PromotionId) {
    return (
      <div
        className={
          "bz-no-upcoming-appoinments text-muted bz-upcomingappointments-card"
        }
      >
        <div className="bz-norecords-available">No Links Available</div>
      </div>
    );
  }

  return (
    <div className="mt-3">
      {data?.map((i: any) => {
        const link = `https://${props?.DomainName}.${process.env.REACT_APP_DOMAIN}.com/promolink/${props?.affiliateDetails?.AffiliateCode}/${props?.Promotion?.ShortCode}/${i?.ShortCode}`;
        return (
          <div>
            <div className="d-flex align-items-center mb-2">
              <div className="flex-grow-1">
                <div className="d-flex align-items-center linkbg " style={{gap:'10px'}}>
                  <div className="flex-grow-1" style={{wordBreak:'break-all'}}>
                    {" "}
                    <a href={link} target="_blank">
                    <div style={{display:'flex', justifyContent:'space-between'}} className="align-items-center">
                      <div> <i
                        className="fa fa-link"
                        style={{
                          fontSize: "11px",
                          marginRight: "5px",
                        }}
                      ></i> </div>
                      <div style={{flexGrow:'1'}}>    {i?.Name}</div>
                   
                   
                    </div>
                    </a>
                  </div>
                  <div className="flex-shrink-0" style={{width:'20px'}}>
                    <span className="bz-cursor-pointer">
                      <MdContentCopy
                        onClick={() => copyToClipBoard(link)}
                        title={"Copy"}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex-shrink-0 px-2">
                {" "}
                <a
                  href="#"
                  className="btn btn-primary-buzops btn-sm text-white"
                  onClick={() => props?.handleSelectLink(link, i?.Name)}
                >
                  {" "}
                  <em className="mdi mdi-share-variant-outline"></em>
                </a>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PackageLinks;
