import {  useEffect } from "react";
import { AffiliateProgramService } from "services/affiliateprogram/index.service";
import { GetLocalStore } from "utils/storage";
import { useHistory } from "react-router-dom";
import useDebounce from "components/customHooks/useDebounce";
import { PageChangeEvent } from "@progress/kendo-react-data-tools";
import useState from 'react-usestateref';
const usePromotions = (props: any) => {
  const history = useHistory();
  const ClubId = GetLocalStore("tenantUserSession")?.ClubId;
  const [loading, setLoading] = useState(false);
  const [promotions, setPromotions] = useState([]);
  const [rewardTypes, setRewardTypes] = useState<any>([]);
  const [page, setPage,pageRef] = useState({
    skip: 0,
    take: 10,
  });
  const [totalItems, setTotalItems] = useState(0);

  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [showInactive, setShowInactive,showInactiveRef] = useState(false);
  const service = new AffiliateProgramService();
  console.log(showInactive)
  useEffect(() => {
    fetchRewardTypes();
  }, []);
  const debouncedValue = useDebounce(searchKeyWord, 500);

  useEffect(() => {
    getPromotions();
  }, [debouncedValue]);

  const handleToggleStatus = async (val: boolean) => {
    setShowInactive(val);
    setPage({...page,skip:0});
    getPromotions()
  };

  const getPromotions = async (includeInactive = showInactiveRef?.current,Skip=pageRef?.current?.skip,Take=pageRef?.current?.take,searchVal=debouncedValue) => {
    let req = {
      ClubId,
      SearchKeyword: "",
      IncludeProspectDetails:true,
      includeInactive: includeInactive,
      PageNumber: Skip / Take, 
      PageSize: Take 
    };
    if(searchVal){
      req={...req,SearchKeyword:searchVal}
    }
    setLoading(true);
    const res = await service.getPromotions(req);
    setPromotions(res?.Items || []);
    setTotalItems(res?.TotalItems);
    setLoading(false);
  };

  const handlePageChange = async (e: PageChangeEvent) => {
    setPage({
      skip: e.skip,
      take: e.take,
    });
    getPromotions()
  };

  const handleSearchPromotion = (val: any) => {
    setSearchKeyWord(val);
    setPage({...page,skip:0});
  };

  const handleCreateForm = () => {
    history.push(`/app/affiliateprogram/${props?.uuid}/promotions/add`);
  };
  const handleEditPromotion = (dataItem: any) => {
    history.push(
      `/app/affiliateprogram/${props?.uuid}/promotions/${dataItem?.PromotionId}/edit`
    );
  };
  const handleViewPromotion = async (dataItem: any) => {
    history.push(
      `/app/affiliateprogram/${props?.uuid}/promotions/${dataItem?.PromotionId}`
    );
  };

  const fetchRewardTypes = async () => {
    const res = await service.getRewardTypes();
    setRewardTypes(res);
  };

  const handleView = async (screen: string, item?: any) => {
    if (screen === "edit") {
      handleEditPromotion(item);
    } else if (screen === "view") {
      handleViewPromotion(item);
    }
  };

  return {
    handleView,
    rewardTypes,
    handleCreateForm,
    handleSearchPromotion,
    searchKeyWord,
    promotions,
    loading,
    showInactive,
    handleToggleStatus,
    page,
    handlePageChange,
    totalItems
  };
};

export default usePromotions;
