import BuzopsLoader from "generic-components/BuzopsLoader";
import React, { useState, useEffect } from "react";
import moment from "moment";
import SchedulesList from "./SchedulesList";
import { TenantService } from "services/tenant/index.service";
import ReactTooltip from "react-tooltip";

const PackageUtilization = (props: any) => {
  const [currentSchedules, setCurrentSchedules] = useState<any>([]);
  const [currentLoading, setCurrentLoading] = useState(false);
  const [futureSchedules, setFutureSchedules] = useState<any>([]);
  const [futureLoading, setfutureLoading] = useState(false);
  const service = new TenantService();
  useEffect(() => {
    fetchCurrentSchedules();
    fetchFutureSchedules();
  }, []);

  const fetchCurrentSchedules = async () => {
    const req = {
      FromDate:
        moment(selectedPeriod?.EffectiveFrom).format("YYYY-MM-DD") +
        "T00:00:00",
      ToDate: moment(selectedPeriod?.EffectiveEnd).format(
        "YYYY-MM-DDTHH:mm:ss"
      ),
      UserMemberId: props?.userData?.UserMemberId,
      ServiceInstanceId:
        props?.packageData?.PackageInstanceId || props?.selectedPackageId,
      IsPackageInstance: true,
      SortDirection: "descending",
      SortBy: "FromTime",
    };
    setCurrentLoading(true);
    const result = await service.getUpcomingAppointments(req);
    setCurrentLoading(false);
    setCurrentSchedules(result?.Result || []);
  };
  const fetchFutureSchedules = async () => {
    const req = {
      FromDate: moment(selectedPeriod?.EffectiveEnd).add(1,'day').format("YYYY-MM-DD") +
      "T00:00:00",
      UserMemberId: props?.userData?.UserMemberId,
      ServiceInstanceId:
        props?.packageData?.PackageInstanceId || props?.selectedPackageId,
      IsPackageInstance: true,
    };
    setfutureLoading(true);
    const result = await service.getUpcomingAppointments(req);
    setfutureLoading(false);
    setFutureSchedules(result?.Result || []);
  };

  const selectedPeriod = props?.selectedPeriodData;
  return (
    <>
      <div className={"row bz-auditHistory-blk"}>
        {props?.loading ? (
          <BuzopsLoader type={"list"} />
        ) : (
          <div>
            <div className="my-3">
              <div className="bz-package-utilization">
                {selectedPeriod?.Classes?.map((item: any) => (
                  <div className="bz-card-new border-start">
                    <div className="card-body p-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <p className="bz-group-name mb-0 font-13">
                            {item?.Name}
                          </p>
                          <p className="mb-0 text-secondary d-flex align-items-center">
                            Redeemed
                            <h4 className="badge badge-info text-white d-inline-block mb-0">
                              {item?.UsedSchedules}
                            </h4>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-2 bz-mb-2_5rem">
              <h6>
                Current Appointments{" "}
                <span
                  data-for={"current-appointment"}
                  data-tip={
                    "Appointments booked during the current billing cycle"
                  }
                >
                  <i
                    style={{ fontSize: "16px" }}
                    className="fa fa-info-circle"
                  ></i>
                </span>
              </h6>
              <ReactTooltip
                id={`current-appointment`}
                place="top"
                type="dark"
                effect="float"
                html={true}
              />
              <SchedulesList
                schedules={currentSchedules}
                loading={currentLoading}
              />
              <br />
              <h6
                
              >
                Future Appointments{" "}
                <span
                  data-for={"future-appointment"}
                  data-tip={
                    "Appointments booked during the future billing cycles"
                  }
                >
                  <i
                    style={{ fontSize: "16px" }}
                    className="fa fa-info-circle"
                  ></i>
                </span>
              </h6>
              <ReactTooltip
                id={`future-appointment`}
                place="top"
                type="dark"
                effect="float"
                html={true}
              />
              <SchedulesList
                schedules={futureSchedules}
                loading={futureLoading}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PackageUtilization;
