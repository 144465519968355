import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormInput, FormMaskedTextBox } from "utils/form-components";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { TenantService } from "services/tenant/index.service";
import BuzopsButton from "generic-components/BuzopsButton";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import {
  NameValidator,
  mobileValidator,
  passwordValidator
} from "validators/validator";
import { GetLocalStore, RemoveLocalStore } from "utils/storage";
import React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useDispatch, useSelector } from "react-redux";
import { setUserConfiguration } from "redux-slices/userSlice";

function Signup(props: any) {
  const dispatch = useDispatch();
  const redirectionObj = useSelector((state: any) => state?.redirection);
  const emailId = GetLocalStore("emailId");
  const history = useHistory();
  const [btnLoading, setBtnLoading] = useState<Boolean>(false);
  const [visible, setVisible] = React.useState(false);
  const [title, setTitle] = useState("");
  const [passwordType, setPasswordType] = useState(true);
  const userDetails = GetLocalStore("loginDetails");
  const [user, setUser] = useState({
    FirstName: userDetails?.FirstName ? userDetails?.FirstName : "",
    LastName: userDetails?.LastName ? userDetails?.LastName : "",
    PhoneNumber: userDetails?.PhoneNumber ? userDetails?.PhoneNumber : "",
  });
  useEffect(() => {
    const userDetails = GetLocalStore("userDetails");
    if (userDetails) {
      history.goBack();
    }
  }, []);
  const handleCheckout = (obj: any) => {
    const { firstPathValue, staffuuid, clientMemberId } = redirectionObj;
    if (firstPathValue === "member") {
      history.push(
        `/${firstPathValue}/${staffuuid}/${clientMemberId}/checkout`,
        obj
      );
    } else if (firstPathValue === "") {
      history.push(`/checkout`, obj);
    } else {
      history.push(`/${firstPathValue}/checkout`, obj);
    }
  };
  const tenantService = new TenantService();
  const handleSubmit = async (dataItem: any) => {
    let req = {
      ...dataItem,
      UserId: userDetails?.UserId,
      IsActivated: userDetails?.IsActivated,
      EmailId: emailId,
      UserMemberId: props.locationData.UserMemberId,
    };
    const serviceItem = GetLocalStore("serviceItem");
    const classItem = GetLocalStore("classItem");
    const packageItem = GetLocalStore("packageItem");
    const planItem = GetLocalStore("planItem");

    if (serviceItem) {
      req={...req,ReferringAssociateId:serviceItem?.AffiliateId,ReferringUserId:serviceItem?.AffiliateUserId,PromotionId:serviceItem?.PromotionId}
    } else if (packageItem) {
      req={...req,ReferringAssociateId:packageItem?.AffiliateId,ReferringUserId:packageItem?.AffiliateUserId,PromotionId:packageItem?.PromotionId}
    } else if (planItem) {
      req={...req,ReferringAssociateId:planItem?.AffiliateId,ReferringUserId:planItem?.AffiliateUserId,PromotionId:planItem?.PromotionId}
    }
    setBtnLoading(true);
    const signup = await tenantService.authenticateUser(req);
    if (signup.response) {
      const userInfo = signup?.data?.Item;
      dispatch(
        setUserConfiguration({
          loggedIn: true,
          userDetails: userInfo,
        })
      );
      if(userInfo?.MemberStatus===16){
        props.handleCloseLogin()
        history.push('/summary')
      }else if (props.guestLayout) {
        let trainerName = props.providerName;
        if (props.match?.params?.providerId) {
          trainerName = props.match?.params?.providerId;
        }
        props.handleCloseLogin();
        const providerItem = GetLocalStore("provider");

        if (serviceItem) {
          let redirectUrl = `/${redirectionObj?.firstPathValue}`;
          let obj = {};
          if (serviceItem?.PrivateServiceType === 1) {
            const serviceId = serviceItem.InternalDataId;
            if (trainerName) {
              redirectUrl = `/${trainerName}/checkout/${serviceId}`;
              obj = { provider: providerItem, selectedService: serviceItem };
            } else {
              redirectUrl = `/checkout/${serviceId}`;
              obj = { selectedService: serviceItem };
            }
          } else {
            redirectUrl = `/${trainerName}/mybooking`;
            obj = { provider: providerItem, selectedService: serviceItem };
          }
          if (redirectionObj?.firstPathValue === "external") {
            history.push(
              `/${redirectionObj?.firstPathValue}${redirectUrl}`,
              obj
            );
          } else {
            history.push(redirectUrl, obj);
          }
        } else if (classItem) {
          handleCheckout({ provider: providerItem, session: classItem });
        } else if (packageItem) {
          handleCheckout({
            provider: providerItem,
            package: packageItem,
          });
        } else if (planItem) {
          if (redirectionObj?.firstPathValue === "external") {
            history.push(`/${redirectionObj?.firstPathValue}/plancheckout`, {
              plan: planItem,
            });
          } else {
            history.push(`/plancheckout`, { plan: planItem });
          }
        } else {
          let redirectUrl = `/${redirectionObj?.firstPathValue}`;
          if (trainerName) {
            redirectUrl = `/${trainerName}`;
          } else {
            redirectUrl = `/`;
          }
          if (redirectionObj?.firstPathValue === "external") {
            history.push(`/${redirectionObj?.firstPathValue}${redirectUrl}`);
          } else {
            history.push(redirectUrl);
          }
        }
      } else {
        props.handleCloseLogin();

        if (!serviceItem && !classItem && !packageItem && !planItem) {
          history.push({
            pathname:
              redirectionObj?.firstPathValue !== ""
                ? `/${redirectionObj?.firstPathValue}/summary`
                : `/summary`,
            state: {
              userDetails: signup.data,
            },
          });
        } else {
          if (serviceItem) {
            const serviceId = serviceItem.InternalDataId;
            if (serviceItem.PrivateServiceType !== 1) {
              if (redirectionObj?.firstPathValue === "external") {
                history.push(
                  `/${redirectionObj?.firstPathValue}/mybooking`,
                  serviceItem
                );
              } else {
                history.push(`/mybooking`, serviceItem);
              }
            } else {
              if (redirectionObj?.firstPathValue === "external") {
                history.push(
                  `/${redirectionObj?.firstPathValue}/checkout/${serviceId}`,{selectedService: serviceItem}
                );
              } else {
                history.push(`/checkout/${serviceId}`,{selectedService: serviceItem});
              }
            }
          }
          if (classItem) {
            handleCheckout({ session: classItem });
          }
          if (packageItem) {
            handleCheckout({ package: packageItem });
          }
          if (planItem) {
            if (redirectionObj?.firstPathValue === "external") {
              history.push(`/${redirectionObj?.firstPathValue}/plancheckout`, {
                plan: planItem,
              });
            } else {
              history.push(`/plancheckout`, { plan: planItem });
            }
          }
        }
      }
      RemoveLocalStore("classItem");
      RemoveLocalStore("packageItem");
      RemoveLocalStore("serviceItem");
      RemoveLocalStore("planItem");
    } else {
      dispatch(
        setUserConfiguration({
          loggedIn: false,
          userDetails: null,
        })
      );
      if (props.guestLayout) {
        props.setAuthRoute("signup");
      } else {
        props.setAuthRoute("signup");
      }
    }
    setBtnLoading(false);
  };
  const handlePasswordDisplay = () => {
    setPasswordType(!passwordType);
  };
  const openDialog = (title: string) => {
    setTitle(title);
    setVisible(true);
  };
  const closeDialog = () => {
    setVisible(false);
  };
  const redirectPage = () => {
    if (props.guestLayout) {
      props.setAuthRoute("login");
    } else {
      props.setAuthRoute("login");
    }
  };
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={user}
        render={(formRenderProps) => (
          <FormElement>
            <div className="">
              <div className="bzo-login-card bz-emailLogin-card border-0 bz-border-radius-1">
                <div className="bzo-login-header">
                  <h4 className="h4 mb-0 text-left">Lets do this!</h4>
                </div>
                <div className="bzo-login-body pb-3">
                  <p className="text-left my-3">
                    {" "}
                    We need a little more info to create your profile
                  </p>
                  <div className="form-group">
                    <Field
                      id={"FirstName"}
                      name={"FirstName"}
                      placeholder="First name"
                      label="First name"
                      component={FormInput}
                      autoFocus={true}
                      validator={(val:any)=>NameValidator(val,"First Name")}
                      customvalidation={true}
                      hidelabel={true}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      id={"LastName"}
                      name={"LastName"}
                      placeholder="Last name"
                      label="Last name"
                      component={FormInput}
                      validator={(val:any)=>NameValidator(val,"Last Name")}
                      customvalidation={true}
                      hidelabel={true}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      id={"PhoneNumber"}
                      name={"PhoneNumber"}
                      placeholder="Phone Number"
                      label="Phone Number"
                      component={FormMaskedTextBox}
                      mask="(999) 000-0000"
                      validator={mobileValidator}
                      customvalidation={true}
                      hidelabel={true}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      id={"Password"}
                      name={"Password"}
                      type={"password"}
                      passwordType={passwordType}
                      placeholder="Password"
                      label="Password"
                      component={FormInput}
                      validator={passwordValidator}
                      customvalidation={true}
                      hidelabel={true}
                      hintMessage={"Must be 8-20 characters long."}
                    />
                    <span
                      className="password_eye_icon"
                      onClick={() => handlePasswordDisplay()}
                      key={passwordType.toString()}
                    >
                      <i
                        className={
                          passwordType ? "fa fa-eye-slash" : "fa fa-eye"
                        }
                      ></i>
                    </span>
                  </div>

                  <div className="">
                    <ButtonGroup>
                      <Button type={"reset"} onClick={() => redirectPage()}>
                        Back
                      </Button>
                      <BuzopsButton
                        type={"submit"}
                        label={"Next"}
                        loading={btnLoading}
                        disable={btnLoading}
                      />
                    </ButtonGroup>
                  </div>
                  <hr className="my-4" />
                  <div className="d-flex justify-content-center text-center buz-text-medium mb-2" style={{maxWidth:'25rem',margin:'auto'}}>
                    <div className="text-center buz-text-small bz-ol-muted buz-font-500">
                      By signing in you are agreeing to the{" "}
                      <div
                        onClick={() => openDialog("Terms of Service")}
                        className="btn btn-link align-baseline buz-text-small bz-cursor-pointer p-0"
                      >
                        Terms of Service
                      </div>{" "}
                      and{" "}
                      <div
                        onClick={() => openDialog("Privacy Policy")}
                        className="btn btn-link align-baseline buz-text-small bz-cursor-pointer p-0"
                      >
                        Privacy Policy.
                      </div>
                    </div>
                  </div>
                  <div className="text-center bz-other-login-option mt-3" style={{maxWidth:'25rem',margin:'auto'}}>
                    <span>
                      Not{" "}
                      <span
                        className="btn btn-link align-baseline bz-cursor-pointer p-0"
                        onClick={() => redirectPage()}
                      >
                        {emailId}?
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </FormElement>
        )}
      />

      {visible && (
        <Dialog
          className={
            title == "Terms of Service"
              ? "bz-mybookings-dialog-box bz-privacyPolicy-dialog bzo-auto-height"
              : "bz-mybookings-dialog-box bz-privacyPolicy-dialog"
          }
          title={title}
          onClose={closeDialog}
        >
          <div className="row">
            <div
              className={
                title == "Terms of Service" ? "col-12" : "col-12 d-none"
              }
            >
              <h4 className="mble-title">Terms of Service</h4>
              <article>
                <p>
                  These Terms govern your access and use of the cloud-based
                  subscription billing platform and its related products and
                  services provided by Buzops. By using our Services, you’re
                  agreeing to be bound by these Terms. If you’re using our
                  Services for an Organization, you’re agreeing to these Terms
                  on behalf of that Organization and in these Terms, “you”,
                  “you’re”, or “your” refers to that Organization.{" "}
                </p>
                <p>
                  We may modify these Terms from time to time by posting the
                  most current version on our website. New features or updates
                  that we add to our products or services are subject to these
                  Terms. If a modification materially reduces your rights, we
                  will notify you. If you do not agree to a modification, you
                  may terminate your use of our products and/or services or
                  request us to terminate the provision of our product or
                  services to you. By continuing to use our products and/or
                  services after the modification comes into effect, you are
                  agreeing to be bound by the modified Terms.
                </p>
                <p className="text-muted">
                  Terms of Service Last Updated: 06/30/2021
                </p>
              </article>
            </div>

            <div
              className={title == "Privacy Policy" ? "col-12" : "col-12 d-none"}
            >
              <h4 className="mble-title">Privacy Policy</h4>
              <article>
                <p>
                  This Privacy Policy governs the manner in which Buzops Inc.
                  and its affiliates collects, uses, maintains, and discloses
                  information collected from users (each, a "User") of
                  the www.buzops.com website URL or associated and/or affiliated
                  IP addresses, sub-domains, redirected links, but strictly
                  limited to these specific associated or affiliated IP or URL
                  addresses, which may also include native or non-native
                  applications ("Site"). This privacy policy applies to the Site
                  and all products and services offered by Buzops Inc. and its
                  affiliates.{" "}
                </p>
              </article>
              <article>
                <h5 className="mble-title">
                  Personal identification information{" "}
                </h5>
                <p>
                  We may collect personal identification information from Users
                  in a variety of ways, including, but not limited to, when
                  Users visit our site, register on the site, place an order,
                  subscribe to the newsletter, respond to a survey, fill out a
                  form, or any other digital method and in connection with other
                  activities, services, features or resources we make available
                  on our Site. Users may be asked for, as appropriate, name,
                  email address, mailing address, phone number, billing or
                  payment information, date of birth, and social security
                  number. Users may, however, visit our Site anonymously. We
                  will collect personal identification information from Users
                  only if they voluntarily submit such information to us or it
                  is provided to us through third party analytics and authorized
                  to be provided by third party analytic services or tools (of
                  which we have no control). Users can always refuse to supply
                  personally identification information, except that it may
                  prevent them from engaging in certain Site related activities.{" "}
                </p>
              </article>
              <article>
                <h5 className="mble-title">
                  Non-personal identification information
                </h5>
                <p>
                  We may collect non-personal identification information about
                  Users whenever they interact with our Site. Non-personal
                  identification information may include the browser name, the
                  type of computer and technical information about Users means
                  of connection to our Site, such as the operating system and
                  the Internet service provider’s utilized and other similar
                  information.{" "}
                </p>
              </article>
              <article>
                <h5 className="mble-title">Web browser cookies</h5>
                <p>
                  Our Site may use "cookies" to enhance User experience. User's
                  web browser places cookies on their hard drive for
                  record-keeping purposes and sometimes to track information
                  about them. Users may choose to set their web browser to
                  refuse cookies, or to alert you when cookies are being sent.
                  If they do so, note that some parts of the Site may not
                  function properly.
                </p>
              </article>
              <article>
                <h5 className="mble-title">
                  How we use collected information{" "}
                </h5>
                <p>
                  Buzops Inc. and its affiliates collects and uses User personal
                  information for the following purposes:{" "}
                </p>
                <ul>
                  <li>
                    To personalize user experience: We may use information in
                    the aggregate to understand how our Users, as a group, or
                    personally, use the products, services, and resources
                    provided on our Site.{" "}
                  </li>
                  <li>
                    To improve our Site: We continually strive to improve our
                    website offerings based on the information and feedback we
                    receive from you and collected data.{" "}
                  </li>
                  <li>
                    To improve customer service: Your information helps us to
                    more effectively respond to your customer service requests
                    and support needs.{" "}
                  </li>
                  <li>
                    To process transactions: We may use the information Users
                    provide about themselves when placing an order only to
                    provide service to that order. We do not share this
                    information with outside parties except to the extent
                    necessary to provide the service.{" "}
                  </li>
                  <li>
                    To administer content, promotion, survey or other Site
                    feature: To send Users information they agreed to receive
                    about topics we think will be of interest to them.{" "}
                  </li>
                  <li>
                    To send periodic emails The email address Users provide for
                    order processing, will only be used to send them information
                    and updates pertaining to their order. It may also be used
                    to respond to their inquiries, and/or other requests or
                    questions. If a User decides to opt-in to our mailing list,
                    they will receive emails that may include, but not limited
                    to, company news, updates, related products, or service
                    information. If at any time the User would like to
                    unsubscribe from receiving future emails, unsubscribe
                    instructions are included at the bottom of each email; or
                    User may contact us through our Site.{" "}
                  </li>
                </ul>
              </article>
              <article>
                <h5 className="mble-title">How we protect your information </h5>
                <p>
                  We adopt appropriate data collection, storage, and processing
                  practices and security measures to protect against
                  unauthorized access, alteration, disclosure or destruction of
                  your personal information, username, password, transaction
                  information and data stored on our Site.{" "}
                </p>
                <p>
                  Sensitive and private data exchange between the Site and its
                  Users happens over a SSL secured communication channel and is
                  encrypted and protected with digital signatures.{" "}
                </p>
              </article>
              <article>
                <h5 className="mble-title">
                  Sharing your personal information{" "}
                </h5>
                <p>
                  We do not sell, trade, or rent Users personal identification
                  information to others. We may share generic aggregated
                  demographic information not linked to any personal
                  identification information regarding visitors and users with
                  our business partners, trusted affiliates, and advertisers for
                  the purposes outlined above. We may use third party service
                  providers to help us operate our business and the Site or
                  administer activities on our behalf, such as, but not limited
                  to, sending out newsletters or surveys. We may share your
                  information with these third parties for those limited
                  purposes provided that you have given us your permission.{" "}
                </p>
              </article>
              <article>
                <h5 className="mble-title">Changes to this privacy policy </h5>
                <p>
                  Buzops Inc. and its affiliates has the discretion to update
                  this privacy policy at any time. When we do, we will revise
                  the updated date at the bottom of this page. We encourage
                  Users to frequently check this page for any changes to stay
                  informed about how we are helping to protect the personal
                  information we collect. You acknowledge and agree that it is
                  your responsibility to review this privacy policy periodically
                  and become aware of any and all modifications.{" "}
                </p>
              </article>
              <article>
                <h5 className="mble-title">Your acceptance of these terms</h5>
                <p>
                  By using this Site, you signify your acceptance of this
                  policy. If you do not agree to this policy, please do not use
                  our Site. Your continued use of the Site following the posting
                  of changes to this policy will be deemed your acceptance of
                  those changes.{" "}
                </p>
              </article>
              <p className="text-muted">
                Privacy Policy Last Updated: 06/30/2021{" "}
              </p>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
}

export default Signup;
