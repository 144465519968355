import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './materialdesignicon.scss'
import './Responsive.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import store from './store'
import { Provider } from 'react-redux'
import { stopReportingRuntimeErrors } from "react-error-overlay";
if (process.env.NODE_ENV === "development") {
  stopReportingRuntimeErrors(); // disables error overlays
}

ReactDOM.render(
  <Provider store={store}>
    <React.Fragment>
      <App />
    </React.Fragment>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
