import BuzopsLoader from "generic-components/BuzopsLoader";
import React, { useEffect, useState } from "react";
import { TenantService } from "services/tenant/index.service";
import { useHistory } from "react-router-dom";
import Location from "components/Location/Location";
const ComplimentaryAppointments = (props: any) => {
  const [availServices, setAvailServices] = useState<any>([]);
  const [loading, setLoading] = useState<any>(true);
  const service = new TenantService();
  const history = useHistory();
  useEffect(() => {
    fetchMemberServices();
  }, []);

  const handleSkip=()=>{
    const rec=availServices.filter((i:any)=>i?.PrivateServiceType === 3)
    handleClick(rec[0])
  }

  const fetchMemberServices = async () => {
    let memberId = props?.userData?.UserMemberId;
    if (memberId) {
      setLoading(true);
      const req = {
        AllowWithOutPurchaseServices: true,
        UserMembers: [
          {
            UserMemberId: memberId,
          },
        ],
      };
      const res = await service.getServiceInstance(req);
      setLoading(false);
      if (res.length > 0) {
        if(res?.length ===1 && res[0]?.PrivateServiceType === 3){
          handleClick(res[0])
        }
        setAvailServices(res);
      } else {
        setAvailServices([]);
      }
    }
  };
  const handleClick = (item: any) => {
    history.push(
      `/member/${props?.uuid}/${props?.userData?.UserMemberId}/mybooking`,
      {
        selectedService: item,
        staffPage: true,
        userData: props?.userData,
        uuid: props?.uuid,
        IsComplimentary:item?.PrivateServiceType ===1 ? true : false
      }
    );
  };
  return (
    <>
      <h5 className="mb-3 bz_fs_1_5">
      <button
          className="btn btn-primary bz-back-btn btn-sm"
          onClick={() => props?.handleSelectionPage("complimentarySchedule", false)}
        >
          <i className="fas fa-arrow-left"></i>
        </button>
        &nbsp;Complimentary One-on-One
      </h5>
      {loading ? (
        <>
          <BuzopsLoader type={"list"} />
        </>
      ) : (
        <>
          {availServices.length === 0 ? (
            <div className={"bz-norecords-available text-muted"}>
              No Complimentary Individial Services{" "}
            </div>
          ) : (
            <>
            <div className="alert alert-warning fade show"
                  role="alert"
                >
              <span>
                  {`To link the complimentary appointment with any existing One-on-One service, kindly select the service below for audit. However, if you prefer to proceed without tagging the One-on-One,`} <span className="btn btn-link" onClick={handleSkip}>skip and continue</span></span>
            </div>
            <div className={"row"}>
              {availServices.filter((serv:any)=>serv?.PrivateServiceType ===1)?.map((val: any, ind: any) => {
                return (
                  <div
                    className={"col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4"}
                  >
                    <div
                      className={`card bz-service-card bz-plans-card bz-shadow bz-complementary bz-arrow-right ${val?.StatusDescription} mb-3`}
                    >
                      <div
                        className={"card-body p-3"}
                        onClick={() => handleClick(val)}
                      >
                        <div className={"bz-service-card-inner pl-0"}>
                          <h5
                            className={
                              "card-title d-flex align-items-center mb-0"
                            }
                          >
                            <div className="d-flex">
                             {val.Name }
                          {val?.Attributes !== "" && <Location Attributes={val?.Attributes} showDetails={true} VirtualMeetingLink={val?.VirtualMeetingLink}/>}
                          </div>
                          </h5>
                        </div>
                        <span className={"bz-arrow-icon"}>
                          <i className={"fas fa-caret-right"}></i>
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ComplimentaryAppointments;
