import React from "react";

const AppointmentsBox = (selectedService: any) => {
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <h3 style={{ fontSize: "32px", fontWeight: 700, color: "#bce2f4" }} className="mb-3">
          Appointments
        </h3>
      </div>
      <div>
      <div className="bz-content-text-row">
          <div className="bz-content-title text-right">
          Paid
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
          {selectedService?.PaidSchedules}
          </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">
          Used
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
          {selectedService?.Completed}
          </div>
        </div>        
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">
          Unused
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
          {selectedService?.PaidSchedules - selectedService?.Completed}
          </div>
        </div>
        <div className="bz-content-text-row mb-2">
          <div className="bz-content-title text-right">
          Unpaid
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
          {selectedService?.NumberOfSessions - selectedService?.PaidSchedules}
          </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">
          Scheduled
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
          {selectedService?.Scheduled}
          </div>
        </div>     
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">
          Complimentary
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
          {selectedService?.Complimentary}
          </div>
        </div>     
      </div>
    </>
  );
};

export default AppointmentsBox;
