import { DropDownList } from "@progress/kendo-react-dropdowns";
import React from "react";

const MemberSelection = (props: any) => {
  const { allClientsList = [], selectedUsermemberId,width="100%" } = props;
  return (
    <>
      <DropDownList
        data={allClientsList}
        textField="FullName"
        dataItemKey="UserMemberId"
        onChange={(e) => {
          props?.handleUpdateClient(e.value);
        }}
        value={
          allClientsList?.filter(
            (res: any) => res?.UserMemberId === selectedUsermemberId
          )[0]
        }
        style={{ width: width }}
      />
    </>
  );
};

export default MemberSelection;
