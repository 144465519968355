import { MultiViewCalendar } from "@progress/kendo-react-dateinputs";
import React, { useState } from "react";
import moment from "moment";

export enum FilterOptions {
  TODAY = "Today",
  TOMORROW = "Tomorrow",
  ThisWeek = "This Week",
  ThisMonth = "This Month",
  ThisQuarter = "This Quarter",
  ThisYear = "This Year",
  Yesterday = "Yesterday",
  NextWeek = "Next Week",
  NextMonth = "Next Month",
  PreviousWeek = "Previous Week",
  PreviousMonth = "Previous Month",
  PreviousQuarter = "Previous Quarter",
  PreviousYear = "Previous Year",
}
const futureFilterOptions = [
  FilterOptions.TODAY,
  FilterOptions.TOMORROW,
  FilterOptions.ThisWeek,
  FilterOptions.ThisQuarter,
  FilterOptions.ThisMonth,
  FilterOptions.ThisYear,
  FilterOptions.NextWeek,
  FilterOptions.NextMonth,
];

const pastFilterOptions = [
  FilterOptions.TODAY,
  FilterOptions.Yesterday,
  FilterOptions.ThisWeek,
  FilterOptions.ThisQuarter,
  FilterOptions.ThisMonth,
  FilterOptions.ThisYear,
  FilterOptions.PreviousWeek,
  FilterOptions.PreviousQuarter,
  FilterOptions.PreviousMonth,
  FilterOptions.PreviousYear,
];

const CustomCalendar = (props: any) => {
  const {
    onlyFutureDates,
    onlyPastDates,
    setFromDateToDate,
    selectedDateItem,
    // setDateFilter,
  } = props;
  let calendarProps = {};
  if (onlyFutureDates) {
    calendarProps = { min: new Date() };
  }
  if (onlyPastDates) {
    calendarProps = { max: moment().subtract(0, "day").toDate() };
  }
  const [config, setConfig] = useState<any>({
    showCalendar: false,
    displayText: true,
  });
  const [selectedTab, setSelectedTab] = useState(selectedDateItem || "Today");

  const EMPTY_SELECTIONRANGE = {
    start: new Date(),
    end: new Date(),
  };

  const [selectedDate, setSelectedDate] =
    React.useState<any>(EMPTY_SELECTIONRANGE);
  const displayCalendar = () => {
    setConfig({
      ...config,
      showCalendar: !config.showCalendar,
      displayText: false,
    });
  };
  const hideCalendar = () => {
    setConfig({
      ...config,
      showCalendar: false,
      displayText: config?.selectedOption === "" ? false : true,
    });
  };
  const getDropDownValues = () => {
    if (onlyFutureDates) {
      return Object.values(FilterOptions).filter((val) =>
        futureFilterOptions.includes(val)
      );
    } else if (onlyPastDates) {
      return Object.values(FilterOptions).filter((val) =>
        pastFilterOptions.includes(val)
      );
    } else {
      return Object.values(FilterOptions);
    }
  };


  const handleDateRange = (selectedOption: any) => {
    let from = moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss");
    let to = moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss");
    if (selectedOption === FilterOptions.TODAY) {
      from = moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss");
      to = moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss");
    } else if (selectedOption === FilterOptions.ThisWeek) {
      if (onlyFutureDates) {
        from = moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss");
        to = moment()
          .endOf("isoWeek")
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ss");
      } else {
        from = moment()
          .startOf("isoWeek")
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss");
        to = moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss");
      }
    } else if (selectedOption === FilterOptions.ThisMonth) {
      if (onlyFutureDates) {
        from = moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss");
        to = moment().endOf("month").endOf("day").format("YYYY-MM-DDTHH:mm:ss");
      } else {
        from = moment().startOf("day").format("YYYY-MM-01THH:mm:ss");
        to = moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss");
      }
    } else if (selectedOption === FilterOptions.ThisQuarter) {
      if (onlyFutureDates) {
        from = moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss");
        to = moment()
          .quarter(moment().quarter())
          .endOf("quarter")
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ss");
      } else {
        from = moment()
          .subtract(2, "month")
          .startOf("day")
          .format("YYYY-MM-01THH:mm:ss");
        to = moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss");
      }
    } else if (selectedOption === FilterOptions.ThisYear) {
      if (onlyFutureDates) {
        from = moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss");
        to = moment().endOf("month").endOf("day").format("YYYY-12-31THH:mm:ss");
      } else {
        from = moment().startOf("day").format("YYYY-01-01THH:mm:ss");
        to = moment().endOf("day").format("YYYY-12-31THH:mm:ss");
      }
    } else if (selectedOption === FilterOptions.Yesterday) {
      from = moment()
        .subtract(1, "day")
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss");
      to = moment()
        .subtract(1, "day")
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ss");
    } else if (selectedOption === FilterOptions.PreviousWeek) {
      from = moment()
        .subtract(6, "day")
        .startOf("isoWeek")
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss");
      to = moment()
        .subtract(6, "day")
        .endOf("isoWeek")
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ss");
    } else if (selectedOption === FilterOptions.PreviousMonth) {
      from = moment().subtract(1, "month").format("YYYY-MM-01THH:mm:ss");
      to = moment()
        .subtract(1, "month")
        .endOf("month")
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ss");
    } else if (selectedOption === FilterOptions.PreviousQuarter) {
      from = moment().subtract(5, "month").format("YYYY-MM-01THH:mm:ss");
      to = moment()
        .subtract(3, "month")
        .endOf("month")
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ss");
    } else if (selectedOption === FilterOptions.PreviousYear) {
      from = moment()
        .subtract(1, "year")
        .startOf("day")
        .format("YYYY-01-01THH:mm:ss");
      to = moment()
        .subtract(1, "year")
        .endOf("day")
        .format("YYYY-12-31THH:mm:ss");
    } else if (selectedOption === FilterOptions.TOMORROW) {
      from = moment()
        .add(1, "day")
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss");
      to = moment().add(1, "day").endOf("day").format("YYYY-MM-DDTHH:mm:ss");
    } else if (selectedOption === FilterOptions.NextWeek) {
      from = moment()
        .add(1, "weeks")
        .startOf("isoWeek")
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss");
      to = moment()
        .add(1, "weeks")
        .endOf("isoWeek")
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ss");
    } else if (selectedOption === FilterOptions.NextMonth) {
      from = moment()
        .add(1, "months")
        .startOf("month")
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss");
      to = moment()
        .add(1, "months")
        .endOf("month")
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ss");
    }

    setFromDateToDate({ from: from, to: to });
    setSelectedDate({
      start: new Date(from),
      end: new Date(to),
    });
    setSelectedTab(selectedOption);
    hideCalendar();
  };

  const handleDateChange = (event: any) => {
    console.log(event, "envnt");
    let currentValue: any = event.value;
    setSelectedTab("");
    setSelectedDate({ ...selectedDate, ...currentValue });
    const from = moment(event.value.start)
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss");
    const to = moment(event.value.end)
      .endOf("day")
      .format("YYYY-MM-DDTHH:mm:ss");

    if (
      to !== "Invalid date" &&
      from !== "Invalid date" &&
      moment(from) > moment("2000-01-01") &&
      moment(to) > moment("2000-01-01")
    ) {
      setFromDateToDate({ from: from, to: to });

      displayCalendar();
      return;
    }
  };
  const calendarComponent = () => {
    if (!config?.showCalendar) {
      return <></>;
    }
    return (
      <div className="bz-date-filter-calendar">
        <div
          className="bz-filter-container"
          style={{ float: "left", position: "relative" }}
        >
          <span
            onClick={() => hideCalendar()}
            className="bz-date-filter-close bz-close-Icon-mobile"
            title="Close"
          >
            <i className="fa fa-window-close fa-2x" aria-hidden="true"></i>
          </span>
          <ul className="bz-unstyled-list">
            {getDropDownValues().map((item: any) => {
              return (
                <li
                  className={selectedTab === item ? "bz-option-selected" : ""}
                  onClick={() => handleDateRange(item)}
                >
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
        <div
          className="no-padding bz-calender-container"
          style={{ float: "right", position: "relative" }}
        >
          <div className="no-padding bz-calender-sec">
            <MultiViewCalendar
              mode={"range"}
              value={selectedTab === "" ? selectedDate : EMPTY_SELECTIONRANGE}
              onChange={handleDateChange}
              id={"multiViewCalendar"}
              views={1}
              {...calendarProps}
            />
          </div>
          <span
            onClick={() => hideCalendar()}
            className="bz-date-filter-close bz-close-Icon-tab"
            title="Close"
          >
            <i className="fa fa-window-close fa-2x" aria-hidden="true"></i>
          </span>
        </div>
      </div>
    );
  };
  return (
    <div className="bz-date-filter-main">
      <div className="bz-dateFilter-container">
        <div
          className="bz-date-filter-textBox"
          onClick={() => displayCalendar()}
        >
          <i className="far fa-calendar-alt"></i>
          <span style={{ fontSize: "0.85rem" }}>
            {selectedTab
              ? selectedTab
              : moment(selectedDate?.start).format("MM/DD/YYYY") +
                "-" +
                moment(selectedDate?.end).format("MM/DD/YYYY")}
          </span>
          <i className="fa fa-caret-down"></i>
        </div>
        {calendarComponent()}
      </div>
    </div>
  );
};

export default CustomCalendar;
