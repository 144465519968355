import { addDates } from "components/checkout/Checkout";
import moment from "moment";
import React, { useState } from "react";
import { ManageTaxesService } from "services/managetaxes/index.service";
import { TenantService } from "services/tenant/index.service";
import { CustomToFixed, getLongDate } from "utils";
import { GetLocalStore } from "utils/storage";
const initialAdditionalPayment={
  dialog:false,
  selectedPaymentProfile:null
}
const usePayRemaingDue = (props: any) => {
  const tommorrow = moment().add(1, "days").format("L");
  const remainingDueRecDetails = {
    recurrenceUnit: { id: 4, text: "Months" },
    NextRecurrence: new Date(tommorrow),
    OccursEvery: 1,
    NoOfInstallments: 1,
  };
  const [showAdditionalPayment, setShowAdditionalPayment] = useState<any>(initialAdditionalPayment);
  const [AlternatePaymentProfileId, setAlternatePaymentProfileId] = useState<any>(null);
  const [makePayment, setMakePayment] = React.useState(false);
  const [amount, setAmount] = React.useState(0);
  const [IsWaivePayment, setIsWaivePayment] = React.useState(false);
  const [IsTaxSkipped, setIsTaxSkipped] = React.useState(true);
  const [paymentProfile, setPaymentProfile] = React.useState<any>([]);
  const [selectedPaymentProfile, setSelectedPaymentProfile] =
    React.useState<any>(undefined);
  const [payRemainingDue, setPayRemainingDue] = React.useState(0);
  const paymentGatewaytype = GetLocalStore("Configuration")?.PaymentGatewayType;
  const [paymentGatewayType, setPaymentGatewayType] = React.useState<any>(paymentGatewaytype);
  const [remDueRecurrence, setRemDueRecurrence] = React.useState(false);
  const [submistatus, setSubmitStatus] = React.useState({
    payRemDue: true,
    updateRecurrence: false,
  });
  const [payDueRecurrencetable, setPayDueRecurrencetable] = React.useState<any>(
    []
  );
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [allMembers, setAllMembers] = useState<any>([]);
  const [addNewItem, setAddNewItem] = useState<any>(null);
  const [Taxes, setTaxes] = React.useState<any>([]);
  const [TotalTaxAmount, setTotalTaxAmount] = React.useState<number>(0);
  const items = [
    {
      iconClass: "fa fa-credit-card pr-1",
      text: "Add credit card",
      key: "addCard",
      visible:true
    },
    {
      iconClass: "fa fa-university pr-1",
      text: "Add bank account",
      key: "addBank",
      visible:paymentGatewayType === 1 ? true:false
    },
  ];

  const [remDueRecurDetails, setRemDueRecurDetails] = React.useState<any>(
    remainingDueRecDetails
  );
  const [payRemDueSubmitLoading, setpayRemDueSubmitLoading] =
    React.useState(false);
  const [serviceData, setserviceData] = React.useState<any>(null);
  const [serviceInstance, setServiceInstance] = React.useState<any>(null);
  React.useEffect(() => {
    updateServiceData();
    getAllSubMembers(props?.userDetails?.UserMemberId)
    fetchTaxes()
  }, []);

  const onCardOrBankAdd = (e: any, memberId: any) => {
    setShowPaymentDialog(true);
    setAddNewItem(e.item.key);
  };

  const getAllSubMembers = async (memberId: any) => {
    const tenantService = new TenantService();
    const res = await tenantService.GetSubMembersWithParent(memberId);
    setAllMembers(res);
  };

  const fetchServiceInstance = async (val: any) => {
    const fetchService = new TenantService();
    const result = await fetchService.getIndividualService(val);
    if (result) {
        setServiceInstance(result)
      return result;
    }
  };
  const updateServiceData = async() => {
    const {
      PackageCost,
      AmountPaid,
      FailedTransactionAmount,
      Name,
      PrivateServiceId,
      OcurrsEvery,
      NoOfInstallments,
      Rate,
      RepeatFrequency,
      Duration,
      NumberOfSessions,
      CanCollectTaxes,
    } = await fetchServiceInstance(props?.serviceInstance?.EntityId);
    let diffamount = PackageCost - AmountPaid;
    if (FailedTransactionAmount > 0) {
      diffamount = FailedTransactionAmount;
    }
    diffamount = parseFloat(diffamount.toFixed(2));
    const Servicedetails = {
      NumberOfSessions: NumberOfSessions,
      Duration: Duration,
      NewService: false,
      PackageCost: PackageCost,
      PackageCostAfterDiscount: PackageCost,
      RepeatFrequency: RepeatFrequency,
      Name: Name,
      RateAfterDiscount: Rate,
      AmountPaid: AmountPaid,
      Rate: Rate,
      PrivateServiceId: PrivateServiceId,
      OcurrsEvery: OcurrsEvery,
      NoOfInstallments: NoOfInstallments,
      CanCollectTaxes: CanCollectTaxes,
      FailedTransactionAmount: FailedTransactionAmount,
    };
    setIsTaxSkipped(!CanCollectTaxes)
    setserviceData(Servicedetails);
    setAmount(diffamount);
    setPayRemainingDue(diffamount);
    await fetchPaymentProfiles(props?.userDetails?.UserMemberId);
  };
  const refreshPaymentProfiles = () => {
    fetchPaymentProfiles(props?.userDetails?.UserMemberId);
  }
  //to get the payment profiles
  async function fetchPaymentProfiles(userMemberId: any,clearAdditionPP=true) {
    const paymentProfileData = new TenantService();
    const result = await paymentProfileData.paymentProfile(userMemberId);
    if (result) {
      if (result.length > 0) {
        const res = result.filter((item: any) => item.CardTypeId !== -1);
        setPaymentProfile(result);
        const test = result.filter(
          (item: any) =>
            item?.PaymentGatewayPaymentProfileID ===
            serviceInstance?.PaymentGatewayProfileId
        );
        if(clearAdditionPP){
          setSelectedPaymentProfile(test[0]);
          setAlternatePaymentProfileId(null)
        }
        return res
      }
    }
  }

   //recurrences calculations
   const CalculateRecurrence = (
    item: any,
    totalCost: any,
    paidToday: any,
    check: boolean
  ) => {
    const data = [];
    if (
      paidToday >= 0 &&
      item.recurrenceUnit != undefined &&
      item.NoOfInstallments > 0 &&
      item.NextRecurrence != undefined
    ) {
      if (totalCost >= paidToday) {
        let BalanceAmount = totalCost - paidToday;
        let NoOfInstallments =
          item.NoOfInstallments > 999 ? 999 : item.NoOfInstallments;
        if (BalanceAmount > 0) {
          let InstallmentAmount = BalanceAmount / NoOfInstallments;
          InstallmentAmount = parseFloat(InstallmentAmount.toFixed(2));
          let unit = item.recurrenceUnit.text;
          let InstallmentDate = new Date(item.NextRecurrence); 
          let totalAmt = 0;
          for (let i = 0; i < NoOfInstallments; i++) {
            totalAmt = totalAmt + InstallmentAmount;
            if (BalanceAmount !== totalAmt && i === NoOfInstallments - 1) {
              InstallmentAmount =
                InstallmentAmount + (BalanceAmount - totalAmt);
            }

            data.push({
              Date: InstallmentDate,
              DisplayDate: getLongDate(InstallmentDate),
              InstallmentAmount: InstallmentAmount,
            });

            InstallmentDate = addDates(
              new Date(InstallmentDate),
              parseInt(item.OccursEvery),
              unit
            );
          }
        }
      }
    }
    if (!check) {
      setPayDueRecurrencetable(data);
    }
  };
  const handleWaivePayment = (val: boolean) => {
    setIsWaivePayment(val);
    updateServiceData();
  };

  const handleChangeSavedCard = (val: any) => {
    setSelectedPaymentProfile(val);
    setAlternatePaymentProfileId(null)
  };

  const checkPayRemainingDue = (val: any) => {
    let value = val;
    if (!val || val === "") {
      value = 0;
    }
    if (val < 0) {
      value = Math.abs(val);
    }
    const { PackageCost, AmountPaid } = serviceInstance;
    const totalAmtToPay = PackageCost - AmountPaid;
    if (value === totalAmtToPay) {
      setPayRemainingDue(value);
      setRemDueRecurrence(false);
      setSubmitStatus({
        ...submistatus,
        payRemDue: true,
      });
      handleTaxCalculation(value)
    } else if (value < totalAmtToPay) {
      setPayRemainingDue(value);
      setRemDueRecurrence(true);
      setSubmitStatus({
        ...submistatus,
        payRemDue: true,
      });
      handleTaxCalculation(value)
    } else {
      setPayRemainingDue(totalAmtToPay);
      setRemDueRecurrence(false);
      setSubmitStatus({
        ...submistatus,
        payRemDue: false,
      });
      handleTaxCalculation(totalAmtToPay)
    }
  };

  React.useEffect(() => {
    if (payRemainingDue >= 0) {
      const amounttoUpdate =
      serviceInstance?.PackageCost - serviceInstance?.AmountPaid;
      CalculateRecurrence(
        remDueRecurDetails,
        amounttoUpdate,
        payRemainingDue,
        false
      );
    }
  }, [payRemainingDue]);

  const handlePayRemainingDue = (val: any, name: string) => {
    const { PackageCost, AmountPaid } = serviceInstance;
    const amounttoUpdate = PackageCost - AmountPaid;
    let value = val;
    if (name !== "NextRecurrence" && val < 0) {
      value = Math.abs(val);
    }
    let data = {
      ...remDueRecurDetails,
      [name]: value,
    };
    if (name === "OccursEvery" && parseInt(value) === 0) {
      data = {
        ...remDueRecurDetails,
        [name]: value,
        NoOfInstallments: 1,
      };
    }
    if (name === "NoOfInstallments" && val > 999) {
      data = {
        ...remDueRecurDetails,
        [name]: 999,
      };
    }
    setRemDueRecurDetails(data);
    setSubmitStatus({
      ...submistatus,
      payRemDue: true,
    });
    CalculateRecurrence(data, amounttoUpdate, payRemainingDue, false);
  };
  const handleReset = () => {
    setRemDueRecurrence(false);
    fetchApis();
    setMakePayment(false);
    updateServiceData();
  };
  const submitPayRemainigDue = async () => {
    if (!selectedPaymentProfile && !IsWaivePayment) {
      props?.handleNotificationMessage(
        "Please select Payment Profile",
        "error"
      );
      return;
    }
    let purchaseAmt=!IsTaxSkipped ? payRemainingDue + TotalTaxAmount : payRemainingDue
    purchaseAmt=CustomToFixed(purchaseAmt,2)
    if(selectedPaymentProfile?.CardTypeId === 8 && purchaseAmt > selectedPaymentProfile?.Credit && !AlternatePaymentProfileId){
      let defaultPaymentProfile=null
      const existedPP=paymentProfile?.filter((i:any)=> i?.CardTypeId !== -1 && i.CardTypeId !==8)
      if(existedPP?.length > 0){
        defaultPaymentProfile=existedPP[0]
      }
      setShowAdditionalPayment({
        dialog:true,
        selectedPaymentProfile:defaultPaymentProfile
      })
      return
    }

    if (
      remDueRecurrence &&
      (!remDueRecurDetails?.OccursEvery ||
        remDueRecurDetails?.OccursEvery <= 0 ||
        !remDueRecurDetails?.NoOfInstallments ||
        remDueRecurDetails?.NoOfInstallments <= 0 ||
        moment(remDueRecurDetails?.NextRecurrence).format("L") ===
          "Invalid date")
    ) {
      return;
    }

    const recurrences = payDueRecurrencetable.map((item: any) => {
      return {
        ...item,
        Date: moment(item.Date).format("L"),
        InstallmentAmount: item.InstallmentAmount.toFixed(2),
      };
    });
    const serviceDet = {
      ...serviceData,
      OcurrsEvery: remDueRecurDetails?.OccursEvery,
      RepeatFrequency: remDueRecurDetails?.recurrenceUnit?.id,
      NoOfInstallments: remDueRecurDetails?.NoOfInstallments,
    };
    const ClientData=allMembers?.filter((i:any)=>i?.UserMemberId === props?.serviceInstance?.UserMemberId)?.[0]
    console.log(ClientData,'ClientData')
    const clientDetails = {
      ClientId: ClientData?.UserMemberId,
      PaymentCards: [],
      UserMemberId: ClientData?.UserMemberId,
      ClientName: ClientData?.FullName,
      UserId: ClientData?.UserId,
      MemberNumber: ClientData?.MemberNumber,
      FirstName: ClientData?.FirstName,
      LastName: ClientData?.LastName,
      PrimaryMemberId:
        ClientData?.ParentId || ClientData?.UserMemberId,
      Email: ClientData?.Email,
    };
    const req:any = {
      RecurrenceText: "",
      Amount: 0,
      IsWaivePayment: IsWaivePayment,
      Service: serviceDet,
      Client: clientDetails,
      PaymentProfile: !IsWaivePayment
        ? {
            PaymentGatewayPaymentProfileID:
              selectedPaymentProfile?.PaymentGatewayPaymentProfileID,
            CustomerProfileID: selectedPaymentProfile?.CustomerProfileID,
            PaymentProfileID: selectedPaymentProfile?.PaymentProfileID,
            MaskedCCNumber: selectedPaymentProfile?.MaskedCCNumber,
          }
        : {},
      DownPayment: payRemainingDue,
      PrivateServiceInstanceId:
        serviceInstance?.PrivateServiceInstanceId,
      CanLoadMemberRecurringDetail:true,
      // MemberRecurringDetailId: props.serviceInstance.MemberRecurringDetailId,
      Status: 1,
      MaskedCCNumber: !IsWaivePayment
        ? selectedPaymentProfile?.MaskedCCNumber
        : undefined,
      Recurrences: recurrences,
      NextRecurringDate: moment(remDueRecurDetails?.NextRecurrence).format("L"),
      Taxes:!IsTaxSkipped && serviceData?.FailedTransactionAmount ===0 ? Taxes : [],
      CanCollectTaxes :!IsTaxSkipped && serviceData?.FailedTransactionAmount ===0 ? true : false,
      TotalTaxAmount:!IsTaxSkipped && serviceData?.FailedTransactionAmount ===0 ? TotalTaxAmount : 0
    };
    if(selectedPaymentProfile?.CardTypeId === 8 && purchaseAmt > selectedPaymentProfile?.Credit){
      console.log(purchaseAmt,selectedPaymentProfile?.Credit)
      const AmountChargeByAlternatePaymentProfile=CustomToFixed(purchaseAmt-selectedPaymentProfile?.Credit,2)
      req.AlternatePaymentProfileId=AlternatePaymentProfileId
      req.AmountChargeByAlternatePaymentProfile=AmountChargeByAlternatePaymentProfile
   }

    const checkoutservice = new TenantService();
    setpayRemDueSubmitLoading(true);
    const result = await checkoutservice.purchaseService(req);
    setpayRemDueSubmitLoading(false);
    if (result.ResponseCode === 100) {
      const successMsg = "Remaining Due paid successfully";
      props?.handleNotificationMessage(successMsg, "success");
      props?.onSuccessfullPayment();
      setRemDueRecurrence(false);
    } else {
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
    await fetchApis();
    setMakePayment(false);
  };

  const fetchApis = async () => {
    await updateServiceData();
    await fetchPaymentProfiles(props?.userDetails?.UserMemberId);
  };
  const fetchTaxes = async () => {
    const req = {};
    const taxService= new ManageTaxesService()
    const res = await taxService.getTaxes(req);
    setTaxes(res);
  };

  const handleMakePayment=()=>{
    setMakePayment(true)
    if(serviceData?.FailedTransactionAmount ===0){
      handleTaxCalculation(payRemainingDue)
    }
  }
  const handleChangeAdditionalPaymentProfile = async (value: any) => {
    setShowAdditionalPayment({
      ...showAdditionalPayment,selectedPaymentProfile:value
    })
  }

  const handleAddAdditionalPayment=()=>{
    if(!showAdditionalPayment?.selectedPaymentProfile?.PaymentGatewayPaymentProfileID){
      const errorMsg = "Please select/add Payment profile";
      props?.handleNotificationMessage(errorMsg, "error");
      return
    }
    setAlternatePaymentProfileId(showAdditionalPayment?.selectedPaymentProfile?.PaymentGatewayPaymentProfileID)
    setShowAdditionalPayment(initialAdditionalPayment)
  }

  const handleTaxCalculation=(purchaseAmt:number,TaxItems=Taxes)=>{
    const TaxesArray=TaxItems.map((i:any)=>{
      const amount=purchaseAmt*i?.Percentage/100;
      const calcAmt=CustomToFixed(amount,2)
        return {
          "TaxId": i?.TaxId,
          "TaxName": i?.TaxName,
          "Percentage": i?.Percentage,
          "Amount": calcAmt
        }
    })
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
          return acc + currentValue?.Amount
    }, 0);
    setTaxes(TaxesArray)
    setTotalTaxAmount(taxAmt)
  }

  return {
    makePayment,
    amount,
    IsWaivePayment,
    handleMakePayment,
    handleWaivePayment,
    paymentProfile,
    selectedPaymentProfile,
    handleChangeSavedCard,
    payRemainingDue,
    checkPayRemainingDue,
    remDueRecurrence,
    handlePayRemainingDue,
    remDueRecurDetails,
    payDueRecurrencetable,
    handleReset,
    submistatus,
    payRemDueSubmitLoading,
    submitPayRemainigDue,
    refreshPaymentProfiles,
    items,
    Taxes,
    TotalTaxAmount,
    serviceData,
    IsTaxSkipped,
    setIsTaxSkipped,
    setShowPaymentDialog,
    setAddNewItem,
    showPaymentDialog,
    addNewItem,
    onCardOrBankAdd,
    setShowAdditionalPayment,
    handleChangeAdditionalPaymentProfile,
    initialAdditionalPayment,
    handleAddAdditionalPayment,
    showAdditionalPayment,
    fetchPaymentProfiles
  };
};

export default usePayRemaingDue;
