import React, { useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import RenderUpcomingAppointments from "./UpcomingAppointments";
import { appointmentTab } from "utils/utils";
import moment from "moment";
import CustomCalendar from "atoms/CustomCalendar";
const tabs = [
  { title: appointmentTab.UPCOMING },
  { title: appointmentTab.COMPLETED },
];

const AppointmentTabs = (props: any) => {
  const [selected, setSelected] = React.useState(0);
  const [fromDateToDate, setFromDateToDate] = useState({
    from: moment().format("YYYY-MM-DDTHH:mm:ss"),
    to: moment().add(1, 'month').endOf("month").endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
  });
  // useEffect(() => {
  //   if(selected === 1){
  //     setFromDateToDate({
  //       from: moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
  //       to: moment().format("YYYY-MM-DDTHH:mm:ss"),
  //     })
  //   }else if(selected === 0) {
  //     setFromDateToDate({
  //       from: moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
  //       to: moment().endOf("month").endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
  //     })
  //   }
  // }, [selected])
  const handleSelect = (val: any) => {
    if (val?.selected === 2) {
      console.log(val);
      return true;
    }
    if(val?.selected === 1){
      setFromDateToDate({
        from: moment().startOf("month").startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
        to: moment().subtract(0, 'day').format("YYYY-MM-DD") + "T23:59:59",
      })
    }else if(val?.selected === 0) {
      setFromDateToDate({
        from: moment().format("YYYY-MM-DDTHH:mm:ss"),
        to: moment().add(1, 'month').endOf("month").endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
      })
    }
    setSelected(val?.selected);
  };
  const setFromDateToDateFunc = (val: any) => {
    console.log(val)
    setFromDateToDate(val);
  }
  return (
    <div className="left-section">
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="mb-3 pt-3 bz_fs_1_5">Appointments Info</h5>
        {/* <DateFilterDropDown
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
            defaultItem=""
            setFromDateToDate={setFromDateToDate}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
        /> */}
      </div>
      <TabStrip
        className="appointment-info-tabs"
        selected={selected}
        onSelect={(e: any) => handleSelect(e)}
      >
        {tabs.map((val, indx) => {
          let text = val.title;
          return (
            <TabStripTab title={val.title} key={indx}>
              {selected === indx && (
                <>
                <CustomCalendar
                  onlyFutureDates={selected === 0}
                  onlyPastDates={selected === 1}
                  setFromDateToDate={setFromDateToDateFunc}
                  selectedDateItem={selected === 0 ? `${moment().format("MM/DD/YYYY")} - ${moment().add(1, 'month').endOf("month").endOf("day").format("MM/DD/YYYY")}`: "This Month"}
                />
                <RenderUpcomingAppointments
                  {...props}
                  type={text}
                  // all={false}
                  // staffPage={props?.staffPage}
                  // uuid={props?.uuid}
                  // userData={props?.userData}
                  // deleteGs={true}
                  // paynowGs={true}
                  // serviceId={null}
                  fromDateToDate={fromDateToDate}
                />
                </>
              )}
            </TabStripTab>
          );
        })}
        {
          <TabStripTab
            title={
              <>
              </>
            }
          />
        }
      </TabStrip>
    </div>
  );
};

export default AppointmentTabs;
