import BuzopsLoader from "generic-components/BuzopsLoader";
import { Card, CardBody, Menu, MenuItem } from "@progress/kendo-react-layout";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Switch, Route, useHistory } from "react-router-dom";
import useAffiliateDashboard from "./useAffiliateDashboard";
import AffiliatePromotions from "./AffiliatePromotions";
import MyLinks from "./MyLinks";
import MyProfile from "./MyProfile";
import { AffiliateDashboardLinks, UserAffiliateStatusEnum } from "utils/form-utils";
import AffiliateOverview from "./Overview";
import AffiliatePromotionOverview from "./AffiliatePromotionOverview";
import AffiliateProgramPayouts from "components/AffiliateProgram/Payouts/AffiliateProgramPayouts";
import AffiliateProfileHeader from "./AffiliateProfileHeader";
import AffiliatePromotionActivity from "./AffiliatePromotionActivity";

const AffiliateDashboard = (props: any) => {
  const history = useHistory();
  const {
    MainMenuItems,
    routeMatch,
    handleMenuSelection,
    selectedMenu,
    loading,
    uuid,
    UserAssociateId,
    affiliateDetails,
    fetchAffiliateById
  } = useAffiliateDashboard(props);

  const TooltipContentTemplate = (propsData: any) => {
    return (
      <a>
        <strong>{propsData.title}</strong>
      </a>
    );
  };

  const renderMenus = (show = false, MenuItems: any) => {
    const Items = MenuItems;
    return (
      <Menu vertical={false}>
        {Items.filter((j: any) => j.visible === true).map(
          (item: any, index: number) => {
            const status = item.to === selectedMenu ? "active" : "inactive";
            return (
              <MenuItem
                data={item}
                key={item.id}
                cssClass={status}
                render={(props: any) => {
                  return (
                    <>
                      <Tooltip
                        // key={`${show.toString()}${props.item.data.id}`}
                        content={(props: any) => (
                          <TooltipContentTemplate title={props.title} />
                        )}
                        anchorElement="target"
                        position="right"
                      >
                        <div style={{ width: "100%" }}>
                          <a
                            onClick={() => handleMenuSelection(props.item.data)}
                            title={props.item.data.text}
                          >
                            <button
                              className={props.item.data.iconClass}
                              aria-hidden="true"
                              title={props.item.data.text}
                            ></button>
                            {show && (
                              <>
                                <span className="bz-menu-text pl-1">
                                  {props.item.data.text}
                                </span>
                              </>
                            )}
                          </a>
                        </div>
                      </Tooltip>
                    </>
                  );
                }}
              ></MenuItem>
            );
          }
        )}
      </Menu>
    );
  };

  const renderBodyItems = (routeParams: any) => {
    const routeId = routeParams?.text;
    console.log(affiliateDetails);
    switch (routeId) {
      case AffiliateDashboardLinks.OVERVIEW:
        return (
          <AffiliateOverview
            associateId={UserAssociateId}
            uuid={uuid}
            admin={uuid ? true :false}
            affiliateDetails={affiliateDetails}
          />
        );
      case AffiliateDashboardLinks.PROMOTIONS:
        if (routeParams?.id) {
          return (
            <AffiliatePromotionOverview
              associateId={UserAssociateId}
              admin={uuid ? true :false}
              uuid={uuid}
              PromotionId={routeParams?.id}
            />
          );
        }
        return (
          <AffiliatePromotions
            uuid={uuid}
            admin={uuid ? true :false}
            associateId={UserAssociateId}
            affiliateDetails={affiliateDetails}
          />
        );
      case AffiliateDashboardLinks.PAYOUTS:
          return <AffiliateProgramPayouts admin={uuid ? true :false} AffiliateUserId={affiliateDetails?.UserId}  payoutFilter={routeParams?.id} />;
      case AffiliateDashboardLinks.MYLINKS:
        return (
          <MyLinks
            associateId={UserAssociateId}
            uuid={uuid}
            affiliateDetails={affiliateDetails}
          />
        );
      case AffiliateDashboardLinks.MYPROFILE:
        return (
          <MyProfile
            associateId={UserAssociateId}
            uuid={uuid}
            affiliateDetails={affiliateDetails}
            fetchAffiliateById={fetchAffiliateById}
          />
        );
      case AffiliateDashboardLinks.PROMOTIONACTIVITY:
        return (
          <AffiliatePromotionActivity
          admin={uuid ? true :false}
            associateId={UserAssociateId}
            uuid={uuid}
            affiliateDetails={affiliateDetails}
          />
        );
      default:
        return (
          <AffiliateOverview
            associateId={UserAssociateId}
            uuid={uuid}
            admin={uuid ? true :false}
            affiliateDetails={affiliateDetails}
          />
        );
    }
  };
  const renderBody = () => {
    return (
      <Switch>
        <Route path={routeMatch?.path}>
          {renderBodyItems(routeMatch?.params)}
        </Route>
      </Switch>
    );
  };
  if (loading) {
    return <BuzopsLoader type="list" />;
  }

  if(affiliateDetails?.Status === UserAffiliateStatusEnum.InActive && !uuid){
    return (<div className="">
    <div id="bz-notfound">
      <div className="bz-notfound">
        {/* <div className="bz-notfound-404"></div> */}
        <h2 style={{ textTransform: "none", fontSize: "1.2rem" }}>
        {"Your Affiliate account has been deactivated. Please contact the administrator to reinstate your account."}
        </h2>
      </div>
    </div>
  </div>)
  }

  return (
    <div className="Affiliate-body">
      <div className={``}>
        <Card className="menu-card-horizontal affliates">
          {uuid ? (
            <span
              className="d-flex justify-content-start p-2"
              onClick={() =>
                history.goBack()
              }
            >
              <span className="bgbackaffilates"> 
              <i
                className="fa fa-arrow-left "
                aria-hidden="true"
                style={{cursor: 'pointer' }}
              ></i>
              </span>
            </span>
          ):null}
          <AffiliateProfileHeader handleMenuSelection={handleMenuSelection} affiliateDetails={affiliateDetails} />
          <CardBody>
            <>{renderMenus(true, MainMenuItems)}</>
          </CardBody>
        </Card>
        <div className="bz-affiliate-content">
          <div className="bz-affiliate-content-area">{renderBody()}</div>
        </div>
      </div>
    </div>
  );
};

export default AffiliateDashboard;
