import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { GetLocalStore, LocalStore } from "utils/storage";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { Checkbox, Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { ListView } from "@progress/kendo-react-listview";
import BuzopsButton from "generic-components/BuzopsButton";
import { CheckUserMemberStatus, CurrencyFormat } from "utils";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import Auth from "components/authcomponent/Auth";
import withNotification from "components/Hoc/withNotification";
import { Error, Hint } from "@progress/kendo-react-labels";
import { TenantService } from "services/tenant/index.service";
import Location from "components/Location/Location";
import { useSelector } from "react-redux";
import { CalculateOffer } from "utils/utils";
import { GenerateOfferLabel } from "components/AffiliateSubscriptions/AffiliateSubscriptions.view";

const Service = (props: any) => {
  const showMoreRef = useRef<any>();
  const isServiceLoading = props.isServiceLoading;
  const redirectionObj = useSelector((state: any) => state?.redirection)
  const history = useHistory();
  const userDetails = GetLocalStore("userDetails");
  const [filterValue, setFilterval] = useState<any>(null);
  const isLoggedIn = userDetails ? true : false;
  const [mobileFilterDialog, setmobileFilterDialog] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [customDialog, setCustomDialog] = useState(false);
  const initialCustomService = {
    Name: "",
    NumberOfSessions: 0,
    DurationInMinutes: 0,
    Rate: 0,
    OriginalRate: 0,
    ServiceRateId: 0,
  };
  const [customService, setcustomService] = useState(initialCustomService);
  const [expandedItem, setExpandItem] = useState(null);
  const [customLoading, setCustomLoading] = useState(false);
  const [servicesData, setServicesData] = useState<any>([]);
  const [checkedValues, setCheckedValues] = useState([0]);
  const [sortval, setSortVal] = useState(false);
  const [loginStatus, setLoginStatus] = useState(false);
  const [checked, setChecked] = useState({
    All: true,
    Paid: false,
    Unpaid: false,
    Complimentary: false,
  });
  const isStaffPage = props?.staffPage;
  const filterValues = [
    { text: "All", id: 0 },
    { text: "Paid", id: 1 },
    { text: "Unpaid", id: 2 },
    { text: "Complimentary", id: 3 },
  ];

  const handleSelectService = (item: any) => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    const serviceId = item.InternalDataId;
    const serviceItem = {
      ...item, AffiliateId: props?.AffiliateDetails?.UserAssociateId || null,
      AffiliateUserId: props?.AffiliateDetails?.UserId || null,
      PromotionId: props?.PromotionId || null,
      CouponId: props?.offerObj?.CouponId || null,
      CouponDescription: props?.offerObj?.CouponCode || null
    }
    if (isLoggedIn) {
      if (item.PrivateServiceType !== 1) {
        if (props?.staffPage) {
          history.push(
            `/member/${props?.uuid}/${props?.userData?.UserMemberId}/mybooking`,
            {
              selectedService: serviceItem,
              staffPage: true,
              userData: props?.userData,
              uuid: props?.uuid,
            }
          );
        } else {
          if (redirectionObj?.firstPathValue === "external") {
            history.push(`/${redirectionObj?.firstPathValue}/mybooking`, {
              selectedService: serviceItem,
            });
          } else {
            history.push(`/mybooking`, {
              selectedService: serviceItem,
            });
          }
        }
      } else {
        if (props?.staffPage) {
          history.push(
            `/member/${props?.uuid}/${props?.userData?.UserMemberId}/checkout/${serviceId}`,
            {
              staffPage: true,
              userData: props?.userData,
              uuid: props?.uuid,
              selectedService: serviceItem
            }
          );
        } else {
          if (redirectionObj?.firstPathValue === "external") {
            history.push(`/${redirectionObj?.firstPathValue}/checkout/${serviceId}`, {
              selectedService: serviceItem
            });
          } else {
            history.push(`/checkout/${serviceId}`, {
              selectedService: serviceItem
            });
          }
        }
      }
    } else {
      if (item.PrivateServiceType !== 1) {
        if (redirectionObj?.firstPathValue === "external") {
          history.push(`/${redirectionObj?.firstPathValue}/mybooking`, {
            selectedService: serviceItem,
          });
        } else {
          history.push(`/mybooking`, {
            selectedService: serviceItem,
          });
        }
      } else {
        LocalStore("serviceItem", serviceItem);
        setLoginStatus(true);
      }
    }
  };

  const handleCloseLogin = () => {
    setLoginStatus(false);
  };

  useEffect(() => {
    setServicesData(props.servicesData);
  }, [props.servicesData]);

  useEffect(() => {
    sortFilter();
  }, [sortval]);

  useEffect(() => {
    filterServices();
  }, [checkedValues]);

  const sortFilter = () => {
    if (sortval) {
      //descending
      const sortData = servicesData.sort((a: any, b: any) =>
        a.Name.toLowerCase() > b.Name.toLowerCase() ? -1 : 1
      );
      setServicesData(sortData);
    } else {
      //ascending
      const sortData = servicesData.sort((a: any, b: any) =>
        a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1
      );
      setServicesData(sortData);
    }
    setPage({ skip: 0, take: 5 });
  };

  const filterServices = (serviceData = props.servicesData, flag = true) => {
    const filter = checkedValues.findIndex((item) => item === 0);
    if (filter !== -1) {
      if (flag) {
        handleSearch(filterValue, serviceData, false);
      } else {
        setServicesData(serviceData);
      }
    } else {
      const data = serviceData.filter((item: any) =>
        checkedValues.includes(item.PrivateServiceType)
      );
      if (flag) {
        handleSearch(filterValue, data, false);
      } else {
        setServicesData(data);
      }
    }
    setPage({ skip: 0, take: 5 });
  };

  const onServiceSelected = (val: any, provider: any, providerName: any) => {
    if (val.PrivateServiceType !== 1) {
      if (redirectionObj?.firstPathValue === "external") {
        history.push(`/${redirectionObj?.firstPathValue}/${providerName}/mybooking`, {
          provider,
          selectedService: val,
          serviceShortName: props?.serviceShortName
        });
      } else {
        history.push(`/${providerName}/mybooking`, {
          provider,
          selectedService: val,
          serviceShortName: props?.serviceShortName
        });
      }
    } else {
      if (userDetails) {
        if (redirectionObj?.firstPathValue === "external") {
          history.push(`/${redirectionObj?.firstPathValue}/${providerName}/checkout`, {
            provider,
            selectedService: val,
          });
        } else {
          history.push(`/${providerName}/checkout`, {
            provider,
            selectedService: val,
          });
        }
      } else {
        LocalStore("serviceItem", val);
        setLoginStatus(true);
      }
    }
  };

  const handleChangeCustomService = async (value: any, name: string) => {
    let val = value;
    if (name === "NumberOfSessions" || name === "DurationInMinutes" || name === "Rate") {
      val = Math.abs(value)
    }

    if (name === "NumberOfSessions" && val > 0) {
      const req = {
        ServiceTypeId: 2,
        NoOfServices: val,
        IsActive: true,
      };
      const service = new TenantService();
      setCustomLoading(true);
      const result = await service?.GetSessionCost(req);
      setCustomLoading(false);
      if (result?.ResponseCode === 100) {
        const dataItem = result?.Item;
        const rateOfAppt = dataItem?.Rate / val
        setcustomService({
          ...customService,
          [name]: val,
          Rate: rateOfAppt,
          OriginalRate: rateOfAppt,
          ServiceRateId: dataItem?.ServiceRateId
        });
      }
    } else {
      setcustomService({
        ...customService,
        [name]: val,
      });
    }
  };

  const handleContinue = async () => {
    setSubmit(true);
    if (
      customService?.DurationInMinutes > 120 ||
      customService?.DurationInMinutes <= 0 ||
      customService?.NumberOfSessions <= 0 ||
      customService?.Rate <= 0 ||
      customService?.Name === ""
    ) {
      return;
    }
    const obj = {
      ...customService,
    }

    history.push(
      `/member/${props?.uuid}/${props?.userData?.UserMemberId}/checkout/${obj?.ServiceRateId}`,
      {
        staffPage: true,
        userData: props?.userData,
        uuid: props?.uuid,
        customService: obj,
      }
    );
    handleCustomEvent();
  };

  const [page, setPage] = React.useState({
    skip: 0,
    take: 5,
  });
  const handlePageChange = (status = "more") => {
    if (status === "less") {
      setPage({
        skip: 0,
        take: 5,
      });
    } else {
      setPage({
        take: page.take + 5,
        skip: 0,
      });
    }
    if (showMoreRef.current) {
      showMoreRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };
  const { take } = page;
  const handleChange = (e: any, name: number, value: string) => {
    setChecked({ ...checked, [value]: !e });
    const data = [...checkedValues];
    const test = checkedValues.findIndex((item) => item === name);
    if (name !== 0) {
      const filter = checkedValues.findIndex((item) => item === 0);
      if (filter !== -1) {
        data.splice(filter, 1);
      }
      if (e) {
        if (test === -1) {
          data.push(name);
        }
      } else {
        if (test !== -1) {
          data.splice(test, 1);
        }
      }
      if (data.length === 0) {
        setCheckedValues([0]);
      } else {
        setCheckedValues(data);
      }
    } else {
      setCheckedValues([0]);
    }
  };

  const handleExpand = (item: any) => {
    setExpandItem(item.PrivateServiceId);
  };
  const handleCollapse = () => {
    setExpandItem(null);
  };

  const MyItemRender = (props: any, data: any) => {
    let item = props.dataItem;
    let collpaseStatus = false;
    if (expandedItem === item.PrivateServiceId) {
      collpaseStatus = true;
    }
    let className = "";
    if (item.PrivateServiceType === 1) {
      className = "bzo-status-success";
    } else if (item.PrivateServiceType === 2) {
      className = "bzo-status-consultation";
    } else {
      className = "bzo-status-Complimentary";
    }

    return (
      <div className="row buz-online-list-view-new bz-one-on-one-purchase">
        <div className="col-md-9 pr-0">
          <div className="p-3 align-middle pe-4 pe-sm-0">
            <div className="d-flex align-items-start align-items-sm-center mb-2">
              <h6 className="service-title bz-fw-6 align-self-center">
                <span>{item?.Name}</span>
                <span className="location-icon">{item?.Attributes !== "" && <Location Attributes={item?.Attributes} showDetails={isStaffPage} VirtualMeetingLink={item?.VirtualMeetingLink} />}</span>
              </h6>
            </div>
            <div className="row gx-0 mt-2 mb-md-3">
              <div className="col-md-3 align-self-center">
                <div className="bz-name time">
                  <span className="bz-availability-appointments">
                    {`${item.Duration}`}
                  </span>
                </div>
              </div>
              <div className="col-md-3 align-self-center">
                <div className="bz-name appoinments">
                  <span className="bz-availability-appointments">
                    {`${item.NumberOfSessions} Appointments`}
                  </span>
                </div>
              </div>
              <div className="col-md-3 align-self-center price-blk">
                <div className="bzc-service-name">
                  <div className="bzc-service-name">
                    <div className="bz-price">
                      <span className="mb-0"><span className="ratelabel"></span>
                        {data?.AffiliateDetails && data?.offerObj ? (<>
                          <del>
                            {CurrencyFormat(item?.Rate)}
                          </del>
                          <span className="buz-font-600 buz-text-medium" style={{ color: 'green' }}>{` ${CurrencyFormat(CalculateOffer(item?.Rate, data?.offerObj))}`}</span>  <GenerateOfferLabel offerObj={data?.offerObj} />
                        </>) : (<>
                          {CurrencyFormat(item?.Rate)}
                        </>)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 align-self-center">
                <div className="bz-name">
                  <span className="bz-availability-appointments">
                    {item.Description !== "" && (
                      <>
                        {collpaseStatus ? (
                          <span
                            className="align-baseline bz-cursor-pointer p-0"
                            onClick={(e) => handleCollapse()}
                          >
                            view details <i className="fas fa-angle-up"></i>
                          </span>
                        ) : (
                          <span
                            className="bz-cursor-pointer  p-0"
                            onClick={(e) => handleExpand(item)}
                          >
                            view details <i className="fas fa-angle-down"></i>
                          </span>
                        )}
                      </>
                    )}
                  </span>
                </div>
              </div>
            </div>

            {/* <div className="section-3">
              {item.PrivateServiceType !== 2 && (
                <div className="bzc-service-name">
                  <div className="bz-name">
                    <p style={{ fontSize: "12px" }}>
                      {"Minimum Appointment Schedule Time"}
                      <strong>
                        {" "}
                        {`${item.MinimumAppointmentBookingDuration} ${item.MinimumAppointmentBookingDurationTypeDescription} in Advance`}
                      </strong>
                    </p>
                  </div>
                </div>
              )}
            </div> */}

            {collpaseStatus && (
              <>
                <div className="service-desc bz-ol-muted">
                  {item.Description}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="col-md-3 pl-0 btn-blk" style={{ alignSelf: "center" }}>
          <div className="section-5">
            {data.provider && data.provider !== null ? (
              <Button
                className="select-button bz-service-select-btn bz-service-select-btn-new p-3"
                style={{
                  backgroundColor: "#008db9",
                  color: "#fff",
                  padding: "10px 40px"
                }}
                onClick={() => {
                  onServiceSelected(item, data.provider, data.providerName);
                }}
              >
                Select
              </Button>
            ) : (
              <Button
                className="select-button bz-service-select-btn bz-service-select-btn-new p-3"
                style={{
                  backgroundColor: "#008db9",
                  color: "#fff",
                  padding: "10px 40px"
                }}
                onClick={() => {
                  handleSelectService(item);
                }}
              >
                Select
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderServiceSearchMobile = () => {
    return (
      <>
        <div className="bzo-leftside-filter-container">
          <div>
            <div className="form-check">
              <Checkbox
                checked={checkedValues.includes(0)}
                name="all"
                label={"All"}
                onChange={(e) => handleChange(e.value, 0, "")}
              />
            </div>
            <div className="form-check">
              <Checkbox
                checked={checkedValues.includes(1)}
                label={"Paid"}
                name={"paid"}
                onChange={(e) => handleChange(e.value, 1, "")}
              />
            </div>

            <div className="form-check">
              <Checkbox
                checked={checkedValues.includes(2)}
                label={"Unpaid"}
                name={"Unpaid"}
                onChange={(e) => handleChange(e.value, 2, "")}
              />
            </div>
            {/* <div className="form-check">
              <Checkbox
                checked={checkedValues.includes(3)}
                label={"Complimentary"}
                name={"complimentary"}
                onChange={(e) => handleChange(e.value, 3, "")}
              />
            </div> */}
          </div>
          <Button
            primary={true}
            className={"float-right mt-3"}
            onClick={() => setmobileFilterDialog(!mobileFilterDialog)}
          >
            Okay
          </Button>
        </div>
      </>
    );
  };

  const renderCustomService = () => {
    return (
      <div className="">
        <div style={{ width: "100%" }} className="bz-add-bank">
          <div className="">
            <div className="row pb-2">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <Input
                  id={"Name"}
                  name={"Name"}
                  value={customService?.Name}
                  placeholder={"Name of the Custom Service"}
                  label={"Name of the Custom Service"}
                  style={{ width: "100%" }}
                  onChange={(e: any) =>
                    handleChangeCustomService(e.value, "Name")
                  }
                />
                {submit && customService?.Name === "" && (
                  <Error>{"Please enter One-on-one name"}</Error>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <NumericTextBox
                  min={0}
                  id={"NumberOfSessions"}
                  name={"NumberOfSessions"}
                  placeholder={"No. of Appointments"}
                  className="k-dropdown-width-100"
                  label={"No. of Appointments"}
                  value={customService?.NumberOfSessions}
                  width={"100%"}
                  onChange={(e: any) =>
                    handleChangeCustomService(
                      e.target.value,
                      "NumberOfSessions"
                    )
                  }
                />
                {submit && customService?.NumberOfSessions <= 0 && (
                  <Error>{"Please enter minimum one Appointment"}</Error>
                )}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <NumericTextBox
                  min={0}
                  max={120}
                  width={"100%"}
                  value={customService?.DurationInMinutes}
                  id={"DurationInMinutes"}
                  name={"DurationInMinutes"}
                  placeholder={"Duration"}
                  className="k-dropdown-width-100"
                  label={"Duration"}
                  onChange={(e: any) =>
                    handleChangeCustomService(
                      e.target.value,
                      "DurationInMinutes"
                    )
                  }
                />
                <Hint>
                  <span className="form-text text-muted">
                    Max duration is 120 minutes
                  </span>
                </Hint>
                {submit &&
                  (customService?.DurationInMinutes <= 0 ||
                    customService?.DurationInMinutes > 120) && (
                    <Error>{"Please enter valid Duration"}</Error>
                  )}
              </div>
            </div>
            <div className="row">
              {customLoading ? <BuzopsLoader type="list" /> : <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <NumericTextBox
                  min={0}
                  id={"Rate"}
                  name={"Rate"}
                  placeholder={"Per Appointment Rate"}
                  className="k-dropdown-width-100"
                  label={"Per Appointment Rate"}
                  value={customService?.Rate}
                  width={"100%"}
                  format={"c2"}
                  onChange={(e: any) =>
                    handleChangeCustomService(
                      e.target.value,
                      "Rate"
                    )
                  }
                />
                {customService?.NumberOfSessions > 0 ? <Hint>
                  <span className="form-text text-muted">
                    {`Estimated Per Appointment Rate is ${CurrencyFormat(customService?.OriginalRate)}`}
                  </span>
                </Hint> : null}
                {submit && customService?.Rate <= 0 && (
                  <Error>{"Please enter Per Appointment Rate"}</Error>
                )}
              </div>}
            </div>
          </div>
          <div className="d-flex pt-2">
            <Button
              type={"reset"}
              onClick={() => handleCustomEvent()}
              className="ml-0"
            >
              Cancel
            </Button>
            <BuzopsButton
              primary
              label={"Continue"}
              type={"submit"}
              onClick={() => handleContinue()}
            />
          </div>
        </div>
      </div>
    );
  };
  const handleCustomEvent = () => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    setCustomDialog(!customDialog);
    setSubmit(false);
    setcustomService(initialCustomService);
  };

  const filterServiceComponent = () => {
    return (
      <div className="bzo-leftside-filter-container-new py-3 px-0 card">
        <div className="bz-check-box-filter-new">
          <button
            className="btn btn-link px-3"
            style={{ color: `${checked.All ? "#000" : "#ff7a0e"}` }}
            onClick={(e) => handleChange(checked.All, 0, "All")}
          >
            All
          </button>
          <br />
          <button
            className="btn btn-link px-3"
            style={{ color: `${checked.Paid ? "#000" : "#ff7a0e"}` }}
            onClick={(e) => handleChange(checked.Paid, 1, "Paid")}
          >
            Paid
          </button>
          <br />
          <button
            className="btn btn-link px-3"
            style={{ color: `${checked.Unpaid ? "#000" : "#ff7a0e"}` }}
            onClick={(e) =>
              handleChange(checked.Unpaid, 2, "Unpaid")
            }
          >
            Unpaid
          </button>
          <br />
          <button
            className="btn btn-link px-3"
            style={{ color: `${checked.Complimentary ? "#000" : "#ff7a0e"}` }}
            onClick={(e) =>
              handleChange(checked.Complimentary, 3, "Complimentary")
            }
          >
            Complimentary
          </button>
        </div>
      </div>
    );
  };

  const showServicesComponent = () => {
    if (isServiceLoading) {
      return <BuzopsLoader type={"list"} />;
    } else {
      if (servicesData.length === 0) {
        return (
          <div className={"bzo-services-container border-0 text-muted"}>
            No Available One-on-One {" "}
          </div>
        );
      } else {
        return (
          <div className="bzo-services-container">
            <ListView
              data={servicesData.slice(0, 0 + take)}
              item={(e) => MyItemRender(e, props)}
            />
            <div className="d-flex justify-content-center align-items-center bz-services-showMore">
              <div ref={showMoreRef}>
                {servicesData.length > take && (
                  <span
                    className="btn btn-link  align-baseline bz-cursor-pointer p-0"
                    onClick={() => handlePageChange()}
                  >
                    View More <i className="fas fa-caret-down"></i>
                  </span>
                )}
                {servicesData.length <= take && servicesData.length > 5 && (
                  <span
                    className="btn btn-link  align-baseline bz-cursor-pointer p-0"
                    onClick={() => handlePageChange("less")}
                  >
                    View Less <i className="fas fa-caret-up"></i>
                  </span>
                )}
              </div>
            </div>
          </div>
        );
      }
    }
  };

  const handleSearch = (
    value: string,
    serviceData = props.servicesData,
    flag = true
  ) => {
    setFilterval(value);
    if (value) {
      const results = serviceData.filter((item: any) =>
        item.Name.toLowerCase().includes(value.toLowerCase())
      );

      if (flag) {
        filterServices(results, false);
      } else {
        setServicesData(results);
      }
    } else {
      if (flag) {
        filterServices(serviceData, false);
      } else {
        setServicesData(serviceData);
      }
    }
  };

  return (
    <>
      {props?.serviceShortName && props?.serviceShortName !== null && (
        <>
          <div className="row">
            <div className={"d-flex justify-content-start mt-3"}>
              <h5 className="card-title bzo-primary-heading mb-0">
                One-on-One
              </h5>
            </div>
          </div>
          <br />
        </>
      )}
      {!props?.serviceShortName && (
        <div className="row">
          <div className="col-xl-12 mb-2 mb-lg-0">
            {props?.staffPage && (
              <Button primary onClick={() => handleCustomEvent()}>
                Select Custom
              </Button>
            )}
          </div>
        </div>
      )}
      {isServiceLoading ? (
        <BuzopsLoader type={"list"} />
      ) : props.servicesData.length === 0 ? (
        <div className={"bzo-services-container border-0 text-muted"}>
          No Available One-on-One{" "}
        </div>
      ) : (
        <>
          {/* <div className="row">
            <div className="form-group bzo-search-service bzo-hide-service-search col-12">
              <div>
              <span className="fa fa-search form-control-feedback"></span>
                <input
                  className="form-control"
                  placeholder={'search service'}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
            </div>
          </div> */}
          <div className="row">
            {!props?.serviceShortName && (
              <>
                {/* <div className="col-xl-3">{filterServiceComponent()}</div> */}
                <div className="col-xl-12 mb-2 mb-lg-0">
                </div>
                <div className="col-xl-12">{showServicesComponent()}</div>
              </>
            )}
            {props?.serviceShortName && (props?.ShortCodeStatus || props?.PromotionId) ? (
              <div className="col-xl-12 col-xxl-12">
                {showServicesComponent()}
              </div>
            ) : null}
            {props?.serviceShortName && !props?.ShortCodeStatus && !props?.PromotionId ? (
              <div className={"bzo-services-container border-0 text-muted"}>
                The requested subscription is no longer accessible to use.
              </div>
            ) : null}
            {!props?.serviceShortName && (
              <div className="bzo-services-filter-mobile">
                <div className="bzo-sort" onClick={() => setSortVal(!sortval)}>
                  <i className="fas fa-sort"></i>
                  Sort&nbsp;&nbsp;
                </div>
                <div
                  className="bzo-filters"
                  onClick={() => setmobileFilterDialog(!mobileFilterDialog)}
                >
                  <i className="fas fa-filter"></i>
                  Filter
                </div>
              </div>
            )}

            {mobileFilterDialog && (
              <Dialog
                className="bzo-filter-dialog"
                title={"Filter Services"}
                width={340}
                onClose={() => setmobileFilterDialog(!mobileFilterDialog)}
              >
                {renderServiceSearchMobile()}
              </Dialog>
            )}

            <Auth
              {...props}
              handleCloseLogin={() => handleCloseLogin()}
              showComponent={loginStatus}
              key={loginStatus.toString()}
            />
          </div>
        </>
      )}
      {customDialog && (
        <Dialog
          className="bz-book-Appoinment-dialog"
          title={"Create Custom One-on-one"}
          onClose={() => handleCustomEvent()}
          width={"35%"}
        >
          {renderCustomService()}
        </Dialog>
      )}
    </>
  );
};

export default withNotification(Service);
