import { createSlice } from "@reduxjs/toolkit";
export const userSlice = createSlice({
  name: "userConfiguration",
  initialState: {
    userDetails: null, //must be /app or /external or /:providerId or /,
    loggedIn: false,
    affiliateDetails:null
  },
  reducers: {
    setUserConfiguration: (state, action) => {
      const {
        userDetails= null,
        affiliateDetails= null,
        loggedIn= false,
      } = action.payload;
      state.userDetails = userDetails;
      state.affiliateDetails = affiliateDetails;
      state.loggedIn = loggedIn;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserConfiguration } = userSlice.actions;

export default userSlice.reducer;
