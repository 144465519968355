import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
import { Input, MaskedTextBox, RadioButton } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import BuzopsButton from "generic-components/BuzopsButton";
import { Button } from "@progress/kendo-react-buttons";
import './index.scss'
import { locationOptions, ScheduleModeOptionEnum } from "utils/form-utils";
import withNotification from "components/Hoc/withNotification";
import { checkSubscriptionStatus, getTenantAddress } from "utils";
import { locationComponentProps, LocOptionType } from "./ClassTypes";
import { FieldWrapper } from "@progress/kendo-react-form";
import { MdPhone,MdOutlineVideocam,MdOutlineLocationOn } from "react-icons/md";
import { mobileValidator, urlValidator } from "validators/validator";
import { Error } from "@progress/kendo-react-labels";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
const LocationDD = (props: locationComponentProps) => {
  let locationDropDown = locationOptions;

  const dummyLocOption = {
    id: 0,
    value: 'None',
    text: 'Please Select Location',
    subText: 'Please Select Location',
    icon: ''
  }
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [scheduleModeOption, setScheduleModeOption] = useState<any>(0);
  const [visibleAC, setVisibleAC] = useState<boolean>(false);
  const [inviteePhoneNumber, setInviteePhoneNumber] = useState<string>("");
  const [traineePhoneNumber, setTraineePhoneNumber] = useState<string>("");
  const [otherAddress, setOtherAddress] = useState<string>("");
  const [otherMeeting, setOtherMeeting] = useState<string>("");
  const [locMapBusinessAddress, setLocMapBusinessAddress] = useState<string>(getTenantAddress());
  useEffect(() => {
    const Attributes = typeof props?.Attributes === "string" && props?.Attributes != "" ? JSON.parse(props?.Attributes) : props?.Attributes
    setSelectedLocation(locationOptions?.filter((i: LocOptionType) => i?.id === Attributes?.ScheduleMode)[0])
    setScheduleModeOption(Attributes?.ScheduleModeOption)
    setTraineePhoneNumber(Attributes?.TraineePhoneNumber || "")
    setOtherAddress(Attributes?.OtherAddress || "")
    setOtherMeeting(Attributes?.OtherMeeting || "")
    let phNum=""
    if(Attributes?.InviteePhoneNumber && Attributes?.InviteePhoneNumber !==""){
      phNum=Attributes?.InviteePhoneNumber
    }else if(props?.Phone && props?.Phone !==""){
      phNum=props?.Phone
    }
    setInviteePhoneNumber(phNum || "")
  }, [props?.Attributes])

  const toggleACDialog = () => {
    setVisibleAC(!visibleAC);
  };
  const CancelLocationSelection = () => {
    setVisibleAC(!visibleAC);
    const Attributes = typeof props?.Attributes === "string" && props?.Attributes != "" ? JSON.parse(props?.Attributes) : props?.Attributes
    if(Attributes){
      setSelectedLocation(locationOptions?.filter((i: LocOptionType) => i?.id === Attributes?.ScheduleMode)[0])
    }else{
      setSelectedLocation(null)
    }
    setScheduleModeOption(Attributes?.ScheduleModeOption || 0)
    setTraineePhoneNumber(Attributes?.TraineePhoneNumber || "")
    setOtherAddress(Attributes?.OtherAddress || "")
    setOtherMeeting(Attributes?.OtherMeeting || "")
    let phNum=""
    if(Attributes?.InviteePhoneNumber && Attributes?.InviteePhoneNumber !==""){
      phNum=Attributes?.InviteePhoneNumber
    }else if(props?.Phone && props?.Phone !==""){
      phNum=props?.Phone
    }
    setInviteePhoneNumber(phNum || "")
  };


  useEffect(() => {
    if (visibleAC && scheduleModeOption === ScheduleModeOptionEnum['InPersonMeetingBusinessAddress'] && !locMapBusinessAddress) {
      props?.handleNotificationMessage('Business Address is not available. Please set it in Business profile page', "error");
    }
  }, [scheduleModeOption]);

  const renderLocationDDItem = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const item = itemProps.dataItem;
    const itemChildren = (
      <div onClick={()=>locationChanged(item)} className="location-list-blk">
          <div className="icon">
            <span>{item.icon}</span>
          </div>
          {visibleAC ? (<><div className="content">
              <span className="title">{item.text}</span>
              <span className="desc">{item.subText}</span>
          </div></>):(<>
          <div className="content" >
              <span className="title">{item.text}</span>
              <span className="desc">{item.subText}</span>
          </div>
        </>)}
      </div>
   
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  const renderLocationDDACItem = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const item = itemProps.dataItem;
    const itemChildren = (
      <div onClick={()=>locationChangedAC(item)} className="location-list-blk">
          <div className="icon">
            <span>{item.icon}</span>
          </div>
          {visibleAC ? (<><div className="content">
              <span className="title">{item.text}</span>
              <span className="desc">{item.subText}</span>
          </div></>):(<>
          <div className="content" >
              <span className="title">{item.text}</span>
              <span className="desc">{item.subText}</span>
          </div>
        </>)}
      </div>
   
    );

    return React.cloneElement(li, li.props, itemChildren);
  };
  const renderLocationDDValue = (
    element: React.ReactElement<HTMLSpanElement>,
    value: any
  ) => {
    const children: JSX.Element[] = []
    if (value.id === 0) {
      children.push(<span key={1}>&nbsp; {element.props.children}</span>)
    }
    if (value.id === 2 && scheduleModeOption === ScheduleModeOptionEnum['VirtualMeetingBuzopsMeeting']) {
      children.push(<span key={2}><MdOutlineVideocam className="bz-md-video"/></span>)
      children.push(<span key={3}>&nbsp; {'Buzops Meet'}</span>)
    }
    if (value.id === 2 && scheduleModeOption === ScheduleModeOptionEnum['VirtualMeetingOtherMeeting']) {
      children.push(<span key={2}><MdOutlineVideocam className="bz-md-video"/></span>)
      children.push(<span key={3}>&nbsp; {otherMeeting || "Other Meeting"}</span>)
    }
    if (value.id === 2 && scheduleModeOption !== ScheduleModeOptionEnum['VirtualMeetingBuzopsMeeting'] && scheduleModeOption !== ScheduleModeOptionEnum['VirtualMeetingOtherMeeting']) {
      children.push(<span key={22}><MdOutlineVideocam className="bz-md-video"/></span>)
      children.push(<span key={23}>&nbsp; {element.props.children}</span>)
    }
    if (value.id === 4 && scheduleModeOption !== ScheduleModeOptionEnum['PhoneCallMyInviteeShouldCall'] && scheduleModeOption !== ScheduleModeOptionEnum['PhoneCallIWillCall']) {
      children.push(<span key={4}><MdPhone className="bz-md-phone"/></span>)
      children.push(<span key={5}>&nbsp; {element.props.children}</span>)
    }
    if (value.id === 4 && scheduleModeOption === ScheduleModeOptionEnum['PhoneCallMyInviteeShouldCall'] && traineePhoneNumber) {
      children.push(<span key={6}><MdPhone className="bz-md-phone"/></span>)
      children.push(<span key={7}>&nbsp; {traineePhoneNumber}</span>)
    }
    if (value.id === 4 && scheduleModeOption === ScheduleModeOptionEnum['PhoneCallMyInviteeShouldCall'] && (!traineePhoneNumber || traineePhoneNumber === "")) {
      children.push(<span key={8}><MdPhone className="bz-md-phone"/></span>)
      children.push(<span key={9}>&nbsp; {element.props.children}</span>)
    }
    if (value.id === 4 && scheduleModeOption === ScheduleModeOptionEnum['PhoneCallIWillCall'] && inviteePhoneNumber) {
      children.push(<span key={10}><MdPhone className="bz-md-phone"/></span>)
      children.push(<span key={11}>&nbsp; {inviteePhoneNumber}</span>)
    }
    if (value.id === 4 && scheduleModeOption === ScheduleModeOptionEnum['PhoneCallIWillCall'] && (!inviteePhoneNumber || inviteePhoneNumber === "")) {
      children.push(<span key={12}><MdPhone  className="bz-md-phone"/></span>)
      children.push(<span key={13}>&nbsp; {element.props.children}</span>)
    }
    if (value.id === 8 && scheduleModeOption === ScheduleModeOptionEnum['InPersonMeetingOtherAddres'] && otherAddress) {
      children.push(<span key={14}><MdOutlineLocationOn className="bz-md-location"/></span>)
      children.push(<span key={15}>&nbsp; {otherAddress}</span>)
    }
    if (value.id === 8 && scheduleModeOption === ScheduleModeOptionEnum['InPersonMeetingOtherAddres'] && (!otherAddress || otherAddress === "")) {
      children.push(<span key={16}><MdOutlineLocationOn className="bz-md-location"/></span>)
      children.push(<span key={17}>&nbsp; {element.props.children}</span>)
    }
    if (value.id === 8 && scheduleModeOption === ScheduleModeOptionEnum['InPersonMeetingBusinessAddress'] && locMapBusinessAddress) {
      children.push(<span key={18}><MdOutlineLocationOn className="bz-md-location"/></span>)
      children.push(<span key={19}>&nbsp; {locMapBusinessAddress}</span>)
    }
    if (value.id === 8 && scheduleModeOption === ScheduleModeOptionEnum['InPersonMeetingBusinessAddress'] && (!locMapBusinessAddress || locMapBusinessAddress === "")) {
      children.push(<span key={20}><MdOutlineLocationOn className="bz-md-location"/></span>)
      children.push(<span key={21}>&nbsp; {element.props.children}</span>)
    }

    return React.cloneElement(element, { ...element.props }, children);
  };

  const GotoBusinessProfile = () => {
    const NavigationUrl = `/Common/Club/Manage`
    const data = { Type: "RedirectUrl", Url: `${NavigationUrl}` };
    window.parent.postMessage(data, "*");
  };

  const locationChanged = (value: any) => {
    setSelectedLocation(value)
    switch (value.id) {
      case 2:
        toggleACDialog()
        // setScheduleModeOption(ScheduleModeOptionEnum['VirtualMeetingBuzopsMeeting'])
        if(!checkSubscriptionStatus(1110)){
          setScheduleModeOption(ScheduleModeOptionEnum['VirtualMeetingOtherMeeting'])
        }else {
          setScheduleModeOption(ScheduleModeOptionEnum['VirtualMeetingBuzopsMeeting'])
        }
        break;
      case 4:
        toggleACDialog()
        setScheduleModeOption(ScheduleModeOptionEnum['PhoneCallIWillCall'])
        if(!props?.showPhoneCallIWillCall){
          setScheduleModeOption(ScheduleModeOptionEnum['PhoneCallMyInviteeShouldCall'])
        }
        break;
      case 8:
        toggleACDialog()
        setScheduleModeOption(ScheduleModeOptionEnum['InPersonMeetingBusinessAddress'])
        break;
      default:
        break;
    }
  }

  const locationChangedAC = (value: any) => {
    setSelectedLocation(value)
    switch (value.id) {
      case 2:
        // handleUpdateLocation(1)
        // toggleACDialog()
        // setScheduleModeOption(ScheduleModeOptionEnum['VirtualMeetingBuzopsMeeting'])
        if(!checkSubscriptionStatus(1110)){
          setScheduleModeOption(ScheduleModeOptionEnum['VirtualMeetingOtherMeeting'])
        }else {
          setScheduleModeOption(ScheduleModeOptionEnum['VirtualMeetingBuzopsMeeting'])
        }
        break;
      case 4:
        setScheduleModeOption(ScheduleModeOptionEnum['PhoneCallIWillCall'])
        if(!props?.showPhoneCallIWillCall){
          setScheduleModeOption(ScheduleModeOptionEnum['PhoneCallMyInviteeShouldCall'])
        }
        break;
      case 8:
        setScheduleModeOption(ScheduleModeOptionEnum['InPersonMeetingBusinessAddress'])
        break;
      default:
        break;
    }
  }

  const handleUpdateLocation = (firstOptionSelected: number = 0) => {
    if(firstOptionSelected===0){
      if(scheduleModeOption === ScheduleModeOptionEnum['PhoneCallIWillCall']){
        if(mobileValidator(inviteePhoneNumber)){
          return
        }
      }
      if(scheduleModeOption === ScheduleModeOptionEnum['PhoneCallMyInviteeShouldCall']){
        if(mobileValidator(traineePhoneNumber)){
          return
        }
      }
      if(scheduleModeOption === ScheduleModeOptionEnum['VirtualMeetingOtherMeeting']){
        if(urlValidator(otherMeeting)){
          return;
        }
      }
      toggleACDialog();
    }
    firstOptionSelected === 0 ? props?.handleUpdateLocation({
      ScheduleMode: selectedLocation?.id,
      ScheduleModeOption: scheduleModeOption || 0,
      InviteePhoneNumber: inviteePhoneNumber || "",
      TraineePhoneNumber:  traineePhoneNumber || "",
      OtherAddress: otherAddress ||  "",
      OtherMeeting: otherMeeting || ""
      // need to send exact value
      // value: selectedLocation?.id > 2 ? (selectedLocation?.id === 4 ? (locPhonePref === 1 ? 'Phone Call' : traineePhoneNumber) : (locMapPref === 1 ? locMapBusinessAddress : locMapAddress)) : ''
    }) :
      props?.handleUpdateLocation({
        ScheduleMode: 2,
        ScheduleModeOption: 0,
        InviteePhoneNumber: "",
        TraineePhoneNumber: "",
        OtherAddress: "",
        OtherMeeting:""
      })
  }
  
  return (
    <>
      {props?.label && (
        <label className="k-label d-flex">
          {"Select Location"}
          <BuzopsTooltip
            position={"right"}
            html={
              <a
                title={`Use the "Location" field to specify how and where both parties will connect at the scheduled time.`}
                className="pl-2 bz-cursor-pointer"
              >
                <i
                  style={{ fontSize: "16px" }}
                  className="fa fa-info-circle"
                ></i>
              </a>
            }
          />
        </label>
      )}
      <DropDownList
        id={"locationDD"}
        data={locationDropDown}
        itemRender={renderLocationDDItem}
        valueRender={renderLocationDDValue}
        // onChange={(e) => locationChanged(e.value)}
        textField="text"
        style={{ width: props?.width }}
        disabled={props?.disabled}
        value={selectedLocation || dummyLocOption}
        // label={props?.label ? 'Select Location *' : ""}
        dataItemKey="id"
        // opened={true}
      />
      {visibleAC && (
        <Dialog
          title={"Select Location"}
          className="LocationDDDialog"
          onClose={() => CancelLocationSelection()}
          minWidth={300}
          width={450}
        >
          <DropDownList
            id={"locationDDAC"}
            data={locationDropDown}
            itemRender={renderLocationDDACItem}
            valueRender={renderLocationDDValue}
            // onChange={(e) => locationChangedAC(e.value)}
            textField="text"
            defaultValue={selectedLocation}
            style={{ width: "100%" }}
            value={selectedLocation}
            label={props?.label ? "Select Location *" : ""}
            dataItemKey="id"
          />
  
          {selectedLocation.id === 2 && (
            <div>
              <div className="">
                {checkSubscriptionStatus(1110) && (
                  <div className="form-check form-check-inline mt-3">
                    <RadioButton
                      name="BuzopsMeet"
                      value={scheduleModeOption}
                      checked={
                        scheduleModeOption ===
                        ScheduleModeOptionEnum["VirtualMeetingBuzopsMeeting"]
                      }
                      label="Buzops Meet"
                      onClick={() => {
                        setScheduleModeOption(
                          ScheduleModeOptionEnum["VirtualMeetingBuzopsMeeting"]
                        );
                      }}
                    />
                  </div>
                )}
                <div className="form-check form-check-inline mt-2">
                  <RadioButton
                    name="otherMeeting"
                    value={scheduleModeOption}
                    checked={
                      scheduleModeOption ===
                      ScheduleModeOptionEnum["VirtualMeetingOtherMeeting"]
                    }
                    label="Other Meeting"
                    onClick={() => {
                      setScheduleModeOption(
                        ScheduleModeOptionEnum["VirtualMeetingOtherMeeting"]
                      );
                    }}
                  />
                </div>
                {scheduleModeOption ===
                  ScheduleModeOptionEnum["VirtualMeetingOtherMeeting"] && (
                  <div style={{ width: "100%" }}>
                    <FieldWrapper>
                      <Input
                        autoComplete={"off"}
                        style={{ width: "100%" }}
                        name={"otherMeeting"}
                        value={otherMeeting}
                        placeholder={"Enter Meeting Url"}
                        onChange={(e: any) => setOtherMeeting(e.target.value)}
                      />
                      {urlValidator(otherMeeting) && (
                        <Error>{urlValidator(otherMeeting)}</Error>
                      )}
                    </FieldWrapper>
                  </div>
                )}
              </div>
            </div>
          )}
          {selectedLocation.id === 4 && (
            <div>
              <div className="Location-PhoneNumber">
                {props?.showPhoneCallIWillCall && (
                  <>
                    <div className="form-check form-check-inline mt-3">
                      <RadioButton
                        name="locPhone1"
                        value={scheduleModeOption}
                        checked={
                          scheduleModeOption ===
                          ScheduleModeOptionEnum["PhoneCallIWillCall"]
                        }
                        label="I will call my Invitee at"
                        onClick={() =>
                          setScheduleModeOption(
                            ScheduleModeOptionEnum["PhoneCallIWillCall"]
                          )
                        }
                      />
                    </div>
                    <small className="form-text bz-text-muted d-block mt-0 mb-2">
                      Buzops will require your Invitee's phone number before
                      scheduling.
                    </small>
                    {scheduleModeOption ===
                      ScheduleModeOptionEnum["PhoneCallIWillCall"] && (
                      <div className="mb-2">
                        <FieldWrapper>
                          <MaskedTextBox
                            mask="(999) 000-0000"
                            name="inviteePhoneNumber"
                            style={{ width: "100%" }}
                            id="inviteePhoneNumber"
                            className="PhoneNumber"
                            value={inviteePhoneNumber}
                            onChange={(e: any) =>
                              setInviteePhoneNumber(e.target.value)
                            }
                          />
                          {mobileValidator(inviteePhoneNumber) && (
                            <Error>{mobileValidator(inviteePhoneNumber)}</Error>
                          )}
                        </FieldWrapper>
                      </div>
                    )}
                  </>
                )}
                <div className="form-check form-check-inline mt-2">
                  <RadioButton
                    name="locPhone2"
                    value={scheduleModeOption}
                    checked={
                      scheduleModeOption ===
                      ScheduleModeOptionEnum["PhoneCallMyInviteeShouldCall"]
                    }
                    label="My Invitee should call me at"
                    onClick={() =>
                      setScheduleModeOption(
                        ScheduleModeOptionEnum["PhoneCallMyInviteeShouldCall"]
                      )
                    }
                  />
                </div>
                <small className="form-text bz-text-muted d-block mt-0 mb-2">
                  Buzops will provide your number after the appointment has been
                  scheduled..
                </small>
                {scheduleModeOption ===
                  ScheduleModeOptionEnum["PhoneCallMyInviteeShouldCall"] && (
                  <div>
                    {/* <Input
                  style={{ marginLeft: '1.25rem', width: "90%" }}
                  autoComplete={"off"}
                  name={"traineePhoneNumber"}
                  value={traineePhoneNumber}
                  placeholder={"Enter Phone Number"}
                  onChange={(e: any) =>
                    setTraineePhoneNumber(e.target.value)
                  }
                /> */}
                    <FieldWrapper>
                      <MaskedTextBox
                        mask="(999) 000-0000"
                        name="traineePhoneNumber"
                        style={{ width: "100%" }}
                        id="traineePhoneNumber"
                        className="PhoneNumber"
                        value={traineePhoneNumber}
                        onChange={(e: any) =>
                          setTraineePhoneNumber(e.target.value)
                        }
                      />
                      {mobileValidator(traineePhoneNumber) && (
                        <Error>{mobileValidator(traineePhoneNumber)}</Error>
                      )}
                    </FieldWrapper>
                  </div>
                )}
              </div>
            </div>
          )}
          {selectedLocation.id === 8 && (
            <div>
              <div className="">
                <div className="form-check form-check-inline mt-3">
                  <RadioButton
                    name="locMap1"
                    value={scheduleModeOption}
                    checked={
                      scheduleModeOption ===
                      ScheduleModeOptionEnum["InPersonMeetingBusinessAddress"]
                    }
                    label="Business Address"
                    onClick={() => {
                      setScheduleModeOption(
                        ScheduleModeOptionEnum["InPersonMeetingBusinessAddress"]
                      );
                    }}
                  />
                </div>
                <small className="form-text bz-text-muted d-block mt-0 mb-2">
                  Buzops will use your Business address.
                </small>
                {scheduleModeOption ===
                  ScheduleModeOptionEnum["InPersonMeetingBusinessAddress"] &&
                  !locMapBusinessAddress && (
                    <div className="alert alert-warning bz-bussiness-address-warning mb-1">
                    Business address is not available.{" "}
                    <span
                      className="alert-link"
                      onClick={() => {
                        GotoBusinessProfile();
                      }}
                    >
                      Click here{" "}
                    </span>
                    to set it up
                  </div>
                  )}
                <div className="form-check form-check-inline mt-2">
                  <RadioButton
                    name="otherAddress"
                    value={scheduleModeOption}
                    checked={
                      scheduleModeOption ===
                      ScheduleModeOptionEnum["InPersonMeetingOtherAddres"]
                    }
                    label="Other Address"
                    onClick={() => {
                      setScheduleModeOption(
                        ScheduleModeOptionEnum["InPersonMeetingOtherAddres"]
                      );
                    }}
                  />
                </div>
                <small className="form-text bz-text-muted d-block mt-0 mb-2">
                  Buzops will use custom address.
                </small>

                {scheduleModeOption ===
                  ScheduleModeOptionEnum["InPersonMeetingOtherAddres"] && (
                  <div style={{ width: "100%" }}>
                    <Input
                      autoComplete={"off"}
                      style={{ width: "100%" }}
                      name={"otherAddress"}
                      value={otherAddress}
                      placeholder={"Enter Location"}
                      onChange={(e: any) => setOtherAddress(e.target.value)}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          <DialogActionsBar layout={"stretched"}>
            <Button onClick={() => CancelLocationSelection()}>Cancel</Button>
            <BuzopsButton
              onClick={() => {
                handleUpdateLocation(0);
              }}
              label={"Save"}
              disabled={
                (selectedLocation?.id === 2 &&
                  scheduleModeOption ===
                    ScheduleModeOptionEnum["VirtualMeetingOtherMeeting"] &&
                  !otherMeeting) ||
                (selectedLocation?.id === 8 &&
                  scheduleModeOption ===
                    ScheduleModeOptionEnum["InPersonMeetingOtherAddres"] &&
                  !otherAddress) ||
                (selectedLocation?.id === 8 &&
                  scheduleModeOption ===
                    ScheduleModeOptionEnum["InPersonMeetingBusinessAddress"] &&
                  !locMapBusinessAddress) ||
                (selectedLocation?.id === 4 &&
                  scheduleModeOption ===
                    ScheduleModeOptionEnum["PhoneCallMyInviteeShouldCall"] &&
                  !traineePhoneNumber) ||
                (selectedLocation?.id === 4 &&
                  scheduleModeOption ===
                    ScheduleModeOptionEnum["PhoneCallIWillCall"] &&
                  !inviteePhoneNumber) ||
                (selectedLocation.id === 4 &&
                  scheduleModeOption !==
                    ScheduleModeOptionEnum["PhoneCallMyInviteeShouldCall"] &&
                  scheduleModeOption !==
                    ScheduleModeOptionEnum["PhoneCallIWillCall"]) ||
                (selectedLocation.id === 8 &&
                  scheduleModeOption !==
                    ScheduleModeOptionEnum["InPersonMeetingOtherAddres"] &&
                  scheduleModeOption !==
                    ScheduleModeOptionEnum["InPersonMeetingBusinessAddress"]) ||
                (selectedLocation.id === 2 &&
                  scheduleModeOption !==
                    ScheduleModeOptionEnum["VirtualMeetingOtherMeeting"] &&
                  scheduleModeOption !==
                    ScheduleModeOptionEnum["VirtualMeetingBuzopsMeeting"])
              }
            />
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
}

export default withNotification(LocationDD)