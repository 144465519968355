import React from "react";
import { CurrencyFormat, getTimeFormat } from "utils";
import { Button } from "@progress/kendo-react-buttons";
import BuzopsButton from "generic-components/BuzopsButton";
import { Checkbox } from "@progress/kendo-react-inputs";
import { LocationForBooking } from "components/Location/Location";

export enum notificationMessages {
  notificationDisabled = "Notifications disabled",
  notificationEnabled = "Notifications enabled"
}
const UpdateSession = (props: any) => {
  const { form, checked, count } = props;
  const startTime = getTimeFormat(form.startTime);
  const duration = `${form.minutes} Minutes`;
  let classTypeValue=""
  if(form?.ClassType === 1){
    classTypeValue="Paid"
  }else if(form?.ClassType === 3){
    classTypeValue= form?.NonPaidLabel
  }
  
  return (
    <>
      <div className={"update-session-page-new"}>
        <p className={"selected-data bzc-notice"}>{props.selectedItemText}</p>
        {/* {props.editType === "single" && ( */}
          <>
            {/* <div
              className={
                checked.ClassSessionStartDate
                  ? "bzc-row row selected-color"
                  : "bzc-row row"
              }
            >
              <div className="col-sm-6 col-md-6">
                <label className="mb-0 mt-2 mt-sm-0 mb-sm-2">Date</label>
              </div>
              <div className="col-sm-6 col-md-6 bz-selected-text">
                {checked.ClassSessionStartDate
                  ? moment(form?.ClassSessionStartDate).format("YYYY-MM-DD")
                  : "No Changes"}
              </div>
            </div> */}
            <div
              className={
                checked.startTime ? "bzc-row row selected-color" : "bzc-row row"
              }
            >
              <div className="col-sm-6 col-md-6">
                <label className="mb-0 mt-2 mt-sm-0 mb-sm-2">Start Time</label>
              </div>
              <div className="col-sm-6 col-md-6 bz-selected-text">
                {checked.startTime ? startTime : "No Changes"}
              </div>
            </div>
            <div
              className={
                checked.duration ? "bzc-row row selected-color" : "bzc-row row"
              }
            >
              <div className="col-sm-6 col-md-6">
                <label className="mb-0 mt-2 mt-sm-0 mb-sm-2">Duration</label>
              </div>
              <div className="col-sm-6 col-md-6 bz-selected-text">
                {checked.duration ? duration : "No Changes"}
              </div>
            </div>
            <div
              className={
                checked.instructor ? "bzc-row row selected-color" : "bzc-row row"
              }
            >
              <div className="col-sm-6 col-md-6">
                <label className="mb-0 mt-2 mt-sm-0 mb-sm-2">Instructor</label>
              </div>
              <div className="col-sm-6 col-md-6 bz-selected-text">
                {checked.instructor ? form?.instructor?.FullName : "No Changes"}
              </div>
            </div>
          </>
        {/* )} */}
        <div
          className={
            checked.HasVirtualMeetingEnabled
              ? "bzc-row row selected-color"
              : "bzc-row row"
          }
        >
          <div className="col-sm-6 col-md-6">
            <label className="mb-0 mt-2 mt-sm-0 mb-sm-2">
              Can Schedule As Virtual Meeting
            </label>
          </div>
          <div className="col-sm-6 col-md-6 bz-selected-text">
            {checked.HasVirtualMeetingEnabled
              ? form?.HasVirtualMeetingEnabled
                ? `Enabled`
                : `Disabled`
              : "No Changes"}
          </div>
        </div>

        <div
          className={
            checked.slots ? "bzc-row row selected-color" : "bzc-row row"
          }
        >
          <div className="col-sm-6 col-md-6">
            <label className="mb-0 mt-2 mt-sm-0 mb-sm-2">
              Maximum Attendees
            </label>
          </div>
          <div className="col-sm-6 col-md-6 bz-selected-text">
            {checked.slots ? form.slots : "No Changes"}
          </div>
        </div>
        <div
          className={
            checked.price ? "bzc-row row selected-color" : "bzc-row row"
          }
        >
          <div className="col-sm-6 col-md-6">
            <label className="mb-0 mt-2 mt-sm-0 mb-sm-2">Price</label>
          </div>
          <div className="col-sm-6 col-md-6 bz-selected-text">
            {checked.price ? "$" + form.price : (checked.ClassType && form?.ClassType === 3) ? "$0":"No Changes"}
          </div>
        </div>
        <div
          className={
            checked.CommunicationPreference
              ? "bzc-row row selected-color"
              : "bzc-row row"
          }
        >
          <div className="col-sm-6 col-md-6">
            <label className="mb-0 mt-2 mt-sm-0 mb-sm-2">
            Communication Preference
            </label>
          </div>
          <div className="col-sm-6 col-md-6 bz-selected-text">
            {checked.CommunicationPreference
              ? form?.CommunicationPreference?.text
              : "No Changes"}
          </div>
        </div>
        <div
          className={
            checked.RemainderDuration
              ? "bzc-row row selected-color"
              : "bzc-row row"
          }
        >
          <div className="col-sm-6 col-md-6">
            <label className="mb-0 mt-2 mt-sm-0 mb-sm-2">
            Reminder to an appointment before
            </label>
          </div>
          <div className="col-sm-6 col-md-6 bz-selected-text">
            {checked.RemainderDuration
              ? `${form.RemainderDuration} ${form.RemainderType?.text} (${form.RemainderDuration === 0 ? notificationMessages.notificationDisabled : notificationMessages.notificationEnabled})`
              : "No Changes"}
          </div>
        </div>
        <div
          className={
            checked.MinimumBookingDuration
              ? "bzc-row row selected-color"
              : "bzc-row row"
          }
        >
          <div className="col-sm-6 col-md-6">
            <label className="mb-0 mt-2 mt-sm-0 mb-sm-2">
              An appointment can be booked before
            </label>
          </div>
          <div className="col-sm-6 col-md-6 bz-selected-text">
            {checked.MinimumBookingDuration
              ? `${form.MinimumBookingDuration} ${form.MinimumBookingDurationType?.text} (${form.MinimumBookingDuration === 0 ? notificationMessages.notificationDisabled : notificationMessages.notificationEnabled})`
              : "No Changes"}
          </div>
        </div>
        <div
          className={
            checked.MinimumCancellationDuration
              ? "bzc-row row selected-color"
              : "bzc-row row"
          }
        >
          <div className="col-sm-6 col-md-6">
            <label className="mb-0 mt-2 mt-sm-0 mb-sm-2">
              An appointment can be cancelled before
            </label>
          </div>
          <div className="col-sm-6 col-md-6 bz-selected-text">
            {checked.MinimumCancellationDuration
              ? `${form.MinimumCancellationDuration} ${form.MinimumCancellationDurationType?.text} (${form.MinimumCancellationDuration === 0 ? notificationMessages.notificationDisabled : notificationMessages.notificationEnabled})`
              : "No Changes"}
          </div>
        </div>
        <div
          className={
            checked.CancellationFee
              ? "bzc-row row selected-color"
              : "bzc-row row"
          }
        >
          <div className="col-sm-6 col-md-6">
            <label className="mb-0 mt-2 mt-sm-0 mb-sm-2">
              Cancellation Fee
            </label>
          </div>
          <div className="col-sm-6 col-md-6 bz-selected-text">
            {checked.CancellationFee
              ? CurrencyFormat(form.CancellationFee)
              : "No Changes"}
          </div>
        </div>
        <div
          className={
            checked.Location
              ? "bzc-row row selected-color"
              : "bzc-row row"
          }
        >
          <div className="col-sm-6 col-md-6">
            <label className="mb-0 mt-2 mt-sm-0 mb-sm-2">
              Location
            </label>
          </div>
          <div className="col-sm-6 col-md-6 bz-selected-text location-text">
            {checked.Location
              ?  form?.Attributes && <div className="d-flex align-items-start"><LocationForBooking Attributes={form?.Attributes} showDetails={true} VirtualMeetingLink={"Virtual Meeting Link"} /></div>
              : "No Changes"}
          </div>
        </div>
        <div
          className={
            checked.ClassType
              ? "bzc-row row selected-color"
              : "bzc-row row"
          }
        >
          <div className="col-sm-6 col-md-6">
            <label className="mb-0 mt-2 mt-sm-0 mb-sm-2">
              Type
            </label>
          </div>
          <div className="col-sm-6 col-md-6 bz-selected-text location-text">
            {checked.ClassType
              ?   classTypeValue
              : "No Changes"}
          </div>
        </div>
        {/* <div
          className={
            checked.PaymentOption
              ? "bzc-row row selected-color"
              : "bzc-row row"
          }
        >
          <div className="col-sm-6 col-md-6">
            <label className="mb-0 mt-2 mt-sm-0 mb-sm-2">
              Payment Option
            </label>
          </div>
          <div className="col-sm-6 col-md-6 bz-selected-text location-text">
            {checked.PaymentOption
              ?   PaymentOptionsradio?.filter((i:any)=>i?.value===form?.PaymentOption)?.[0]?.label
              : "No Changes"}
          </div>
        </div> */}
        {/* <div className={checked.location?'row selected-color':'row'}>
          <div className="col-md-3">
            <label className="mb-0 mt-2 mt-sm-0 mb-sm-2">Location</label>
          </div>
          <div className="col-md-3">
            {checked.location ? form.location : "No Changes"}
          </div>
        </div> */}
        {/* <div className={checked.color?'row selected-color':'row'}>
          <div className="col-md-3">
            <label className="mb-0 mt-2 mt-sm-0 mb-sm-2">Color</label>
          </div>
          <div className="col-md-3">
            {checked.color ? (
              <ColorPalette value={form.color} palette={[form.color]} />
            ) : (
              "No Changes"
            )}
          </div>
        </div> */}
        <div className="bzc-row-btn mt-3">
          <hr />
          <div className="bzc-update-session-options-row-buttons">
            <div className="section-1">
              <Checkbox
                name="client"
                value={checked.client}
                onChange={(e) => props.handleChange(e, "client")}
                label={"Send Update Notifications to Client"}
              />
            </div>
            <div className="section-1">
              <Button onClick={() => props.startOver()}>Start Over</Button>
              <BuzopsButton
                primary
                label={`Update ${count} Appointments`}
                onClick={() => props.handleSubmit()}
                disable={props.updateLodaing}
                loading={props.updateLodaing}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateSession;
