import React, { useEffect, useState } from "react";
import { CapitalizeStr } from "utils";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import PurchaseClassList from "components/class/PurchaseClassList";
import { TenantService } from "services/tenant/index.service";
import Service from "components/service/Service";
import { GetLocalStore } from "utils/storage";
import PurchasePlanList from "components/plans/PurchasePlanList";
import PurchasePackageList from "components/package/PurchasePackageList";

const Purchase = (props: any) => {
  const [tenantDetails, setTenantDetails] = useState<any>(GetLocalStore("tenantDetails"));
  const [servicesData, setServicesData] = useState<any>([]);
  const [isServiceLoading, setIsServiceLoading] = useState(false);
  const tabs = [
    {
      title: "One-on-One",
      visible: !props?.staffPage
        ? tenantDetails?.IsIndividualServicesTabVisible
        : true,
      id: 1,
      name:'service'
    },
    {
      title: "Group",
      visible: !props?.staffPage
        ? tenantDetails?.IsGroupServicesTabVisible
        : true,
      id: 2,
      name:'groupevent'
    },
    {
      title: "Memberships",
      visible: !props?.staffPage ? tenantDetails?.IsMemberplanTabVisible : true,
      id: 3,
      name:'plan'
    },
    {
      title: "Packages",
      visible: !props?.staffPage ? tenantDetails?.IsPackageTabVisible : true,
      id: 4,
      name:'package'
    },
  ];


  let selectedTabvalue = 0;
  const mainTabs=tabs.filter((i:any)=>i?.visible===true)
  if (props?.addMoreClasses) {
    selectedTabvalue = mainTabs?.findIndex((i:any)=>i?.name==='groupevent');
  } else {
    selectedTabvalue = mainTabs?.findIndex((i:any)=>i?.name===props?.purchaseItem);
  }
  selectedTabvalue = selectedTabvalue < 0 ? 0:selectedTabvalue
  const [selectedTab, setSelectedTab] = useState<number>(selectedTabvalue);
  const service = new TenantService();
  useEffect(() => {
    fetchServices();
  }, []);
  async function fetchServices() {
    const req = { IncludeHostOnlineOnly: props?.staffPage ? false : true, IsActive:true };
    setIsServiceLoading(true);
    const servicedet = await service.getServiceDetails(req);
    const result = servicedet.Result;
    if (result) {
      setServicesData(result);
    }
    setIsServiceLoading(false);
  }


  const handleTabSelect = (tabValue: number) => {
    setSelectedTab(tabValue);
    props?.unloadFunc();
  };

  return (
    <>
      <div className="row justify-content-center bz-classes-in-online bz-tabstrip">
        <TabStrip
          selected={selectedTab}
          onSelect={(e) => handleTabSelect(e.selected)}
        >
          {mainTabs.map((item, index) => {
              return (
                <TabStripTab title={`${CapitalizeStr(item.title)}`} key={index}>
                  {item.id === 1 && (
                    <Service
                      servicesData={servicesData}
                      isServiceLoading={isServiceLoading}
                      staffPage={props?.staffPage}
                      uuid={props?.uuid}
                      userData={props?.userData}
                      // serviceShortName={props?.shortCodeValue}
                    />
                  )}
                  {item.id === 2 && (
                    <>
                      
                      <PurchaseClassList
                        page={"online"}
                        selectedClasses={props?.addMoreClasses}
                        classSelectedUserMemberId={props?.classSelectedUserMemberId}
                        unloadFunc={() => props.unloadFunc()}
                        addMoreFunctionality={true}
                        staffPage={props?.staffPage}
                        uuid={props?.uuid}
                        userData={props?.userData}
                        PackageSelection={false}
                        // PackageSelection={
                        //   props?.purchaseItem === "package" ? true : false
                        // }
                      />
                    </>
                  )}
                  {item.id === 4 && (
                    <PurchasePackageList
                      page={"online"}
                      staffPage={props?.staffPage}
                      uuid={props?.uuid}
                      userData={props?.userData}
                    />
                  )}
                  {item.id === 3 && (
                    <PurchasePlanList
                      staffPage={props?.staffPage}
                      uuid={props?.uuid}
                      userData={props?.userData}
                    />
                  )}
                </TabStripTab>
              );
            })}
        </TabStrip>
      </div>
    </>
  );
};

export default Purchase;
