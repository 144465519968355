import moment from "moment";
import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { getTimeZoneShortHand } from "utils";
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { LocationForBooking } from "components/Location/Location";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
const ConfirmationComponent = (props: any) => {
  const redirectionObj = useSelector((state: any) => state?.redirection);
  const history = useHistory();
  const {
    selectedSchItems,
    selectedItems,
    updateAppointmentData,
    userDetails,
    TenantZone,
    Attributes,
    guestPages
  } = props?.location.state;
  
  const { service, provider, recurrenceDropdownOption, recurrenceSchedules } =
    selectedItems;
  const timezoneShorthand = getTimeZoneShortHand(TenantZone);
  let count = 0;
  let schedules: any;
  if (recurrenceDropdownOption.id === "doesNotRepeat") {
    count = selectedSchItems.length;
    schedules = [...selectedSchItems];
  } else {
    const records = recurrenceSchedules.filter(
      (i: any) => i?.IsAvailable === true
    );
    count = records.length;
    schedules = [...records];
  }
  let duration = service?.Duration;
  if (!userDetails) {
    duration = service?.DurationInMinutes;
  }
  let trainerEqual = true;

  if (
    selectedItems.updateStatus &&
    updateAppointmentData?.TrainerId !== provider.AssociateId
  ) {
    trainerEqual = false;
  }
  let dateEqual = true;
  if (
    selectedItems.updateStatus &&
    moment(updateAppointmentData?.FromTime).format("MM/DD/YYYY") !==
      moment(selectedSchItems[0].date).format("MM/DD/YYYY")
  ) {
    dateEqual = false;
  }
  let timeEqual = true;
  if (
    selectedItems.updateStatus &&
    moment(updateAppointmentData?.FromTime).format("hh:mm A") !==
      selectedSchItems[0].time
  ) {
    timeEqual = false;
  }
  const OldAttributes = updateAppointmentData?.Attributes;
  const OldAttributeValues =
    OldAttributes && OldAttributes !== "" && typeof OldAttributes === "string"
      ? JSON.parse(OldAttributes)
      : OldAttributes;
  const NewAttributeValues =
    Attributes && Attributes !== "" && typeof Attributes === "string"
      ? JSON.parse(Attributes)
      : Attributes;
  let locEqual = true;
  if (
    OldAttributeValues?.ScheduleModeOption !==
    NewAttributeValues?.ScheduleModeOption
  ) {
    locEqual = false;
  }

  const handleConfirmation = () => {
    if (!userDetails) {
      if (redirectionObj?.firstPathValue !== "") {
        if (redirectionObj?.firstPathValue === "external") {
          history.push(
            `/${redirectionObj?.firstPathValue}/${redirectionObj?.secondRoute}`
          );
        } else {
          history.push(`/${redirectionObj?.firstPathValue}`);
        }
      } else {
        history.push(`/`);
      }
    } else {
      if (!redirectionObj?.staffPage) {
        if (redirectionObj?.firstPathValue === "external") {
          history.push(`/${redirectionObj?.firstPathValue}/summary`);
        } else {
          history.push(`/summary`);
        }
      } else {
        let urlRedirection = `/member/${redirectionObj?.staffuuid}/${userDetails?.PrimaryMemberId}`;

        if (
          selectedItems?.service?.PrivateServiceInstanceId &&
          selectedItems?.service?.PrivateServiceType === 1
        ) {
          urlRedirection = `/member/${redirectionObj?.staffuuid}/${userDetails?.PrimaryMemberId}/pi-${selectedItems?.service?.PrivateServiceInstanceId}`;
        } else if (props?.location?.state?.UpdateAppointment) {
          if (updateAppointmentData?.PrivateServiceType === 1) {
            urlRedirection = `/member/${redirectionObj?.staffuuid}/${userDetails?.PrimaryMemberId}/pi-${updateAppointmentData?.EntityId}`;
          } else {
            urlRedirection = `/member/${redirectionObj?.staffuuid}/${userDetails?.PrimaryMemberId}/scheduleoneone`;
          }
        }
        history.push(urlRedirection);
      }
    }
  };

  const selctedLoccationPhone = Attributes.ScheduleMode === 4 ? "align-items-start location-phone" : 'align-items-center';
  
  return (
    <div className="success-component">
      <div className="success-info">
        <div className="card-content">
          <h6 className="align-self-center bz_fs_1_5 bz-fw-6">Confirmed</h6>
          <div className="align-self-start">
            You have scheduled{" "}
            {!selectedItems.updateStatus && <>{`${count} Appointments`}</>} with{" "}
            {!trainerEqual && <del>{updateAppointmentData?.TrainerName}</del>}
            <b>{provider.FullName}</b>
          </div>
          <h4 className="bz-fw-6">
            {selectedItems.updateStatus ? service.ServiceName : service.Name}
          </h4>
          <div className="text-muted bz-fw-6">
            <i className="fas fa-globe" aria-hidden="true"></i> {TenantZone}
          </div>
          <div className="text-capitalize bz-fw-6 text-muted">
            <i className="fas fa-clock" aria-hidden="true"></i>{" "}
            {`${duration} Minutes`}
          </div>

          {!userDetails ||
          selectedItems?.updateStatus ||
          schedules?.length === 1 ? (
            <>
              {(!userDetails || selectedSchItems?.length === 1) &&
                !selectedItems?.updateStatus && (
                  <>
                    <div className={`bz-fw-6 text-muted d-flex location-text-blk ${selctedLoccationPhone}`}>
                      <LocationForBooking
                        Attributes={Attributes}
                        showDetails={true}
                        VirtualMeetingLink={provider?.VirtualMeetingLink}
                        linkEnable={true}
                        clientPhnNum={userDetails?.Phone}
                      />
                    </div>
                    <div className="text-capitalize bz-fw-6 text-muted booking-date">
                      <i
                        className="fa fa-calendar"
                        aria-hidden="true"
                        style={{ width: "1rem", marginRight: "0.2rem" }}
                      ></i>
                      {moment(selectedSchItems[0]?.date).format(
                        "dddd, MMMM DD, YYYY"
                      )}{" "}
                      {`${selectedSchItems[0]?.time} ${timezoneShorthand}`}
                    </div>
                  </>
                )}
              {selectedItems.updateStatus && (
                <>
                  {!locEqual && (
                    <div
                      className="bz-fw-6 text-muted d-flex align-items-center location-text-blk"
                      style={{ textDecoration: "line-through" }}
                    >
                      <LocationForBooking
                        Attributes={OldAttributeValues}
                        showDetails={true}
                        VirtualMeetingLink={provider?.VirtualMeetingLink}
                        linkEnable={false}
                        clientPhnNum={userDetails?.Phone}
                      />
                    </div>
                  )}
                  <div className="bz-fw-6 text-muted d-flex align-items-center location-text-blk">
                    <LocationForBooking
                      Attributes={Attributes}
                      showDetails={true}
                      VirtualMeetingLink={provider?.VirtualMeetingLink}
                      linkEnable={true}
                      clientPhnNum={userDetails?.Phone}
                    />
                  </div>
                  <div className="text-capitalize bz-fw-6  text-muted">
                    <i
                      className="pr-1 fa fa-calendar"
                      aria-hidden="true"
                      style={{ width: "1rem" }}
                    ></i>{" "}
                    {!dateEqual && (
                      <del>
                        {moment(updateAppointmentData?.FromTime).format(
                          "MM/DD/YYYY"
                        )}
                      </del>
                    )}
                    <span className="text-muted ml-2">
                      {moment(selectedSchItems[0].date).format("MM/DD/YYYY")}
                    </span>{" "}
                    {!timeEqual && (
                      <del>
                        {`${moment(updateAppointmentData?.FromTime).format(
                          "h:mm A"
                        )} ${
                          updateAppointmentData?.CustomTimeZone
                            ? updateAppointmentData?.CustomTimeZone
                            : getTimeZoneShortHand(
                                updateAppointmentData?.UserTimeZoneId
                              )
                        }`}
                      </del>
                    )}
                    <span className="text-muted ml-2">{`${selectedSchItems[0]?.time} ${timezoneShorthand}`}</span>{" "}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {recurrenceDropdownOption.id === "doesNotRepeat" ? (
                <>
                  <Grid
                    data={schedules}
                    className="bzo-appoinmentBooked-grid"
                    style={{ maxHeight: "300px" }}
                  >
                    <Column
                      field="date"
                      title="Date"
                      width="150px"
                      cell={(props: GridCellProps) => (
                        <td>
                          {!dateEqual && (
                            <del>
                              {moment(updateAppointmentData?.FromTime).format(
                                "MM/DD/YYYY"
                              )}
                            </del>
                          )}{" "}
                          {moment(props.dataItem.date).format("MM/DD/YYYY")}
                        </td>
                      )}
                    />
                    <Column
                      field="time"
                      title="Time"
                      width="150px"
                      cell={(props: GridCellProps) => (
                        <td>
                          {!timeEqual && (
                            <del>
                              {`${moment(
                                updateAppointmentData?.FromTime
                              ).format("h:mm A")} ${
                                updateAppointmentData?.CustomTimeZone
                              }`}
                            </del>
                          )}{" "}
                          {`${props.dataItem.time} ${timezoneShorthand}`}
                        </td>
                      )}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    data={schedules}
                    className="bzo-appoinmentBooked-grid"
                    style={{ maxHeight: "300px" }}
                  >
                    <Column
                      field="StartDateTime"
                      title="Date"
                      width="150px"
                      cell={(props: GridCellProps) => (
                        <td>
                          {moment(props.dataItem.StartDateTime).format(
                            "MM/DD/YYYY"
                          )}
                        </td>
                      )}
                    />
                    <Column
                      field="StartDateTime"
                      title="Time"
                      width="150px"
                      cell={(props: GridCellProps) => (
                        <td>
                          {`${moment(props.dataItem.StartDateTime).format(
                            "h:mm A"
                          )} ${timezoneShorthand}`}
                        </td>
                      )}
                    />
                  </Grid>
                </>
              )}
            </>
          )}
          <div className="align-self-start  bz-fw-6 ">
            {"A calendar invitation has been sent to your email address."}
          </div>
          {!guestPages && <Button
              primary={true}
              className={"align-self-center"}
              style={{ textTransform: "initial" }}
              onClick={() => handleConfirmation()}
            >
              {!userDetails ? "Add Another Appointment" : "Go to overview"}
            </Button>}
        </div>
      </div>
    </div>
  );
};

export default ConfirmationComponent;
