import React, { useRef } from 'react';
import BuzopsLoader from "generic-components/BuzopsLoader";
import moment from "moment";
import { CurrencyFormat, getFullImageUrl } from "utils";
import { DropDownButton } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import BuzopsButton from "generic-components/BuzopsButton";
import withNotification from "components/Hoc/withNotification";
import ClientNameWithMemberType from "./ClientNameWithMemberType";
import Location from "components/Location/Location";
import { useUpcomingAppointments } from "./upcomingAppointments.hook";
import { Loader } from '@progress/kendo-react-indicators';
import RenderPayRemainingdue from 'components/CheckIns/RenderPayRemainingDue';
import { MdAccessTimeFilled } from 'react-icons/md';
import { BuzopsTooltip } from 'generic-components/BuzopsTooltip';
import { TbChecks } from 'react-icons/tb';
import Cancellation from 'components/session/Cancellation';
import BuzopsWindow from 'generic-components/BuzopsWindow';
import { ListView } from '@progress/kendo-react-listview';
import { FaExclamationTriangle } from 'react-icons/fa';
import ChangePaymentSession from 'components/session/ChangePaymentSession';

export enum upcomingAppointmentEnum {
  payNow = 'Pay Now'
}

const RenderUpcomingAppointments = (props: any) => {
  const {
    loading,
    searchAppointments,
    visible,
    cancelClients,
    session,
    visibility,
    renewConfirmDialog,
    setRenewConfirmDialog,
    selectedAppt,
    handleUpdateStatus,
    toggleDialog,
    isLoading,
    cancelAppointment,
    revertAppointment,
    notes,
    addNotes,
    notesLoading,
    SubmitNotes,
    itemsList,
    GroupServicePermission,
    ScheduleReSchedulePermission,
    NotesPermission,
    DeletePermission,
    CanRevertSchedule,
    DisputePermission,
    ShowNoShowPermission,
    handleSelection,
    getUpcomingAppointments,
    checkinItem,
    checkForFutureRecords,
    payRemainingDueItem,
    transactionState,
    onWindowClose,
    handleVisibility,
    WaitListItems,
    changePaymentObj,
    selectedWaitListClient
  } = useUpcomingAppointments(props);


  const ref = useRef<any>(null);
  const { type, all = false, staffPage = false } = props;

  const handleScroll = async(event: any) => {
    const e = event.nativeEvent;
    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      if(searchAppointments.pageNumber+1 <  searchAppointments.maxPageNumber && ref.current.className === 'd-none') {
        if(ref.current){
          ref.current.className = "";
        }
        await getUpcomingAppointments(searchAppointments.pageNumber+1);
        if(ref.current){
          ref.current.className = "d-none";
        }
      }
    }
  }
  const AppointmentDialog = () => {
    return (
      <>
        <Dialog
          title={"Please confirm"}
          onClose={toggleDialog}
          minWidth={"25%"}
          className="bz-book-Appoinment-dialog bz-delete-appoinment-dialog"
        >
          <div className="ready-to-book-appt-dialog">
            <div className="column-1-section cancel-label">
              {`Are you sure you want to ${visible?.delete ? `Cancel` : `Revert the Appointment Completion`
                } ?`}
            </div>
            <div className="column-1-section">
              Date<span className="purchased-dots">:</span>
            </div>
            <div className="column-2-section">
              {moment(selectedAppt?.FromTime).format("MM/DD/YYYY")}
            </div>
            <div className="column-1-section">
              Time<span className="purchased-dots">:</span>
            </div>
            <div className="column-2-section">
              {moment(selectedAppt?.FromTime).format("h:mm A")}
            </div>
            <div className="column-1-section">
              Provider<span className="purchased-dots">:</span>
            </div>
            <div className="column-2-section">{selectedAppt?.TrainerName}</div>
          </div>

          <div
            className={`column-button-section book-appt-btns justify-content-end w-100 mt-2 ${selectedAppt?.ParentScheduleId > 0 ? "three-btns" : ""
              }`}
          >
            <Button onClick={toggleDialog} className="ml-0">
              {"No"}
            </Button>
            {visible?.delete ? (
              <>
                <BuzopsButton
                  label={"Yes"}
                  style={{ textTransform: "none" }}
                  loading={isLoading.cancelBtnLoading}
                  disable={
                    isLoading.cancelBtnLoading ||
                    isLoading.canceRecurrencelBtnLoading
                  }
                  onClick={() => cancelAppointment(selectedAppt)}
                />
                {selectedAppt?.ParentScheduleId > 0 && (
                  <BuzopsButton
                    label={"Cancel this and recurrence appointments"}
                    style={{ textTransform: "none" }}
                    loading={isLoading.canceRecurrencelBtnLoading}
                    disable={
                      isLoading.canceRecurrencelBtnLoading ||
                      isLoading.cancelBtnLoading
                    }
                    onClick={() => cancelAppointment(selectedAppt, true)}
                  />
                )}
              </>
            ) : (
              <>
                <BuzopsButton
                  label={"Yes"}
                  loading={isLoading.cancelBtnLoading}
                  disable={isLoading.cancelBtnLoading}
                  onClick={() => revertAppointment(selectedAppt)}
                />
              </>
            )}
          </div>
        </Dialog>
      </>
    );
  };

  const NotesDialog = () => {
    return (
      <>
        <Dialog title={"Please Add Notes"} onClose={toggleDialog}>
          <p
            style={{
              margin: "0",
              width: "350px",
              textAlign: "center",
              fontSize: "16px",
            }}
            className="bz-addNotes-textarea"
          >
            <textarea
              name="Notes"
              value={notes}
              className="form-control"
              onChange={(e) => addNotes(e)}
              rows={4}
              cols={50}
            ></textarea>
          </p>
          <DialogActionsBar>
            <Button onClick={toggleDialog}>{"Cancel"}</Button>
            <BuzopsButton
              label={"Save"}
              loading={notesLoading}
              disabled={notesLoading || !notes || notes?.trim()?.length === 0}
              onClick={() => SubmitNotes(selectedAppt)}
            />
          </DialogActionsBar>
        </Dialog>
      </>
    );
  };

  const renderRenewConfirmDialog = () => {
    return (
      <Dialog
        title={"Please confirm"}
        onClose={() => setRenewConfirmDialog(false)}
      >
        <p style={{ margin: "25px", width: "350px", textAlign: "center" }}>
          {`This is scheduled to auto-renew the payment in the amount of [${CurrencyFormat(selectedAppt?.PackageCost || 0
    )}]. Would you like to auto-renew this service?`}
        </p>
        <DialogActionsBar>
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            onClick={() =>
              handleUpdateStatus(
                { ...selectedAppt, CanRenew: false },
                selectedAppt.text,
                false
              )
            }
          >
            Cancel, <small>turn off auto-renew</small>
          </button>
          <Button
            primary
            onClick={() =>
              handleUpdateStatus(
                { ...selectedAppt, CanRenew: true },
                selectedAppt.text,
                false
              )
            }
          >
            Ok, <small>please charge this client</small>
          </Button>
        </DialogActionsBar>
      </Dialog>
    );
  };

  if (loading) {
    return (
      <>
        <div
          className={
            "bz-no-upcoming-appoinments text-muted bz-upcomingappointments-card"
          }
        >
          <BuzopsLoader type="list" />
        </div>
      </>
    );
  }
  if (searchAppointments?.upcomingAppointmentsData.length === 0) {
    return (
      <>
        <div
          className={
            "bz-no-upcoming-appoinments text-muted bz-upcomingappointments-card"
          }
        >
          {type === "Today" && (
            <div className="bz-norecords-available">
              No Appointments for Today
            </div>
          )}
          {type !== "Today" && (
            <div className="bz-norecords-available">No {type} Appointments</div>
          )}
        </div>
      </>
    );
  }
  let cardClassName = "bz-upcomingappointments-card";
  if (all) {
    cardClassName = "bz-appoinments-page";
  }
  const requestIfNeeded = () => {
    const nextPage = searchAppointments.pageNumber + 1;
    const maxPageNum = searchAppointments.maxPageNumber;

    if (nextPage <= maxPageNum) {
      return {
        status: true,
        page: nextPage,
      };
    } else {
      return {
        status: false,
        page: nextPage,
      };
    }
  };

  const renderItem = (item: any) => {
    let status: any = null;
    let className = "";
    let paidStatus = null;
    let classNameVal = "";
    let paidclassNameVal = "";
    let items = itemsList.filter(
      (i: any) => {
        if(i?.visibleForFuture === true){
          return true
        }else if(i?.visibleForPast === true){
          if(moment(item?.FromTime).format('YYYYMMDDHHmmss') < moment().format('YYYYMMDDHHmmss')){
            console.log("IF")
            return true
          }
          return false
        }
      } 
    );
    if (
      checkForFutureRecords(item) && 
      type !== "Completed"&&
      staffPage &&
      !item?.CheckinTimestamp
    ) {
      items.push(checkinItem);
    }
    if (!item.IsPaid && type !== "Completed") {
      items.push(payRemainingDueItem);
    }
    if (item.IsPaid) {
      if (item.Status === 2) {
        status = "Show";
        classNameVal = "badge bzo-service-status bzo-status-success";
      } else if (item.Status === 3) {
        status = "No Show";
        classNameVal = "badge bzo-service-status bzo-status-consultation";
      } else if (item.Status === 4) {
        status = "Dispute";
        classNameVal = "badge bzo-service-status bzo-status-Complimentary";
      } else if (item.Status === 5) {
        status = "Expired";
        classNameVal = "badge bzo-service-status bzo-status-Complimentary";
      }
      if(item?.IsWaitList && type !== "Completed"){
        paidStatus = "Waitlisted";
        paidclassNameVal ="badge bz-badge-warning";
        if(staffPage){
          if(item?.PaymentMode === 0 || item?.PaymentMode === 2){
            items=WaitListItems
          }else{
            items=WaitListItems.filter((i:any)=> i?.id !== 20)
          }
        }
      }
    } else {
      if (item.PrivateServiceType === 1 && !item?.IsWaitList) {
        paidStatus = "Payment Pending";
        paidclassNameVal =
          "badge bzo-service-status bzo-status-paymentRequired";
      }else if(item?.IsWaitList && type !== "Completed"){
        paidStatus = "Waitlisted";
        paidclassNameVal ="badge bz-badge-warning";
        if(staffPage){
          if(item?.PaymentMode === 0 || item?.PaymentMode === 2){
            items=WaitListItems
          }else{
            items=WaitListItems.filter((i:any)=> i?.id !== 20)
          }
        }else{
          items=items.filter((i:any)=> i?.text !== upcomingAppointmentEnum.payNow)
        }
      }
    }

    if (type === "Completed") {
      if (item.IsPaid) {
        const selectedItems = itemsList.map((i: any) => {
          if (i?.visibleForPast === true) {
            if (i.id === 5 && item?.EntityType === 2) {
              return status === null ? { ...i } : { ...i, icon: " " };
            }
            if (status === null && i.id !== 7) {
              return { ...i };
            }
            if (status !== null && i.id !== 6 && i.id !== 5) {
              return { ...i, icon: status === i?.text ? "check" : " " };
            }
          }
        });
        items = selectedItems?.filter((j: any) => j !== undefined);
      }
    }
    if (!staffPage) {
      items = items?.filter((i: any) => i?.visibleForClient === true);
      if (item?.EntityType === 2) {
        items = items.filter((i: any) => i?.visibleForGs === true);
      } else {
        items = items.filter((i: any) => i?.visibleForIs === true);
      }
    } else {
      if (item?.EntityType === 2) {
        items = items
          .filter((i: any) => i?.visibleForGs === true)
          ?.map((j: any) => {
            if (
              j?.text === "Cancel" ||
              j?.text === "Dispute" ||
              j?.text === "Show" ||
              j?.text === "No Show" ||
              j?.text === "Notes"
            ) {
              return { ...j, disabled: !GroupServicePermission(item) };
            }
            return { ...j };
          });
      } else {
        items = items
          .filter((i: any) => i?.visibleForIs === true)
          ?.map((j: any) => {
            if (j?.text === "Reschedule") {
              return { ...j, disabled: !ScheduleReSchedulePermission(item) };
            } else if (j?.text === "Notes") {
              return { ...j, disabled: !NotesPermission(item) };
            } else if (j?.text === "Cancel") {
              return { ...j, disabled: !DeletePermission(item) };
            } else if (j?.text === "Revert Appointment Completion") {
              return { ...j, disabled: !CanRevertSchedule(item) };
            } else if (j?.text === "Dispute") {
              return { ...j, disabled: !DisputePermission(item) };
            } else if (j?.text === "Show" || j?.text === "No Show") {
              return { ...j, disabled: !ShowNoShowPermission(item) };
            }
            return { ...j };
          });
      }
    }
    const Attributes = item?.Attributes;
    return (
      <>
        <div className="col-md-6 col-xxl-4 card bz-appoinments-info-card">
          <div
            className={`${className} bz-upcoming-apponments-sm-card mb-3 bz-overview-appoinmentsInfo`}
          >
            <div className="card-body">
              <div className="media">
                <img
                  alt=""
                  className="bz-user-img align-self-center mr-2"
                  src={
                    item.TrainerPhoto.trim()
                      ? getFullImageUrl(item.TrainerPhoto)
                      : "/images/user.png"
                  }
                />
                <div className="media-body">
                  <div>
                    <div className="d-flex">
                      <h6 className="mt-0 mb-0">{`${item.TrainerName}`}</h6>
                      <div className="bz-appoinments-card-status">
                        {item?.TrainerComments && (
                          <span
                            className="mr-1"
                            onClick={() => handleSelection("Notes", item)}
                          >
                            <i
                              className="fa fa-sticky-note"
                              aria-hidden="true"
                            ></i>
                          </span>
                        )}
                        {paidStatus !== null && (
                           <span className={`${item?.IsWaitList ? paidclassNameVal:""}`}>
                            <BuzopsTooltip
                              position={"top"}
                              toolTipClass={"phone-tooltip"}
                              html={
                                <span
                                  className={
                                    item?.IsWaitList
                                      ? "payment-required-warning d-inline-flex align-items-center  bz-cursor-pointer"
                                      : "payment-warning text-danger bz-cursor-pointer pe-all"
                                  }
                                  title={
                                    item?.IsWaitList
                                      ? "Your spot is reserved, and payment will be collected once your booking is confirmed."
                                      : "Payment Pending"
                                  }
                                >
                                  {item?.IsWaitList ? (
                                   <>{paidStatus}</>
                                  ) : (
                                    <FaExclamationTriangle
                                  />
                                  )}
                                </span>
                              }
                            />
                          </span>
                        )}
                        {status !== null && (
                          <span className={`${classNameVal} mr-0`}>
                            {status}
                          </span>
                        )}
                      </div>
                    </div>

                    <p
                      className="bz-service-type mb-0"
                      style={{ lineHeight: 1.2, marginTop: "0.2rem" }}
                    >
                      <span className="mr-1 text-capitalize  d-flex justify-content-start">
                        {`${item.ServiceName}`}
                        {Attributes &&
                          Attributes !== "" &&
                          Attributes !== "null" && (
                            <Location
                              Attributes={Attributes}
                              showDetails={true}
                              VirtualMeetingLink={item?.VirtualMeetingLink}
                            />
                          )}
                      </span>
                    </p>
                    <p className="bz-dateTime text-muted mb-0">
                      <small className="mr-1">{`${moment(item.FromTime).format(
                        "MM/DD/YYYY"
                      )}`}</small>
                      <small>{`${item.ScheduleName}`}</small>
                    </p>
                    <span className="bz-member-info text-muted bz-fs-12px text-capitalize">
                      <ClientNameWithMemberType
                        memberType={item?.MemberType}
                        clientName={item?.MemberName}
                      />
                    </span>
                    {item.EntityType === 3 && (
                      <span
                        className={`badge bzo-service-status bzo-status-consultation mx-0`}
                        style={{ marginTop: "0.25rem", marginBottom: "0.1rem" }}
                      >
                        {(item?.PrivateServiceType == 3
                          ? "Complimentary"
                          : item?.PrivateServiceType == 2
                          ? "Unpaid"
                          : item?.IsComplimentary
                          ? "Complimentary "
                          : `${item?.Ordinal} of ${item?.NumberOfSessions} Scheduled`) +
                          (item?.IsImmediateSession
                            ? ` as Quick Checkout`
                            : ``)}
                      </span>
                    )}
                    <span>
                      {item?.CheckinTimestamp && (
                        <div className="d-flex align-items-center justify-content-start bz-member-info bz-fs-12px text-capitalize checked-in-success">
                          <span className="pr-1">
                            <MdAccessTimeFilled />
                          </span>
                          <BuzopsTooltip
                            html={
                              <span title="Checked-in">
                                {moment(item?.CheckinTimestamp).format(
                                  "hh:mm A"
                                )}
                              </span>
                            }
                          />
                          <span className="checkedin-success">
                            <TbChecks />
                          </span>
                        </div>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              {!props?.NoActions ? (<span className="bz-elipsisis-icon bz-elipsis-container">
                {item?.ScheduleId===selectedWaitListClient?.ScheduleId && selectedWaitListClient?.loading ? <Loader size="small" type={"pulsing"} /> :
                <DropDownButton
                  onItemClick={(e) => handleSelection(e.item.text, item)}
                  className={
                    "pl-2"
                  }
                  // buttonclassName={"my-bookings_upcoming-appointments_body_tag_menu"}
                  buttonClass={
                    "elipsis-btn-icon"
                  }
                  items={items}
                  iconClass="fa fa-ellipsis-v "
                  look="flat"
                  popupSettings={{
                    popupClass: "upcoming-appoinment-popup",
                    anchorAlign:{
                      horizontal:"right",
                      vertical:"bottom"
                    },
                    popupAlign:{
                      horizontal:"right",
                      vertical:"top"
                    }
                  }}
                />}
              </span>
              ):null}
            </div>
          </div>
        </div>
      </>
    );
  };
  const renderPayRemainingDue = () => {
    return (
      <RenderPayRemainingdue transactionState={transactionState} onWindowClose={() => onWindowClose()} userDetails={{ ...props?.userData, UserMemberId: props?.userData?.MainUserId || props?.userData?.UserMemberId }} staffPage={props?.staffPage} />
    );
  };
  const popUpWindow = () => {
    let Component = <></>;
    let title = "";
    let visible = false;
    if (visibility?.cancel) {
      Component = (
        <Cancellation
          classSession={session}
          fromDashboard={true}
          isInhouse={props?.staffPage}
          cancelClients={cancelClients}
          handleWindow={() => handleVisibility({ ...visibility, cancel: false })}
        />
      );
      title = "Cancel Appointment";
      visible = visibility.cancel;
    }else if (visibility?.changePayment) {
      Component = (
        <ChangePaymentSession
          isInhouse={false}
          fromDashboard={true}
          isClient={!props?.staffPage}
          payNowItem={changePaymentObj}
          classSession={session}
          handleWindow={() => handleVisibility({ ...visibility, changePayment: false })}
        />
      );
      title = "Change Payment";
      visible = visibility.changePayment;
    }
    return (
      <BuzopsWindow
        title={
          <>
            <div className={"buzops-window-title"}>
              &nbsp;{title}
            </div>
          </>
        }
        reduceWidth={10}
        className={"chargeback-global-window"}
        visibility={visible}
        key={visible.toString()}
        onWindowClose={() => handleVisibility({ ...visibility, changePayment: false,cancel:false })}
      >
        {Component}
      </BuzopsWindow>
    );
  };
  return (
    <>
      <ListView
        onScroll={(e) => handleScroll(e)}
        data={searchAppointments.upcomingAppointmentsData || []}
        item={(props) => renderItem(props?.dataItem)}
        style={{
          width: "100%",
        }}
        className={"bz-upcoming-appointments-list-view"}
      />
      <div className='d-none' ref={ref}>
        <div className='text-center'><Loader type="converging-spinner" /></div>
      </div>
      {(visible?.delete || visible?.revert) && AppointmentDialog()}
      {visible?.notes && NotesDialog()}
      {renewConfirmDialog && renderRenewConfirmDialog()}
      {renderPayRemainingDue()}
      {popUpWindow()}
    </>
  );
};

export default withNotification(RenderUpcomingAppointments);
