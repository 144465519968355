import React, { useState } from 'react';
import {
  Input,
} from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { MdSearch } from "react-icons/md";

const itemsList = [
  {
    id: 0,
    text: "All",
  },
  {
    id: 1,
    text: "Membership",
  },
  {
    id: 2,
    text: "Add-On",
  },
  {
    id: 3,
    text: "One-on-One",
  },
  {
    id: 4,
    text: "Packages",
  },
];
const DeclineFilter = (props: any) => {
  const [searchParams, setSearchParams] = useState<any>({
    SearchKeyword: "",
    ServiceType: 0,
    SelectAll: true
  })
  return (
    <>
      <div className="dflexBtn group-event-top-blk">
        {
          <>
            <div className="bz-classes-search" style={{ width: "260px" }}>
              <Input
                type={"search"}
                style={{ width: "100%" }}
                className="form-control"
                placeholder={`Client Number, email, User Name`}
                onChange={(e) =>
                  setSearchParams({ ...searchParams, SearchKeyword: e.target.value })
                }
              // onChange={(e) => setSearchText(e.value)}
              // value={searchText}
              />
            </div>
          </>
        }
        <div className=" dropdown-serviceType" style={{ width: "200px" }}>
          <DropDownList
            id={`serviceType`}
            name={`serviceType`}
            data={itemsList}
            style={{ width: "100%" }}
            textField="text"
            dataItemKey="id"
            value={itemsList?.find((val: any) => val.id === searchParams.ServiceType)}
            onChange={(e) => {
              console.log(e.target.value)
              setSearchParams((prev: any) => {
                return { ...prev, ServiceType: e.target.value?.id };
              })
            }
            }
          // onChange={(e) => handleSelectServiceType(e.value)}
          />
        </div>
        <div>
          <span
            className="btn btn-primary"
            onClick={() => props?.fetchDeclinedPayments(searchParams)}
          >
            <MdSearch title={"Search"} />
          </span>
        </div>
      </div>
    </>
  );
};

export default DeclineFilter;
