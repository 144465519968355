import { Button } from "@progress/kendo-react-buttons";
import { AutoComplete, ListItemProps } from "@progress/kendo-react-dropdowns";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { RadioButton } from "@progress/kendo-react-inputs";
import BuzopsButton from "generic-components/BuzopsButton";
import React, { useEffect, useRef, useState } from "react";
import { FormInput, FormMaskedTextBox } from "utils/form-components";
import {
  NameValidator,
  emailValidator,
  mobileValidator,
  requiredValidator,
} from "validators/validator";
import { ClassService } from "services/class/index.service";
import { Dialog } from "@progress/kendo-react-dialogs";
import { GetLocalStore } from "utils/storage";

const ClientUpdateForm = (props: any) => {
  const IsAffiliateFeatureEnabled=GetLocalStore("Configuration")?.IsAffiliateFeatureEnabled
  const clientCreateRef: any = useRef(null);
  const classService = new ClassService();
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showMemberExistsModal, setShowMemberExistsModal] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [userData, setUserData] = useState<any>();
  const [disabledContinue, setDisabledContinue] = useState(false);
  const [isStaff, setIsStaff] = useState(false);

  const [selectedValue, setSelectedValue] = useState("");
  useEffect(() => {
    if (selectedValue === "new") {
      clientCreateRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedValue]);

  const validateEmail = async (value: string) => {
    const resp = await classService.validateEmail(value);
    setIsStaff(false);

    if (resp.Item.UserId !== 0) {
      if (resp.Item.UserMemberId > 0) {
        if (resp.Item.UserMemberIsActive) {
          setUserData(resp.Item);
          setShowMemberExistsModal(true);
          props.setUserId(resp.Item.UserId);
        } else {
          setUserData(resp.Item);
          setShowActivateModal(true);
          props.setUserId(resp.Item.UserId);
        }
      } else {
        setShowEmailModal(true);
        setUserData(resp.Item);
        props.setUserId(resp.Item.UserId);
        setIsStaff(true);
      }
    } else {
      setShowEmailModal(false);
      setUserData(undefined);
    }
  };

  const checkIsStaff = async (value: string) => {
    if (!value) return;
    const resp = await classService.validateEmail(value);
    setIsStaff(false);

    if (resp.Item.UserId !== 0 && resp.Item.UserAssociateId > 0) {
      setIsStaff(true);
    } else {
      setIsStaff(false);
    }
  };

  useEffect(() => {
    checkIsStaff(props?.clientFormValues?.Email);
  }, [props?.clientFormValues?.Email]);

  const activateMember = async () => {
    const resp = await classService.activateMember(userData.UserMemberId);
    if (resp.ResponseCode === 100) {
      props?.isPaymentForm === false &&
        props.fetchClientData(userData.UserMemberId);
      props.isPaymentForm === true && props?.updateSelectedClientData(userData);
    } else {
      const successMsg = "Unable to activate member";
      props.setMessage(successMsg);
      props.setNotification({ success: false, error: true });
    }
  };
  const [timer, setTimer] = useState<any>();

  const handleFieldChange = (event: any) => {
    const { target, value } = event;
    if (target.name === "Email") {
      setDisabledContinue(false);
      clearTimeout(timer);

      const newTimer = setTimeout(() => {
        validateEmail(value);
      }, 1000);
      setTimer(newTimer);
    }
  };

  const updateUserFields = (formRenderProps: any) => {
    formRenderProps.onChange("FirstName", {
      value: userData?.FirstName,
    });
    formRenderProps.onChange("LastName", {
      value: userData?.LastName,
    });
    formRenderProps.onChange("PhoneNumber", {
      value: userData?.Phone || "",
    });
    formRenderProps.onChange("UserId", {
      value: userData?.UserId || "",
    });
    setIsStaff(true);
  };

  const clientUpdate = (props: any) => {
    return (
      <Form
        initialValues={props.clientFormValues}
        onSubmit={props.handleClientSubmit}
        render={(formRenderProps: any) => (
          <>
            <div className="col-12">
              {isStaff && (
                <div className="alert alert-warning mb-0 py-2 px-3 fade show">
                  {`This profile is already associated with a ${IsAffiliateFeatureEnabled ? "staff/affilaite":"staff"} in this club.
                  Profile changes cannot be made. Changes can only be made from
                  the ${IsAffiliateFeatureEnabled ? "Staff/Affiliate":"Staff"} page.`}
                </div>
              )}
            </div>
            <FormElement style={{ width: "100%" }}>
              <div className="row col-12">
                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3">
                  <Field
                    id={"FirstName"}
                    name={"FirstName"}
                    label={"First Name"}
                    component={FormInput}
                    validator={requiredValidator}
                    customvalidation={true}
                    disabled={isStaff}
                  />
                </div>
                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3">
                  <Field
                    id={"LastName"}
                    name={"LastName"}
                    label={"Last Name"}
                    component={FormInput}
                    validator={requiredValidator}
                    customvalidation={true}
                    disabled={isStaff}
                  />
                </div>
                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3">
                  <Field
                    id={"PhoneNumber"}
                    name={"PhoneNumber"}
                    placeholder="Phone Number"
                    label="Phone Number"
                    component={FormMaskedTextBox}
                    mask="(999) 000-0000"
                    validator={mobileValidator}
                    customvalidation={true}
                    disabled={isStaff}
                  />
                </div>
                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3">
                  <Field
                    id={"Email"}
                    name={"Email"}
                    placeholder="Email Id"
                    label="Email Id"
                    component={FormInput}
                    validator={emailValidator}
                    customvalidation={true}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="row pt-3 float-right pb-2">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    {props.page === "inhouse-class" ||
                    props.page === "inhouse-package" ? (
                      <Button
                        onClick={() => {
                          setShowActivateModal(false);
                          props.handleChangeClient();
                          setIsStaff(false);
                        }}
                      >
                        Change Client
                      </Button>
                    ) : (
                      <Button onClick={formRenderProps.onFormReset}>
                        Reset
                      </Button>
                    )}
                    <Button
                      primary={true}
                      type={"submit"}
                      disabled={!formRenderProps.allowSubmit}
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </div>
            </FormElement>
          </>
        )}
      />
    );
  };

  const clientCreate = (props: any) => {
    return (
      <Form
        onSubmit={props.handleAddClient}
        render={(formRenderProps) => (
          <FormElement style={{ width: "100%" }}>
            {showEmailModal && (
              <Dialog
                className="bz-selectClient-dialog bz-edit-email-dialog"
                title={"Email Exists"}
                onClose={() => {
                  setShowEmailModal(false);
                  setDisabledContinue(true);
                }}
                style={{ zIndex: 9999 }}
              >
                <div className="bzc-row">
                  <p>
                    {`An existing ${IsAffiliateFeatureEnabled ? "staff/affiliate":"staff"} member with the provided email address
                    already exists. Please click "Continue" to create the client
                    profile using the existing ${IsAffiliateFeatureEnabled ? "staff/affiliate":"staff"} member's details.`}
                  </p>
                  <div className="bzc-edit-session-options-row-buttons">
                    <Button
                      onClick={() => {
                        setShowEmailModal(false);
                        setUserData(undefined);
                        setDisabledContinue(true);
                        formRenderProps.onChange("UserId", {
                          value: undefined,
                        });
                      }}
                      className="ml-0"
                    >
                      Cancel
                    </Button>
                    <BuzopsButton
                      onClick={() => {
                        setShowEmailModal(false);
                        updateUserFields(formRenderProps);
                      }}
                      label={"Continue"}
                      className="mr-0"
                    />
                  </div>
                </div>
              </Dialog>
            )}

            {showMemberExistsModal && (
              <Dialog
                className="bz-selectClient-dialog bz-edit-email-dialog"
                title={"Email Exists"}
                onClose={() => {
                  setShowMemberExistsModal(false)
                  setDisabledContinue(true);
                }}
                style={{ zIndex: 9999 }}
              >
                <div className="bzc-row">
                  <p className="mb-1">
                    A client with the same email already exists.
                  </p>

                  <ul className="pl-4 mb-1">
                    <li>
                      {userData.FirstName} {userData.LastName} ({userData.Email}
                      )
                    </li>
                  </ul>

                  <p>Click "Continue" to proceed with this client.</p>

                  <div className="bzc-edit-session-options-row-buttons">
                    <Button
                      onClick={() => {
                        setShowMemberExistsModal(false);
                        setUserData(undefined);
                        setDisabledContinue(true);
                      }}
                      className="ml-0"
                    >
                      Cancel
                    </Button>
                    <BuzopsButton
                      onClick={() => {
                        setShowMemberExistsModal(false);
                        props.isPaymentForm === false &&
                          props.fetchClientData(userData.UserMemberId);
                        props.isPaymentForm === true &&
                          props?.updateSelectedClientData(userData);
                      }}
                      label={"Continue"}
                      className="mr-0"
                      autoFocus={true}
                    />
                  </div>
                </div>
              </Dialog>
            )}

            {showActivateModal && (
              <Dialog
                className="bz-selectClient-dialog bz-edit-email-dialog"
                title={"Activate Prospect"}
                onClose={() => {
                  setShowEmailModal(false);
                  setShowActivateModal(false);
                  setDisabledContinue(true);
                }}
                style={{ zIndex: 9999 }}
              >
                <div className="bzc-row">
                  <p className="mb-1">
                  This email ID and associated prospect is archived. Click "Continue" to activate this archived prospect.
                  </p>
                  {/* <p className="mb-1">
                    An inactive prospect with the same email already exists.
                  </p> */}
                  {/* <ul className="pl-4 mb-1">
                    <li>
                      {userData.FirstName} {userData.LastName} ({userData.Email}
                      )
                    </li>
                  </ul>
                  <p>
                    Click "Continue" to re-activate this prospect and proceed.
                  </p> */}
                  <div className="bzc-edit-session-options-row-buttons">
                    <Button
                      onClick={() => {
                        setShowActivateModal(false);
                        setDisabledContinue(true);
                      }}
                      className="ml-0"
                    >
                      Cancel
                    </Button>
                    <BuzopsButton
                      onClick={() => {
                        setShowEmailModal(false);
                        activateMember();
                      }}
                      label={"Continue"}
                      className="mr-0"
                    />
                  </div>
                </div>
              </Dialog>
            )}
            <div className="col-12">
              {isStaff && (
                <div
                  className="alert alert-warning mb-0 py-2 px-3"
                  role="alert"
                >
                  {`This profile is already associated with a ${IsAffiliateFeatureEnabled ? "staff/affiliate":"staff"} in this club.
                  Profile changes cannot be made. Changes can only be made from
                  the ${IsAffiliateFeatureEnabled ? "staff/affiliate":"staff"} page.`}
                </div>
              )}
              <div className="row">
                <div className="col-xl-12">
                  <Field
                    id={"FirstName"}
                    name={"FirstName"}
                    placeholder="First Name"
                    label="First Name"
                    component={FormInput}
                    validator={(val:any)=>NameValidator(val,"First Name")}
                    customvalidation={true}
                    hidelabel={true}
                    onChange={handleFieldChange}
                    disabled={isStaff}
                  />
                </div>
                <div className="col-xl-12">
                  <Field
                    id={"LastName"}
                    name={"LastName"}
                    placeholder="Last Name"
                    label="Last Name"
                    component={FormInput}
                    validator={(val:any)=>NameValidator(val,"Last Name")}
                    customvalidation={true}
                    hidelabel={true}
                    onChange={handleFieldChange}
                    disabled={isStaff}
                  />
                </div>
                <div className="col-xl-12">
                  <Field
                    id={"PhoneNumber"}
                    name={"PhoneNumber"}
                    placeholder="Phone Number"
                    label="Phone Number"
                    component={FormMaskedTextBox}
                    mask="(999) 000-0000"
                    validator={mobileValidator}
                    customvalidation={true}
                    hidelabel={true}
                    onChange={handleFieldChange}
                    disabled={isStaff}
                  />
                </div>
                <div className="col-xl-12">
                  <Field
                    id={"Email"}
                    name={"Email"}
                    placeholder="Email Id"
                    component={FormInput}
                    validator={emailValidator}
                    hidelabel={true}
                    onChange={handleFieldChange}
                  />
                </div>
              </div>
              <div className="bz-row pt-3 pb-2 float-right">
                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 ">
                  {/* <Button
                    primary={true}
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                  >
                    Continue
                  </Button> */}
                  <BuzopsButton
                    primary
                    type={"submit"}
                    label={"Continue"}
                    disabled={
                      !formRenderProps.allowSubmit ||
                      props.clientSubmitBtnLoading ||
                      disabledContinue
                    }
                    loading={props.clientSubmitBtnLoading}
                  />
                </div>
              </div>
            </div>
          </FormElement>
        )}
      />
    );
  };

  const itemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const itemChildren = `${itemProps?.dataItem?.FullName} ${itemProps?.dataItem?.Email}`;

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <>
      {Object.keys(props.clientFormValues).length !== 0 && clientUpdate(props)}
      {Object.keys(props.clientFormValues).length === 0 && (
        <>
          <div className="bz-row d-flex justify-content-start pt-4 mb-4">
            <div className="col-12">
              <div className="form-check form-check-inline">
                <RadioButton
                  name="selectionType"
                  value="pick"
                  label="Pick a Client"
                  checked={selectedValue === "pick"}
                  onChange={() => setSelectedValue("pick")}
                />
              </div>
              {!props?.disableCreate && (
                <div className="form-check form-check-inline">
                  <RadioButton
                    name="selectionType"
                    value="new"
                    label="Add New Client"
                    checked={selectedValue === "new"}
                    onChange={() => setSelectedValue("new")}
                  />
                </div>
              )}
            </div>
          </div>
          {selectedValue && selectedValue === "new" && (
            <>
              {clientCreate(props)}
              <i ref={clientCreateRef}></i>
            </>
          )}
          {selectedValue && selectedValue === "pick" && (
            <>
              <FormElement style={{ width: "100%" }}>
                <div className="">
                  <div className="bz-row">
                    <div className="col-12">
                      <AutoComplete
                        data={props.clientsList}
                        itemRender={itemRender}
                        value={
                          props?.selectedClient?.type === "search"
                            ? props?.selectedClient?.value
                            : props?.selectedClient?.value?.FullName
                        }
                        onChange={(e: any) => props.handleClientSelecton(e)}
                        placeholder={"Client"}
                        loading={props?.clientSearchLoading}
                        listNoDataRender={() => (
                          <span className={"bzo-noData-found"}>
                            {props?.clientSearchLoading
                              ? "Searching for Client..."
                              : "Client is not available please add new client"}
                          </span>
                        )}
                      />
                    </div>
                  </div>
                  <div className="bz-row pt-3 pb-2 float-right">
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 ">
                      <BuzopsButton
                        primary
                        label={"Continue"}
                        loading={props.clientSubmitBtnLoading}
                        onClick={(e: any) => {
                          props.handleClientSelectionSubmit(e);
                        }}
                        className="mr-0"
                      />
                    </div>
                  </div>
                </div>
              </FormElement>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ClientUpdateForm;
