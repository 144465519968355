import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { Notification as Notify } from "@progress/kendo-react-notification";
import {
  PaymentItemRender,
  PaymentValueRender,
} from "components/Payment/PaymentRenderItems";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { TenantService } from "services/tenant/index.service";
import {
  CheckUserMemberStatus,
  CurrencyFormat,
  CustomToFixed,
} from "utils";
import { CancellationOnOptions, CreditsErrorMessage } from "utils/form-utils";
import AddonsInPlanCancel from "./AddonsInPlanCancel";
import PlanCancelSummary from "./PlanCancelSummary";
import FailedTransactionsGrid from "../FailedTransactionsGrid";
import withNotification from "components/Hoc/withNotification";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { BiInfoCircle } from "react-icons/bi";
import { TooltipContentRefundTax } from "components/Transactions/Refund";
import { Tooltip } from "@progress/kendo-react-tooltip";

const PlanCancel = (props: any) => {
  const [cancelDetails, setCancelDetails] = useState<any>({});
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [totalCancellationAmount, setTotalCancellationAmount] =
    useState<any>(0);
  const [paymentProfiles, setPaymentProfiles] = useState<any>([]);
  const [pageType, setPageType] = useState("plan");
  const [loading, setLoading] = useState(true);
  const [addonLoading, setAddonLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [confirmBtnLoading, setConfirmBtnLoading] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const service = new TenantService();

  useEffect(() => {
    fetchAllApis();
  }, []);

  const fetchAllApis = async () => {
    setLoading(true);
    await fetchPlanSummary();
    await fetchPaymentProfiles(props?.userData?.MainUserId);
    setLoading(false);
  };

  const fetchPlanSummary = async () => {
    const req = {
      PageNumber: 0,
      PageSize: 32767,
      UserMemberId: props?.userData?.UserMemberId,
      MemberPlanId: props?.selectedPlanId,
    };
    const res = await service?.getMemberPlanSummary(req);
    setSelectedPlan(res);
    await fetchCancellationDetails(res?.MemberNumber);
  };

  const fetchCancellationDetails = async (MemberNumber: any) => {
    const MemberPlanId = props.selectedPlanId;
    const req = {
      InvoiceNumber: MemberNumber,
      MemberPlanId,
      UserMemberId: props?.userData?.UserMemberId,
    };
    const result = await service.GetPlanCancellationDetails(req);
    if (result?.Result?.length > 0) {
      if (result?.Result[0]?.CancellationRequestedId > 0) {
        setCancelDetails(result);
      } else {
        if (result?.Result[0]?.IsNeedToCancel === true) {
          const resultRecords = result?.Result?.map((i: any, index: number) => {
            if (index === 0) {
              if(!i?.MinimumDaysReached){
                return {
                  ...i,
                  IsNeedToCancel: true,
                  CancellationOn: 1,
                  DisableCancelItToday: true,
                };
              }
              if (
                (i?.DurationType === 1 && i?.Duration === 1) ||
                i?.DurationType === 3
              ) {
                return {
                  ...i,
                  IsNeedToCancel: true,
                  CancellationOn: 1,
                  DisableCancelItToday: true,
                };
              }
              return { ...i, IsNeedToCancel: true, CancellationOn: 0 };
            } else {
              return { ...i };
            }
          });
          const rec = { ...result, Result: resultRecords };
          await UpdatePlanCancellationDetails(rec);
        } else {
          setCancelDetails(result);
        }
      }
    } else {
      setCancelDetails(null);
    }
  };

  const calculateTotalCancellationAmount = (data: any) => {
    let totalCancellationAmt = 0;
    data?.Result?.forEach((i: any) => {
      totalCancellationAmt += i?.AmountToCollect;
      i?.AddOns?.forEach((addon: any) => {
        totalCancellationAmt += addon?.AmountToCollect;
      });
    });
    setTotalCancellationAmount(totalCancellationAmt);
  };

  async function fetchPaymentProfiles(userMemberId: any) {
    const result = await service.paymentProfile(userMemberId);
    setPaymentProfiles(result || []);
  }

  const showErrorMessage = (key: any, mainKey: any) => {
    if (
      key === "PaymentGatewayPaymentProfileId" &&
      totalCancellationAmount > 0 &&
      (!cancelDetails[key] || cancelDetails[key] === 0)
    ) {
      return <Error>{`${mainKey} is required`}</Error>;
    } else if (
      key !== "PaymentGatewayPaymentProfileId" &&
      (!cancelDetails[key] || cancelDetails[key].length <= 0)
    ) {
      return <Error>{`${mainKey} is required`}</Error>;
    }
    return <></>;
  };

  const selectionChange = async (event: any, EntityId: number = 0) => {
    const dataItem = JSON.parse(JSON.stringify(cancelDetails?.Result[0]));
    const originalDup = dataItem?.FailedTransactions;

    const original = originalDup.map((item: any) => {
      if (
        item?.TransactionHistoryId === event?.dataItem?.TransactionHistoryId
      ) {
        item.CanWaive = !event.dataItem.CanWaive;
      }
      return item;
    });
    const updatedCancellationDetails = {
      ...dataItem,
      FailedTransactions: original,
    };
    const cancelDet = {
      ...cancelDetails,
      Result: [updatedCancellationDetails],
    };
    setCancelDetails(cancelDet);
    await UpdatePlanCancellationDetails(cancelDet);
  };

  const handleCalculateSurcharges = (refundAmt: any, TaxMetaData: any) => {
    if (TaxMetaData) {
      const mainTaxItems = TaxMetaData?.split(",");
      const TaxesArray = mainTaxItems.map((i: any) => {
        const dataItem = i?.split(":");
        const percentage = parseFloat(dataItem[1]);
        const description = `${dataItem[0]} - (${percentage}%)`;
        const amount = (refundAmt * percentage) / 100;
        const calcAmt = CustomToFixed(amount, 2);
        return {
          Description: description,
          Percentage: percentage,
          Amount: calcAmt,
        };
      });
      const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
        return acc + currentValue?.Amount;
      }, 0);
      return {
        TaxesArray,
        taxAmt,
      };
    } else {
      return {
        TaxesArray: [],
        taxAmt: 0,
      };
    }
  };

  const handleChange = async (value: any, name: string) => {
    let update = false;
    const data = JSON.parse(JSON.stringify(cancelDetails));
    let cancelDet = data;
    if (name === "paymentProfile") {
      const PaymentGatewayPaymentProfileID =
        value?.PaymentGatewayPaymentProfileID;
      cancelDet = {
        ...data,
        PaymentGatewayPaymentProfileId: PaymentGatewayPaymentProfileID,
        CardTypeId: value?.CardTypeId,
        Credit: value?.Credit,
      };
    } else if (name === "Comments") {
      cancelDet = {
        ...data,
        [name]: value,
      };
    } else if (name === "CancellationOn" || name === "CancellationFee") {
      let val = value;
      const dataItem = JSON.parse(JSON.stringify(cancelDetails?.Result[0]));
      if (name === "CancellationFee") {
        val = value || 0;
      }
      const updatedCancellationDetails = {
        ...dataItem,
        [name]: val,
      };
      cancelDet = {
        ...data,
        Result: [updatedCancellationDetails],
      };
      update = true;
    } else {
      const dataItem = JSON.parse(JSON.stringify(cancelDetails?.Result[0]));
      const updatedCancellationDetails = {
        ...dataItem,
        [name]: value,
      };
      cancelDet = {
        ...data,
        Result: [updatedCancellationDetails],
      };
    }
    setCancelDetails(cancelDet);
    if (update) {
      await UpdatePlanCancellationDetails(cancelDet);
    }
  };
  const TooltipContentTemplate = (propData: any) => {
    return (
      <div>
        <span className="">
          {`If you do not wish to provide a refund or 
    if you would like to collect an amount higher 
    than the refund amount,please specify the 
    cancellation fee in the provided field. 
    This fee will be charged to the user 
    in the event of a cancellation`}
        </span>
      </div>
    );
  };
  const UpdatePlanCancellationDetails = async (dataItem: any) => {
    setAddonLoading(true);
    const res = await service.UpdatePlanCancellationDetails(dataItem);
    setAddonLoading(false);
    if (res?.ResponseCode === 100) {
      const result = res?.Item;
      const resultRecords = result?.Result?.map((i: any, index: number) => {
        if (index === 0) {
          if(!i?.MinimumDaysReached){
            return {
              ...i,
              IsNeedToCancel: true,
              CancellationOn: 1,
              DisableCancelItToday: true,
            };
          }
          if (
            (i?.DurationType === 1 && i?.Duration === 1) ||
            i?.DurationType === 3
          ) {
            return {
              ...i,
              IsNeedToCancel: true,
              CancellationOn: 1,
              DisableCancelItToday: true,
            };
          }
          return { ...i, IsNeedToCancel: true };
        } else {
          return { ...i };
        }
      });
      const rec = { ...result, Result: resultRecords };
      handleUpdateDataWithSurcharge(rec);
      await calculateTotalCancellationAmount(rec);
    } else {
      const errorMsg =
        res?.ErrorMessages.length > 0
          ? res?.ErrorMessages[0]
          : "Error in Getting Cancellation Details of Membership";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  const handleAddonNext = async () => {
    if (cancelDetails?.Result)
      if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
        props?.handleNotificationMessage("", "error", true, "client");
        return;
      } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
        props?.handleNotificationMessage("", "error", false, "staff");
        return;
      }
    setBtnLoading(true);
    const result = await service.UpdatePlanAddOnCancellationDetails(
      cancelDetails
    );
    setBtnLoading(false);
    if (result?.ResponseCode === 100) {
      setPageType("final");
      handleUpdateDataWithSurcharge(result?.Item);
      await calculateTotalCancellationAmount(result?.Item);
    }
  };
  const handleUpdateDataWithSurcharge = (dataItem: any) => {
    const updateData = dataItem?.Result?.map((i: any) => {
      const PlanTaxItem = handleCalculateSurcharges(
        i?.AmountToRefund,
        i?.MetaData
      );
      const AddonItems = i?.AddOns?.map((j: any) => {
        const AddonTaxItem = handleCalculateSurcharges(
          j?.AmountToRefund,
          j?.MetaData
        );
        return {
          ...j,
          TaxesArray: AddonTaxItem?.TaxesArray,
          taxAmt: AddonTaxItem?.taxAmt,
        };
      });
      return {
        ...i,
        TaxesArray: PlanTaxItem?.TaxesArray,
        taxAmt: PlanTaxItem?.taxAmt,
        AddOns: AddonItems,
      };
    });
    setCancelDetails({ ...dataItem, Result: updateData });
  };
  const handleApproveConfirmation = async () => {
    const check = handleValidation();
    if (check) {
      setBtnLoading(true);

      const result = await service.PlanCancelApproveConfirmation(cancelDetails);
      setBtnLoading(false);

      if (result?.ResponseCode === 100) {
        setConfirmDialog(true);
        setConfirmText(result?.Item);
      }
    }
  };

  const handleAddonPrevious = async () => {
    setPageType("plan");
  };
  const handleCompleteCancellation = async () => {
    setConfirmBtnLoading(true);
    const result = await service.PlanCompleteCancellation(cancelDetails);
    setConfirmBtnLoading(false);
    setConfirmDialog(false);
    setConfirmText("");
    setPageType("plan");
    setTotalCancellationAmount(0);
    if (result?.ResponseCode === 100) {
      props?.handleNotificationMessage(result?.SuccessMessage, "success");

      await fetchAllApis();
    } else {
      const errorMsg =
        result?.ErrorMessages.length > 0
          ? result?.ErrorMessages[0]
          : "Error in Cancelling Membership";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const renderConfirmationDialog = () => {
    return (
      <Dialog
        className="bz-book-Appoinment-dialog bz-plan-cancellation-dialog"
        title={"Buzops"}
        onClose={() => handleCancel()}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: confirmText,
          }}
        ></div>
        <div className="k-dialog-footer">
          <Button primary={false} onClick={() => handleCancel()}>
            Cancel
          </Button>
          <BuzopsButton
            label={"Ok"}
            loading={confirmBtnLoading}
            onClick={() => handleCompleteCancellation()}
          />
        </div>
      </Dialog>
    );
  };
  const handleCancel = () => {
    setConfirmDialog(false);
  };

  const handleValidation = () => {
    console.log(cancelDetails,'cancelDetails')
    if (!cancelDetails?.Comments || cancelDetails?.Comments === "") {
      return false;
    }
    if (
      totalCancellationAmount > 0 &&
      (!cancelDetails?.PaymentGatewayPaymentProfileId ||
        cancelDetails?.PaymentGatewayPaymentProfileId === 0)
    ) {
      return false;
    }

    if(cancelDetails?.PaymentGatewayPaymentProfileId && cancelDetails?.CardTypeId === 8 && totalCancellationAmount > cancelDetails?.Credit){
      const errorMsg = CreditsErrorMessage
      props?.handleNotificationMessage(errorMsg, "error");
      return false
    }
    return true;
  };

  const handleAddonItem = (key: any, value: any) => {
    const dataItem = JSON.parse(JSON.stringify(cancelDetails?.Result[0]));
    const addonItems = dataItem?.AddOns?.map((i: any) => {
      if (i?.EntityId === key) {
        return value;
      } else {
        return i;
      }
    });

    const updatedCancellationDetails = {
      ...dataItem,
      AddOns: addonItems,
    };

    const cancelDet = {
      ...cancelDetails,
      Result: [updatedCancellationDetails],
    };
    setCancelDetails(cancelDet);
  };

  if (loading) {
    return <BuzopsLoader type="list" />;
  }
  if (!cancelDetails?.Result) {
    return <div className="bz-norecords-available">{"No Data Found"}</div>;
  }

  return (
    <>
      {cancelDetails?.Result?.map((dataItem: any) => {
        if (dataItem) {
          const {
            PlanName,
            StartDate,
            MemberEndDate,
            FailedTransactions,
            IsNeedToCancel,
            MinimumDaysReached,
            AmountPaid,
            Notification,
            AmountToCollect,
            CancellationOn,
            CancellationDate,
            ImmediateCancellationDate,
            NormalCancellationDate,
            CancellationRequestedId,
            CancellationApprovedBy,
            CancellationApprovedDate,
            CancellationComments,
            CancellationFee,
            DurationType,
            Duration,
            AddOns,
            AmountToRefund = 0,
            MetaData = null,
            TaxesArray = [],
            taxAmt = 0,
          } = dataItem;
          return (
            <>
              {/* <h5 className="mb-3 bz_fs_1_5">
                {" "}
                <button
                  className="btn btn-primary bz-back-btn btn-sm"
                  onClick={() => props?.gotoPlanOverview()}
                >
                  <i className="fas fa-arrow-left"></i>
                </button>
                &nbsp;{"Membership Cancellation"}
              </h5> */}
              {/* <hr /> */}
              <div className="bz-subsection-row bz-plan-overview bz-addon-cancellation">
                <div className="left-section">
                  <h5 className="mb-3 pt-3 bz_fs_1_5">Membership Details</h5>
                  <div className="bz-mt-2rem bz-content-text-row-mobile">
                    {/* <div className="bz-content-text-row">
                      <div className="bz-content-title text-right">
                        Client Name
                      </div>
                      <span className="bz-colon text-center">:</span>
                      <div className="bz-content-body">
                        <div className="d-flex align-items-center">
                          {MemberType === 0 ? (
                            <img src={PrimaryMember} className="bz-member-icon" />
                          ) : (
                            <img src={SubMember} className="bz-member-icon" />
                          )}
                          {FullName}
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {pageType === "plan" && (
                    <>
                      <div className="bz-content-text-row-mobile">
                        <div className="bz-content-text-row">
                          <div className="bz-content-title text-right">
                            Membership Name
                          </div>
                          <span className="bz-colon text-center">:</span>
                          <div className="bz-content-body">{PlanName}</div>
                        </div>
                        <div className="bz-content-text-row">
                          <div className="bz-content-title text-right">
                            Signup Date
                          </div>
                          <span className="bz-colon text-center">:</span>
                          <div className="bz-content-body">
                            {moment(selectedPlan?.PurchasedOn).format("L")}
                          </div>
                        </div>
                        <div className="bz-content-text-row">
                          <div className="bz-content-title text-right">
                            Start Date
                          </div>
                          <span className="bz-colon text-center">:</span>
                          <div className="bz-content-body">
                            {StartDate === null
                              ? "NA being MTM plan"
                              : moment(StartDate).format("L")}
                          </div>
                        </div>
                        <div className="bz-content-text-row">
                          <div className="bz-content-title text-right">
                            {" "}
                            End Date
                          </div>
                          <span className="bz-colon text-center">:</span>
                          <div className="bz-content-body">
                            {MemberEndDate === null
                              ? DurationType === 2
                                ? "NA being MTM plan"
                                : "NA"
                              : moment(MemberEndDate).format("L")}
                          </div>
                        </div>
                        <hr />
                        <h5 className="mb-3 pt-3 bz_fs_1_5">
                          Cancellation Details
                        </h5>
                        {CancellationRequestedId > 0 && (
                          <>
                            <div className="bz-content-text-row">
                              <div className="bz-content-title text-right">
                                Approved by
                              </div>
                              <span className="bz-colon text-center">:</span>
                              <div className="bz-content-body">
                                {CancellationApprovedBy}
                              </div>
                            </div>
                            <div className="bz-content-text-row">
                              <div className="bz-content-title text-right">
                                Approved Date
                              </div>
                              <span className="bz-colon text-center">:</span>
                              <div className="bz-content-body">
                                {CancellationApprovedDate === null
                                  ? "NA"
                                  : moment(CancellationApprovedDate).format(
                                      "L"
                                    )}
                              </div>
                            </div>
                            <div className="bz-content-text-row">
                              <div className="bz-content-title text-right">
                                Comments of Approval
                              </div>
                              <span className="bz-colon text-center">:</span>
                              <div className="bz-content-body">
                                {CancellationComments}
                              </div>
                            </div>
                            <div className="bz-content-text-row">
                              <div className="bz-content-title text-right">
                                Cancel On
                              </div>
                              <span className="bz-colon text-center">:</span>
                              <div className="bz-content-body">
                                {
                                  CancellationOnOptions?.filter(
                                    (i: any) => i?.id === CancellationOn
                                  )?.[0]?.text
                                }
                              </div>
                            </div>
                            <div className="bz-content-text-row">
                              <div className="bz-content-title text-right">
                                Cancellation Date
                              </div>
                              <span className="bz-colon text-center">:</span>
                              <div className="bz-content-body">
                                {CancellationDate === null
                                  ? "NA"
                                  : moment(CancellationDate).format("L")}
                              </div>
                            </div>
                          </>
                        )}
                        {/* commented as per #8889 */}
                        {Notification && !IsNeedToCancel && (
                          <div className="bz-content-text-row bz-notify-row">
                            <div className="bz-content-title text-right"></div>
                            <span className="bz-colon text-center"></span>
                            <div className="bz-content-body">
                              {" "}
                              <div className="bz-plan-note">
                                <p className="mb-0">
                                  <Notify key="info" type={{ style: "info" }}>
                                    {Notification}
                                  </Notify>
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                        {!MinimumDaysReached &&
                          CancellationRequestedId === 0 && (
                            <div className="bz-content-text-row bz-notify-row">
                              <div className="bz-content-title text-right"></div>
                              <span className="bz-colon text-center"></span>
                              <div className="bz-content-body">
                                <div className="bz-plan-note bz-cancellation-notification">
                                  <p className="mb-0 bz-black-font">
                                    <Notify key="info" type={{ style: "none" }}>
                                      {`Since you are cancelling the Membership within minimum days, the system will process your ${CurrencyFormat(
                                        AmountPaid
                                      )} refund.`}
                                    </Notify>
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                      </div>

                      {FailedTransactions?.length > 0 &&
                        pageType === "plan" && (
                          <FailedTransactionsGrid
                            details={FailedTransactions}
                            selectionChange={(e: any, id: number) =>
                              selectionChange(e, id)
                            }
                            EntityId={0}
                          />
                        )}
                      {IsNeedToCancel && (
                        <>
                          <div className="bz-mb-2_5rem">
                            {CancellationRequestedId === 0 &&
                              IsNeedToCancel &&
                              pageType === "plan" && (
                                <>
                                  <div className="bz-content-text-row align-items-start bz-notify-row">
                                    <div className="bz-content-title text-right">
                                      Cancel On{" "}
                                      <span className="required-asterisk">
                                        *
                                      </span>
                                    </div>
                                    <span className="bz-colon text-center">
                                      :
                                    </span>
                                    <div className="bz-content-body">
                                      <DropDownList
                                        data={CancellationOnOptions}
                                        textField="text"
                                        dataItemKey="id"
                                        disabled={
                                          !MinimumDaysReached || (((DurationType === 1 &&
                                            Duration === 1) ||
                                            DurationType === 3) &&
                                          CancellationOn === 1)
                                        }
                                        value={
                                          CancellationOnOptions?.filter(
                                            (i: any) => i?.id === CancellationOn
                                          )?.[0] || {
                                            id: 0,
                                            text: "Please select Cancellation Type",
                                          }
                                        }
                                        onChange={(e) =>
                                          handleChange(
                                            e?.value?.id,
                                            "CancellationOn"
                                          )
                                        }
                                        style={{ width: "100%" }}
                                      />
                                      {CancellationOn === 1 && (
                                        <p
                                          className={
                                            "CancelInfo notificxation-blue mb-0"
                                          }
                                        >
                                          {"Service will cancel today."}
                                        </p>
                                      )}
                                      {CancellationOn === 2 && (
                                        <p
                                          className={
                                            "CancelInfo notificxation-blue mb-0"
                                          }
                                        >
                                          {
                                            "Service will cancel on the next recurring billing date and the user will not get charged."
                                          }
                                        </p>
                                      )}
                                      {CancellationOn === 3 && (
                                        <p
                                          className={
                                            "CancelInfo notificxation-blue mb-0"
                                          }
                                        >
                                          {
                                            " Allow for 30-day cancellation notice and will cancel after next recurring service."
                                          }
                                        </p>
                                      )}
                                      {CancellationOn === 0 && (
                                        <Error>
                                          {"Please Select Cancellation Type"}
                                        </Error>
                                      )}
                                    </div>
                                  </div>
                                  {CancellationOn > 0 && (
                                    <div className="bz-content-text-row bz-notify-row">
                                      <div className="bz-content-title text-right">
                                        Cancellation Date
                                      </div>
                                      <span className="bz-colon text-center">
                                        :
                                      </span>
                                      <div className="bz-content-body">
                                        {CancellationOn === 1 &&
                                          moment().format("L")}
                                        {CancellationOn === 2 &&
                                          moment(
                                            ImmediateCancellationDate
                                          ).format("L")}
                                        {CancellationOn === 3 &&
                                          moment(NormalCancellationDate).format(
                                            "L"
                                          )}
                                      </div>
                                    </div>
                                  )}
                                  {selectedPlan?.RenewalApplicable && (
                                    <div className="bz-content-text-row bz-notify-row">
                                      <div className="bz-content-title text-right"></div>
                                      <span className="bz-colon text-center"></span>
                                      <div className="bz-content-body">
                                        If you want to cancel the membership
                                        after completion of current billing
                                        cycle please{" "}
                                        <a
                                          className="btn btn-link"
                                          onClick={() =>
                                            props?.gotoPlanRenewalPage(true)
                                          }
                                        >
                                          Click Here
                                        </a>
                                      </div>
                                    </div>
                                  )}
                                  <div className="bz-content-text-row bz-notify-row">
                                    <div className="bz-content-title text-right">
                                      <div className="d-flex justify-content-end">
                                        <div>Cancellation Fee </div>
                                        <BuzopsTooltip
                                          toolTipClass={
                                            "Cancellation-fee-tooltip"
                                          }
                                          position={"top"}
                                          content={(propdata: any) => (
                                            <TooltipContentTemplate
                                              title={propdata.title}
                                            />
                                          )}
                                          html={
                                            <button
                                              className="btn btn-icon"
                                              title={"Cancellation Fee"}
                                            >
                                              <BiInfoCircle color="#008db9" />
                                            </button>
                                          }
                                        />
                                      </div>
                                    </div>
                                    <span className="bz-colon text-center">
                                      :
                                    </span>
                                    <div className="bz-content-body">
                                      <NumericTextBox
                                        id={`CancellationFee`}
                                        name={`CancellationFee`}
                                        min={0}
                                        format={"c2"}
                                        className="k-dropdown-width-100"
                                        value={CancellationFee}
                                        onChange={(e) =>
                                          handleChange(
                                            e.target.value,
                                            "CancellationFee"
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  {CancellationOn > 0 && (<>
                                    {AmountToRefund > 0 && (
                                      <div className="bz-content-text-row bz-notify-row">
                                        <div className="bz-content-title text-right">
                                          <div className="d-flex justify-content-end">
                                            <div>{"Amount to Refund"}</div>
                                            <BuzopsTooltip
                                              html={
                                                <button
                                                  className="btn btn-icon"
                                                  title={
                                                    "Prorated amount for remaining days of current billing cycle"
                                                  }
                                                >
                                                  <BiInfoCircle color="#008db9" />
                                                </button>
                                              }
                                            />
                                          </div>
                                        </div>
                                        <span className="bz-colon text-center">
                                          :
                                        </span>
                                        <div className="bz-content-body">
                                          {CurrencyFormat(AmountToRefund)}
                                        </div>
                                      </div>
                                    )}
                                    {AmountToCollect > 0 && (
                                      <div className="bz-content-text-row bz-notify-row">
                                        <div className="bz-content-title text-right">
                                          <div className="d-flex justify-content-end">
                                            <div>{"Amount to Collect"}</div>
                                          </div>
                                        </div>
                                        <span className="bz-colon text-center">
                                          :
                                        </span>
                                        <div className="bz-content-body">
                                          {CurrencyFormat(AmountToCollect)}
                                        </div>
                                      </div>
                                    )}
                                    {addonLoading ? (
                                      <BuzopsLoader type="list" />
                                    ) : (
                                      <>
                                        {AmountToRefund > 0 && (
                                          <>
                                            {AmountToRefund > 0 && MetaData && (
                                              <div className="bz-content-text-row">
                                                <div className="bz-content-title text-right">
                                                  <Tooltip
                                                    content={(
                                                      tooltipProps: any
                                                    ) => {
                                                      return (
                                                        <TooltipContentRefundTax
                                                          title={
                                                            tooltipProps.title
                                                          }
                                                          data={TaxesArray}
                                                          TotalTaxAmount={taxAmt}
                                                          Name={PlanName}
                                                        />
                                                      );
                                                    }}
                                                    tooltipClassName="bz-tooltip-table"
                                                  >
                                                    <a
                                                      title="Surcharges"
                                                      style={{
                                                        borderBottom:
                                                          "1px dotted #0d6efd",
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      {
                                                        "Surcharges to be refunded"
                                                      }
                                                    </a>
                                                  </Tooltip>
                                                </div>
                                                <span className="bz-colon text-center">
                                                  :
                                                </span>
                                                <div className="bz-content-body">
                                                  {CurrencyFormat(taxAmt)}
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                    </>)}
                                </>
                              )}
                          </div>
                          <hr />
                        </>
                      )}
                    </>
                  )}
                  {/* addon cancellation */}
                  {addonLoading ? (
                    <BuzopsLoader type="list" />
                  ) : (
                    <>
                      {IsNeedToCancel &&
                        AddOns.length > 0 &&
                        pageType === "plan" && (
                          <>
                            {AddOns?.length && (
                              <h5 className="mb-3 pt-3 bz_fs_1_5">
                                Add-on Details
                              </h5>
                            )}
                            {AddOns?.map((addonItem: any, index: number) => {
                              return (
                                <>
                                  <AddonsInPlanCancel
                                    addonItem={addonItem}
                                    planItem={dataItem}
                                    handleAddonItem={(key: any, value: any) =>
                                      handleAddonItem(key, value)
                                    }
                                    handleCalculateSurcharges={(
                                      array: any,
                                      value: any
                                    ) =>
                                      handleCalculateSurcharges(array, value)
                                    }
                                  />
                                </>
                              );
                            })}
                          </>
                        )}
                    </>
                  )}
                  {/* plan cancel summary section */}

                  {pageType === "final" && (
                    <PlanCancelSummary
                      cancelDetails={dataItem}
                      totalCancellationAmount={totalCancellationAmount}
                      userData={props?.userData}
                      paymentProfiles={paymentProfiles}
                      valueRender={(
                        el: React.ReactElement<HTMLSpanElement>,
                        value: any
                      ) => PaymentValueRender(el, value, value)}
                      itemRender={PaymentItemRender}
                      showErrorMessage={(key: any, mainKey: any) =>
                        showErrorMessage(key, mainKey)
                      }
                      handleChange={(val: any, name: string) =>
                        handleChange(val, name)
                      }
                      fetchPaymentProfiles={(usermemberId: any) =>
                        fetchPaymentProfiles(usermemberId)
                      }
                    />
                  )}

                  <div className="bz-mt-2rem bz-mb-2_5rem">
                    {IsNeedToCancel &&
                      !cancelDetails?.HideNext &&
                      !addonLoading &&
                      pageType === "plan" && (
                        <>
                          <div className="bz-content-text-row">
                            <div className="bz-content-title text-right"></div>
                            <span className="bz-colon text-center"></span>
                            <div className="bz-content-body">
                              <BuzopsButton
                                label={"Next"}
                                loading={btnLoading}
                                disabled={CancellationOn === 0}
                                onClick={() => handleAddonNext()}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    {pageType === "final" && (
                      <>
                        <div className="bz-content-text-row">
                          <div className="bz-content-title text-right"></div>
                          <span className="bz-colon text-center"></span>
                          <div className="bz-content-body">
                            <BuzopsButton
                              label={"Previous"}
                              onClick={() => handleAddonPrevious()}
                            />
                            <BuzopsButton
                              label={"Complete"}
                              loading={btnLoading}
                              onClick={() => handleApproveConfirmation()}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {confirmDialog && renderConfirmationDialog()}
            </>
          );
        }
      })}
    </>
  );
};

export default withNotification(PlanCancel);
