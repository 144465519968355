import React from "react";
import { RadioButton } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { GetLocalStore } from "utils/storage";
import CheckPermissions from "utils/permissions";
import { PermissionEnum } from "utils/PermissionEnum";
import { CurrencyFormat, checkIfUserCanAddBankAccount } from "utils";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { BsCCircleFill } from "react-icons/bs";
import { FaExclamationTriangle } from "react-icons/fa";

const SavedCards = (propsData: any) => {
  const GotoPayConfiguration = () => {
    const NavigationUrl = `/Common/Club/ManagePaymentGateway`
    const data = { Type: "RedirectUrl", Url: `${NavigationUrl}` };
    window.parent.postMessage(data, "*");
  };
  const userDetails = JSON.parse(
    JSON.stringify(GetLocalStore("userDetails"))
  );
  let userSession = userDetails?.UserSession
  if (propsData.page === "inhouse-class" || propsData.page === "inhouse-package") {
    userSession = JSON.parse(
      JSON.stringify(GetLocalStore("tenantUserSession"))
    )
  }
  let paymentProfiles = propsData?.paymentProfile;
  const HasCashPaymentEnabled = userSession?.ClubPreferences?.HasCashPaymentEnabled
  const IsPaymentConfigured = userSession?.ClubPreferences?.IsPaymentConfigured
  if (!HasCashPaymentEnabled && !IsPaymentConfigured) {
    return (
      <div className="col-12 add-payment pl-24">
        <div
          className="alert alert-warning fade show"
          role="alert"
        >
          {CheckPermissions(PermissionEnum["PaymentPortalConfiguration"]) ? (<>
            <span>
              {`To collect payments, visit your `}
              <a
                className={"btn btn-link text-left mb-1"}
                onClick={() => {
                  GotoPayConfiguration();
                }}
              >
                <u>{`payment portal configuration page `}</u>
              </a>
              {` and choose your preferred provider`}
            </span>
          </>) : (<>
            {propsData?.staffPage ? (<span>
              {`To collect payments, please contact your administrator`}
            </span>) : (<span>
              {`Electronic payments are not enabled, please check back again later`}
            </span>)}
          </>)}
        </div>
      </div>
    );
  }
  if (paymentProfiles?.length > 0) {
    return paymentProfiles?.map((item: any) => {
      let icon = item.IsCreditCard ? "fa fa-credit-card" : `fa fa-university ${item?.BankAccountType === 1 && "savingBankAccount"}`;
      let Name = item.IsCreditCard ? "Name on Card " : "Account Name";
      let cardClass = `payment-profile-credit-card-Item`
      if (!HasCashPaymentEnabled && item?.CardTypeId === -1) {
        cardClass = `payment-profile-credit-card-Item text-muted`
      }
      if (item?.CardTypeId === -1) {
        icon = "fa fa-dollar-sign";
        Name = "Name";
      }
      if (item?.CardTypeId === 8 && item?.Credit <= 0) {
        return (<>
        </>)
      }
      return (
        <div className="col-12 add-payment pl-24">
          <RadioButton
            id={`rb${item?.PaymentGatewayPaymentProfileID}`}
            name="group1"
            onChange={() => {
              propsData.handleChangeSavedCard(item);
            }}
            checked={
              propsData?.selectedPaymentProfile?.selectedPayment
                ?.PaymentGatewayPaymentProfileID ===
              item?.PaymentGatewayPaymentProfileID
            }
            disabled={!HasCashPaymentEnabled && item?.CardTypeId === -1}
          >
            <label htmlFor={`rb${item?.PaymentGatewayPaymentProfileID}`}>
              <div className={cardClass}>
                <div className="section-card-details d-flex align-items-center">
                  <div className="pr-1">{item?.CardDescriptionWithoutExpiryDate}</div>
                  {item?.Status === "ACL" ? <BuzopsTooltip
                    style={{ display: 'inline-block' }}
                    html={
                      <span title="The account has been closed, contact card holder for new card">
                        <FaExclamationTriangle style={{ color: 'red', marginTop: '-4px' }} />
                      </span>
                    }
                  /> : null}
                  {!HasCashPaymentEnabled && item?.CardTypeId === -1 && <small>{` (Not enable. Please contact administrator)`}</small>}
                </div>
                <div className="section-icon-details">
                  {item?.CardTypeId !== 8 ? <span>
                    {item.IsCreditCard ? <i className={icon} aria-hidden="true"></i> : <BuzopsTooltip
                      html={
                        <a
                          title={item?.BankAccountType === 1 ? "Savings Bank" : "Checking Bank"}
                        >
                          <i className={icon} aria-hidden="true"></i>
                        </a>
                      }
                    />}
                  </span> : item?.CardTypeId === 8 ? <BsCCircleFill style={{ fontSize: "1rem" }} /> : null}
                </div>
                {item?.CardTypeId !== 8 ? <div className="section-card-name-details">
                  <small>{Name}</small>
                  <div>
                    {item?.MemberName.split(",")[1]}{" "}
                    {item?.MemberName.split(",")[0]}
                  </div>
                </div> : <div className="section-card-name-details">
                  <small>{"Balance"}</small>
                  <div>
                    {CurrencyFormat(item?.Credit)}
                  </div>
                </div>}
                {item.IsCreditCard && item?.CardTypeId !== 8 && (
                  <div className="section-expiry-details">
                    <small>{"Expiry"}</small>
                    <div>{item?.ExpiryDate}</div>
                  </div>
                )}
              </div>
            </label>
          </RadioButton>
        </div>
      );
    });
  } else {
    return (
      <div className="col-12 add-payment pl-24">
        <Error>{"No payment profiles available"}</Error>
      </div>
    );
  }
};

const OtherPaymentMethods = (props: any) => {
  const { card, bank } = props.paymentMethod;
  return (
    <>
      <div className="row col-12 another-payment pl-24">
        {(props.paymentGatewayType === 1 || props.paymentGatewayType === 3 || props.paymentGatewayType === 4) && (
          <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-2 d-flex">
            <RadioButton
              id={`card`}
              name="group2"
              onChange={() => props.handleForm("card")}
              checked={card}
            >
              <label htmlFor={`card`} className="mb-0">
                <div className="Add-credit-card">
                  {"Add Debit/Credit/ATM card"}
                </div>
              </label>
            </RadioButton>
          </div>
        )}
        {checkIfUserCanAddBankAccount() && (
          <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex">
            <RadioButton
              id={`bank`}
              name="group2"
              onChange={() => props.handleForm("bank")}
              checked={bank}
            >
              <label htmlFor={`bank`} className="mb-0">
                <div className="Add-bank-account">{"Add Bank Account"}</div>
              </label>
            </RadioButton>
          </div>
        )}
      </div>
    </>
  );
};

const PaymentProfileSelection = (props: any) => {
  const { card, bank } = props.paymentMethod;
  return (
    <>
      <h6 className="buz-font-600 p-2 payment-txt pl-24">{`Saved Payment Methods`}</h6>
      {SavedCards(props)}
      <hr />
      <h6 className="buz-font-600 p-2 payment-txt pl-24">{`Other Payment Methods`}</h6>
      <div className="test">{OtherPaymentMethods(props)}</div>
      {(props.page === "inhouse-class" || props.page === "online-class") ? (<div className="col-12 select-payment-profile-text pl-24">
        {!props.selectedPaymentProfile.selectedPayment && !card && !bank && !props?.checkPaymentProfileNotRequired(true) && (
          <Error>{"Please select/add payment profile"}</Error>
        )}
      </div>) : (<div className="col-12 select-payment-profile-text pl-24">
        {!props.selectedPaymentProfile.selectedPayment && !card && !bank && (
          <Error>{"Please select/add payment profile"}</Error>
        )}
      </div>)}

    </>
  );
};
export default PaymentProfileSelection;
