import React from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  ComboBox,
  DropDownList,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { Card, CardBody } from "@progress/kendo-react-layout";
import { ListView } from "@progress/kendo-react-listview";
import Auth from "components/authcomponent/Auth";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { Button } from "@progress/kendo-react-buttons";
import moment from "moment";
import { convertToTimezone, getCompleteDate, getFullDate, getTimeFormat, getTimeZoneShortHand } from "utils";
import withNotification from "components/Hoc/withNotification";
import { Loader } from "@progress/kendo-react-indicators";
import MemberSelection from "components/submemberseletion/MemberSelection";
import ClientNameWithMemberType from "components/summary/ClientNameWithMemberType";
import ClassSessionItem from "./ClassSessionItem";
import usePurchaseClassList from "./PurchaseClassList.hook";
import { GetLocalStore } from "utils/storage";
import { useHistory } from "react-router-dom";
const PurchaseClassList = (props: any) => {
  const history = useHistory();

  const {
    PackagesData,
    AllClientsList,
    clientFormValues,
    toggleClientConfirmDialog,
    handleSubmit,
    isLoggedIn,
    clientSubmitDetails,
    handleClientSelection,
    selectedClassLength,
    handleClearAllSlots,
    selectedClasses,
    handleCloseLogin,
    loginStatus,
    confirmClientDialog,
    handleClientConfirmation,
    classesList,
    handleFilter,
    ClassNamefilterChange,
    filterContent,
    isClassLoading,
    classSessionsList,
    showMoreRef,
    pageLoading,
    handlePageChange,
    handleSelectClass,
    findItem,
    handlePackageChange,
    selectedPackage,
    btnLoading,
    checkZeroDollar
  } = usePurchaseClassList(props);
  const [expandedItem, setExpandItem] = React.useState(null);
  const packageItemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const item = itemProps.dataItem;
    const schedulesToUse =
      item.SessionType === 2
        ? "Unlimited"
        : `${item?.SchedulesUsed} of ${
            item?.SchedulesToUse + item?.SchedulesUsed
          }`;
    const itemChildren = (
      <span>
        <div className="package-item">
          <div className="package-details bz-payment-options">
            <div className="package-name">
              <span className="primary-heading">{item?.Name}</span>
            </div>
            <div className="package-expiry-schedules">
              <small className="package-schedules ">Redeemed: {`${schedulesToUse}`}</small>
              <br />
              {/* <span> {"|"}</span> */}
              <small className="package-expiry">
                {`${
                  item?.ExpireFrom === null
                    ? "N/A"
                    : moment(item?.ExpireFrom).format("L")
                } to ${
                  item?.ExpireOn === null
                    ? "N/A"
                    : moment(item?.ExpireOn).format("L")
                }`}
              </small>
            </div>
          </div>
        </div>
      </span>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };
  const PackageListDropDown = () => {
    return (
      <ComboBox
        data={PackagesData}
        dataItemKey="PackageInstanceId"
        textField="Name"
        filterable={true}
        placeholder="Filter by package"
        itemRender={packageItemRender}
        style={{ width: "100%" }}
        onChange={handlePackageChange}
        value={selectedPackage || props?.selectedPackage}
      />
    );
  };

  const renderClientConfirmationDialog = () => {
    const selectedClientInfo = AllClientsList?.filter(
      (res: any) => res?.UserMemberId === clientFormValues?.UserMemberId
    )[0];
    return (
      <Dialog
        className={`bz-book-Appoinment-dialog ${btnLoading ? "pe-none" : ""}`}
        title={"Please Confirm"}
        onClose={() => toggleClientConfirmDialog()}
        width={"35%"}

      >
        <div>
          <p className={"dialogContainer"}>
            {`Looks like you have additional family members on account, please confirm you are making purchases for the below client.`}
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <ClientNameWithMemberType
              clientName={selectedClientInfo?.FullName}
              memberType={selectedClientInfo?.MemberType}
            />
          </div>
          <div className="column-button-section book-appt-btns">
            <Button primary={false} onClick={() => toggleClientConfirmDialog()}>
              No
            </Button>
            <BuzopsButton label={"Yes"} onClick={() => handleSubmit()}  disabled={btnLoading}
                    loading={btnLoading}
                    key={`${btnLoading}btn-yes`}/>
          </div>
        </div>
      </Dialog>
    );
  };

  const handleExpand = (item: any) => {
    setExpandItem(item.ClassSessionId);
  };
  const handleCollapse = () => {
    setExpandItem(null);
  };
  const MyItemRender = (data: any,redeem=false) => {
    let item = data.dataItem;
    if (item && item.values && item.values.length > 0) {
      return (
        <>
          <div
            className="text-center text-lg-center text-md-center bz-fw-600 mb-2"
            key={item?.date?.toString()}
          >
            <div className="text-capitalize">{`Group On ${getFullDate(
              item?.date
            )} ${getTimeZoneShortHand(GetLocalStore("tenantUserSession")?.TimeZone?.Id)}`}</div>
          </div>
          {item.values.map((item1: any, indx: number) => {
            const check = findItem(item1, selectedClasses);
            return (
              <ClassSessionItem
                staffPage={props?.staffPage}
                dataItem={item1}
                check={check}
                redeem={redeem}
                handleSelectClass={handleSelectClass}
                key={`${item1.ClassSessionId}${check}`}
                handleCollapse={handleCollapse}
                handleExpand={handleExpand}
                expandedItem={expandedItem}
              />
            );
          })}
        </>
      );
    } else {
      return <></>;
    }
  };

  const selectedItemRender = (props: any) => {
    const item = props.dataItem;

    return item?.values.length > 0 ? (
      <>
        <div className="card bz-upcoming-apponments-sm-card mb-2">
          <div className="card-body py-2 px-0">
            <div className="media">
              <div className="media-body">
                <div>
                  <div className="mt-0 mb-2 text-capitalize">
                    <strong>{item?.className}</strong>
                  </div>

                  {item?.values.map((item1: any, indx: number) => {
                    return (
                      <div
                        className="bzc-classes-selected-time"
                        key={item1?.ClassSessionId?.toString()}
                      >
                        <div>
                          <span className="text-muted text-uppercase d-block mb-0">
                            {" "}
                            <i className="far fa-calendar-alt"></i>
                            {getTimeFormat(item1?.ClassSessionStartDate)} -{" "}
                            {getTimeFormat(item1?.ClassSessionEndTime)}
                          </span>

                          <span className="text-muted text-capitalize d-block mb-0">
                            <i className="far fa-clock"></i>
                            {getCompleteDate(item1?.ClassSessionStartDate)}
                            {item1?.CanBookAsWaitlist ? <span className="badge bz-badge-warning ml-1">Waitlisted</span> :null}
                          </span>
                          <span
                            className="bz-close-icon2"
                            onClick={() => {
                              handleSelectClass(item1, "delete");
                            }}
                          >
                            <i className="far fa-trash-alt"></i>
                          </span>
                        </div>

                        <hr
                          className={`${
                            indx + 1 !== item.values.length
                              ? "show my-2"
                              : "hide"
                          }`}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : (
      <></>
    );
  };

  const RenderClassSessionsList = () => {
    if (isClassLoading) {
      return <BuzopsLoader type={"list"} />;
    } else if (classSessionsList.classSessionData.length === 0) {
      return (
        <>
          <div className={"bz-no-upcoming-appoinments text-muted"}>
            No Group Available{" "}
          </div>
        </>
      );
    }
    let redeem=false;
    if(selectedPackage || props?.selectedPackage){
      redeem=true;
    }
    return (
      <>
        <ListView
          item={(props) => MyItemRender(props,redeem)}
          // onScroll={(e) => scrollHandler(e)}
          data={classSessionsList.classSessionData || []}
          style={{
            width: "100%",
          }}
        />
        {!props?.classSessionId ?
        <div className="d-flex justify-content-center align-items-center bz-services-showMore">
          <div ref={showMoreRef}>
            {pageLoading ? (
              <>
                <Loader type="converging-spinner" />
              </>
            ) : (
              <>
                <span
                  className="btn btn-link  align-baseline bz-cursor-pointer p-0"
                  onClick={() => handlePageChange()}
                >
                  Next 10 Days <i className="fas fa-caret-down"></i>
                </span>
              </>
            )}
          </div>
        </div>:null}
      </>
    );
  };

  const filterClassSessionList = () => {
    return (
      <>
        <div className="d-flex bz-purchase-class-filter mb-3 mt-2">
          {!props?.classShortName && (
            <div className="mb-3 mb-sm-0 mr-sm-3">
              <DropDownList
                data={classesList}
                textField="Name"
                dataItemKey="ClassId"
                id={"class"}
                name={"class"}
                onChange={(e) => handleFilter(e.value, "class")}
                value={filterContent.class}
                // defaultItem={"All Group"}
                filterable={true}
                onFilterChange={(e) => ClassNamefilterChange(e)}
                disabled={isClassLoading}
              />
            </div>
          )}
          <div className="">
            {" "}
            <DatePicker
              name="fromDate"
              id="fromDate"
              min={!props.staffPage ? convertToTimezone(new Date(),true): new Date()}
              value={filterContent.fromDate}
              onChange={(e) => handleFilter(e.target.value, "fromDate")}
              disabled={isClassLoading}
            />
          </div>
        </div>
      </>
    );
  };

  const handleNavigateToParentGroup=()=>{
    history.push(`/${props?.classShortName}`)
  }

  const showPackageMessage = () => {
    return <div className="alert alert-warning fade show mt-3"
        role="alert">
          {props?.selectedPackage ? <strong>Note: Only classes from your purchased group package are shown.</strong>:<strong>Note: Only classes from your purchased group package are shown. Deselect the package to view all.</strong>}
        </div>
  }

  if (
    props?.classShortName &&
    !props?.ShortCodeStatus
  ) {
    let message = "The Group not available to book/enroll";
    return (
      <>
        <div className="row">
          <div className={"d-flex justify-content-start mt-3"}>
            <h5 className="card-title bzo-primary-heading mb-0">Group</h5>
          </div>
        </div>
        <br />{" "}
        <div className={"bzo-services-container border-0 text-muted"}>
          {message}
        </div>
      </>
    );
  }


  if (
    props?.classShortName &&
    props?.ShortCodeStatus &&
    props?.ShortCodeDescription
  ) {
    let message = <div className={"bzo-services-container border-0 text-muted"}>The Appointment not available to book/enroll</div>
    switch (props?.ShortCodeDescription) {
      case "Expired":
        message = <div className={"bzo-services-container border-0 text-muted"}>The appointment has been completed. <span className="btn btn-link text-decoration-underline" onClick={()=>handleNavigateToParentGroup()}>Click here</span>  to schedule future appointments for the same group. Thank you!</div>
        break;
      case "Cancelled":
        message = <div className={"bzo-services-container border-0 text-muted"}>The appointment has been cancelled. <span className="btn btn-link text-decoration-underline" onClick={()=>handleNavigateToParentGroup()}>Click here</span> to schedule future appointments for the same group. Thank you!</div>
        break;
      default:
        break;
    }
    return (
      <>
        <div className="row">
          <div className={"d-flex justify-content-start mt-3"}>
            <h5 className="card-title bzo-primary-heading mb-0">Group</h5>
          </div>
        </div>
        <br />{" "}
          {message}
       
      </>
    );
  }

  return (
    <>
      {isLoggedIn && clientSubmitDetails?.UserMemberId && (
        <>
          {!props?.selectedPackage && (
            <div className="row justify-content-between align-items-md-end align-items-xl-center mb-3 mt-2">
              <div className="col-sm-6 col-xl-4 order-2 order-xl-1">
                <label>Package:</label>
                {PackageListDropDown()}
              </div>
              {
                selectedPackage &&
                <div className="col-sm-12 col-xl-4 order-1 order-xl-2 text-left text-xl-center" style={{alignSelf: "center"}}>
                  <br className="d-none d-xl-inline"/>
                  <div>
                  <label>Enrollment Indicator:</label> &nbsp;
                    {`${selectedPackage?.SessionType === 2 ? "Unlimited" : `${(selectedPackage?.SchedulesToUse)}/${selectedPackage?.SchedulesToUse + selectedPackage?.SchedulesUsed} left`} `}
                  </div>
                </div>
              }
              <div className="col-sm-6 col-xl-4 order-3 order-xl-3 mt-3 mt-sm-0">
                <label>Purchase/Enrollment For:</label>
                <MemberSelection
                  allClientsList={AllClientsList}
                  selectedUsermemberId={clientSubmitDetails?.UserMemberId}
                  handleUpdateClient={(val: any) => handleClientSelection(val)}
                />
              </div>
            </div>
          )}
          {
            selectedPackage &&
            <div>
              {showPackageMessage()}
              
            </div>
          }
          <hr className="mt-1" />
        </>
      )}

      <div
        className={
          props.page == "online" ? "bz-online-classes" : "bz-inhouse-classes"
        }
      >
        <div className="bzc-purchase-class-list-view bz-purchaseclass-scroll">
          <div
            className={
              selectedClassLength() > 0
                ? "bzc-purchase-class-list-row bz-class-selected"
                : "row"
            }
          >
            <div
              className={
                selectedClassLength() > 0
                  ? "bzc-left-side-card bz-groupservices-left-side-card"
                  : "col-xs-12 col-md-12 col-md-12 col-lg-12"
              }
            >
              <Card className="my-0">
                {props?.classShortName && props?.classShortName !== null && (
                  <h5 className="card-title bzo-primary-heading mb-0">Group</h5>
                )}
                <CardBody className="bz-purchase-class-list pb-0">
                  {!props?.classSessionId ? filterClassSessionList():null }
                  <RenderClassSessionsList />
                </CardBody>
              </Card>
            </div>
            <div
              className={
                selectedClassLength() > 0 ? "bzc-right-side-card" : "d-none"
              }
            >
              <div
                className={`card bz-cart-card bz-shadow-class ${
                  props.page !== "inhouse"
                    ? "bz-shadow-class-online"
                    : "bz-shadow-class-inhouse"
                }`}
              >
                <div className="card-header">
                  Cart ({selectedClassLength()})
                  <span
                    className="bz-close-icon-new float-right"
                    role={"button"}
                    title={"Clear All"}
                    onClick={() => handleClearAllSlots()}
                  >
                    <i
                      className="far fa-trash-alt"
                      style={{ fontSize: "1.2rem" }}
                    ></i>
                  </span>
                </div>
                <div className="card-body" style={{ overflow: "auto" }}>
                  <ListView data={selectedClasses} item={selectedItemRender} />
                </div>

                <div className="d-flex justify-content-end p-2">
                  {(selectedPackage || props?.selectedPackage || (props?.staffPage && checkZeroDollar())) ?  <BuzopsButton
                    disabled={btnLoading}
                    loading={btnLoading}
                    label={"Enroll" }
                    onClick={() => handleClientConfirmation()}
                  />: <BuzopsButton
                  label={"Proceed to Buy"}
                  onClick={() => handleClientConfirmation()}
               
                />}
                </div>
                {(selectedPackage || props?.selectedPackage  || (props?.staffPage && checkZeroDollar())) && (
                  <div className="d-flex justify-content-end p-2 px-3">
                    <span className="">
                      {" "}
                      {props?.staffPage && checkZeroDollar() ?
                       <small className="session-redeem">
                       Note: {`Above selected Appointments are unpaid appointments can be enrolled directly`}
                     </small>: <small className="session-redeem">
                        Note: {`Above selected Appointments will be redeemed with `}
                        <u>{`${selectedPackage?.Name || props?.selectedPackage?.Name}.`}</u>
                      </small>
                      }
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {confirmClientDialog && renderClientConfirmationDialog()}
      <Auth
        {...props}
        handleCloseLogin={() => handleCloseLogin()}
        showComponent={loginStatus}
        key={loginStatus.toString()}
      />
    </>
  );
};

export default withNotification(PurchaseClassList);
