import React, { useEffect, useRef, useState } from "react";
import { Card } from "@progress/kendo-react-layout";
import { useHistory } from "react-router-dom";
import { GetLocalStore, LocalStore } from "utils/storage";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { ListView } from "@progress/kendo-react-listview";
import { CheckUserMemberStatus, CurrencyFormat } from "utils";
import Auth from "components/authcomponent/Auth";
import withNotification from "components/Hoc/withNotification";
import { TenantService } from "services/tenant/index.service";
import { Button } from "@progress/kendo-react-buttons";
import { useSelector } from "react-redux";
import { GenerateOfferLabel } from "components/AffiliateSubscriptions/AffiliateSubscriptions.view";
import { CalculateOffer } from "utils/utils";

const PurchasePlanList = (props: any) => {
  const service = new TenantService();
  const showMoreRef = useRef<any>();
  const history = useHistory();
  const userDetails = GetLocalStore("userDetails");
  const isLoggedIn = userDetails ? true : false;
  const [planData, setPlanData] = useState<any>([]);
  const [filteredPlanData, setfilterPlanData] = useState<any>([]);
  const [planLoading, setPlanLoading] = useState(false);
  const [loginStatus, setLoginStatus] = useState(false);
  const redirectionObj = useSelector((state:any)=>state?.redirection)

  const handleSelectPlan = (dataItem: any) => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    const planItem = {
      ...dataItem,
      AffiliateId: props?.AffiliateDetails?.UserAssociateId  || null,
      AffiliateUserId: props?.AffiliateDetails?.UserId  || null,
      PromotionId: props?.PromotionId  || null,
      CouponId: props?.offerObj?.CouponId  || null,
      CouponDescription: props?.offerObj?.CouponCode  || null,
    };    
    if (isLoggedIn) {
      if (props?.staffPage) {
        const urlRedirect = `/member/${props?.uuid}/${props?.userData?.UserMemberId}/purchaseplan`;
        history.push(urlRedirect, { planItem: planItem })
      } else {
        if(redirectionObj?.firstPathValue === "external"){
          history.push(`/${redirectionObj?.firstPathValue}/plancheckout`, { plan: planItem });
        }else{
          history.push(`/plancheckout`, { plan: planItem });
        }
      }
    } else {
      LocalStore("planItem", planItem);
      setLoginStatus(true);
    }
  };

  const handleCloseLogin = () => {
    setLoginStatus(false);
  };

  useEffect(() => {
    fetchPlansList();
  }, []);

  async function fetchPlansList() {
    let req: any = { CanHostOnline: true };
    if (props?.staffPage) {
      req = {};
    }
    if(props?.planShortName){
      req={ShortCode:props?.planShortName}
    }
    if(props?.PromotionId){
      req = {...req,PromotionId:props?.PromotionId}
    }
    setPlanLoading(true);
    const res = await service.AvailablePlansToSubscribe(req);
    setPlanLoading(false);
    setPlanData(res);
    setfilterPlanData(res);
    if (isLoggedIn && res?.length===1 && props?.planShortName && !props?.PromotionId) {
      handleSelectPlan(res[0])
    }
  }

  const [page, setPage] = React.useState({
    skip: 0,
    take: 5,
  });
  const handlePageChange = (status = "more") => {
    if (status === "less") {
      setPage({
        skip: 0,
        take: 5,
      });
    } else {
      setPage({
        take: page.take + 5,
        skip: 0,
      });
    }
    if (showMoreRef.current) {
      showMoreRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };
  const { skip, take } = page;

  const MyItemRender = (propData: any) => {
    let item = propData.dataItem;
    let className = "";
    className = "bzo-status-success-new";
    const spanWidth = item?.Fees?.length > 1 ? 'rateLabelWidth': '';
    let frequencyDescription="One Time"
    if(item.DurationType === 1){
      if(item.Duration===1 && item?.CanAutoRenew){
        frequencyDescription="Every 1 Month"
      }else if(item.Duration===1 && !item?.CanAutoRenew){
        frequencyDescription="One Time"
      }else if(item.Duration > 1){
        if(!item?.CanAutoRenew && !item.AllowRecurring){
          frequencyDescription="One Time"
        }else if(!item?.CanAutoRenew && item.AllowRecurring){
          // frequencyDescription="Monthly"
          frequencyDescription="One Time"
        }else if(item?.CanAutoRenew && !item.AllowRecurring){
          // frequencyDescription=item.DurationDescription
          frequencyDescription=`Every ${item?.Duration} Months`
        }else if(item?.CanAutoRenew && item.AllowRecurring){
          frequencyDescription=`Every ${item?.Duration} Months`
          // frequencyDescription="Monthly"
        }
      }
    }else if(item.DurationType === 2){
      frequencyDescription="Every 1 Month"
    }else if(item.DurationType === 3 && item?.CanAutoRenew){
      frequencyDescription=`Every ${item?.Duration} ${item?.Duration > 1 ? "Days":"Day"}`
    }

    let price=item.MonthlyRate
    if(item.DurationType === 1 && !item?.AllowRecurring){
        price=item.MonthlyRate * item?.Duration
    }

    return (
      <div className="row buz-online-list-view-new bz-membership-rate">
        <div className="col-md-9 px-0">
          <div className="p-3 align-middle bz-cursor-pointer pe-4 pe-sm-0">
            <div className="d-flex align-items-center mb-md-3">
              <h6 className="service-title bz-fw-6 mb-0">{item?.Name}</h6>
              {/* <span
                className={
                  item.MonthlyRate || item?.Fees?.length > 0
                    ? "bzo-status-success"
                    : "bzo-status-consultation"
                }
              >
                {item.MonthlyRate > 0 || item?.Fees?.length > 0
                  ? "Paid"
                  : "Free"}
              </span> */}
            </div>
            <div className="row gx-0 mt-2 mb-md-3">
              <div className="col-md-4 align-self-center">
                <div className="bz-name">
                  <span className="bz-availability-appointments bz-planPurchase">
                    <span>Term</span>
                    <span className="bz-colon">:</span>
                    <span>{(item?.Duration > 1 || item?.DurationType===2) ? item?.DurationDescription : item?.DurationDescription?.slice(0, -1)}</span>
                  </span>
                </div>
              </div>
              <div className="col-md-4 section-2 align-self-center my-1 my-md-0">
                <div className="bz-name">
                  <span className="bz-availability-appointments bz-planPurchase">
                    <span>Frequency</span>
                    <span className="bz-colon">:</span>
                    <span>
                      {frequencyDescription}
                    </span>
                  </span>
                </div>
              </div>
              <div className="col-md-4 align-self-center price-blk">
                <div className="bzc-service-name">
                  <div className="bzc-service-name">
                    <div className={`bz-price d-flex flex-column ${spanWidth}`}>
                      <span className="mb-0 bz-planPurchase">
                        <span className="ratelabel">Rate</span>
                        <span className="bz-colon">:</span>
                        {props?.AffiliateDetails && props?.offerObj  ? (<>
                          <del>
                            {CurrencyFormat(price)}
                          </del>
                          <span className="buz-font-600 buz-text-medium" style={{color:'green'}}>{` ${CurrencyFormat(CalculateOffer(price,props?.offerObj))}`}</span>  <GenerateOfferLabel offerObj={props?.offerObj}/>
                        </>):(<>
                          {CurrencyFormat(price)}
                        </>)}
                      </span>
                      {item?.Fees?.length > 0 &&
                        item?.Fees?.map((i: any) => {
                          return (
                            <>
                              <span className="mb-0 bz-planPurchase">
                                <span className="ratelabel">{i?.FeeType}</span>
                                <span className="bz-colon">:</span>
                                <span>{CurrencyFormat(i?.Amount)}</span>
                              </span>
                            </>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 btn-blk" style={{ alignSelf: "center" }}>
          <div className="section-5">
            <Button
              className="select-button bz-service-select-btn bz-service-select-btn-new p-3"
              style={{ backgroundColor: "#008db9", color: "#fff" }}
              onClick={() => {
                handleSelectPlan(item);
              }}
            >
              Select
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const showPlansListComponent = () => {
    if (planLoading) {
      return <BuzopsLoader type={"list"} />;
    } else {
      if (filteredPlanData.length === 0) {
        return (
          <div className={"bzo-services-container border-0 text-muted"}>
            No Available Memberships{" "}
          </div>
        );
      } else {
        return (
          <Card className={"bzo-services-container border-0 pt-0"}>
            <ListView
              data={filteredPlanData.slice(0, 0 + take)}
              item={(e) => MyItemRender(e)}
            />
            <div className="d-flex justify-content-center align-items-center bz-services-showMore">
              <div ref={showMoreRef}>
                {filteredPlanData.length > take && (
                  <span
                    className="btn btn-link  align-baseline bz-cursor-pointer p-0"
                    onClick={() => handlePageChange()}
                  >
                    View More <i className="fas fa-caret-down"></i>
                  </span>
                )}
                {filteredPlanData.length <= take &&
                  filteredPlanData.length > 5 && (
                    <span
                      className="btn btn-link  align-baseline bz-cursor-pointer p-0"
                      onClick={() => handlePageChange("less")}
                    >
                      View Less <i className="fas fa-caret-up"></i>
                    </span>
                  )}
              </div>
            </div>
          </Card>
        );
      }
    }
  };

  const handleSearch = (value: string) => {
    if (value) {
      const results = planData.filter((item: any) =>
        item.Name.toLowerCase().includes(value.toLowerCase())
      );
      setfilterPlanData(results);
    } else {
      setfilterPlanData(planData);
    }
  };

  const filterPlanComponent = () => {
    return (
      <div className="bzo-leftside-filter-sec row">
        <div className="col-12 col-xl-12 order-1 order-xl-2">
          <div className="bzo-leftside-filter-container">
            <div className="bz-check-box-filter"></div>
            <div className="form-group bzo-search-service mb-0 order-2 order-xl-1 mt-xl-0">
              <span className="fa fa-search form-control-feedback"></span>
              <input
                className="form-control"
                placeholder={"Search Membership"}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
        {props?.planShortName && props?.planShortName !== null && (
        <>
          <div className="row">
            <div className={"d-flex justify-content-start mt-3"}>
              <h5 className="card-title bzo-primary-heading mb-0">
              Memberships
              </h5>
            </div>
          </div>
          <br />
        </>
      )}
      {planLoading ? (
        <BuzopsLoader type={"list"} />
      ) : planData.length === 0 ? (<>
       {props?.planShortName  && !props?.ShortCodeStatus && !props?.PromotionId ?  <div className={"bzo-services-container border-0 text-muted"}>
        The requested subscription is no longer accessible to use.
        </div>:
        <div className={"bzo-services-container border-0 text-muted"}>
          No Available Memberships
        </div>}
      </>) : (
        <>
        {props?.planShortName  && !props?.ShortCodeStatus && !props?.PromotionId ?  <div className={"bzo-services-container border-0 text-muted"}>
        The requested subscription is no longer accessible to use.
        </div>: <div className="row">
            <div className="col-xl-12">{showPlansListComponent()}</div>
            <Auth
              {...props}
              handleCloseLogin={() => handleCloseLogin()}
              showComponent={loginStatus}
              key={loginStatus.toString()}
            />
          </div>}
         
        </>
      )}
    </>
  );
};

export default withNotification(PurchasePlanList);
