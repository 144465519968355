import { RequestType, StatusCode } from "utils/constants";
import axios from "axios";
interface IOptions {
  successCallBack?: any;
  errorCallBack?: any;
  setLoading?: any;
}

class ApiClient  {
  private static instance: ApiClient;

  private isSuccessResponse = (status: any): boolean => {
    return (
      status === StatusCode.Ok ||
      status === StatusCode.Created ||
      status === StatusCode.Accepted ||
      status === StatusCode.NoContent ||
      status === StatusCode.MultipleChoices ||
      status === StatusCode.Gone
    );
  };

  public static getInstance() {
    if (!ApiClient.instance) {
      ApiClient.instance = new ApiClient();
    }
    return ApiClient.instance;
  }

  private async handleRequest(
    type: RequestType,
    endpoint: string,
    payload?: any,
    options?: IOptions
  ) {
    const url = `${process.env.REACT_APP_API_URL}/api/${endpoint}`;

    let response;
    switch (type) {
      case RequestType.GET:
        response = await axios.get(url);

        break;

      case RequestType.POST:
        response = await axios.post(url, payload);
        break;

      case RequestType.PATCH:
        response = await axios.patch(url, payload);
        break;

      case RequestType.PUT:
        response = await axios.put(url, payload);
        break;

      case RequestType.DELETE:
        response = await axios.delete(url);
        break;
    }

    const responseHandler = this.isSuccessResponse(response?.status)
      ? this.handleSuccess
      : this.handleError;

    return responseHandler(response, {
      ...options,
    });
  }

  private handleSuccess(response: any, options: IOptions) {
    if (typeof options?.successCallBack === "function") {
      options.successCallBack(response);
    }

    return { data: response.data, headers: response.headers };
  }

  private handleError(response: any, options: IOptions) {
    switch (response?.status) {
      case StatusCode.InternalServerError:
      case StatusCode.BadRequest:
      case StatusCode.Conflict:
      case StatusCode.NotFound: {
        break;
      }

      case StatusCode.Forbidden: {
        break;
      }

      case StatusCode.Unauthorized: {
        break;
      }
      case StatusCode.ClientClosedRequest:
      case StatusCode.TooManyRequests: {
        break;
      }
    }

    if (typeof options?.errorCallBack === "function")
      options.errorCallBack(response);
    return { data: response?.data || [], headers: response?.headers };
  }

  get = async (url: string, options?: IOptions) => {
    return await this.handleRequest(RequestType.GET, url, {}, options);
  };

  post = async (url: string, payload: any, options?: IOptions) => {
    return await this.handleRequest(RequestType.POST, url, payload, options);
  };

  patch = async (url: string, payload: any, options?: IOptions) => {
    return await this.handleRequest(RequestType.PATCH, url, payload, options);
  };

  put = async (url: string, payload: any, options?: IOptions) => {
    return await this.handleRequest(RequestType.PUT, url, payload, options);
  };

  delete = async (url: string, options?: IOptions) => {
    return await this.handleRequest(RequestType.DELETE, url, {}, options);
  };
}

export const useApiClient = () => {
  const axiosRequest = ApiClient.getInstance();
  return { axiosRequest };
};
