import React, { useEffect, useState } from "react";
import { Card, CardBody } from "@progress/kendo-react-layout";
import moment from "moment";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { ListView } from "@progress/kendo-react-listview";
import {
  AutoComplete,
  DropDownList,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { CheckInService } from "services/checkin/index.service";
import TimeZoneSetup from "utils/time-zone-setup";
import { GetLocalStore } from "utils/storage";
import { ClassService } from "services/class/index.service";
import CheckinFromLiveStream from "./CheckinFromLiveStream";
import { FaWalking } from "react-icons/fa";
import { MdDownload } from "react-icons/md";
import ExcelExport from "atoms/ExcelExport/ExcelExport";
import { getFullImageUrl } from "utils";
import BuzopsButton from "generic-components/BuzopsButton";
import CheckinsConstants from "./contants";
import useClubCheckinv2, {
  ActionType,
  IClubCheckin,
} from "services/access-control/clubCheckinv2.service";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { SecuritySystemType } from "utils/constants";
import CheckinsReport from "components/reports/Checkins/Components/CheckinsReport.view";
import BuzopsWindow from "generic-components/BuzopsWindow";

const LiveStream = (props: any) => {
  const [clientSearchLoading, setClientSearchLoading] = useState(false);
  const [clientsList, setClientsList] = useState<any>([]);
  const [activityList, setActivityLogList] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [openLogs, setOpenLogs] = useState<any>(false);
  const [selectedClient, setSelectedClient] = useState<any>({
    type: "search",
    value: "",
  });
  const [selectedFilterOption, setSelectedFilterOption] = useState<any>({
    id: 0,
    text: `Current Day`,
  });
  const [selectedCheckInTypeOption, setSelectedCheckInTypeOption] =
    useState<any>({
      id: 1,
      text: `All Check-Ins`,
    });

  const container = React.useRef<HTMLDivElement>(null);
  const _exporter = React.createRef();
  const { events, startConnection, notificationState } = useClubCheckinv2(
    GetLocalStore("tenantUserSession")?.ExternalClubId?.toUpperCase()
  ); //pass the clubId here
  const checkinService = new CheckInService();
  const [isTimeMachineInitialised, setIsTimeMachineInitialised] =
    useState(false);

  const updateNewClubCheckin = (checkIn: IClubCheckin) => {
    console.log(
      `New ClubCheckin Found: Use Token to call API: ${GetLocalStore("token")}`
    );
    console.log(
      `@@@ New Checkin Received from SignalR: ${JSON.stringify(checkIn)}`
    );
    addUserInfo(checkIn);
  };

  const excelExport = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  const onChangeFilter = (e: any) => {
    setSelectedFilterOption(e);
  };
  const onChangeCheckInTypeFilter = (e: any) => {
    setSelectedCheckInTypeOption(e);
  };

  const addUserInfo = async (msg: any) => {
    console.log("msg", msg);
    msg.Occured = msg.OccuredFormatted;
    msg.ScheduledStartTime = msg.ScheduledStartTimeFormatted;
    setActivityLogList((prev: any) => {
      prev.unshift(msg);
      return [...prev];
    });

    setData((prev: any) => {
      prev.unshift(msg);
      return [...prev];
    });
  };
  useEffect(() => {
    if (
      notificationState.action === ActionType.Reconnecting &&
      props.securitySystemType !== undefined
    ) {
      getLiveStream();
    }
  }, [notificationState, props.securitySystemType]);

  useEffect(() => {
    document.title = "Buzops - Live Stream";
  }, []);

  const getLiveStream = async () => {
    setLoading(true);

    let req: any = {
      PageSize: 10000,
      PageNumber: 0,
      FromDate: moment().format("YYYY-MM-DD") + "T00:00:00",
      ToDate: moment().format("YYYY-MM-DD") + "T23:59:59",
      ...(props.securitySystemType ===
        SecuritySystemType.ProximityReaderSystem && {
        AccessPoint: props.configuredEntries.filter(
          (entry: any) => entry?.UsedForSiteAccess === true
        )?.[0]?.Id,
      }),
    };
    if (selectedCheckInTypeOption?.id !== 1) {
      req = { ...req, CheckinType: selectedCheckInTypeOption?.id };
    }
    if (selectedClient.type === "select") {
      const UserId = selectedClient?.value?.UserId || null;
      if (UserId) {
        req = { ...req, UserId };
      }
    }

    let fromTime = null;
    let toTime = null;
    if (selectedFilterOption?.id) {
      console.log(selectedFilterOption);
      fromTime = moment();
      fromTime.subtract(selectedFilterOption?.id, "hour");
      toTime = moment().format("YYYY-MM-DDTHH:mm:ss");
    }
    if (fromTime && toTime) {
      req = {
        ...req,
        FromDate: fromTime.format("YYYY-MM-DDTHH:mm:ss"),
        ToDate: toTime,
      };
    }
    const url =
      props.securitySystemType === SecuritySystemType.ProximityReaderSystem
        ? `HardwareAccess/Checkins`
        : `SimpleAccess/Checkins`;
    const res = await checkinService.getCheckins(url, req);
    setActivityLogList(res?.Item?.Result);
    setData(res?.Item?.Result.slice(0, 10));
    setLoading(false);
  };

  const getCheckinMessage = (type: number) => {
    switch (type) {
      case 0:
        return "Location";
      case 2:
        return "Group Class";
      case 3:
        return "One-on-One";
      default:
        return "";
    }
  };

  const getExcelData = (data: any) => {
    return data.map((row: any) => {
      return {
        ...row,
        Type: getCheckinMessage(row.Type),
        TrainerName: row.TrainerName,
      };
    });
  };

  const filterData = (data: any) => {
    if (selectedCheckInTypeOption.id === 1) {
      return data;
    } else {
      return data?.filter((d: any) => d.Type === selectedCheckInTypeOption.id);
    }
  };
  const renderActivity = (props: any) => {
    let className = "";
    const dataItem = props.dataItem;
    if (dataItem?.Type === 0) {
      return (
        <div className="card ba-activity-card bz-card">
          <div className="card-body livestream_list liststrem">
            <div className="row col-md-12">
              <div
                className="col-md-2 d-flex align-items-center justify-content-center py-2 mobile-align"
                style={{ minWidth: "fit-content" }}
              >
                <div className="ba-date-container">
                  <span className="ba-time livestream_time">
                    {" "}
                    {moment(dataItem.Occured).format("hh:mm:ss A")}
                  </span>
                  <span className="ba-date">
                    {moment(dataItem.Occured).format("DD")}

                    <span className="ba-date-month">
                      {" "}
                      {moment(dataItem.Occured).format("MMM")}
                    </span>
                  </span>
                </div>
              </div>
              <div className="col-md-5 d-flex align-items-center py-2 border-mobile">
                <div className="livestream_client ">
                  <div className="media">
                    <img
                      className="bz-user-img align-self-center mr-2"
                      width={75}
                      height={75}
                      src={
                        dataItem?.Photo?.trim()
                          ? getFullImageUrl(dataItem.Photo)
                          : "/images/user-img.png"
                      }
                    />
                    <div className="media-body">
                      <div>
                        <div className="d-flex">
                          <h6 className="mt-3 mb-0 customername">
                            {dataItem?.FullName}
                          </h6>
                          <div className="bz-appoinments-card-status"></div>
                        </div>
                        <p className="bz-dateTime mb-1 clubname">
                          {dataItem?.ClubName}
                        </p>
                        <span className="checkedinlable">Checked-in for</span>
                        <p className="checkedinfor pb-0">
                          {" "}
                          {getCheckinMessage(dataItem?.Type)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-5 d-flex align-items-center py-2 ">
                <div className="livestream_info">
                  <h5 className={`card-title mb-0 ${className}`}>
                    <span className="livestream_info_walk">
                      <FaWalking />
                    </span>
                    Direct Walk-in
                  </h5>
                  {/* <h6 className="card-subtitle text-muted mb-2">
                      {dataItem?.ClubName}
                    </h6> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="card ba-activity-card bz-card">
        <div className="card-body liststrem">
          <div className="row col-md-12">
            <div
              className="col-md-2 d-flex align-items-center justify-content-center py-2  mobile-align"
              style={{ minWidth: "fit-content" }}
            >
              <div className="ba-date-container">
                <span className="ba-time livestream_time">
                  {" "}
                  {moment(dataItem.Occured).format("hh:mm:ss A")}
                </span>
                <span className="ba-date">
                  {moment(dataItem.Occured).format("DD")}
                  <span className="ba-date-month">
                    {" "}
                    {moment(dataItem.Occured).format("MMM")}
                  </span>
                </span>
              </div>
            </div>
            <div className="col-md-5 d-flex align-items-center py-2 border-mobile">
              <div className="livestream_client">
                <div className="media">
                  <img
                    className="bz-user-img align-self-center mr-2"
                    width={75}
                    height={75}
                    src={
                      dataItem?.Photo?.trim()
                        ? getFullImageUrl(dataItem?.Photo)
                        : "/images/user-img.png"
                    }
                  />
                  <div className="media-body">
                    <div>
                      <div className="d-flex">
                        <h6 className="mt-3 mb-0 customername">
                          {dataItem?.FullName}
                        </h6>
                        <div className="bz-appoinments-card-status"></div>
                      </div>
                      <p className="bz-dateTime  mb-0 clubname">
                        {dataItem?.ClubName}
                      </p>
                      <span className="checkedinlable">Checked-in for</span>
                      <p className="checkedinfor pb-0">
                        {" "}
                        {getCheckinMessage(dataItem?.Type)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5 d-flex align-items-center py-2">
              <div className="media livestream_info">
                <img
                  className="bz-user-img align-self-center mr-2"
                  width={50}
                  src={
                    dataItem?.TrainerPhoto?.trim()
                      ? getFullImageUrl(dataItem.TrainerPhoto)
                      : "/images/user.png"
                  }
                />
                <div className="media-body">
                  <div>
                    <div className="d-flex">
                      <h6 className="mt-0 mb-0 customername">
                        {dataItem?.TrainerName}
                      </h6>
                    </div>
                    <p className="bz-dateTime clubname mb-0">
                      {getCheckinMessage(dataItem?.Type)}
                    </p>
                    <p className="bz-service-type mb-0">
                      {/* <span>{`2 of 10`}</span> */}
                      <span className="mr-1 text-capitalize  d-flex">
                        {dataItem?.EntityName}
                      </span>
                    </p>
                    <p className="bz-dateTime text-muted mb-0">
                      {/* <small className="mr-1">{`${moment().format("MM/DD/YYYY")}`}</small> */}
                      {dataItem?.ScheduledStartTime}
                    </p>
                    {/* <span className="bz-member-info text-muted bz-fs-12px text-capitalize">
                                    <ClientNameWithMemberType memberType={item?.MemberType} clientName={item?.MemberName} />
                                </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  async function autoCompleteClients(str: string) {
    const req = {
      Where: {
        SearchKeyword: str,
        IsGloablSearch: true,
      },
    };
    setClientSearchLoading(true);
    const clientservice = new ClassService();
    const result = await clientservice.autoCompleteClients(req);
    setClientSearchLoading(false);
    setClientsList(result);
  }

  const itemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const itemChildren = `${itemProps?.dataItem?.FullName} ${itemProps?.dataItem?.Email}`;

    return React.cloneElement(li, li.props, itemChildren);
  };
  const handleClientSelecton = (e: any) => {
    if (e.syntheticEvent?.key === "Enter") {
      if (clientsList?.length > 0) {
        setSelectedClient({
          ...selectedClient,
          type: "select",
          value: clientsList[0],
        });
      }
      return true;
    }
    setSelectedClient({
      ...selectedClient,
      type: "search",
      value: e.value,
    });
    if (e.syntheticEvent?.type === "change" && e.value.length >= 3) {
      autoCompleteClients(e.value);
    }
    if (e.syntheticEvent?.type === "click") {
      // autoCompleteClients(e.value)
      setSelectedClient({
        ...selectedClient,
        type: "select",
        value: e.value,
      });
    }
  };

  const renderActivityList = () => {
    if (loading) {
      return <BuzopsLoader type={"list"} />;
    }
    if (data?.length === 0) {
      return (
        <>
          <div className="bz-norecords-available">No Activities </div>
        </>
      );
    }
    return (
      <div className="livestream-cont">
        <div className="card ba-activity-card livestream_head d-none">
          <div className="card-body ">
            <div className="ba-activity-row">
              <div className="ba-activity-coloumn-1 coloumn">
                <strong>Time & Date</strong>
              </div>
              <div className="ba-activity-coloumn-2 coloumn">
                <strong>Checked-In-For</strong>
              </div>
              <div className="ba-activity-coloumn-2 coloumn">
                <strong>Information</strong>
              </div>
              <div className="ba-activity-coloumn-3 coloumn">
                <strong>Client</strong>
              </div>
            </div>
          </div>
        </div>
        <ExcelExport
          data={getExcelData(activityList) || []}
          fileName={`Report for ${new Date().getFullYear()}.xlsx`}
          _exporter={_exporter}
          columns={CheckinsConstants.LiveStreamExcelColumns}
        />
        <ListView
          data={filterData(data)}
          onScroll={scrollHandler}
          // data={data}
          // item={renderActivity}
          // item={(props) => renderActivity(props)}

          item={renderActivity}
          className={"bzc-checkins-list"}
          style={{ width: "100%", height: 800 }}
        />
      </div>
    );
  };

  const scrollHandler = (event: any) => {
    const e = event.nativeEvent;
    if (
      e.target.scrollTop + 2 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      const moreData = activityList.slice(data.length, data.length + 10);
      if (moreData.length > 0) {
        setData(data.concat(moreData));
      }
    }
  };

  useEffect(() => {
    startConnection();
    events(updateNewClubCheckin);
  }, []);

  const getTenantTime = async () => {
    const res = await checkinService.getTenantTime();
    const tenantData = GetLocalStore("tenantUserSession");
    TimeZoneSetup(res?.Item, tenantData);
    setIsTimeMachineInitialised(true);
  };

  useEffect(() => {
    getTenantTime();
  }, []);

  useEffect(() => {
    const tenantData = GetLocalStore("tenantUserSession");
    if (
      tenantData &&
      isTimeMachineInitialised &&
      props.securitySystemType !== undefined
    ) {
      getLiveStream();
    }
  }, [
    selectedCheckInTypeOption,
    selectedClient,
    selectedFilterOption,
    isTimeMachineInitialised,
    props.securitySystemType,
  ]);

  return (
    <div>
      <Card className="site-checkin-livestream">
        <CardBody>
          <div className="filter-container pb-2 livestream-filter-cont">
            <div className="live-stream-mobile w-100">
                <div className="bz-row">
                <div className="filter-options col-lg-7 col-xl-8 pl-0 mb-md-3 mb-lg-0 pr-0 pr-lg-2">
                <DropDownList
                  defaultValue={selectedCheckInTypeOption}
                  textField="text"
                  dataItemKey="id"
                  data={CheckinsConstants.CheckinTypeOptions}
                  value={selectedCheckInTypeOption}
                  onChange={(e) => onChangeCheckInTypeFilter(e.target.value)}
                />
                <DropDownList
                  defaultValue={selectedFilterOption}
                  textField="text"
                  dataItemKey="id"
                  data={CheckinsConstants.LiveStreamFilterOptions}
                  value={selectedFilterOption}
                  onChange={(e) => onChangeFilter(e.target.value)}
                />
                <AutoComplete
                  data={clientsList}
                  style={{ width: "280px" }}
                  className="select_clientdrop"
                  itemRender={itemRender}
                  value={
                    selectedClient?.type === "search"
                      ? selectedClient?.value
                      : selectedClient?.value?.FullName
                  }
                  onChange={(e: any) => handleClientSelecton(e)}
                  placeholder={"Select Client"}
                  loading={clientSearchLoading}
                  listNoDataRender={() => (
                    <span className={"bzo-noData-found"}>
                      {clientSearchLoading
                        ? "Searching for Client..."
                        : "Client is not available please add new client"}
                    </span>
                  )}
                />
              </div>
              <div className="filter-options filter-right col-lg-5 col-xl-4 px-0">
                {/* <div style={{width:'20%'}}>&nbsp;</div> */}
                <div className="input-group justify-content-end">
                  <CheckinFromLiveStream {...props} />
                  <div className="input-group-append ml-3">
                    <div className="export-btn-container">
                      {/* <DropDownButton
                  text="Export"
                  onItemClick={(event) => onItemClickHandler(event.item.key)}
                  // onItemClick={(e: any) => onCardOrBankAdd(e, item?.ParentUserMemberId)}
                  items={items}
                  look="flat"
                  className="btn btn-link add-new-btn export-btn"
                /> */}
                      <span onClick={excelExport} className="download-icon">
                        <MdDownload title="Download" />
                      </span>
                    </div>
                  </div>
                  <BuzopsButton
                    label="Logs"
                    onClick={() => setOpenLogs(true)}
                    className="ml-3 mr-0"
                  />
                </div>
              </div>
                </div>
            </div>
          </div>
          <div ref={container}>{renderActivityList()}</div>
        </CardBody>
      </Card>
      <NotificationGroup
        style={{
          right: 5,
          top: 80,
          alignItems: "flex-end",
          // flexWrap: "wrap-reverse",
        }}
      >
        {notificationState?.msg && (
          <Notification
            key="info"
            type={{ style: notificationState.type as any, icon: true }}
          >
            <span className="text-uppercase">{notificationState?.msg}</span>
          </Notification>
        )}
      </NotificationGroup>
        <BuzopsWindow
        key={openLogs.toString()}
        reduceWidth={10}
        onWindowClose={() => {
          setOpenLogs(false)
        }}
        title={"Manage Chargeback and Returns"}
        className={"chargeback-global-window bz-checkin-logs-window"}
        visibility={openLogs}
    >
          <div className="Self-CheckIn">
            <CheckinsReport securitySystemType={props.securitySystemType} onWindowClose={() => {
            setOpenLogs(false)
        }} configuredEntries={props?.configuredEntries} />
          </div>
        </BuzopsWindow>
    </div>
  );
};

export default LiveStream;
