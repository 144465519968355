import React, { useEffect, useState } from "react";
import { Button, Chip, ChipList, ChipProps } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import BuzopsLoader from "generic-components/BuzopsLoader";
import moment from "moment";
import { PackageService } from "services/package/index.service";
import { TenantService } from "services/tenant/index.service";
import { renderFrequencyItem } from "components/package/PurchasePackageList";
import { CheckUserMemberStatus, CurrencyFormat, CustomToFixed, checkHasTaxEnable } from "utils";
import { filterBy } from "@progress/kendo-data-query";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import BuzopsButton from "generic-components/BuzopsButton";
import withNotification from "components/Hoc/withNotification";
import PackageCoupons from "../PackageCoupons";
import { ManageTaxesService } from "services/managetaxes/index.service";
import { TooltipContentPackageService } from "components/checkout/OrderSummary";
import PurchaseClassList from "components/class/PurchaseClassList";
import { Grid,GridColumn as Column } from "@progress/kendo-react-grid";
import { PackageFrequencyEnum, PackageInstanceClassMappingStatus, PackageInstanceStatus, PackageInstanceStatusEnum } from "utils/form-utils";
import { usePackageUtilization } from "components/package/PackageUtilization.hook";
import { Error } from "@progress/kendo-react-labels";
import PackageUtilization from "components/package/PackageUtilization";
import { BiEdit } from "react-icons/bi";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Notification as Notify } from "@progress/kendo-react-notification";
import ReactTooltip from 'react-tooltip';

const PackageOverView = (props: any) => {
  const [loading, setLoading] = useState<any>(true);
  const [userData, setUserData] = useState<any>(props?.userData);
  const [removeCouponValue, setRemoveCouponValue] = useState<any>(null);
  const [couponValue, setCouponValue] = useState<any>(null);
  const [showCoupon, setShowCoupon] = useState<boolean>(false);
  const [CouponsToAdd, setCouponsToAdd] = useState<any>([]);
  const [OriginalCouponsToAdd, setOriginalCouponsToAdd] = useState<any>([]);
  const [selectedPackage, setSelectedPackage] = useState<any>(null);
  const [packagesToUse, setPackagesToUse] = useState<any>(null);
  const [couponsList, setCouponsList] = useState<any>([]);
  const tenantService = new TenantService();
  const [couponAddVisible, setCouponAddVisible] = useState<any>(false);
  const [couponRemoveVisible, setCouponRemoveVisible] = useState<any>(false);
  const [dialogLoading, setDialogLoading] = useState<any>(false);
  const [rolloverLoading, setRollOverLoading] = useState<any>(false);
  const [PredictedTaxes, setPredictedTaxes] = useState<any>([]);
  const [PredictedTotalTaxAmount, setPredictedTotalTaxAmount] = useState<number>(0);
  const [groupDialog, setGroupDialog] = useState(false);
  const [showUtilizations, setShowUtilizations] = useState(false);
  const [showRolloverDialog, setShowRollOverDialog] = useState(false);
  const [selectedBillingPeriod, setSelectedBillingPeriod] = useState<any>(null);
  const [rolloverAppointment, setRolloverAppointment] = useState<any>(0);
  const {
    loading: utilizationLoading,
    billingPeriods,
    handleChange,
    selectedPeriod,
    fetchBillingAPI
  } = usePackageUtilization(props);

  useEffect(() => {
    let memberId = props?.userData?.UserMemberId;
    if (memberId) {
      fetchAllApis(memberId)
    }

  }, []);
  const fetchAllApis=async(memberId:any)=>{
    const taxes=await fetchTaxes() 
    fetchUserDetails(memberId)
    fetchPackageSummary(taxes);
    fetchPackagesToUse()
    getCouponData()
  }


  const fetchTaxes = async () => {
    const req = {};
    const taxService= new ManageTaxesService()
    const res = await taxService.getTaxes(req);
    setPredictedTaxes(res);
    return res
  };


  const fetchPackageSummary = async (taxes=PredictedTaxes) => {
    const req = {
      UserMemberId: props?.userData?.UserMemberId,
      PackageInstanceId: props?.selectedPackageId,
    };
    setLoading(true);
    const service = new PackageService();
    const getPackageDetails = await service.packageInstanceFilter(req);
    const data={...getPackageDetails?.Items[0],Occurrence:getPackageDetails?.Items[0]?.Occurrs}
    props?.setSelectedSubscription(data);
    const statusDescription=PackageInstanceStatus?.filter((i:any)=>i?.id===data?.Status)?.[0]?.text
    props?.setSelectedSubscriptionStatus(statusDescription);
    setSelectedPackage(data);
    let couponsArray = [...data?.CompletedCoupons, ...data?.ActiveCoupons];
    setCouponsList(couponsArray || []);
    setShowCoupon(false);
    setCouponValue(null);
    handleTaxCalculation(data?.RecurringAmount,taxes)
    setLoading(false);
  };

  const fetchPackagesToUse = async () => {
    let startDate = moment().format("YYYY-MM-DD") + "T00:00:00";
    let ToDate = moment().add(1, "year").format("YYYY-MM-DD") + "T23:59:59";
    const req = {
      UserMemberId: props?.userData?.UserMemberId,
      FromDate: startDate,
      ToDate,
    };
      const service = new PackageService();
      const result = await service.packageInstanceToUse(req);
      const dataItem= result?.find((i:any)=>i?.PackageInstanceId===props?.selectedPackageId)
      setPackagesToUse(dataItem || null);
  };

  
  const handleTaxCalculation=(purchaseAmt:number,TaxItems=PredictedTaxes)=>{
    const TaxesArray=TaxItems.map((i:any)=>{
      const amount=purchaseAmt*i?.Percentage/100;
      const calcAmt=CustomToFixed(amount,2)
        return {
          "TaxId": i?.TaxId,
          "TaxName": i?.TaxName,
          "Percentage": i?.Percentage,
          "Amount": calcAmt
        }
    })
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
          return acc + currentValue?.Amount
    }, 0);
      setPredictedTaxes(TaxesArray)
      setPredictedTotalTaxAmount(taxAmt)
  }
  const getCouponData = async () => {
    const req = {
      UserMemberId: props?.userData?.UserMemberId,
      EntityType: 7,
      EntityId: props?.selectedPackageId,
      CouponType:1
    };
    const res = await tenantService.AvailableCouponsToApply(req);
    setCouponsToAdd(res);
    setOriginalCouponsToAdd(res)
  };

  const fetchUserDetails=async (userMemberId:any)=>{
    setLoading(true)
    const service = new TenantService();
    const res=await service?.getUserInfo(userMemberId)
    setLoading(false)
    const {UserMemberId,UserId,MemberNumber,User:{Email,FirstName,FullName,LastName,Photo,DateOfBirth,Address:{Phone}}}=res
    const data={
      UserMemberId,UserId,Email,FirstName,FullName,LastName,Photo,DateOfBirth,Phone,MemberNumber
    }
    setUserData(data)
  }
  const handleCoupon = (val: any) => {
    setCouponValue(val);
  };

  const CouponfilterChange = (e: any) => {
    const filteredData = CouponFilterData(e.filter);
    setCouponsToAdd(filteredData);
  };

  const CouponFilterData = (filter: any) => {
    const data = OriginalCouponsToAdd.slice();
    return filterBy(data, filter);
  };

  const TooltipContentTemplate = (props: any) => {
    if (props?.data?.Classes?.length === 0) {
      return <div>{"No Used Appoinments"}</div>;
    }
    return (
      <table className="table table-sm table-borderless">
        {props?.data?.Classes?.map((item: any) => (
          <tr className="border-0 p-0" style={{ width: "100%" }}>
            <td>{item?.Name}</td>
            <td className="text-right">{item?.UsedSchedules}</td>
          </tr>
        ))}
      </table>
    );
  };

  const handleAddCouponToPackage = async (val: boolean) => {
    if (val) {
      await AddCouponToPackage();
    }
    toggleAddCouponDialog();
  };

  const AddCouponToPackage = async () => {
    const req = { ...couponValue, EntityType: 5, EntityId: props?.selectedPackageId,UserMemberId: props?.userData?.UserMemberId};
    setDialogLoading(true);
    const result = await tenantService?.AddCouponToExistingPlan(req);
    setDialogLoading(false);
    if (result?.ResponseCode === 100) {
      const successMsg = `The Coupon Applied Successfully`;
      props?.handleNotificationMessage(successMsg, "success");
      await fetchPackageSummary();
    } else {
      const errorMsg = result?.ErrorMessages?.[0] || "Error In Applying Coupon";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const handleRemoveCouponFromPackage = async (val: boolean) => {
    if (val) {
      await handleRemoveCoupon();
    }
    toggleRemoveCouponDialog();
  };
  const handleRemoveCoupon = async () => {
    const {MemberCouponId} = removeCouponValue;
    const req={
      MemberCouponId,
      EntityType:5,
      UserMemberId: props?.userData?.UserMemberId,
      EntityId: props?.selectedPackageId,
    }
    setDialogLoading(true);
    const result = await tenantService?.DeActivateMemberCoupon(req);
    setDialogLoading(false);
    if (result?.ResponseCode === 100) {
      const successMsg = result?.SuccessMessage;
      props?.handleNotificationMessage(successMsg, "success");
      await fetchPackageSummary();
    } else {
      const errorMsg = result?.ErrorMessages?.[0] || "Error In Removing Coupon";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const toggleAddCouponDialog = () => {
    if(!couponValue){
      const errorMsg =  "Please select coupon";
      props?.handleNotificationMessage(errorMsg, "error");
      return;
    }
    if (!props?.staffPage && !CheckUserMemberStatus(userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    setCouponAddVisible(!couponAddVisible);
  };
  const toggleRemoveCouponDialog = () => {
    setCouponRemoveVisible(!couponRemoveVisible);
  };

  const handleRemoveCouponItem = (dataItem: any) => {
    setRemoveCouponValue(dataItem);
    toggleRemoveCouponDialog();
  };
  
  const renderAssociatedGS = () => {
    if (loading) {
      return <BuzopsLoader type={"list"} />;
    }
    if (!selectedPackage?.AssociatedClasses || selectedPackage?.AssociatedClasses?.length === 0) {
      return "No Group to Reedem";
    }

    return (
      <div className="row bz-mt-2rem bz-mb-2_5rem">
        <div className="bz-class-description bz-packages-tags-new bz-redeem-package">
          <ChipList
            style={{ width: "100%", flexWrap: "wrap" }}
            defaultData={selectedPackage?.AssociatedClasses}
            chip={(props1: ChipProps) => {
              return (
                <Chip
                  style={{
                    width: "auto",
                    borderRadius: "11px",
                    fontSize: "14px",
                    height: "22px",
                    marginBottom: "5px",
                  }}
                  type={"success"}
                  disabled={true}
                  text={props1?.dataItem?.Name}
                  removable={false}
                />
              );
            }}
          />
        </div>
      </div>
    );
  };
  const DateCell = (props: any) => {
    if(props?.dataItem?.IsCurrentBillingPeriod && props?.field==="RenewalOn" && selectedPackage?.CancelledOn){
      return <td>NA</td>
    }
    return (
      <td>
        {props?.dataItem[props?.field]
          ? moment(props?.dataItem[props?.field]).format("L")
          : "NA"}
      </td>
    );
  };
  const handleEditRollOver=(dataItem:any)=>{
    setSelectedBillingPeriod(dataItem)
    setShowRollOverDialog(true)
    setRolloverAppointment(dataItem?.RollOverSchedules)
  }
  const handleUpdateRollOver=async()=>{
    let mininmumRolloverAppts=0
    if(selectedBillingPeriod){
      const totalUsedSchedules=selectedBillingPeriod?.UsedRollOverSchedules+ selectedBillingPeriod?.UsedSchedules
      const check=totalUsedSchedules-selectedBillingPeriod?.Schedules
      if(check > 0){
        mininmumRolloverAppts=check
      }
    }
    if(rolloverAppointment < mininmumRolloverAppts){
      return
    }
    const req={
      "PackageInstanceClassMappingId": selectedBillingPeriod.PackageInstanceClassMappingId,
      "RollOverSchedules": rolloverAppointment,
    }
    const service = new PackageService();
    setRollOverLoading(true)
    const res= await service.UpdateRollOverAppoinments(req)
    setRollOverLoading(false)
    if (res?.ResponseCode === 100) {
      const successMsg = `Rollover Appointment Updated Successfully`;
      props?.handleNotificationMessage(successMsg, "success");
      handleCancelRollOver()
      await fetchPackageSummary();
      await fetchBillingAPI()
    } else {
      const errorMsg = res?.ErrorMessages?.[0] || "Error In Updating  Rollover Appointment";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  }

  const handleCancelRollOver=()=>{
    setSelectedBillingPeriod(null)
    setShowRollOverDialog(false)
    setRolloverAppointment(0) 
  }

  const renderSubscriptionDetails = () => {
    if (loading) {
      return <BuzopsLoader type={"list"} />;
    }
    if (selectedPackage === null) {
      return "No Subscriptions Data Found";
    }

    const {
      Name,
      Status,
      PurchasedOn,
      Cost,
      SessionType,
      ExpireOn,
      Schedules,
      RecurringAmount,
      SalePerson,
      Frequency,
      CancollectTaxes,
      CancelledOn
    } = selectedPackage;
    let expiryAfter=null
    if(SessionType===2 && Frequency===1){
      expiryAfter = moment(ExpireOn).format("L");
    }

    return (
      <>
      <div className="bz-mt-2rem bz-mb-2_5rem">
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Name</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{Name}</div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Purchased On</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {PurchasedOn ? moment(PurchasedOn).format("L") || "-" : "-"}
          </div>
        </div>

        <div className="bz-content-text-row  show-tax-content-row">
          <div className="bz-content-title text-right">
            <div className="d-flex flex-column justify-content-end">
            <span>Total Cost {CancollectTaxes && <span>*</span>}</span>
              {CancollectTaxes && <small style={{fontSize:'10px',color:'#777'}}>(Exclusion of Surcharges)</small>}
            </div>
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{`${CurrencyFormat(Cost)}`}</div>
        </div>
        <div className="bz-content-text-row  show-tax-content-row">
          <div className="bz-content-title text-right">
            <div className="d-flex flex-column justify-content-end">
            <span>Total Appointments</span>
            </div>
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{SessionType===2 ? "Unlimited":Schedules}</div>
        </div>

        <div className="bz-content-text-row show-tax-content-row">
          <div className="bz-content-title text-right">
            <div className="d-flex flex-column justify-content-end">
              <span>Recurring Amount {checkHasTaxEnable() && CancollectTaxes && RecurringAmount > 0 && <span>*</span>}</span>
              {checkHasTaxEnable() && CancollectTaxes && RecurringAmount > 0 && <small style={{fontSize:'10px',color:'#777'}}><Tooltip
                content={(tooltipProps: any) => {
                  return (
                    <TooltipContentPackageService
                      title={tooltipProps.title}
                      data={PredictedTaxes}
                      TotalTaxAmount={PredictedTotalTaxAmount}
                    />
                  );
                }}
                tooltipClassName="bz-tooltip-table"
              >
                <a
                  title="Surcharges"
                  style={{
                    borderBottom: "1px dotted #0d6efd",
                    cursor: "pointer",
                  }}
                >
                  {"(Surcharges)"}
                </a>
              </Tooltip></small>}
            </div>
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {Frequency ===PackageFrequencyEnum.ONETIME ? `NA`: RecurringAmount ? `${CurrencyFormat(RecurringAmount)}` : CurrencyFormat(0)}
            {CancollectTaxes && RecurringAmount > 0 && <small className="pl-1">{`(Exclusion of Surcharges)`}</small>}
          </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Repeat Every</div>
          <span className="bz-colon text-center">:</span>
          <div
            className="bz-content-body"
            style={{ textTransform: "capitalize" }}
          >
            {Frequency === PackageFrequencyEnum.ONETIME
              ? "NA being One Time"
              : renderFrequencyItem(selectedPackage, expiryAfter)}
          </div>
        </div>
        {CancelledOn ?  <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Cancels On</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {ExpireOn ? moment(ExpireOn).format("L") || "NA" : "NA"}
          </div>
        </div>: <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Expires On</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {ExpireOn ? moment(ExpireOn).format("L") || "NA" : "NA"}
          </div>
        </div>}
       

        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Signed up by</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{SalePerson}</div>
        </div>
      </div>
      <div >
        <Grid
          data={billingPeriods}
          className="bzc-grid bzc-pkg-overview-grid"
          style={{maxHeight:'212px',overflow:'auto'}}
        >
          <Column
            field="EffectiveFrom"
            title="Start Date"
            cell={DateCell}
          />
          <Column
            field="RenewalOn"
            title="Next Renewal Date"
            cell={DateCell}

          />
          {SessionType===1 && Frequency !== PackageFrequencyEnum.ONETIME && <Column
            field="RollOverSchedules"
            title="Rollover Appointments"
            cell={(propData) => {
              if(propData?.dataItem?.BillingPeriod ===1){
                return <td>{`NA`}</td>
              }
              let check=false
              if(billingPeriods?.length >1 && billingPeriods?.find((i:any)=>i?.Status=== 2) && propData?.dataItem?.Status ===1 && Status===1 && props?.staffPage){
                check =true
              }
              return <td>
               {propData?.dataItem?.RollOverSchedules > 0 ? <span>{propData?.dataItem?.UsedRollOverSchedules > 0 ? <span className="btn-link" onClick={() => {
                setShowUtilizations((prev) => !prev);
                handleChange(propData?.dataItem);
              }} data-for={"appointment-link"} data-tip={"Used Rollover Appointments"}>{propData?.dataItem?.UsedRollOverSchedules}</span>:<span>{propData?.dataItem?.UsedRollOverSchedules}</span>} of {propData?.dataItem?.RollOverSchedules}</span>:<span>
               {propData?.dataItem?.RollOverSchedules}
               </span>
               }
               {check && <span className="cursor-pointer" data-for={"edit-rollover"} data-tip={"Edit Rollover Appointments"}><BiEdit color="#008DB9" size={'16px'} onClick={()=>handleEditRollOver(propData.dataItem)} /></span>}<ReactTooltip id={`edit-rollover`} place="top" type="dark" effect="float" html={true} /> </td>
            }}

          />}
          <Column
            field="RepeatEvery"
            title="Appointments"
            cell={(props) => {
              return <td>
                {props?.dataItem?.UsedSchedules > 0 ?
                <span><span className="btn-link" onClick={() => {
                setShowUtilizations((prev) => !prev);
                handleChange(props?.dataItem);
              }} data-for={"appointment-link"} data-tip={"Used Appointments"}>{props?.dataItem?.UsedSchedules}</span> of <span>{SessionType === 2 ? "Unlimited":props?.dataItem?.Schedules }</span><ReactTooltip id={`appointment-link`} place="top" type="dark" effect="float" html={true} /></span>:<span><span data-for={"appointment-link1"} data-tip={"Used Appointments"}>{props?.dataItem?.UsedSchedules}</span> of <span>{SessionType === 2 ? "Unlimited":props?.dataItem?.Schedules }</span><ReactTooltip id={`appointment-link1`} place="top" type="dark" effect="float" html={true} /></span>}
              
              </td>
            }}
          />
          <Column
            field="Status"
            title="Status"
            cell={(props) => {
              const statusItem=PackageInstanceClassMappingStatus?.filter((i:any)=>i?.id===props?.dataItem?.Status)?.[0]?.text
              return <td>{statusItem}</td>
            }}
          />
        </Grid>
        
      </div>
      </>
    );
  };

  const RenderUtilizedClasses = () => {
      return (
          <div className="d-flex flex-column">
           <h5 className="bz_fs_1_5">
                {" "}
                <button
                  className="btn btn-primary bz-back-btn btn-sm"
                  onClick={() => setShowUtilizations(false)}
                >
                  <i className="fas fa-arrow-left"></i>
                </button>
                &nbsp;{"Package Utilization"}
              </h5>
            <PackageUtilization {...props} selectedPeriodData={selectedPeriod} loading={utilizationLoading} billingPeriods={billingPeriods} />
          </div>
      )
  }
  const renderGroupClassesDialog = () => {
    let memberId = props?.userData?.UserMemberId;
    return (
      <>
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-primary bz-back-btn btn-sm mr-2"
            onClick={() => toggleGroupDialog()}
          >
            <i className="fas fa-arrow-left"></i>
          </button>
          <h5>
          {`${selectedPackage?.Name}`}
          </h5>
        </div>
        <PurchaseClassList
          page={"inhouse"}
          userMemberId={memberId}
          handleWindow={() => toggleGroupDialog()}
          selectedPackage={selectedPackage}
          staffPage={props?.staffPage}
        />
      </>
    );
  };
  const checkBillingCycleValidation=()=>{
    const records=packagesToUse?.BillingPeriods?.filter((i:any)=>i?.SchedulesToUse > 0)
    if(records?.length > 0){
      return true
    }
    return false
  }
  const toggleGroupDialog = () => {
    setGroupDialog(!groupDialog);
  }
  const handleTogglePurchasePage = (e: any) => {
    e.stopPropagation();
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    toggleGroupDialog();
  }
  if(groupDialog){
    return renderGroupClassesDialog();
  }
  if(showUtilizations){
    return <RenderUtilizedClasses />
  }
  let mininmumRolloverAppts=0
  if(selectedBillingPeriod){
    const totalUsedSchedules=selectedBillingPeriod?.UsedRollOverSchedules+ selectedBillingPeriod?.UsedSchedules
    const check=totalUsedSchedules-selectedBillingPeriod?.Schedules
    if(check > 0){
      mininmumRolloverAppts=check
    }
  }
  const statusDescription=PackageInstanceStatus?.filter((i:any)=>i?.id===selectedPackage?.Status)?.[0]?.text
  return (
    <>
      <h5 className="mb-3 bz_fs_1_5">Packages Overview</h5>
      <div className="bz-subsection-row subscription-blk bz-pakage-overview">
        <div className="left-section">
          <h5 className="mb-3 pt-3 bz_fs_1_5">Subscription Info    <span
                          className={`badge badge-status-${statusDescription}`}
                        >
                          {statusDescription}
                        </span></h5>
          {renderSubscriptionDetails()}
          {selectedPackage?.NextRecurrence && props?.staffPage && <div className="applied-coupons">
                <h5 className="mb-3 pt-3 bz_fs_1_5">Applied Coupons</h5>
                <PackageCoupons
                  loading={loading}
                  status={selectedPackage?.Status}
                  showCoupon={showCoupon}
                  selectedPackage={selectedPackage}
                  setShowCoupon={setShowCoupon}
                  CouponsToAdd={CouponsToAdd}
                  handleCoupon={handleCoupon}
                  couponValue={couponValue}
                  CouponfilterChange={CouponfilterChange}
                  couponsList={couponsList}
                  toggleAddCouponDialog={toggleAddCouponDialog}
                  handleRemoveCouponItem={handleRemoveCouponItem}
                  couponsClass="bz-coupon-pkg"
                />
              </div>}
        </div>
        <div className="right-section mt-3">
          <div className="card bz-card bz-shadow bz-overview-cards bz-associated-groups bz-classes-tag-card">
            <div className="card-body">
              <div className="card-title">
                
                <div className="d-flex justify-content-between align-items-center">
                    <span>
                    Reedem for
                    </span>
                    {(!selectedPackage?.ExpireOn || moment().format("YYYY-MM-DD") < moment(selectedPackage?.ExpireOn).format("YYYY-MM-DD")) && ((selectedPackage?.SessionType === 1 && checkBillingCycleValidation()) || selectedPackage?.SessionType === 2) && (selectedPackage?.Status === PackageInstanceStatusEnum.ACTIVE || selectedPackage?.Status === PackageInstanceStatusEnum.FUTURENOTPAID || selectedPackage?.Status === PackageInstanceStatusEnum.FUTUREPAID || selectedPackage?.Status === PackageInstanceStatusEnum.CANCELLED) && <span className={"bz-schedule-link-btn2"}>
                      <Button
                        primary={false}
                        onClick={(e) => handleTogglePurchasePage(e)}
                      >
                        <i className="fas fa-user-plus"></i>Enroll
                      </Button>
                    </span>}
                  </div>
                </div>
              {renderAssociatedGS()}
            </div>
          </div>
        </div>
      </div>
      {couponAddVisible && (
          <Dialog
            className="bz-book-Appoinment-dialog bz-applyCoupon-dialog"
            title={"Are you sure you want to apply the selected coupon?"}
            onClose={() => toggleAddCouponDialog()}
            width={"30rem"}
          >
            {couponsList?.length > 0 && (<>
              <div className={"alert alert-warning py-2 px-3"}>
                <strong>Note:</strong> It will override if there is any existing same type of coupon.
              </div>
            </>)}
            <div className="ready-to-book-appt-dialog">
              <div className="column-1-section text-right">
                Name<span className="purchased-dots px-3">:</span>
              </div>
              <div className="column-2-section" style={{fontWeight:600}}>{couponValue?.Description}</div>
            </div>
            <DialogActionsBar>
              <Button onClick={() => handleAddCouponToPackage(false)}>
                {"Cancel"}
              </Button>
              <BuzopsButton
                label="Ok"
                loading={dialogLoading}
                disabled={dialogLoading}
                onClick={() => handleAddCouponToPackage(true)}
              />
            </DialogActionsBar>
          </Dialog>
        )}  
  
        {couponRemoveVisible && (
          <Dialog onClose={() => toggleRemoveCouponDialog()}>
            <p className={"dialogContainer"}>Are you sure to Deactivate ?</p>
            <DialogActionsBar>
              <Button onClick={() => handleRemoveCouponFromPackage(false)}>
                {"Cancel"}
              </Button>
              <BuzopsButton
                label="Ok"
                loading={dialogLoading}
                disabled={dialogLoading}
                onClick={() => handleRemoveCouponFromPackage(true)}
              />
            </DialogActionsBar>
          </Dialog>
        )} 
            {showRolloverDialog && (
          <Dialog
            className="bz-book-Appoinment-dialog"
            title={"Adjust Rollover Appointments"}
            onClose={() => handleCancelRollOver()}
            width={"35%"}
          >
            <div className="ready-to-book-appt-dialog pb-1">
              <div className="column-1-section">
                Start Date<span className="purchased-dots">:</span>
              </div>
              <div className="column-2-section">
              
              {selectedBillingPeriod?.EffectiveFrom
          ? moment(selectedBillingPeriod?.EffectiveFrom).format("L")
          : "NA"}
              </div>
            </div>
            <div className="ready-to-book-appt-dialog pb-1">
              <div className="column-1-section">
                Next Renewal Date<span className="purchased-dots">:</span>
              </div>
              <div className="column-2-section">
              {selectedBillingPeriod?.RenewalOn
          ? moment(selectedBillingPeriod?.RenewalOn).format("L")
          : "NA"}
              </div>
            </div>
            <div className="ready-to-book-appt-dialog pb-1">
              <div className="column-1-section">
                Appointments<span className="purchased-dots">:</span>
              </div>
              <div className="column-2-section">{`${selectedBillingPeriod?.UsedSchedules} of ${selectedBillingPeriod?.Schedules}`}</div>
            </div>
            <div className="ready-to-book-appt-dialog pb-1">
              <div className="column-1-section">
                Rollover Appoinments<span className="purchased-dots">:</span>
              </div>
              <div className="column-2-section">
                <NumericTextBox
                  value={rolloverAppointment}
                  name={"rolloverAppointments"}
                  id={"rolloverAppointments"}
                  placeholder="Appointments"
                  min={0}
                  format={'n0'}
                  onChange={(e) => {
                    setRolloverAppointment(e.target.value);
                  }}
                />
                <Error>{rolloverAppointment < mininmumRolloverAppts && "The Rollover schedules can't be reduced as utilized schedules with in the billing period"}</Error>
                </div>
            </div>
            <p className={"dialogContainer"}>
            <Notify key="info" type={{ style: "info" }}>Note: Adjust appointments for the current billing cycle within the current period, while any remaining appointments will roll over according to the configured rollover settings</Notify>
            </p>
            <DialogActionsBar>
              <Button onClick={() => handleCancelRollOver()}>
                {"Cancel"}
              </Button>
              <BuzopsButton
                label="Ok"
                loading={rolloverLoading}
                disabled={rolloverLoading}
                onClick={() => handleUpdateRollOver()}
              />
            </DialogActionsBar>
          </Dialog>
        )} 
        
    </>
  );
};

export default withNotification(PackageOverView)
